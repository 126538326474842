import React from 'react';
import { formatDate } from 'client/helpers';
import imageNull from 'client/img/vacancy-no-image.png?url';
import { Picture } from 'components';
import './style.scss';

export class VacancyCard extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { vacancy, onClick } = this.props;

		return (
			<div className='vacancy-item p3' onClick={onClick}>
				<div className='text-vacancy'>
					<p className='title h7'>{vacancy?.title}</p>
					<p className='initiator p2'>{vacancy.organization?.name}</p>
					<div className='location-date'>
						{vacancy.county?.name ? <p className="location p3">{vacancy.county?.name}</p> : ''}
						{vacancy.publishAt &&
							<p className="date p3">Дата публикации: {formatDate(vacancy.publishAt, 'dd.MM.yyyy')}</p>}
					</div>
				</div>
				<div className='image-vacancy-wrapper'>
					<Picture src={vacancy.image ? vacancy?.downloadFile('image') : imageNull} className='image-vacancy' width={64} height={64} />
				</div>
			</div>
		);
	}
}
