import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Picture } from 'components';

@observer
export default class MediaSlide extends React.Component {

	constructor(props) {
		super(props);
	}


	loadRecord = (record) => {
		if(this.props.setActiveSlide) this.props.setActiveSlide(record);
	};


	render() {
		const { attachment } = this.props;
		const className = classNames(`media-slider-small`, `place-0`);
		return (
			<>
				<div className='media-slider-container'>
					<div onClick={() => this.loadRecord(attachment)} className={className}>
						{attachment && <Picture src={attachment.downloadFile('filename')} alt='media-slider-image' />}
					</div>
				</div>
			</>
		);
	}
}
