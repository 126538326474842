import { observer } from 'mobx-react';
import React from 'react';
import { RecordSelect, Select, Tab, Tabs } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';
import t from 'i18n';
import { CATEGORY_URL } from 'client/pages/search/constants';
import { Desktop, Mobile, NotPhone, Phone } from 'components';
import FiltersMobile from 'client/pages/search/filters/FiltersMobile';

@observer
export default class Filters extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { filter, onChangeFilter, totalCount, records, goToCategory } = this.props;
		const { county, order } = filter;
		const tabs = Object.entries(CATEGORY_URL);

		return (
			<div className='search-filters'>
				<Tabs type='manual'>
					<Tab
						title={
							<>
								<span className='tab-category'>{t('all')}</span>
								<span> {totalCount}</span>
							</>
						}
					/>
					{tabs.map(([category, path]) => (
						<Tab
							title={
								<>
									<span className='tab-category'>{t(`searchPage.${category}`)}</span>
									<span> {records ? records[category].totalCount : 0}</span>
								</>
							}
							onClick={() => goToCategory(path)}
							key={category}
						/>
					))}
				</Tabs>
				<div className='selectors'>
					<NotPhone>
						<RecordSelect model={store.model.County} property='name' placeholder={'Районы'} value={county} onChange={(r) => onChangeFilter('county', r)} />
					</NotPhone>
					<Phone>
						<FiltersMobile onChangeFilter={onChangeFilter} />
					</Phone>
					<div className='sort'>
						<span>Сортировка: </span>
						<Select
							items={[
								{ label: 'По дате (по возрастанию)', value: 'createdAt asc' },
								{ label: 'По дате (по убыванию)', value: 'createdAt desc' },
							]}
							value={order}
							isRequired
							onChange={(r) => onChangeFilter('order', r)}
							className='drop-down-sm'
							placeholder={'По дате'}
						/>
					</div>
				</div>
			</div>
		);
	}
}
