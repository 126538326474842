import Overlay from 'ol/Overlay';
import { get as getProj } from 'ol/proj';

import './tip-leg.svg?url';
import './style.scss';

export default class WeatherTip {

	element = null;
	
	constructor(store) {
		this.map = store.map;
		this.store = store;
		const extent = getProj('EPSG:3857').getExtent();
		this.maxExtent = extent[2];
		this.create();
	}
	
	update = (coords, weatherImage, weatherLayerConfig, colorRamp) => {
		const { width, height } = weatherImage;
		let { type, min, max, convert, measure } = weatherLayerConfig;
		if (type === 'silam') {
			min = this.store.silamMin;
			max = this.store.silamMax;
		}

		let [lon, lat] = coords;
		lon = lon + this.maxExtent;
		lat = this.maxExtent - lat;

		let x = Math.round(width * lon / (this.maxExtent * 2)) + width;
		let y = Math.round(height * lat / (this.maxExtent * 2));
		if (x > width) x %= width;

		const imgCtx = weatherImage.getContext('2d');

		let byteValue = 0;

		const imgData = imgCtx.getImageData(x - 1, y - 1, 3, 3);
		for (let i = 0; i < 9; i++) {
			byteValue += imgData.data[i * 4];
		}
		byteValue = Math.floor(byteValue / 9);

		// const imgData = imgCtx.getImageData(x, y, 1, 1);
		// byteValue = imgData.data[0];

		let value = (max - min) * byteValue / 255 + min;
		if (convert) value = convert(value);

		const r = colorRamp[byteValue * 4];
		const g = colorRamp[byteValue * 4 + 1];
		const b = colorRamp[byteValue * 4 + 2];

		// const colorDisc = this.element.getElementsByClassName('weather-tip-color')[0];
		// colorDisc.style.background = `rgb(${r},${g},${b})`;

		// console.log('>', value, measure);

		const text = this.element.getElementsByClassName('weather-tip-text')[0];
		text.innerHTML = value + (measure ? ' ' + measure : '');
		this.overlay.setPosition(coords);

		this.element.classList.remove('hidden');

		return { value, measure, r, g, b };
	};

	hide = () => {
		if (this.element) {
			this.element.classList.add('hidden');
		}
	};

	create = () => {
		const tipContainer = document.createElement('div');
		tipContainer.className = 'weather-tip hidden';

		const container = document.createElement('div');
		container.className = 'weather-tip-content';
		tipContainer.appendChild(container);

		// const colorDisc = document.createElement('div');
		// colorDisc.className = 'weather-tip-color';
		// container.appendChild(colorDisc);

		const text = document.createElement('div');
		text.className = 'weather-tip-text';
		container.appendChild(text);

		const leg = document.createElement('div');
		leg.className = 'weather-tip-leg';
		tipContainer.appendChild(leg);

		this.overlay = new Overlay({
			element: tipContainer,
			autoPan: false,
		});

		if (tipContainer.parentNode) tipContainer.parentNode.style.zIndex = 10;

		this.map.addOverlay(this.overlay);
		this.element = tipContainer;
	};

}