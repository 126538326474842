import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumbs, Picture, SmallArticle, SocialSinglePage } from 'components';
import { formatDate } from 'helpers';
import store from 'client/store';
import './style.scss';
import t from 'i18n';
import { PageNotFound } from '../../404';
import ArrowLeft from '-!svg-react-loader?name=Icon!client/img/icon-arrow_left-black.svg';
import ArrowRight from '-!svg-react-loader?name=Icon!client/img/icon-arrow_right.svg';
import MediaSlider from './MediaSlider';
import { SearchPage } from 'components/search-page';
import classNames from 'classnames';
import StarsLeftIcon from 'img/pattern-stars-left2.svg';
import AllSitesIcon from 'img/icon-all_sites-grey.svg';
import SearchInput from 'components/search-page/SearchInput';
import StarsRightIcon from 'img/pattern-stars-right.svg';

@withRouter
@observer
export default class Article extends React.Component {
	static propTypes = {
		comments: PropTypes.bool,
		social: PropTypes.bool,
		more: PropTypes.bool,
	};

	static defaultProps = {
		comments: true,
		social: true,
		more: true,
	};

	@observable article = null;
	@observable latestArticles = null;
	@observable sameTopicArticles = null;
	@observable prevArticle = null;
	@observable error = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.id !== prevProps.match.params.id || this.props.id !== prevProps.id) {
			this.init();
		}
	}

	init = async () => {
		this.id = parseInt(this.props.match.params.id);
		const model = store.model.Article;
		let organizationId = store.ui.site.organizationId;
		let siteId = store.ui.site.id;
		if (this.id) {
			try {
				this.article = await store.model.ViewArticle.findById(this.id, {
					include: [
						{ relation: 'category', scope: { fields: ['id', 'name', 'path', 'fields'] } },
						{ relation: 'topics', scope: { fields: ['id', 'name'] } },
					],
				});
				this.latestArticles = await model.find({
					where: { id: { neq: this.article.id }, organizationId, siteId },
					limit: 6,
					order: 'publishAt desc',
				});
				this.sameTopicArticles = await model.find({
					where: { topicId: this.article.topicId, id: { neq: this.id }, organizationId, siteId },
					limit: 3,
				});
				const prevArticle = await model.find({
					where: {
						id: { neq: this.article.id, publishAt: { lte: this.article.publishAt } },
						organizationId,
						siteId,
					},
					limit: 1,
					order: 'publishAt desc , id desc',
				});
				this.prevArticle = prevArticle[0];
				this.mainSite = (await store.model.Site.find({ where: { isMain: true, publish: true } }))?.[0];
			} catch (e) {
				this.error = e.message;
			}
		} else {
			this.error = 'Страница не найдена';
		}
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		if (this.error) return <PageNotFound />;

		const { social } = this.props;

		const crumbs = [];
		if (this.article.categoryId !== 1) {
			crumbs.push({ title: this.article.category.name, path: '/' + this.article.category.path });
		} else {
			crumbs.push({
				title: this.article.countyId !== 1 ? 'Новости района' : 'Новости',
				path: '/' + this.article.category.path,
			});
		}
		crumbs.push({ title: this.article.title });
		const fields = this.article.category.fields || {};

		// Убираем &nbsp если есть и добавляем margin к стилям тега p. Т_т
		const text = this.article.text;
		const inlineStyles = {
			paragraph: 'margin-bottom: 20px;',
		};

		const formattedText = text.replace(/<p>&nbsp;<\/p>/g, '<p></p>').replace(/<p[^>]*>/g, (match) => {
			const existingStyle = match.match(/style="([^"]*)"/);
			const existingStyleAttribute = existingStyle ? existingStyle[1] : '';
			const newStyleAttribute = `${existingStyleAttribute}${inlineStyles.paragraph}`;
			return `<p style="${newStyleAttribute}">`;
		});

		const searchPage = classNames('search-page', { 'search-page-with-crumbs': crumbs });

		return (
			<>
				{/*<SearchPage headerName={this.article.title} noSearch crumbs={crumbs} />*/}
				<div className='page-content-single article-page-container'>
					<div className='article-page'>
						<div className='wrapper-top-content'>
							<div className={searchPage}>
								<div className='content'>
									<div className='wrapper-category-and-crumbs'>
										{crumbs && <Breadcrumbs crumbs={crumbs} />}
										<div className='right templates-tabs'>
											<a href={'https://' + this.mainSite?.domain} className='portal-link'>
												<AllSitesIcon />
												<div className='link-text'>{t('goPortal')}</div>
											</a>
										</div>
									</div>
									<div className='search-title-line'>
										<span className='h1 header'>{this.article.title}</span>
									</div>
								</div>
								<StarsRightIcon className='stars-right' />
							</div>
						</div>
						<div className='info'>
							<div className='left'>
								{fields.publishAt && fields.publishAt.active && <span className='date p4'>{formatDate(this.article.publishAt, 'dd MMMM yyyy HH:MM')}</span>}
								{((fields.date0 && fields.date0.active) || (fields.date0 && fields.date0.active)) && (
									<span className='date p4'>
										{this.article.date0 ? formatDate(this.article.date0, 'dd MMMM yyyy') : ''}
										{this.article.date0 && this.article.date1 ? ' - ' : ''}
										{this.article.date1 ? formatDate(this.article.date1, 'dd MMMM yyyy') : ''}
									</span>
								)}
								| <span className='date-topic p4'>{this.article?.topics?.name}</span>
							</div>
						</div>
						<div className='article-container'>
							<div className='article'>
								{fields.topic && fields.topic.active ? (
									<div className='topics'>
										<Topic article={this.article} />
									</div>
								) : null}
								{this.article.image && (
									<>
										<div className='image'>
											<Picture src={this.article.downloadFile('image')} alt='' />
										</div>
									</>
								)}

								<div className='content'>
									{this.article.subtitle && (
										<div className='subtitle'>
											<p>
												<strong>{this.article.subtitle}</strong>
											</p>
										</div>
									)}
									<div className='text' dangerouslySetInnerHTML={{ __html: formattedText }} />
									<MediaSlider articleId={this.id} path={`/news/${this.id}`} modelName={'Article'} />
									{fields.labels && fields.labels.active ? (
										<div className='labels'>{this.article.labelNames && this.article.labelNames.map((label) => <div className='label'>{label}</div>)}</div>
									) : null}
									<div className='footer'>
										{this.article.author && <div className='author'>{this.article.author}</div>}
										<div className='social-share'>
											<div>{t('share')}</div>
											{social && (
												<div className='social-bottom'>
													<SocialSinglePage title={this.article.title} />
												</div>
											)}
										</div>
									</div>
								</div>
								{this.sameTopicArticles?.length ? (
									<div className='more-container'>
										<div className='related-news'>
											<span className='p4'>{t('news.newsByTheme')}</span>
											<span className='line'></span>
										</div>
										<div className='news-more'>
											<div className='articles'>{this.sameTopicArticles && this.sameTopicArticles.map((article) => <SmallArticle article={article} />)}</div>
										</div>
									</div>
								) : (
									''
								)}
							</div>
						</div>
						<div className='back-forward'>
							<div>
								<Link to={'/news'} className='back'>
									<ArrowLeft className='arrow-left' />
									<span className='back-to-chapter p4'>{t('backToSection')}</span>
								</Link>
							</div>
							<div>
								{this.prevArticle && (
									<Link to={`/news/${this.prevArticle.id}`} className='forward'>
										<ArrowRight className='arrow-right' />
										<span className='forward-to-chapter p4'>{this.prevArticle.title}</span>
									</Link>
								)}
							</div>
						</div>
					</div>

					{/* SideBar */}
					<div className='sidebar'>
						<div className='sidebar-news'>
							<span className='p4'>{t('interesting')}</span>
							<span className='line'></span>
						</div>
						{this.latestArticles && this.latestArticles.map((article) => <SmallArticle key={article.id} article={article} />)}
					</div>
				</div>
			</>
		);
	}
}

const Topic = ({ article }) => {
	return article.topics().length
		? article.topics().map((topic, i) => {
				return (
					<Link to={`/${article.categoryPath}/topic/${topic.name}`}>
						<div key={i} className='topic'>
							{topic.name}
						</div>
					</Link>
				);
			})
		: null;
};
