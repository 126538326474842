export const temperatureColors = [
	'rgba(115,  70, 105, 1.0)',
	'rgba(202, 172, 195, 1.0)',
	'rgba(162,  70, 145, 1.0)',
	'rgba(143,  89, 169, 1.0)',
	'rgba(157, 219, 217, 1.0)',
	'rgba(106, 191, 181, 1.0)',
	'rgba(100, 166, 189, 1.0)',
	'rgba( 93, 133, 255, 1.0)',
	'rgba( 68, 125,  99, 1.0)',
	'rgba(128, 147,  24, 1.0)',
	'rgba(243, 183,   4, 1.0)',
	'rgba(232,  83,  25, 1.0)',
	'rgba( 71,  14,   0, 1.0)',
];

export const pressureColors = [
	'rgba(8,   16,  48,  1.0)',
	'rgba(0,   32,  96,  1.0)',
	'rgba(0,   52,  146, 1.0)',
	'rgba(0,   90,  148, 1.0)',
	'rgba(0,   117, 146, 1.0)',
	'rgba(26,  140, 147, 1.0)',
	'rgba(103, 162, 155, 1.0)',
	'rgba(155, 183, 172, 1.0)',
	'rgba(182, 182, 182, 1.0)',
	'rgba(176, 174, 152, 1.0)',
	'rgba(167, 147, 107, 1.0)',
	'rgba(163, 116, 67,  1.0)',
	'rgba(159, 81,  44,  1.0)',
	'rgba(142, 47,  57,  1.0)',
	'rgba(111, 24,  64,  1.0)',
	'rgba(48,  8,   24,  1.0)',
];

export const silamColorsOld = {
	0.0: 'rgba(  0,  89, 161, 0.8)',
	0.1: 'rgba(255, 187,   1, 1.0)',
	0.3: 'rgba(255, 140,   0, 1.0)',
	0.5: 'rgba(237,  14,   5, 1.0)',
	0.8: 'rgba( 80,   0,   0, 1.0)',
};

export const silamColors = {
	colors: [
		[    0, [   0, 102, 151, 220 ]],
		[   10, [ 125, 182, 209, 255 ]],
		[   15, [ 170, 183, 189, 255 ]],
		[   25, [ 194, 195, 125, 255 ]],
		[  150, [ 232,  83,  25, 255 ]],
		[  200, [ 189,  52,  19, 255 ]],
		[  300, [  75,  12,   0, 255 ]],
		[ 1200, [  75,  12,   0, 255 ]],
	],
	scale: [
		[    0, 0.00, [   0, 102, 151, 255 ]],
		[   10, 0.25, [ 125, 182, 209, 255 ]],
		[   20, 0.50, [ 194, 195, 125, 255 ]],
		[  100, 0.75, [ 232,  83,  25, 255 ]],
		[ 1000, 1.00, [  75,  12,   0, 255 ]],
	],
};

export const ozoneColors = [
	'rgba(53,25,47,1)',
	'rgba(162,70,145,1)',
	'rgba(110,81,217,1)',
	'rgba(79,151,193,1)',
	'rgba(82,203,167,1)',
	'rgba(59,197,67,1)',
	'rgba(231,174,5,1)',
	'rgba(232,83,25,1)',
];

export const precipitationColors = [
	'rgba( 68, 125,  99, 0.0)',
	'rgba(  0, 120, 250, 0.5)',
	'rgba(  0,  80, 190, 1.0)',
];

export const humidityColors = [
	'rgba(255, 180,   0, 1.0)',
	'rgba(  0, 165, 180, 1.0)',
	'rgba(  0, 165, 180, 1.0)',
	'rgba(  0,  64, 145, 1.0)',
];
