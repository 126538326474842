import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import './style.scss';
import { AutoAnimate } from 'components';
import ArrowDown from '../../img/arrows/arrow-down.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';

@observer
export default class ListItem extends React.Component {
	static propTypes = {
		enableAccordion: PropTypes.bool,
		countChildItem: PropTypes.number,
	};

	static defaultProps = {
		enableAccordion: true,
		countChildItem: 5,
	};

	@observable showAll = false;

	constructor(props) {
		super(props);
	}

	toggleShow = () => {
		this.showAll = !this.showAll;
	};

	getRecordField = (record, fields) => {
		return fields.find((field) => record[field]);
	};

	generateLinkPath = (record) => {
		const { module, link } = record;
		if (!module) return '/';

		if (module === 'documents' && record.subject) return `${module}/subject/${record.subject.code}`;
		if (module === 'question-answer' && record.subCategory) return `${module}/subCategory/${record.subCategory.code}`;
		return link || module;
	};

	generateName = (record) => {
		const field = this.getRecordField(record, ['subCategory', 'subject']);
		return field ? record[field].name : record.name || t(`sites.${record.module}`);
	};

	render() {
		const { item, enableAccordion, countChildItem, isFooter, portalIsShowAll } = this.props;

		let title = t(`sites.${item.module}`);
		if (item?.subject?.name && item.module === 'documents') title = item?.subject?.name;
		if (item?.subCategory?.name && item.module === 'question-answer') title = item?.subCategory?.name;

		const linkToChoice = this.generateLinkPath(item);
		const { showAll, toggleShow } = this;
		let childItemCount = countChildItem;
		if (portalIsShowAll) {
			childItemCount = item.children.length;
		}
		const childItems = showAll || !enableAccordion ? item.children : item.children.slice(0, childItemCount);
		return (
			<div className='portals-map-item' key={item.id}>
				{item.type !== 'module' ? (
					<div className='portals-list-item-wrapper'>
						<Link to={item.link} className='portals-list-item'>
							<div className='portals-list-item-text'>{item.name || (item.module && t(`sites.${item.module}`))}</div>
						</Link>
					</div>
				) : (
					<div className='portals-list-item-wrapper'>
						<Link to={`/${linkToChoice}`} className='portals-list-item'>
							<div className='portals-list-item-text'>{title}</div>
						</Link>
					</div>
				)}

				{!isFooter && (
					<AutoAnimate className='wrapper-submenu'>
						{childItems.map((child, index) => {
							let title = t(`sites.${child.module}`);
							if (child?.subject?.name && child.module === 'documents') title = child?.subject?.name;
							if (child?.subCategory?.name && child.module === 'question-answer') title = child?.subCategory?.name;
							return (
								<div className='portals-map-item-content portals-map-item-content-children' key={index}>
									<Link to={`/${this.generateLinkPath(child)}`} className='portals-map-item-content-item'>
										{title}
									</Link>
								</div>
							);
						})}
						{item.children.length > countChildItem && enableAccordion && !portalIsShowAll && (
							<p
								className={classNames({
									'show-all-submenu-items': true,
									active: showAll,
								})}
								onClick={toggleShow}
							>
								<ArrowDown />
								{showAll ? t('subMenu.hide') : t('subMenu.more')}
							</p>
						)}
					</AutoAnimate>
				)}
			</div>
		);
	}
}
