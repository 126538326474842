import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { Popover } from '@smartplatform/ui';

const Hint = ({ text, trigger }) => {
	const props = {
		content: text,
		className: 'filter-hint',
		contentClassName: 'filter-hint-popup',
		offset: 5,
		position: 'topLeft',
		trigger: trigger || 'click',
	};

	return <Popover {...props}><FontAwesomeIcon icon={faInfoCircle}/></Popover>;
}

export default Hint;
