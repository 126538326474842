import React from 'react';
import VacancyStore from 'client/pages/vacancy/store';
import t from 'i18n';
import { Button, Picture } from 'components';
import ExternalLink from 'helpers/externalLink';
import IconUpload from 'img/icon-upload.svg';
import { formatDate } from 'helpers';
import imageNull from 'client/img/vacancy-no-image-big.png?url';
import './style.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { SvgIcon } from 'components/svgIcon';
import { Loader } from '@smartplatform/ui';
@observer
export default class MobileSinglePage extends React.Component{

	@observable vacancy = null;

	constructor(props) {
		super(props);
		this.store = new VacancyStore(this.props.match.params.id, new URLSearchParams(this.props.location.search).get('search'));
		this.init();
	}

	init = async () => {
		await this.store.loadVacancy(this.props.match.params.id);
		this.vacancy = this.store.vacancy;
		console.log('this.vacancy', this.vacancy);
	}

	render(){

		if(!this.vacancy) return <div className='wrapper-center-content-vacancy'><Loader /></div> ;

		return (
			<>
				<div className='vacancy-page'>
					<Link to='/vacancy' className='wrapper-back'>
						<SvgIcon name='left-arrow' />
						{t('vacancy.titles')}
					</Link>
					<div className="text-vacancy">
						<div className="vacancy-item">
							<div className="top-content-vacancy">
								<div>
									<p className="initiator p2">{this.vacancy?.organization?.name}</p>
								</div>
								<div>
									<p className="title h7">{this.vacancy?.title}</p>
								</div>
								<div className="category p2">{this.vacancy?.branch?.name}</div>
							</div>
						</div>
						{this.vacancy?.image ? (
							<Picture src={this.vacancy.downloadFile('image')} width={142} height={142} className="image-vacancy"
											 alt="no-image" />
						) : (
							<div className="image-vacancy">
								<Picture src={imageNull} width={142} height={142} alt="no-image" />
							</div>
						)}
					</div>
					<div className="wrapper-additional-info">
						<div className="status-county">
							<div className="status-county-item">
								<div className="title-status-county p4">{t('status')}</div>
								<p className="p2">{this.vacancy?.status?.name}</p>
							</div>
							<div className="status-county-item2">
								<div className="title-status-county p4">{t('county.title')}</div>
								<p className="p2">{this.vacancy?.county?.name}</p>
							</div>
						</div>
						<div className="education-work_experience">
							<div className="education-work_experience-item">
								<div className="title-education-work_experience p4">{t('vacancy.education')}</div>
								<p className="p2">{this.vacancy?.education?.name}</p>
							</div>
							<div className="education-work_experience-item2">
								<div className="title-education-work_experience p4">{t('vacancy.workExp')}</div>
								<p className="p2">{this.vacancy?.workExp?.name}</p>
							</div>
						</div>
					</div>
					<div className="other-vacancy">
						<div className="margin_bottom">
							<div className="description p4">{t('description')}</div>
							<div className="p2" dangerouslySetInnerHTML={{ __html: this.vacancy?.description }} />
						</div>
						<div className="margin_bottom">
							<div className="footing p4">{t('vacancy.basis')}</div>
							<div className="footing-item p2">
								<ExternalLink to={this.vacancy?.linkBasis}>{this.vacancy?.basis}</ExternalLink>
							</div>
						</div>
						<div className="margin_bottom">
							<div className="origin-source p4">{t('vacancy.primarySourceSite')}</div>
							<div className="origin-source-item p2">
								<ExternalLink to={this.vacancy?.linkPrimarySourceSite}>{this.vacancy?.primarySourceSite}</ExternalLink>
							</div>
						</div>
						<div className="margin_bottom">
							<div className="contacts p4">{t('contacts')}</div>
							<div className="p2">{this.vacancy?.contacts}</div>
						</div>
						<div className='margin_bottom'>
							<div className='contacts p4'>{t('vacancy.contactPerson')}</div>
							<p className='status p2'>
								<ExternalLink to={this.vacancy?.linkContactPerson}>{this.vacancy?.contactPerson}</ExternalLink>
							</p>
						</div>
						<div className='margin_bottom'>
							<div className='app p4'>{t('application')}</div>
							<div className='application'>
								{this.vacancy?.attachments().map((a) => (
									<a className='docs' target='_blank' href={a.downloadFile('filename')} download key={a.id}>
										<div className='file-name p2'>{a.filename}</div>
										<IconUpload />
									</a>
								))}
							</div>
						</div>
						<div className='date margin_bottom'>
							<div className='p2'>
								{t('publishAt')}: {formatDate(this.vacancy?.publishAt, 'dd.MM.yyyy')}
							</div>
							<div className='p3'>
								{t('updatedAt')}: {formatDate(this.vacancy?.updatedAt, 'dd.MM.yyyy')}
							</div>
						</div>

						<div className='button margin_bottom'>
							<ExternalLink to={this.vacancy?.linkButton}>
								<Button className='button-1 p2'>{t('vacancy.respond')}</Button>
							</ExternalLink>
						</div>
					</div>
				</div>
			</>
		);
	}

}