import React, { Component } from 'react';
import './style.scss';
import { observer, Provider } from 'mobx-react';
import SupportMeasureStore from '../store';
import { Tab, Tabs } from '@smartplatform/ui';
import { t } from 'i18next';
import { observable } from 'mobx';
import store from 'client/store';
import { NotPhone, Phone, SupportMeasureSmall, AutoAnimate, ShowMoreButton } from 'components';
import { Link } from 'react-router-dom';
import { SearchPage } from 'components/search-page';

@observer
export default class SupportMeasure extends Component {
	@observable labels = [];
	@observable topics = [];
	@observable search = '';
	@observable recordFiltered = [];
	@observable records = [];

	constructor(props) {
		super(props);
		this.store = new SupportMeasureStore();
		this.supportMeasureLabels();
		this.supportMeasureTopics();
	}

	componentDidUpdate() {
		this.records = this.store.records;
	}

	supportMeasureLabels = async () => {
		this.labels = await store.model.SupportMeasureLabel.find({
			includes: [{ relation: 'labels', scope: { fields: ['id', 'name'] }, limit: 10 }],
		});
	};

	supportMeasureTopics = async () => {
		this.topics = await store.model.SupportMeasure.find({
			includes: [{ relation: 'topics', scope: { fields: ['id', 'name'] }, limit: 10 }],
		});
	};

	handleSearchQuery = (record) => {
		this.search = record.toLowerCase();
		this.filterRecords();
	};

	filterRecords = () => {
		this.recordFiltered = this.records.filter((data) => data.title.toLowerCase().includes(this.search));
	};

	searchFilterItems = () => {
		return this.store.records;
	};

	resetAllTags = () => {
		this.store = new SupportMeasureStore();
		this.records = [];
		this.records = this.store.records;
	};

	render() {
		const { setTab, tab, onChangeFilter, isLoading, totalCountSupportMeasure, showMoreSupportMeasure, recordsPopular } = this.store;
		const allTopic = [...new Set(this.topics.map((rec) => rec.topics))];

		// убираем повторяющиеся теги для sidebar
		const tags = [...new Set(this.labels.map((r) => r?.label))];

		const tabsContent = (
			<Tabs type='manual'>
				<Tab title={t('supportMeasure.all')} onClick={() => setTab('all')} />
				{allTopic.map((topic, index) => {
					return <Tab title={topic?.name} key={index} onClick={() => onChangeFilter('topic', topic)} />;
				})}
			</Tabs>
		);
		return (
			<Provider store={this.store}>
				<>
					<SearchPage
						headerName={t('supportMeasure.title')}
						placeholder={t('supportMeasure.searchBySupportMeasure')}
						search={this.search}
						onChangeSearch={(r) => this.handleSearchQuery(r)}
					/>
					<div className='support-measure-page'>
						<div className='support-measure-container'>
							<div className='support-measure-item'>
								<NotPhone>{tabsContent}</NotPhone>
								{tab === 'all' && (
									<AutoAnimate className='support-measure-all'>
										{!this.isLoading && this.search === '' && this.records
											? this.records.map((rec) => (
													<Link to={`/support-measure/${rec.id}`} key={rec.id}>
														<div className='support-measure-items'>
															<div className='topic'>{rec.topics?.name}</div>
															<div className='title'>{rec?.title}</div>
															<div className='description' dangerouslySetInnerHTML={{ __html: rec?.description?.replace(/(<([^>]+)>)/gi, '') }} />
														</div>
													</Link>
											  ))
											: this.recordFiltered &&
											  this.recordFiltered.map((rec) => (
													<Link to={`/support-measure/${rec.id}`} key={rec.id}>
														<div className='support-measure-items'>
															<div className='topic'>{rec.topics?.name}</div>
															<div className='title'>{rec?.title}</div>
															<div className='description' dangerouslySetInnerHTML={{ __html: rec?.description.replace(/(<([^>]+)>)/gi, '') }} />
														</div>
													</Link>
											  ))}
									</AutoAnimate>
								)}
								{this.records.totalCount > 6 && (
									<ShowMoreButton
										isLoading={isLoading}
										records={this.records}
										totalCount={totalCountSupportMeasure}
										showMore={showMoreSupportMeasure}
										formsArray={[t('supportMeasure.formsArrayFirst'), t('supportMeasure.formsArraySecond'), t('supportMeasure.formsArrayThird')]}
									/>
								)}
							</div>

							{/* SideBar */}
							<div>
								<div className='sidebar'>
									<div className='sidebar-support-measure'>
										<span className='p4'>{t('tag.plural')}</span>
										<span className='line'></span>
									</div>
									<div className='tags-container'>
										{tags &&
											tags.map((tag) => (
												<div className='tags p1' key={tag?.id} onClick={() => onChangeFilter('label', tag)}>
													{'#' + tag?.name}&nbsp;
												</div>
											))}
									</div>
									<div className='right p1' onClick={this.resetAllTags}>
										{t('tag.all')}
									</div>
								</div>
								<div className='sidebar'>
									<div className='sidebar-support-measure'>
										<span className='p4'>{t('popular')}</span>
										<span className='line'></span>
									</div>
									<div className='popular-support-measure'>
										{recordsPopular &&
											recordsPopular.map((rec) => {
												return <SupportMeasureSmall key={rec.id} records={rec} />;
											})}
									</div>
								</div>
							</div>

							{/* Tabs mobile */}
							<Phone>{tabsContent}</Phone>
						</div>
					</div>
				</>
			</Provider>
		);
	}
}
