import { inject, observer } from 'mobx-react';
import React from 'react';
import { RecordSelect, Select } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@inject('store')
@observer
export default class Filters extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { filter, onChangeFilter } = this.store;
		const { status, organization, order } = filter;
		return (
			<div className='vacancy-filters'>
				<div className='vacancy-filters__wrapper'>
					<div className='left'>
						<Select
							items={[
								{ label: 'День', value: 'day' },
								{ label: 'Неделя', value: 'week' },
								{ label: 'Месяц', value: 'month' },
								{ label: 'За всё время', value: 'year' },
							]}
							placeholder={'За всё время'}
							value={filter.calendar}
							isRequired
							onChange={(x) => onChangeFilter('calendar', x)}
						/>
						<RecordSelect
							model={store.model.VacancyStatus}
							property='name'
							placeholder={t('status')}
							value={status}
							onChange={(r) => onChangeFilter('status', r)}
						/>
						<RecordSelect
							model={store.model.Organization}
							property='name'
							placeholder={t('organization.title')}
							value={organization}
							onChange={(r) => onChangeFilter('organization', r)}
						/>
					</div>
					<div className='right'>
						<span>Сортировка: </span>
						<Select
							items={[
								{ label: 'По дате (по возрастанию)', value: 'publishAt asc nulls last' },
								{ label: 'По дате (по убыванию)', value: 'publishAt desc nulls last' },
							]}
							value={order}
							isRequired
							onChange={(r) => onChangeFilter('order', r)}
							placeholder={'По дате'}
						/>
					</div>
				</div>
			</div>
		);
	}
}
