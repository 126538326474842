import { observable } from 'mobx';
import store from 'client/store';
import { getModelWhere } from 'helpers';
import { endOfDay, endOfMinute } from 'date-fns';

const PER_PAGE = 9;
export default class NewsStore {
	@observable filter = {
		county: null,
		topic: null,
		appurtenance: null,
	};

	@observable records = null;
	@observable eventsByDate = null;
	@observable isLoading = true;
	@observable smallTab = 'last';

	constructor() {
		this.init();
		this.initSmall();
	}

	init = async () => {
		const model = store.model.ViewArticle;
		const isMainShowAll = false;
		const where = getModelWhere(model, isMainShowAll);
		this.filter.county && where.and.push({ countyId: this.filter.county.id });
		this.filter.topic && where.and.push({ topicId: this.filter.topic.id });
		this.filter.appurtenance && where.and.push({ appurtenanceId: this.filter.appurtenance.id });
		where.and.push({ publishAt: { lt: endOfMinute(new Date()) } });
		this.records = await model.find({
			where,
			include: ['labels', 'magazine', 'county', 'topics', 'category', 'appurtenance'],
			limit: PER_PAGE,
			order: ['priority desc', 'publishAt desc'],
		});

		this.isLoading = false;
	};

	initSmall = async () => {
		this.isLoading = true;
		this.recordSmall = await store.model.Article.find({
			where: this.whereSmall,
			order: this.filter.order,
		});
		this.isLoading = false;
	};

	setTab = async (tab) => {
		this.tab = tab;
		if (tab === 'all') {
			this.filter.appurtenance = null;
		}
		await this.init();
	};

	setTabSmallArticle = async (smallTab) => {
		this.smallTab = smallTab;
		await this.init();
	};

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.init();
	};
}
