import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export default class Tooltip extends Component {
	@observable visible = false;

	static propTypes = {
		children: PropTypes.node.isRequired,
		content: PropTypes.string,
		position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
		style: PropTypes.objectOf(PropTypes.string),
	};

	static defaultProps = {
		content: 'Tooltip content',
		style: {},
		position: 'top',
	};

	show = () => {
		this.setVisibility(true);
	};

	hide = () => {
		this.setVisibility(false);
	};

	setVisibility = (visible) => {
		setTimeout(() => {
			this.visible = visible;
		}, 300);
	};

	render() {
		const { children, content, style, position } = this.props;
		const classes = classNames('tooltip', position);
		return (
			<span className='tooltip-wrapper'>
				{this.visible && content !== null && (
					<span style={style} className={classes}>
						{content}
					</span>
				)}
				<span className='targetElement' onMouseEnter={this.show} onMouseLeave={this.hide}>
					{children}
				</span>
			</span>
		);
	}
}
