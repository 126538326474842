import React from 'react';
import { DesktopMenu } from './menu';
import './InnerPageHeader.scss';
import Logo from './logo/Logo';
import { observer } from 'mobx-react';
import store from 'client/store';
import BadVisionPopup from 'components/header/bad-vision/BadVisionPopup';
import { SecondaryHeaderBottom } from './menu/SecondaryHeaderBottom';
import { debounce } from 'lodash';
import { NotPhone, Phone } from 'components';
import classNames from "classnames";

@observer
export class InnerPageHeader extends React.Component {
	constructor(props) {
		super(props);
		this.scroll = debounce(this.storeScroll);
	}

	storeScroll = () => {
		if (window.scrollY >= 90) {
			document.documentElement.classList.add('scroll-90');
		} else {
			document.documentElement.classList.remove('scroll-90');
		}
	};

	componentDidMount() {
		if (store.local.badVision) {
			document.addEventListener('scroll', this.scroll, { passive: true });
		}
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.scroll);
	}

	render() {
		const isMap = store.route.path.includes('/map');
		const isHome = store.route.path === '/';
		const wrapperClass = classNames('inner-page-header-container')

		return (
			<>
				<NotPhone>
					<div className={wrapperClass}>
						{store.local.badVision && (
							<div className='blind-popup-wrapper'>
								<BadVisionPopup />
							</div>
						)}
						<div className='inner-page-header-wrapper'>
							<div className='inner-page-header'>
								<Logo />
								<DesktopMenu className={'text-black'} deepBlue={'deep-blue'} />
							</div>
						</div>
						{(!isMap && !isHome) && (
							<>
								<SecondaryHeaderBottom />
							</>
						)}
					</div>
				</NotPhone>
				<Phone>
					<div className='inner-page-header-container'>
						<div className='inner-page-header-wrapper'>
							<div className='inner-page-header'>
								<Logo />
								<DesktopMenu className={'text-black'} deepBlue={'deep-blue'} />
							</div>
						</div>
					</div>
				</Phone>
			</>
		);
	}
}
