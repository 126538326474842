import React from 'react';
import { inject, observer } from 'mobx-react';
import './style.scss';
import { Loader } from '@smartplatform/ui';
import { EventCard, ShowMoreButton } from 'components';
import t from 'i18n';

@inject('store')
@observer
export class Announcement extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	onRowClick = (record) => {
		this.store.loadEvent(record.id);
	};

	render() {
		const { records, isLoading, showMoreEvents, recordsAll } = this.store;

		if (isLoading) return <Loader />;

		if (!records.length) return <div className='p1 no-record'>К сожалению, по вашему запросу не найдены события</div>;

		return (
			<>
				<div className='announcement'>
					{!isLoading && records.map((record) => <EventCard record={record} onClick={() => this.onRowClick(record)} key={record.id} />)}
				</div>
				<ShowMoreButton
					isLoading={isLoading}
					records={records}
					showMore={showMoreEvents}
					formsArray={[t('events.formsArrayFirst'), t('events.formsArraySecond'), t('events.formsArrayThird')]}
					totalCount={recordsAll.totalCount}
				/>
			</>
		);
	}
}
