import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EventsPage from './list/Events';

export const Events = () => (
	<Switch>
		<Route path='/events' exact component={EventsPage} />
		<Route path='/events/:id' component={EventsPage} />
	</Switch>
);
