import React from 'react';
import { Switch, Route } from 'react-router-dom';
import OpenDataPage from './OpenDataPage';
import OpenDatas from 'client/pages/open-data/OpenDatas';

export const OpenData = () => (
	<Switch>
		<Route path='/open-data' exact component={OpenDatas} />
		<Route path='/open-data/:id' component={OpenDataPage} />
	</Switch>
);
