import { observer } from 'mobx-react';
import React from 'react';
import { DatePicker, RecordSelect, Select } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';
import t from 'i18n';

@observer
export default class Filters extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	componentDidUpdate() {
		this.store = this.props.store;
	}

	render() {
		const { filter, onChangeFilter } = this.props.store;
		const { dataCategory, organization, order, actualDataAt } = filter;

		return (
			<div className='open-data-filters'>
				<div className='selectors'>
					<div className='category-organization-date-picker'>
						<RecordSelect
							model={store.model.OpenDataCategory}
							property='name'
							className='data-category-select'
							value={dataCategory}
							placeholder={t('category')}
							onChange={(r) => onChangeFilter('dataCategory', r)}
						/>
						<RecordSelect
							model={store.model.Organization}
							property='name'
							className='data-organization-select'
							value={organization}
							placeholder={t('organization.title')}
							onChange={(r) => onChangeFilter('organization', r)}
						/>
						<DatePicker
							className='date-picker'
							value={actualDataAt}
							placeholder={t('openData.actualDataAt')}
							onChange={(r) => onChangeFilter('actualDataAt', r)}
						/>
					</div>
					<div className='sort'>
						<span className='p2'>Сортировка по: </span>
						<Select
							items={[
								{ label: t('openData.byUpdateDateAscending'), value: 'updatedAt asc' },
								{ label: t('openData.byUpdateDateDescending'), value: 'updatedAt desc' },
							]}
							value={order}
							isRequired
							width={270}
							onChange={(r) => onChangeFilter('order', r)}
							className='drop-down-sm'
						/>
					</div>
				</div>
			</div>
		);
	}
}
