import React from 'react';
import StarIcon from 'img/star.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { numToString } from 'helpers';
import t from 'i18n';
import './rating.scss';

export class Rating extends React.Component {
	static propTypes = {
		rating: PropTypes.number.isRequired,
		starsNumber: PropTypes.number.isRequired,
		averageRating: PropTypes.number,
		ratingsNumber: PropTypes.number,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		starsNumber: 5,
	};

	onStarClick = (rating) => {
		if (this.props.rating !== rating && !this.props.disabled) {
			this.props.onClick?.(rating);
		}
	};
	render() {
		const { starsNumber, rating, averageRating, ratingsNumber, disabled } = this.props;

		return (
			<div className='rating-wrapper'>
				{!rating && <div className='putRating'>{t('putRating')}</div>}
				<div className='stars'>
					{[...new Array(starsNumber)].map((star, index) => {
						const isRatingFilled = rating > index;
						const isAverageFilled = Math.round(averageRating) > index;
						let className = 'star';
						if (disabled) className = classNames(className, 'disabled');

						const isAverageMode = Boolean(averageRating);
						if (isAverageMode) {
							if (isAverageFilled) className = classNames(className, disabled ? 'filled-dark' : 'filled-light');
						}
						if (isRatingFilled) className = classNames(className, { 'filled-dark': !isAverageMode }, 'filled-dark-hover');

						return <StarIcon key={index} className={className} onClick={() => this.onStarClick(index + 1)} />;
					})}
				</div>
				{averageRating && <div className='averageRating'>{averageRating}</div>}
				{!!ratingsNumber && (
					<div className='ratingsNumber'>
						({ratingsNumber} {numToString(ratingsNumber, [t('rating.one'), t('rating.two'), t('rating.five')])})
					</div>
				)}
			</div>
		);
	}
}
