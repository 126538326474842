import React from 'react';
import { useMediaQuery } from 'react-responsive';

const DESKTOP = 1248;

export const Desktop = ({ children }) => useMediaQuery({ minWidth: DESKTOP }) ? children : null;
export const Tablet = ({ children }) => useMediaQuery({ minWidth: 641, maxWidth: DESKTOP - 1 }) ? children : null;
export const Phone = ({ children }) => useMediaQuery({ maxWidth: 640 }) ? children : null;
export const NotPhone = ({ children }) => useMediaQuery({ minWidth: 641 }) ? children : null;
export const Mobile = ({ children }) => useMediaQuery({ maxWidth: DESKTOP - 1 }) ? children : null;
