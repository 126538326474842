import _format from 'date-fns/format';

export const formatDate = (date, format = 'dd.MM.yyyy HH:mm') => (date ? _format(new Date(date), format) : '');

export const dayTitle = (number) => {
	if (number > 10 && [11, 12, 13, 14].includes(number % 100)) return 'дней';
	let last_number = number % 10;
	if (last_number === 1) return 'день';
	if ([2, 3, 4].includes(last_number)) return 'дня';
	if ([5, 6, 7, 8, 9, 0].includes(last_number)) return 'дней';
};
