import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from '../../../store';
import PollResults from './PollResults';
import { Button, Input } from '@smartplatform/ui';

@observer
export default class Polls extends React.Component {
	@observable poll;
	@observable votes;
	@observable voted;

	@observable answers = {};
	@observable answersCustom = {};

	constructor(props) {
		super(props);
		this.poll = props.poll;
	}

	submit = async () => {
		const visitorId = store?.visitorId;
		let votes = [];

		for (const [key, values] of Object.entries(this.answers)) {
			let vote = null;
			if (Array.isArray(values)) {
				for (const value of values) {
					if (value === 'custom') {
						const answer = this.answersCustom[key] ?? '';
						vote = new store.model.PollVote({ pollId: key, answer, visitorId });
					} else {
						vote = new store.model.PollVote({ pollId: key, answer: value, visitorId });
					}
					votes.push(vote);
				}
			} else {
				if (values === 'custom') {
					const answer = this.answersCustom[key] ?? '';
					vote = new store.model.PollVote({ pollId: key, answer, visitorId });
				} else {
					vote = new store.model.PollVote({ pollId: key, answer: values, visitorId });
				}
				votes.push(vote);
			}
		}
		await Promise.all(votes.map((x) => x?.save()));
		this.props.init();
	};

	onChange = (id, name, multiselect) => {
		if (multiselect) {
			this.answers[id] = this.answers[id] || [];

			if (!this.answers[id].includes(name)) {
				this.answers[id].push(name);
			} else {
				this.answers[id].splice(this.answers[id].indexOf(name), 1);
			}
		} else {
			this.answers[id] = name;
		}
	};

	onChangeCustom = (id, fields, e) => {
		if (fields.length === 1) {
			this.answers[id] = 'custom';
		}
		this.answersCustom[id] = e.target.value;
	};

	render() {
		const { polls, isVoted } = this.props;

		let form = [];
		for (const poll of polls) {
			const fields = poll.fields();

			if (isVoted) {
				form.push(<PollResults poll={poll} key={poll.id}/>)
			} else {
				form.push(
					<div key={poll.id} className={`discussion-poll ${poll.hasImage ? 'has-image' : ''}`}>
						<div className='discussion-poll-name'>{poll.name}</div>
						<form>
							{fields.map((field) => (
								<div className='poll-form-field' key={`vote-${field.id}`}>
									{poll.hasImage && (
										<div className='image'>
											<img src={field.downloadFile('image')} alt='' />
										</div>
									)}
									{field.user ? (
										<div className='d-flex align-items-center'>
											<Input name='checked'
												   onChange={() => this.onChange(poll.id, 'custom', poll.multiselect)}
												   type={poll.multiselect ? 'checkbox' : 'radio'} />
											<input
												type='text'
												onChange={(value) => this.onChangeCustom(poll.id, fields, value)}
												value={this.answersCustom[field.id]}
												placeholder='Укажите ваш вариант ответа'
												className='custom'
											/>
										</div>
									) : (
										<div className='d-flex align-items-center'>
											<Input
												name='checked'
												onChange={() => this.onChange(poll.id, field.name, poll.multiselect)}
												type={poll.multiselect ? 'checkbox' : 'radio'}
											/>
											<label>{field.name}</label>
										</div>
									)}
								</div>
							))}
						</form>
					</div>
				);
			}
		}

		return (
			<div className='discussion-polls'>
				{form}
				{!isVoted && <Button type='submit' variant='primary' className='discussion-submit' onClick={this.submit} >Отправить</Button>}
			</div>
		);
	}
}
