import { Route, Switch } from 'react-router-dom';
import React from 'react';
import _Managements from './Managements';
import ManagementPersonPage from './page/ManagementPersonPage';

export const Managements = () => (
    <Switch>
        <Route path='/managements' exact component={_Managements} />
        <Route path='/managements/:id' exact component={ManagementPersonPage} />
    </Switch>
);
