import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import './style.scss';

import caretLeft from 'client/img/icon-caret-left.svg?url';
import caretRight from 'client/img/icon-caret-right.svg?url';
import { wrapperChain } from 'lodash/seq';

const LINE_HEIGHT = 10; // высота метки 30px + 10px нижний margin
const MORE_LINES = 5;

@observer
export default class Topics extends React.Component {
	static propTypes = {
		onChangeFilter: PropTypes.func,
		topics: PropTypes.any,
	};

	@observable topics = [];
	@observable visibleCount = 0;
	@observable isLoading = true;

	hideOthers = false;
	lineHeight = LINE_HEIGHT;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		let topics = this.props.topics.sort((a, b) => b.mainPriority || a.name.localeCompare(b.name));
		//двигаем метку в роуте вперед
		this.topics = topics;
		this.isLoading = false;
	};

	// записываем в this.container ссылку на контейнер меток (topics-list)
	onMount = (el) => (this.container = el);

	onTopicMount = (el) => {
		if (el) {
			// положение top относительно контейнера (первая строка - 0, вторая - LINE_HEIGHT, и т.д.)
			const top = el.offsetTop - el.parentNode.offsetTop;
			// эта метка уже на 3-й строке, надо прятать всё что после неё
			if (top > this.lineHeight && !this.hideOthers) {
				this.hideOthers = true;
				// также прячем пару предыдущих элементов, чтобы освободить место для кнопки
				if (el.previousSibling) {
					el.previousSibling.style.display = 'none';
					if (el.previousSibling.previousSibling) el.previousSibling.previousSibling.style.display = 'none';
				}
			}
			// прячем все последующие элементы, по мере их появления в DOM-дереве документа
			this.hideOthers ? (el.style.display = 'none') : (this.visibleCount += 1);
		}
	};

	loadMore = () => {
		this.hideOthers = false;
		this.lineHeight += LINE_HEIGHT * MORE_LINES;
		this.visibleCount = 0;
		if (this.container) {
			const topicElements = this.container.getElementsByClassName('topic');
			for (const topic of topicElements) {
				topic.style.display = 'inline-block';
				const top = topic.offsetTop - topic.parentNode.offsetTop;
				if (top > this.lineHeight && !this.hideOthers) {
					this.hideOthers = true;
					if (topic.previousSibling) {
						topic.previousSibling.style.display = 'none';
						if (topic.previousSibling.previousSibling) topic.previousSibling.previousSibling.style.display = 'none';
					}
				}
				this.hideOthers ? (topic.style.display = 'none') : (this.visibleCount += 1);
			}
		}
	};
	render() {
		const { selected, onChangeFilter } = this.props;
		if (this.isLoading) return null;
		return (
			<div className='topics-wrapper'>
				<div className='topics-list' ref={this.onMount}>
					{this.topics.map((topic) => {
						//если на листе кликнули в метку,то она становится selected
						const className = 'topic' + (selected && selected.id === topic.id ? ' selected' : '');
						//общие пропсы
						const generalProps = { className, ref: this.onTopicMount };
						//1ый кейс - страница материала , 2ой - страница листа с материалами(c функцией для селекта метки)
						const component = (
							<div {...generalProps} onClick={() => onChangeFilter('topic', topic)}>
								{topic.name}
							</div>
						);
						// );

						return <React.Fragment key={topic.id}>{component}</React.Fragment>;
					})}
				</div>
				{!this.isLoading && this.visibleCount !== this.topics.length && (
					<div className='caret-left_right'>
						<div>
							<img src={caretLeft} className='topics-more' alt='carretLeftIcon' />
						</div>
						<div>
							<img src={caretRight} className='topics-more' onClick={this.loadMore} alt='carretRightIcon' />
						</div>
					</div>
				)}
			</div>
		);
	}
}
