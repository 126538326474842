import React from 'react';
import { Link } from 'react-router-dom';
import { relative } from 'client/helpers';
import t from 'i18n';

export default class Column extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { article } = this.props;
		if (!article) return;
		const stringWithoutTags = article.text?.replace(/(<([^>]+)>)/gi, '');
		return (
			// переделать на статьи, когда они будут
			<Link to={`/news/${article.id}`} className='mid-column'>
				<div className='images'>{article.image ? <img src={article.downloadFile('image')} alt='' /> : <span className='no-image'>{t('noImage')}</span>}</div>
				<div>
					<div className='date p3'>{article.category?.name}</div>
					<div className='title h6'>{article.title}</div>
					<div className='text-middle p2' dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
					<div className='info'>
						<div className='date p3'>{relative(article.publishAt)}</div>
					</div>
				</div>
			</Link>
		);
	}
}
