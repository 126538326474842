import React, { Component } from 'react';
import { Button, Input } from '@smartplatform/ui';
import store from 'client/store';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import './style.scss';

@observer
class Captcha extends Component {
	@observable captcha = null;
	@observable status = '';
	@observable input = '';

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.captcha = await store.model.CitizenAppeal.getCaptcha();
	};

	checkCaptcha = () => {
		if (this.input.toLowerCase() === this.captcha.text.toLowerCase()) {
			this.props.onSuccess();
		} else {
			this.status = t('LOGIN_INVALID_CAPTCHA');
		}
	};

	render() {
		return (
			<>
				{this.captcha && (
					<div className='captcha'>
						{t('captcha')}
						<div dangerouslySetInnerHTML={{ __html: this.captcha.data }} />
						<span className='captcha-status'>{this.status}</span>
						<div className='captcha-input'>
							<Input value={this.input} onChange={(value) => (this.input = value)} placeholder={t('inputCaptcha')} />
							<Button variant='primary' onClick={this.checkCaptcha}>
								{t('checkCaptcha')}
							</Button>
						</div>
					</div>
				)}
			</>
		);
	}
}

export default Captcha;
