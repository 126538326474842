import React, { useEffect, useRef, useState } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import './style.scss';
import store from 'client/store';
import mapIcon from 'client/img/icon-map.svg?url';
import siteIcon from 'client/img/icon-site.svg?url';
import { RecordSelect } from '@smartplatform/ui';
import { COUNTY_DATA } from './MapData';
import { relative } from 'client/helpers';
import { Link } from 'react-router-dom';
import t from 'i18n';

@observer
export default class InteractiveMap extends React.Component {
	@observable records = [];
	@observable articlesLoading = true;
	@observable articles = [];
	@observable isLoading = true;
	@observable countyId = 0;
	@observable county = null;
	@observable population = 0;

	constructor(props) {
		super(props);
		this.init();
	}

	handleClick = (id) => {
		this.countyId = id;
		this.county = this.records[id];
		this.loadArticles();
	};

	init = async () => {
		this.records = await store.model.County.find({});
		this.county = this.records[0];
		this.isLoading = false;
		this.records.forEach((county) => {
			this.population += county.population;
		});
		this.loadArticles();
	};

	loadArticles = async () => {
		this.articlesLoading = true;

		const articles = [...new Array(5).map((v) => null)];

		const slots = await store.model.ArticlePlace.find({
			where: {
				block: 'news',
				articleId: { neq: null },
				countyId: store.local.countyId,
			},
			order: 'place asc',
		});
		let slotRecords = [];
		if (slots.length > 0) {
			slotRecords = await store.model.ViewLabel.getArticles({
				willBePublished: true,
				articleIds: slots.map((slot) => slot.articleId),
				limit: 5,
				fields: ['id', 'title', 'publishAt', 'image', 'commentsCount', 'viewsCount'],
			});
		}
		slots.forEach((slot) => {
			articles[slot.place] = slotRecords.find((record) => record.id === slot.articleId);
		});

		const autoRecords = await store.model.ViewLabel.getArticles({
			willBePublished: true,
			category: this.props.category || 1,
			limit: 10 - slotRecords.length,
			skip: 0,
			order: 'publishAt desc',
			showOnMain: false,
			county: this.countyId,
			exclude: slots.map((slot) => slot.articleId),
			fields: ['id', 'title', 'publishAt', 'image', 'commentsCount', 'viewsCount'],
		});

		let index = 0;
		articles.forEach((article, i) => {
			if (!article && index < autoRecords.length) {
				articles[i] = autoRecords[index++];
			}
		});

		this.articles = articles;

		this.articlesLoading = false;
	};

	onDropdownChange = (county) => {
		this.countyId = county.id - 1;
		this.county = county;
		this.loadArticles();
	};

	render() {
		if (this.isLoading) return null;
		const descriptionWithoutTags = this.county.description ? this.county.description.replace(/(<([^>]+)>)/gi, '') : null;

		return (
			<div className='interactive-map'>
				<div className='map-container'>
					<div className='content'>
						<div className='title'>{t('interactiveMap.title')}</div>

						<div className='map-info'>
							<div className='map-data'>
								{this.county && (
									<>
										<RecordSelect
											model={store.model.County}
											computed={(county) => (
												<div className='interactive-map-selector'>
													<img
														src={county.flag && county.downloadFile('flag') + '?image=[{"resize":{"width":32, "height":32}}]'}
														alt=''
														height={32}
														width={32}
													/>
													<span>{county.name}</span>
												</div>
											)}
											placeholder={'По районам'}
											value={this.county}
											noTotal={true}
											position='topLeft'
											itemsPerPage={40}
											onChange={this.onDropdownChange}
											className='dropdown'
										/>
										<div className='county-description' dangerouslySetInnerHTML={{ __html: descriptionWithoutTags }} />
										<div className='info-grid'>
											<Info title={t('county.admCenter')}>{this.county.adminCenter}</Info>
											<Info title={t('county.square')}>{this.county.area} км²</Info>
											<Info title={t('county.population')}>
												{this.county.population} чел. ({((this.county.population / this.population) * 100).toFixed(2)} %)
											</Info>
											<Info title={t('county.includes')}>{this.county.includes}</Info>
										</div>
										{/*<div className='weather'>*/}
										{/*	<div className='info'>*/}
										{/*		<div className='city'>Сангар</div>*/}
										{/*		<div className='description'>Преимущ. солнечно</div>*/}
										{/*		<div className='rain'>Вероятность осадков: 2%</div>*/}
										{/*	</div>*/}
										{/*	<div className='temperature'>*/}
										{/*		<div className='text'>-36°C</div>*/}
										{/*		<div className='icon'></div>*/}
										{/*	</div>*/}
										{/*</div>*/}
									</>
								)}
							</div>
							<div className='map'>
								<svg xmlns='http://www.w3.org/2000/svg' width='490' height='492' viewBox='0 0 590 589' fill='none'>
									{this.records.map((county, i) => (
										<CountySvg
											key={i}
											selected={!this.isLoading && i === this.countyId}
											name={(COUNTY_DATA[i] && COUNTY_DATA[i].name) || i + 'name'}
											onClick={() => this.handleClick(i)}
											flag={county.flag && county.downloadFile('flag') + '?image=[{"resize":{"width":200}}]'}
										>
											{COUNTY_DATA[i] && COUNTY_DATA[i].path}
										</CountySvg>
									))}
								</svg>
							</div>
						</div>
						<div className='buttons'>
							{this.county?.site && (
								<a className='main-site' rel='noopener noreferrer' href={'https://' + this.county?.site}>
									<span>{t('interactiveMap.goToSite')}</span>
									<img className='svg-image' src={siteIcon} alt='' />
								</a>
							)}
							{this.county?.otherSites && (
								<a className='other-sites' rel='noopener noreferrer' href={this.county?.otherSites}>
									<span>{t('county.sitesOfThisArea')}</span>
								</a>
							)}
							{this.county?.gis && (
								<a className='gis' rel='noopener noreferrer' href={this.county?.gis}>
									<img className='svg-image' src={mapIcon} alt='' />
									<span>{t('gisMap.title')}</span>
								</a>
							)}
						</div>
					</div>
					<div className='side-bar'>
						<div className='title'>{t('interactiveMap.lastNewsCounty')}</div>
						<div className='articles-list'>
							{!this.articlesLoading &&
								this.articles.map((article, i) => (
									<React.Fragment key={i}>
										{article && (
											<Link to={`/article/${article.id}`} className='article'>
												<div className='article-title'>{article?.title}</div>
												<p className='date'>{relative(article?.updatedAt)}</p>
											</Link>
										)}
									</React.Fragment>
								))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function Info({ title, children }) {
	return (
		<div className='info-cell'>
			<div className='info-title'>{title}</div>
			{children}
		</div>
	);
}

function CountySvg({ name, selected, onClick, flag, children }) {
	const ref = useRef();
	const [rect, setRect] = useState({ x: 0, y: 0, width: 100, height: 100 });

	useEffect(() => {
		setRect(ref.current.getBBox());
	}, [ref.current]);

	return (
		<g id={name && 'id'} className={`${selected ? 'selected' : ''}`} onClick={onClick}>
			<defs>
				<clipPath id={`${name}_mask`}>{children}</clipPath>
			</defs>
			<g ref={ref} style={{ backgroundColor: 'red' }}>
				{children}
			</g>
			<g className='flag'>
				{children}
				<image x={rect.x - 25} y={rect.y - 25} width={rect.width + 60} height={rect.height + 60} clipPath={`url(#${name}_mask)`} href={flag} />
			</g>
		</g>
	);
}
