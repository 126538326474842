import { observer } from 'mobx-react';
import React from 'react';
import { RecordSelect, Select } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';

@observer
export default class Filters extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	componentDidUpdate() {
		this.store = this.props.store;
	}

	render() {
		const { filter, onChangeFilter } = this.props.store;
		const { branchCategory, subCategory, order } = filter;

		return (
			<div className='question-answer-filters'>
				<div className='selectors'>
					<div>
						<RecordSelect
							model={store.model.QuestionAnswerCategory}
							property='name'
							value={branchCategory}
							placeholder={'Категория'}
							onChange={(r) => onChangeFilter('branchCategory', r)}
						/>
						<RecordSelect
							model={store.model.QuestionAnswerSubCategory}
							property='name'
							value={subCategory}
							width={160}
							placeholder={'Подкатегория'}
							onChange={(r) => onChangeFilter('subCategory', r)}
						/>
					</div>
					<div className='sort'>
						<span className='p2'>Сортировка: </span>
						<Select
							items={[
								{ label: 'По дате (по возрастанию)', value: 'publishAt asc' },
								{ label: 'По дате (по убыванию)', value: 'publishAt desc' },
							]}
							value={order}
							isRequired
							width={208}
							onChange={(r) => onChangeFilter('order', r)}
							className='drop-down-sm'
							placeholder={'По дате'}
						/>
					</div>
				</div>
			</div>
		);
	}
}
