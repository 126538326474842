import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Documents from 'client/pages/documents/index';

export const DocumentsRoute = ({ match }) => {
	const { path } = match;
	return (
		<Switch>
			<Route path={path} exact component={Documents} />
			<Route path={`${path}/subject/:subjectCode`} exact component={Documents} />
		</Switch>
	);
};
