import React from 'react';
import { RecordCard, SocialSinglePage, Picture } from 'components';
import t from 'i18n';
import './materialRecordCard.scss';
import MediaSlider from '../../news/article/MediaSlider';

export const MaterialRecordCard = ({ path, record, currentTitle, headerTitle }) => (
	<RecordCard backUrl={path} backTitle={t('aboutRepublic.title')} currentTitle={currentTitle} className='material-record-card'>
		<div className='title'>{headerTitle}</div>
		<Picture src={record.downloadFile('image')} className='item-image' width={900} />
		<div className='item-description' dangerouslySetInnerHTML={{ __html: record.description }} />
		<MediaSlider modelName={record.MODEL.INFO.name} articleId={record.id} className='media-slider' />
		<div className='social-share'>
			<div>{t('share')}</div>
			<div className='social-bottom'>
				<SocialSinglePage title={t('aboutRepublic.generalInformation')} />
			</div>
		</div>
	</RecordCard>
);
