import React, { Component } from 'react';
import store from 'client/store';
import t from 'i18n';
import { Button } from 'components';
import './style.scss';

class AppealSentPage extends Component {
	constructor(props) {
		super(props);
		store.ui.title = t('citizensAppeals.appealSent');
	}

	goToMainPage = () => {
		store.route.push({ path: '/' });
	};
	render() {
		return (
			<div className='page-content-single appeal-sent-page-single'>
				<div className='appeal-sent-page'>
					<div className='p1'>{t('citizensAppeals.thankYouVeryMuch')}</div>
					<div className='p1'>{t('citizensAppeals.yourLetterSent')}</div>
				</div>
				<Button color='light-blue' onClick={this.goToMainPage}>
					{t('backToMainPage')}
				</Button>
			</div>
		);
	}
}

export default AppealSentPage;
