import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import store from '../../../store';
import Media from 'img/media/media.svg';
import { observable } from 'mobx';
import MediaCard from '../../media/list/MediaCard';
import { getModelWhere } from 'helpers';
import t from 'i18n';

@observer
export default class MediaBroadcast extends React.Component {
	@observable records = true;
	@observable liveVideo = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const model = store.model.ViewAlbum;
		const isMainShowAll = false;
		const where = getModelWhere(model, isMainShowAll);
		this.records = await model.find({
			where,
			include: ['media'],
			limit: 4,
			order: 'viewsCount desc',
		});
		this.liveVideo = await model.find({
			where: { ...where, isLive: true },
			include: ['media'],
			limit: 1,
			order: 'date desc',
		});
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;

		return (
			<div className='media-broadcast'>
				<div className='media-broadcast-container'>
					<div className='media-broadcast-header'>
						<span>{t('media.video')}</span>
						<Media />
						<div className='line'></div>
					</div>
					<div className='media-broadcast-video'>
						{this.records &&
							this.records.map((record) => (
								<MediaCard key={record.id} record={record} onClick={() => store.route.push({ path: `/media/${record.id}` })} type={record.type} />
							))}
					</div>
				</div>
				<div className='media-broadcast-container'>
					<div className='media-broadcast-header'>
						<span>{t('broadcast.title')}</span>
						<div className='line'></div>
					</div>
					<div className='media-broadcast-live'>
						{this.liveVideo[0] && (
							<MediaCard record={this.liveVideo[0]} onClick={() => store.route.push({ path: `/media/${this.liveVideo[0].id}` })} type={'video'} />
						)}
					</div>
				</div>
			</div>
		);
	}
}
