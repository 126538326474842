import React, { Component } from 'react';
import ScrollBar from 'components/scroll-bar/ScrollBar';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Input } from '@smartplatform/ui';
import { AutoAnimate } from 'components';
import t from 'i18n';

@inject('store')
@observer
export default class Sidebar extends Component {
	@observable county = [];
	@observable searchCounty = '';
	@observable searchBranch = '';
	@observable searchEducation = '';
	@observable searchWorkExperience = '';

	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.resetAllSidebarFilters = this.resetAllSidebarFilters;
	}

	filterSidebarSearch = (field, prop) => {
		const record = [...new Set(this.store.recordsAll?.map((m) => m[field]))];
		return record.filter((data) => data?.name.toLowerCase().includes(this[prop]));
	};

	resetSearch = (searchProp, checkboxId, checkboxType, changeCheckbox) => {
		this[searchProp] = '';
		// сброс чекбоксов
		const formBlock = document.getElementById(`${checkboxId}-checkbox`);
		if (formBlock) {
			const inputArr = formBlock?.querySelectorAll(`input[type=${checkboxType}]`);
			inputArr.forEach((el) => {
				el.checked = false;
			});
		}
		this.props.store.onChangeCheckbox(`${changeCheckbox}`)(null);
		if (checkboxId === 'experience') {
			this.props.store.sideBarFilter.experience = null;
		}
		this.store.checkFilterConditions();
	};

	resetCountySearch = () => {
		this.resetSearch('searchCounty', 'county', 'checkbox', 'counties');
	};

	resetBranchSearch = () => {
		this.resetSearch('searchBranch', 'branch', 'checkbox', 'branches');
	};

	resetEducationSearch = () => {
		this.resetSearch('searchEducation', 'education', 'checkbox', 'educations');
	};

	resetWorkExperienceSearch = () => {
		this.resetSearch('searchWorkExperience', 'experience', 'radio');
	};

	resetAllSidebarFilters = () => {
		this.resetCountySearch();
		this.resetWorkExperienceSearch();
		this.resetEducationSearch();
		this.resetBranchSearch();
		this.store.checkFilterConditions();
	};

	onChangeFilter = (field, record) => {
		this[field] = record;
	};

	render() {
		const { onChangeCheckbox, records } = this.store;

		const countyArray = new Set(records?.map((m) => m.county));
		countyArray.delete(null);
		const uniqueArr = [...countyArray];
		uniqueArr.forEach((record) => (record.total = records.filter((vacancy) => vacancy.countyId === record.id).length));

		const branchArray = new Set(records?.map((m) => m.branch));
		branchArray.delete(null);
		const uniqueBranchArr = [...branchArray];
		uniqueBranchArr.forEach((record) => (record.total = records.filter((vacancy) => vacancy.branchId === record.id).length));

		const educationArray = new Set(records?.map((m) => m.education));
		educationArray.delete(null);
		const uniqueEducationArr = [...educationArray];
		uniqueEducationArr.forEach((record) => (record.total = records.filter((vacancy) => vacancy.educationId === record.id).length));

		const workExpArray = new Set(records?.map((m) => m.workExp));
		workExpArray.delete(null);
		const uniqueWorkExpArr = [...workExpArray];
		uniqueWorkExpArr.forEach((record) => (record.total = records.filter((vacancy) => vacancy.workExpId === record.id).length));

		const placeholder = 'Поиск по параметру';
		return (
			<AutoAnimate className='sidebar'>
				{uniqueArr.length > 0 && (
					<div>
						<div className='search-sidebar'>
							<div className='d-flex justify-content-between mb-3'>
								<span className='h7'>{t('county.title')}</span>
								<span className='p3 cursor-pointer' onClick={this.resetCountySearch}>
									{t('reset')}
								</span>
							</div>
							<Input value={this.searchCounty} onChange={(value) => this.onChangeFilter('searchCounty', value)} placeholder={placeholder} />
						</div>
						<div id='county-checkbox'>
							<ScrollBar
								records={this.searchCounty === '' ? uniqueArr : this.filterSidebarSearch('county', 'searchCounty')}
								h={'300'}
								onChange={onChangeCheckbox('counties')}
								store={this.store}
							/>
						</div>
					</div>
				)}
				{uniqueBranchArr.length > 0 && (
					<div>
						<div className='search-sidebar'>
							<div className='d-flex justify-content-between mb-3'>
								<span className='h7'>{t('vacancy.branch')}</span>
								<span className='p3 cursor-pointer' onClick={this.resetBranchSearch}>
									{t('reset')}
								</span>
							</div>
							<Input value={this.searchBranch} onChange={(value) => this.onChangeFilter('searchBranch', value)} placeholder={placeholder} />
						</div>
						<div id='branch-checkbox'>
							<ScrollBar
								records={this.searchBranch === '' ? uniqueBranchArr : this.filterSidebarSearch('branch', 'searchBranch')}
								h={'200'}
								onChange={onChangeCheckbox('branches')}
								store={this.store}
							/>
						</div>
					</div>
				)}
				{uniqueEducationArr.length > 0 && (
					<div>
						<div className='search-sidebar'>
							<div className='d-flex justify-content-between mb-3'>
								<span className='h7'>{t('vacancy.education')}</span>
								<span className='p3 cursor-pointer' onClick={this.resetEducationSearch}>
									{t('reset')}
								</span>
							</div>
							<Input value={this.searchEducation} onChange={(value) => this.onChangeFilter('searchEducation', value)} placeholder={placeholder} />
						</div>
						<div id='education-checkbox'>
							<ScrollBar
								records={this.searchEducation === '' ? uniqueEducationArr : this.filterSidebarSearch('education', 'searchEducation')}
								h={'95'}
								onChange={onChangeCheckbox('educations')}
								store={this.store}
							/>
						</div>
					</div>
				)}
				{uniqueWorkExpArr.length > 0 && (
					<div>
						<div className='search-sidebar'>
							<div className='d-flex justify-content-between mb-3'>
								<span className='h7'>{t('vacancy.workExp')}</span>
								<span className='p3 cursor-pointer' onClick={this.resetWorkExperienceSearch}>
									{t('reset')}
								</span>
							</div>
							<Input onChange={(value) => this.onChangeFilter('searchWorkExperience', value)} value={this.searchWorkExperience} placeholder={placeholder} />
						</div>
						<div id='experience-checkbox'>
							<ScrollBar
								records={this.searchWorkExperience === '' ? uniqueWorkExpArr : this.filterSidebarSearch('workExp', 'searchWorkExperience')}
								h={'169'}
								typeCheckbox={'radio'}
								onChange={(experience) => (this.props.store.sideBarFilter.experience = experience)}
							/>
						</div>
					</div>
				)}
				{uniqueArr.length + uniqueWorkExpArr.length + uniqueEducationArr.length + uniqueBranchArr.length > 0 && (
					<span className='reset-all p3' onClick={this.resetAllSidebarFilters}>
						{t('resetAll')}
					</span>
				)}
			</AutoAnimate>
		);
	}
}
