import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AllProjects from './AllProjects';
import Single from './Single';

export const NpaDocuments = () => (
	<Switch>
		<Route path='/npa' exact component={AllProjects} />
		<Route path='/npa/:id' exact component={Single} />
	</Switch>
);
