import React from 'react';
import { MobileFilters } from 'components/Filters';
import store from 'client/store';
import { InputFilter } from 'components/Filters/MobileFilters/InputFilter';
import { DatePicker } from '@smartplatform/ui';

export default class FiltersMobile extends React.Component{

	constructor(props) {
		super(props);

	}

	render(){

		const { onChangeFilter } = this.props;
		const { search, procedure, type, status, date } = this.props.filter;
		const placeholder = 'Поиск по параметру';

		return(
			<div className='npa-filters-mobile'>
				<MobileFilters>
					<div className='wrapper-date-picker'>
						<DatePicker value={date} onChange={(r) => onChangeFilter('date', r)} placeholder={'Дата публикации'} />
					</div>
					<InputFilter
						model={store.model.DiscussionsNPAProcedure}
						property='name'
						onChange={(r) => onChangeFilter('procedure', r)}
						label={'Процедура'}
						placeholder={placeholder}
						nameInput='DiscussionsNPAProcedure'
					/>
					<InputFilter
						model={store.model.DiscussionsNPAStatus}
						property='name'
						onChange={(r) => onChangeFilter('status', r)}
						label={'Статус'}
						placeholder={placeholder}
						nameInput='DiscussionsNPAStatus'
					/>
					<InputFilter
						model={store.model.DiscussionsNPAType}
						property='name'
						onChange={(r) => onChangeFilter('type', r)}
						label={'Тип'}
						placeholder={placeholder}
						nameInput='DiscussionsNPAType'
					/>
				</MobileFilters>
			</div>
		);
	}

}