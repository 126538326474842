import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import { formatDate } from 'client/helpers';
import { Button } from 'components';

@observer
export default class Poll extends React.Component {
	onClick = () => store.route.push({ path: `/discussions/${this.props.poll.id}` });

	render() {
		const { name, startdate, votedCount, site } = this.props.poll;

		return (
			<div className='discussions-poll'>
				<div className='poll-text'>{name}</div>
				<div className='poll-data'>
					<span>Опубликовано: {formatDate(startdate)}</span>
					<span>Всего опрошено: {votedCount}</span>
				</div>
				<div className='poll-site'>{site?.name}</div>
				<Button onClick={this.onClick} color='grey'>Пройти опрос</Button>
			</div>
		);
	}
}
