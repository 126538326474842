import React from 'react';
import { observable } from 'mobx';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { asArray } from 'ol/color';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { get as getProj } from 'ol/proj';
import { createXYZ } from 'ol/tilegrid';
import MVT from 'ol/format/MVT';

import { ColorUtils } from '@smartplatform/ui';

const TILE_SIZE = 2048;

export default class CountiesLayer {
	@observable counties = [];

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.source = new VectorTileSource({
			format: new MVT(),
			tileGrid: createXYZ({
				extent: getProj('EPSG:3857').getExtent(),
				maxZoom: 18,
				tileSize: TILE_SIZE,
			}),
			tileUrlFunction: this.tileUrlFunction,
			tileLoadFunction: this.tileLoadFunction,
		});

		this.layer = new VectorTileLayer({
			source: this.source,
			style: this.getStyle,
		});

		this.layer.setZIndex(3);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
	};

	getStyle = (feature) => {
		const name = feature.getProperties().title;
		const { background } = ColorUtils.getColorForString(name);
		const { r, g, b } = ColorUtils.hexToRgb(background);
		return new Style({
			fill: new Fill({ color: asArray([r, g, b, 0.2]) }),
			stroke: new Stroke({ color: '#888', width: 1, lineDash: [1, 4] }),
			text: new Text({
				textAlign: 'center',
				textBaseline: 'middle',
				font: '11px/14px sans-serif',
				text: name,
				fill: new Fill({ color: '#888' }),
			}),
		});
	};

	tileUrlFunction = (coords) => {
		return `/api/mvt?model=County&x=${coords[1]}&y=${coords[2]}&z=${coords[0]}&columns=id,name&buffer=0&extent=${TILE_SIZE}`;
	};

	tileLoadFunction = (tile, url) => {
		tile.setLoader(function (extent, resolution, projection) {
			fetch(url).then(function (response) {
				response.arrayBuffer().then(function (data) {
					const format = tile.getFormat(); // ol/format/MVT configured as source format
					const renderFeatures = format.readFeatures(data, {
						extent: extent,
						featureProjection: projection,
					});
					renderFeatures.forEach((f) => {
						const record = f.getProperties();
						f.properties_ = {
							title: record.name,
							record,
						};
					});
					tile.setFeatures(renderFeatures);
				});
			});
		});
	};

	setVisible = () => {
		this.layer.setVisible(this.settings.show);
	};
}
