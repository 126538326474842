import NewsLogo from 'img/icon-news.svg';
import EventsLogo from 'img/icon-events.svg';
import VacancyLogo from 'img/icon-vacancy.svg';
import QuestionAnswerLogo from 'img/icon-question_answer.svg';
import GisMapIcon from 'img/icon-gis-map.svg';
import AllSitesIcon from 'img/icon-all_sites.svg';
import AboutRepublicLogo from 'img/about-republic.svg';
import OpenDataIcon from 'img/icon-open-data.svg';
import PhonebookIcon from 'img/menu-portal-footer/phone.svg';
import Document from 'img/support-measure.svg';
import MediaIcon from 'img/menu-portal-footer/media-gray.svg';
import Discussions from 'img/menu-portal-footer/discussions.svg';
import CitizensAppealsIcon from 'img/menu-portal-footer/appeal-gray.svg';
import React from 'react';

const IMAGES_MAP = {
	news: <NewsLogo />,
	events: <EventsLogo />,
	vacancy: <VacancyLogo />,
	'question-answer': <QuestionAnswerLogo />,
	map: <GisMapIcon />,
	'all-sites': <AllSitesIcon />,
	'about-republic': <AboutRepublicLogo />,
	'open-data': <OpenDataIcon />,
	phonebook: <PhonebookIcon />,
	'support-measure': <Document />,
	documents: <Document />,
	media: <MediaIcon />,
	npa: <Document />,
	discussions: <Discussions />,
	'citizens-appeals': <CitizensAppealsIcon />,
};

export default IMAGES_MAP;
