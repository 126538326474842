import React from 'react';
import classNames from 'classnames';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import './style.scss';

export const AutoAnimate = ({ children, className, type = 'div', options = { duration: 150 } }) => {
	const [animationParent] = useAutoAnimate(options);
	className = classNames('auto-animate', className);
	return React.createElement(type, { ref: animationParent, className: className }, children);
};
