import { observable } from 'mobx';

export default class UIStore {
	site = null;
	modules = [];
	menuMain = [];
	organization = null;
	siteTypes = [];
	main3sites = [];
	popularSites = [];
	siteWidgets = [];
	@observable title = '';
	@observable showPortalMap = false;

	onOpenPortalMap = () => (this.showPortalMap = true);
	onClosePortalMap = () => (this.showPortalMap = false);

	constructor(store) {
		this.store = store;
	}

	init = async () => {
		const { languages, site, menuMain, modules, siteTypes, main3sites, popularSites, allSites, totalSitesCount, siteWidgets } =
			await this.store.model.Site.getUiStore({
				domain: this.store.domain !== 'localhost' ? this.store.domain : 'sakhagov.smartplatform.pro',
			});

		this.organization = new this.store.model.Organization(site.organization);
		this.languages = languages;
		this.site = new this.store.model.Site(site);
		this.menuMain = menuMain;
		this.modules = modules;
		this.siteTypes = siteTypes.map((record) => new this.store.model.SiteType(record));
		this.main3sites = main3sites.map((record) => new this.store.model.Site(record));
		this.popularSites = popularSites.map((record) => new this.store.model.Site(record));
		this.allSites = allSites.map((record) => new this.store.model.Site(record));
		this.totalSitesCount = totalSitesCount;
		this.siteWidgets = siteWidgets.map((record) => new this.store.model.Widget(record));

		if (!this.store.local.languageId && this.languages.length) {
			this.store.local.languageId = this.languages[0].id;
		}
		this.store.local.save();
	};
}
