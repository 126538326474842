import React from 'react';
import { inject, observer } from 'mobx-react';
import { Column, Loader, Table } from '@smartplatform/ui';
import './style.scss';
import { dateAndLocation, eventAndOrganizer } from './rowFormat';
import t from 'i18n';
import { ShowMoreButton } from 'components';

@inject('store')
@observer
export class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	onRowClick = (record) => {
		this.store.loadEvent(record.id);
	};

	render() {
		const { records, isLoading, showMoreEvents, recordsAll } = this.props.store;

		if (isLoading) return <Loader />;

		if (!records.length) return <div className='p1 no-record'>К сожалению, по вашему запросу не найдены события</div>;

		return (
			<div className='wrapper-list-event'>
				<Table rows={records} onRowClick={this.onRowClick} multiline={true} className='event-list-table'>
					<Column relation='type' property='name' label='Тип мероприятия' width={100} />
					<Column computed={dateAndLocation} label='Дата и место проведения' width={100} />
					<Column computed={eventAndOrganizer} label='Событие и организатор' />
					<Column relation='county' property='name' label='Город' width={100} />
					<Column relation='status' property='name' label='Статус мероприятия' width={100} />
				</Table>
				<ShowMoreButton
					isLoading={isLoading}
					records={records}
					showMore={showMoreEvents}
					formsArray={[t('events.formsArrayFirst'), t('events.formsArraySecond'), t('events.formsArrayThird')]}
					totalCount={recordsAll.totalCount}
				/>
			</div>
		);
	}
}
