import React from 'react';
import './style.scss';
import classNames from 'classnames';
import 'img/layouts/mountains.svg?url';
import PropTypes from 'prop-types';

const backgroundPictures = ['mountains'];

export const SecondaryLayout = ({ children, backgroundPicture, className }) => {
	className = classNames('secondary-layout', backgroundPicture, className);
	return <div className={className}>{children}</div>;
};

SecondaryLayout.propTypes = {
	backgroundPicture: PropTypes.oneOf(backgroundPictures),
};
