import React from 'react';
import SmallQuestionAnswer from '../question-answer-small/SmallQuestionAnswer';
import t from 'i18n';

export default class Sidebar extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { tags, onChangeFilter, resetAllTags, records } = this.props;

		return (
			<>
				<div className='sidebar'>
					<div className='sidebar-question-answer'>
						<span className='p4'>{t('tag.plural')}</span>
						<span className='line'></span>
					</div>
					<div className='question-answer-tags-container'>
						{tags &&
							tags.map((tag, index) => {
								return (
									<div className='tags p1' key={index} onClick={() => onChangeFilter('label', tag)}>
										{'#' + tag?.name}&nbsp;
									</div>
								);
							})}
					</div>
					<div className='right p1' onClick={resetAllTags}>
						{t('tag.all')}
					</div>
				</div>
				<div className='sidebar'>
					<div className='sidebar-question-answer'>
						<span className='p4'>{t('popular')}</span>
						<span className='line'></span>
					</div>
					<div className='wrapper-popular'>
						{records &&
							records.map((rec) => {
								return <SmallQuestionAnswer key={rec.id} questionAnswer={rec} />;
							})}
					</div>
				</div>
			</>
		);
	}
}
