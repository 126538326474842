import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const ExternalLink = ({ to, children, ...rest }) => {
	const isExternal = to && to ? to.startsWith('http') || to.startsWith('www') : null;

	return isExternal ? (
		<a href={to && to} target='_blank' rel='noopener noreferrer' {...rest}>
			{children}
		</a>
	) : (
		<RouterLink to={to && to} {...rest}>
			{children}
		</RouterLink>
	);
};

export default ExternalLink;
