import React from 'react';
import { MobileFilters } from 'components/Filters';
import store from 'client/store';
import { Input, RecordSelect, Select } from '@smartplatform/ui';
import { InputFilter } from 'components/Filters/MobileFilters/InputFilter';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export class FiltersMobile extends React.Component{

	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render(){
		const { filter, onChangeFilter } = this.store;
		const { category, order } = filter;

		const placeholder = 'Поиск по параметру';

		return (
			<div className='wrapper-columns-mobile-filters'>
				<MobileFilters>
					<InputFilter
						model={store.model.Category}
						property="name"
						onChange={(r) => onChangeFilter('category', r)}
						label={'Категория'}
						placeholder={placeholder}
						nameInput='Category'
					/>
				</MobileFilters>
				<div className='sort'>
					<span className='p2'>Сортировка: </span>
					<Select
						items={[
							{ label: 'По дате (по возрастанию)', value: 'createdAt asc' },
							{ label: 'По дате (по убыванию)', value: 'createdAt desc' },
						]}
						value={order}
						isRequired
						width={208}
						onChange={(r) => onChangeFilter('order', r)}
						className='drop-down-sm'
						placeholder={'По дате'}
					/>
				</div>
			</div>
		);
	}
}