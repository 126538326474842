import React from 'react';
import { observer } from 'mobx-react';
import MoreLogo from 'img/icon-more.svg';
import { PortalMap } from './portal-map';
import classNames from 'classnames';
import { NotPhone, Phone } from "components";
import { Link } from "react-router-dom";
import { Menu } from './Menu';
import IMAGES_MAP from "constants/MenuPortalFooterImages";

import store from 'client/store';
import t from "i18n";
import './style.scss';

@observer
export class DesktopMenu extends React.Component {
	isActive = (path) => (path === store.route.path ? 'active' : '');

	render() {
		const { className, deepBlue } = this.props;
		const { onOpenPortalMap, showPortalMap } = store.ui;
		const classNameMenu = className !== 'text-black' ? 'link' : 'link blue-border-background';
		const isMap = store.route.path.includes('/map');
		const isHome = store.route.path === '/';
		const desktopClassName = classNames('desktop-menu', { 'deep-blue': deepBlue }, { 'secondary-menu-wrapper': !isMap && !isHome });


		const secondaryDesktopMenu = (
			<>
				<div className={desktopClassName}>
					<div className="secondary-header-sitename p4">{store.ui.site.name}</div>
					<div className="links">
						<div className={classNameMenu}>
							<Link to={`/map`}>
								<div className='link-content'>
									{IMAGES_MAP['map']} {t(`sites.map`)}
								</div>
							</Link>
						</div>
						<div className={classNameMenu}>
							<Link to={`/all-sites`}>
								<div className='link-content'>
									{IMAGES_MAP['all-sites']} {t(`sites.all-sites`)}
								</div>
							</Link>
						</div>
					</div>
				</div>
				{showPortalMap && <PortalMap />}
			</>
		);

		const defaultDesktopMenu = (
			<>
				<div className={desktopClassName}>
					<Menu className={classNameMenu} />
					<div className='right links'>
						<div className={classNameMenu + this.isActive('/still')} onClick={onOpenPortalMap}>
							<div className='link-content'>
								<MoreLogo />
								<div className='show-more'>ЕЩЕ</div>
							</div>
						</div>
					</div>
				</div>
				{showPortalMap && <PortalMap />}
			</>
		);

		return isMap || isHome ? (defaultDesktopMenu) : (
			<>
				<NotPhone>
					{secondaryDesktopMenu}
				</NotPhone>
				<Phone>
					{defaultDesktopMenu}
				</Phone>
			</>
		);
	}
}
