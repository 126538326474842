import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import { Link } from 'react-router-dom';
import store from 'client/store';
import DropdownMenu from './DropdownMenu';
import t from 'i18n';
import IMAGES_MAP from 'constants/MenuPortalFooterImages';

@observer
export class Menu extends React.Component {
	generateLinkPath = (record) => {
		const { module } = record;

		if (!module) {
			return '/';
		}

		if (module === 'documents' && record.subjectCode) return `${module}/subject/${record.subjectCode}`;
		if (module === 'question-answer' && record.subCategoryCode) return `${module}/subCategory/${record.subCategoryCode}`;

		return module;
	};

	render() {
		const isActive = (path) => (path === store.route.path ? ' active' : '');
		const getMenuElement = (record) => {
			const { link, module, name, subCategoryName, subjectName } = record;

			const linkToChoice = this.generateLinkPath(record);

			let title = t(`sites.${module}`);
			if (subjectName && module === 'documents') title = subjectName;
			if (subCategoryName && module === 'question-answer') title = subCategoryName;

			return !record.hasOwnProperty('module') ? (
				<Link to={{ pathname: link }} target='_blank'>
					<div className='link-content'>
						<span>{name}</span>
					</div>
				</Link>
			) : (
				<Link to={`/${linkToChoice}`}>
					<div className='link-content'>
						{IMAGES_MAP[module]} {title}
					</div>
				</Link>
			);
		};

		const items = [];
		store.ui.menuMain.forEach((menu) => {
			const { module, children } = menu;
			const activePath = this.generateLinkPath(menu);
			items.push(
				<div className={this.props.className + (module ? isActive(`/${activePath}`) : '')} key={items.length}>
					{getMenuElement(menu)}
					{!!children.length && <DropdownMenu items={children.map((x) => getMenuElement(x))} />}
				</div>
			);
		});
		return <div className='links'>{items}</div>;
	}
}
