import React, { Component } from 'react';
import { SearchPage } from "components/search-page";
import { ManagementGroup } from "./ManagementGroup";
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class Managements extends Component {
	@observable records = [];
	@observable search = '';
	@observable managements = [];

	constructor() {
		super();
		this.init();
	}

	init = async () => {
		const _records = await store.ui.organization.managementGroup();
		const groupsIds = _records.map((group) => group.id);
		const trimmed = this.search.trim();

		this.records = await store.model.ManagementGroup.find({
			where: { id: { inq: groupsIds } },
			order: 'order'
		});

		this.managements = await store.model.Management.find({ where: { fio: { ilike:`%${trimmed}%` } } });
		let groups = [];

		for (let group of this.records) {
			const managements = await group.managements();
			groups.push({ title: group.title, managements: this.managements.filter((management) => [...managements.map((i) => i.id)].includes(management.id))});
		}

		this.records = groups;
	}

	onSearch = (value) => {
		this.search = value;
		this.init();
	}

	render() {
		const { onSearch, search } = this;
		return (
				<>
					<SearchPage headerName={t('managements.pageTitle')} placeholder={t('managements.pageTitle')} search={search} onChangeSearch={onSearch} />
					<div className='management-page'>
						<div className='management-page-group-list'>
							{this.records.map((record) => (<ManagementGroup title={record.title} managements={record.managements} key={record.title} />))}
						</div>
					</div>
				</>
		);
	}
}
