import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import store from 'client/store';

export const PageNotFound = () => (
	<div className='page-not-found'>
		<span className='page'>Страница находится в разработке</span>
		{store.ui.site.published && (
			<Link to='/' className='link'>
				Назад на главную
			</Link>
		)}
	</div>
);
