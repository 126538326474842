import React from 'react';
import { Button } from '@smartplatform/ui';
import { formatCount } from 'helpers';
import './showMoreButton.scss';
import PropTypes from 'prop-types';
import t from 'i18n';

export const ShowMoreButton = ({ isLoading, records, totalCount, noTotal, showMore, formsArray, recordsToDisplay }) => {
	const remainingCount = records ? totalCount && totalCount - records.length : null;

	return (
		<>
			{!isLoading && records && records.length > 0 && records.length < totalCount && totalCount && (
				<Button variant='info' size='md' onClick={showMore} className='button-show-more'>
					<span>
						{t('showMore')} {remainingCount} {formatCount(remainingCount, formsArray)}
					</span>
				</Button>
			)}
			{noTotal && !isLoading && records && records.length >= recordsToDisplay && (
				<Button variant='info' size='md' onClick={showMore} className='button-show-more'>
					<span>{t('showMore')}</span>
				</Button>
			)}
		</>
	);
};

ShowMoreButton.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	records: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	totalCount: PropTypes.number,
	recordsToDisplay: PropTypes.number,
	showMore: PropTypes.func.isRequired,
	formsArray: PropTypes.array,
	noTotal: PropTypes.bool,
};
