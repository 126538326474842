import React from 'react';
import { inject, observer } from 'mobx-react';
import { AutoAnimate, ShowMoreButton, DocumentCard } from 'components';
import t from 'i18n';
import { Select } from '@smartplatform/ui';
import { ORDER_ITEMS } from './constants';

export const Documents = inject('documentsStore')(
	observer(({ documentsStore }) => {
		const { records, order, onChange, isLoading, totalCountDocuments, showMoreDocuments } = documentsStore;
		return (
			<div className='content'>
				<div className='top'>
					<div className='total-count'>
						{t('document.found')}: {records?.totalCount}
					</div>
					<div className='order-by'>
						{t('orderBy')}{' '}
						<Select items={ORDER_ITEMS} size='small' value={order} width={200} isRequired className='grey' onChange={onChange('order')} noSearch />
					</div>
				</div>
				<AutoAnimate>
					{records.map((record) => (
						<DocumentCard record={record} key={record.id} />
					))}
				</AutoAnimate>
				<ShowMoreButton
					isLoading={isLoading}
					showMore={showMoreDocuments}
					totalCount={totalCountDocuments}
					records={records}
					formsArray={['документ', 'документа', 'документов']}
				/>
			</div>
		);
	})
);
