import React from 'react';
import './single-style.scss';
import './style.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import { Button } from '@smartplatform/ui';
import downloadIcon from 'client/img/data-open/icon-download.svg?url';
import CheckCircle from 'client/img/check-circle.svg';
import Comments from './Comments';

@observer
export default class Single extends React.Component {
	@observable records = [];
	@observable isLoading = true;
	@observable totalCount = 0;
	@observable dateFrom = null;
	@observable dateTo = null;
	@observable procedure = null;
	@observable stages = null;
	@observable attachments = null;
	@observable type = null;
	@observable commentSend = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = this.props.match.params.id;
		this.error = null;
		if (this.id) {
			try {
				const record = await store.model.DiscussionsNPA.find({
					where: {
						id: this.id,
					},
					include: [{ relation: 'procedure', scope: { include: ['stages'] } }, 'type', 'status', 'attachments'],
				});
				this.record = record[0];
				this.procedure = this.record.procedure;
				this.stages = this.procedure.stages();
				this.type = this.record.type;
				this.currentStage = this.stages.find((stage) => stage.id === this.record.stageId);
				this.status = this.record.status;
				this.attachments = this.record?.attachments();
			} catch (e) {
				this.error = e.message;
			}
		}
		this.isLoading = false;
	};

	formatDate(date) {
		const dateObj = new Date(date);
		let year = new Intl.DateTimeFormat('ru', { year: 'numeric' }).format(dateObj);
		let month = new Intl.DateTimeFormat('ru', { month: '2-digit' }).format(dateObj);
		let day = new Intl.DateTimeFormat('ru', { day: '2-digit' }).format(dateObj);

		return `${day}.${month}.${year}`;
	}

	render() {
		if (this.isLoading) {
			return <div className='memory-list'>...</div>;
		}

		const stageIndex = this.currentStage && this.stages.indexOf(this.currentStage);

		return (
			<div className='page-content-single npa-single'>
				<div className='general block'>
					<div className='top'>
						<h4>Проект</h4>
						<div className='status-date'>
							<div className='status field'>
								<p className='p2 left'>Статус:</p>
								<p className='p2 right'>{this.status?.name}</p>
							</div>
							<div className='date field'>
								<p className='p2 left'>Дата размещения:</p>
								<p className='p2 right'>{this.formatDate(this.record.createdAt)}</p>
							</div>
						</div>
						<div className='text p2' dangerouslySetInnerHTML={{ __html: this.record?.text?.replace(/\n/g, '<br/>') }} />
						<div className='from field'>
							<div className='left'>Ведомство:</div>
							<div className='right'>{this.record?.department}</div>
						</div>
					</div>
					<div className='files'>
						{this.attachments?.map((file, index) => (
							<File key={index} file={file} record={this.record} index={index} />
						))}
					</div>
				</div>
				<div className='info block'>
					<h4>Информация</h4>
					<div className='table'>
						<InfoRow title={'Вид проекта:'} value={this.type?.name} />
						<InfoRow title={'Дата начала:'} value={this.formatDate(this.record?.startDate)} />
						<InfoRow title={'Дата окончания:'} value={this.formatDate(this.record?.endDate)} />
						<InfoRow title={'Контактное лицо:'} value={this.record?.contactPerson} />
						<InfoRow title={'Почтовый адрес для направления заключения:'} value={this.record?.address} />
					</div>
				</div>
				<div className='procedure block'>
					<h4>Процедура</h4>
					<p className='p2'>{this.procedure?.name}</p>
				</div>
				<div className='block'>
					<h4>Этапы процедуры</h4>
					<div className='stages'>
						{this.stages?.map((stage, index) => (
							<Stage key={index} stage={stage} stageStatus={index < stageIndex ? 'finished' : index === stageIndex ? 'started' : 'not-started'} />
						))}
					</div>
				</div>
				{this.record.procedure.code === 'discussion' && (
					<div className='discussion block'>
						<h4>Обсуждение</h4>
						{!this.commentSend && <Comments discussionId={this.record.id} onSend={() => (this.commentSend = true)} />}
						{this.commentSend && (
							<div className='form-sent'>
								<CheckCircle />
								<p className='message'>
									Ваше обращение отправлено!
									<br />
									На указанную вами почту придет ответ.
								</p>
								<Button onClick={() => (this.commentSend = false)}>Отправить ещё обсуждение</Button>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

function InfoRow({ title, value }) {
	return (
		<div className='row'>
			<p className='p2 grey2'>{title}</p>
			<p className='p2'>{value}</p>
		</div>
	);
}

function Stage({ stage, stageStatus }) {
	return (
		<div className={`stage ${stageStatus}`}>
			<div className='scale'>
				<div className='stage-indicator'>
					<CheckCircle />
					<div className='circle' />
				</div>
			</div>
			<div className='text'>{stage.name}</div>
		</div>
	);
}

function File({ file, record, index }) {
	const re = /(?:\.([^.]+))?$/;

	return (
		<div className='file'>
			<a className='download' href={file.downloadFile('filename')} download>
				<img src={downloadIcon} alt='download' />
				<p className='extention'>{re.exec(file.filename)[1]}</p>
			</a>
			<div className='filename'>
				<p className='p2'>{file.filename}</p>
				<p className='p5 grey2'>{record.attachments() && record.attachments()[index].fileSize}</p>
			</div>
		</div>
	);
}
