import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import Filters from 'client/pages/news/filters/Filters';
import NewsLists from 'client/pages/news/newsLists';
import NewsStore from 'client/pages/news/store';
import { TabBar, Topics } from 'components';
import './style.scss';
import { withRouter } from 'react-router-dom';
import { SearchPage } from '../../components/search-page';
import t from 'i18n';

@withRouter
@observer
class Lenta extends Component {
	constructor(props) {
		super(props);
		this.store = new NewsStore(new URLSearchParams(this.props.location.search).get('search'));
	}

	render() {
		const { onChangeFilter, filter, records, topics } = this.store;
		return (
			<Provider store={this.store}>
				<>
					<SearchPage
						headerName={t('news.title')}
						placeholder={t('news.searchByNews')}
						search={filter.search}
						onChangeSearch={(r) => onChangeFilter('search', r)}
					/>
					<div className='news-lenta'>
						<TabBar />
						<div className='tags-counties'>
							<div className='topics'>{topics && <Topics topics={topics} className='news-topics' onChangeFilter={onChangeFilter} />}</div>
							<div>
								<Filters />
							</div>
						</div>
						<div className='articles-list'>{records && <NewsLists articles={records} store={this.store} onChangeFilter={onChangeFilter} />}</div>
					</div>
				</>
			</Provider>
		);
	}
}

export default Lenta;
