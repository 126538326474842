import React, { Suspense, useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { Footer, InnerPageHeader } from 'components';
import { Loader } from '@smartplatform/ui';
import './style.scss';
import { ZOOM_WIDTH } from 'constants';

const InnerPage = ({ children }) => {

	return (
		<>
			<InnerPageHeader />
			<div className='inner-page-content'>
				<Suspense fallback={<Loader size={18} />}>
					<Switch>{children}</Switch>
				</Suspense>
			</div>
			<Footer />
		</>
	);

};

export default InnerPage;
