import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import { Select } from '@smartplatform/ui';
import { observable } from 'mobx';

@observer
export class Language extends React.Component {
	@observable value = store.local.languageId;
	onLanguageChange = (languageId) => {
		this.value = languageId;
		store.local.languageId = this.value;
		store.local.save();
		store.ui.onClosePortalMap();
		setTimeout(() => {
			store.appKey++; // обновляем все приложение с задержкой
		}, 0);
	};
	render() {
		const items = store.ui.languages.map(({ name, id }) => ({ label: name, value: id }));
		return <Select size='small' items={items} value={this.value} className='language' isRequired onChange={this.onLanguageChange} noTotal noSearch />;
	}
}
