import { inject, observer } from 'mobx-react';
import React from 'react';
import store from 'client/store';
import { RecordSelect, DatePicker } from '@smartplatform/ui';
import './style.scss';
import t from 'i18n';

@inject('store')
@observer
export default class Filters extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { filter, onChangeFilter } = this.store;
		const { county, date0, date1 } = filter;
		return (
			<div className='news-filters'>
				<div className='selectors-news'>
					<RecordSelect model={store.model.County} property='name' placeholder={t('byCounty')} value={county} onChange={(r) => onChangeFilter('county', r)} />
				</div>
				<div className='news-filters-date'>
					<DatePicker value={date0} onChange={(r) => onChangeFilter('date0', r)} showTimeSelect={false} placeholder={t('date1')} />
					<DatePicker value={date1} onChange={(r) => onChangeFilter('date1', r)} showTimeSelect={false} placeholder={t('date2')} />
				</div>
			</div>
		);
	}
}
