import store from 'client/store';
import {
	Footer,
	MainPageHeader,
	SliderBig,
	SliderSmall,
	PopularServicesBig,
	SupportMeasureWidget,
	SuperBlock,
	MainPageHeaderMobile,
	NotPhone,
	Phone,
} from 'components';
import { observer } from 'mobx-react';
import React from 'react';
import News from './news/News';
import './style.scss';
import SummaryBlock from 'components/summary-block/SummaryBlock';
import LinksFeed from './links-feed/LinksFeed';
import InteractiveMap from './Interactive-map/InteractiveMap';
import MediaFeed from './media-feed/MediaFeed';
import MediaBroadcast from './media-broadcast/MediaBroadcast';
import AllSites from './all-sites';
import Events from './events/EventsSmall';
import QuestionAnswer from './question-answer';
import Poster from './poster/Poster';

@observer
export default class MainPage extends React.Component {
	render() {
		const modules = {
			'popular-banner': SummaryBlock,
			popular: PopularServicesBig,
			superblock: SuperBlock,
			map: InteractiveMap,
			news: News,
			'question-answer': QuestionAnswer,
			'all-sites': AllSites,
			'support-measures': SupportMeasureWidget,
			resources: LinksFeed,
			events: Events,
			'banner-big': SliderBig,
			'banner-feed': SliderSmall,
			'media-feed': MediaFeed,
			'media-broadcast': MediaBroadcast,
			poster: Poster,
		};

		let items = [];

		for (const module of store.ui.modules) {
			const Component = modules[module.code];
			if (typeof Component === 'function') {
				items.push(<Component key={module.code} />);
			}
		}
		return (
			<div className='main-page' key='root'>
				<NotPhone>
					<MainPageHeader />
				</NotPhone>
				<Phone>
					<MainPageHeaderMobile />
				</Phone>
				<div className='main-page-content'>{items}</div>
				<Footer />
			</div>
		);
	}
}
