import React from 'react';
import { Desktop, Mobile, NotPhone, Phone, PopularServiceBigModules } from 'components';
import './style.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import { getModelWhere } from 'helpers';

@observer
export default class PopularServicesBig extends React.Component {
	@observable records = null;
	@observable isLoading = null;
	@observable selected = 0;
	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		const isMainShowAll = false;
		const where = getModelWhere(store.model.PopularServicesBig, isMainShowAll);
		this.records = await store.model.PopularServicesBig.find({
			where,
			include: [
				{
					relation: 'module',
					scope: { include: [{ relation: 'icon', scope: { fields: ['id', 'name', 'code'] } }] },
				},
			],
		});
		this.isLoading = false;
	};

	handleClick = (e) => {
		let value = e.target.getAttribute('data-value');
		if (value !== null) {
			this.selected = value;
		}
	};

	render() {
		if (this.isLoading) return null;
		const module = this.records.map((rec) => rec.module());
		// Делаем проверку на кол-во модулей
		const newArrModule = module && module.filter((obj) => obj.totalCount >= 4 && obj.totalCount <= 6);
		// кол-во отображаемых индикаторов под модулем
		const totalCount = newArrModule.length;
		const style = this.selected >= 1 ? { transform: `translateX(-${this.selected * 1200}px)` } : {};
		const renderIndicator = [];
		for (let i = 0; i < totalCount; i++) {
			renderIndicator.push(<li data-value={i} key={i} className={this.selected == i ? 'active' : ''}></li>);
		}

		const desktopContent = (
			<div className="big-popular-services-items" style={style}>
				{newArrModule.map((records, index) => (
					<div className="big-popular-services-item" key={index}>
						<PopularServiceBigModules records={records} isLoading={this.isLoading} />
					</div>
				))}
			</div>
		);

		const allItemsArray = newArrModule.reduce((arraySummary, item) => {
			arraySummary = [...arraySummary, ...item];
			return arraySummary;
		}, []);

		const mobileContent = (
			<div className="big-popular-services-items">
				<PopularServiceBigModules records={allItemsArray}  isLoading={this.isLoading} />
			</div>
		);



		return (
			<div className='popular-services-big'>
				<div className='big-popular-services-header h6'>Популярные услуги</div>
				<NotPhone>
					{desktopContent}
				</NotPhone>
				<Phone>
					{mobileContent}
				</Phone>
				{newArrModule.length > 1 && (
					<ul onClick={this.handleClick} className='big-popular-services-indicators'>
						{renderIndicator}
					</ul>
				)}
			</div>
		);
	}
}
