import React from 'react';
import './style.scss';
import { SvgIcon } from 'components/svgIcon';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export class MobileFilters extends React.Component{

	@observable showPanel = false;

	constructor(props) {
		super(props);
	}

	togglePanel = () => {
		this.showPanel = !this.showPanel;
	}

	render(){
		return(
			<div className='main-wrapper-mobile-filters'>
				<div className='filter-show-control' onClick={this.togglePanel}>{this.props.control ? this.props.control : 'Фильтры'}</div>
				<div className={`wrapper-moving-panel ${this.showPanel ? 'active' : ''}`}>
					<div className='wrapper-head-panel'>
						{this.props.head ? this.props.head : 'Фильтры'}
						<div className='wrapper-close' onClick={this.togglePanel}>
							<SvgIcon name='close' />
						</div>
					</div>
					{this.props.children ? this.props.children : ''}
				</div>
			</div>
		);
	}
}