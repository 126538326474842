import React, { Component } from 'react';
import './style.scss';
import store from 'client/store';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import { Breadcrumbs, Mobile, Phone, Rating } from 'components';
import OpenDataItem from 'client/pages/open-data/OpenDataItem';
import OpenDataIntelligenceTable from 'client/pages/open-data/OpenDataIntelligenceTable';
import { PageNotFound } from '../404';
import { SvgIcon } from 'components/svgIcon';
import { Link } from 'react-router-dom';

@observer
export default class OpenDataPage extends Component {
	@observable record = null;
	@observable ratingDisplayDelay = false;
	@observable isLoading = true;
	@observable ratingsNumber;
	@observable userRating;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.id !== prevProps.match.params.id || this.props.id !== prevProps.id) {
			this.init();
		}
	}

	init = async () => {
		this.isLoading = true;
		this.id = this.props.match.params.id;
		if (parseInt(this.id)) {
			try {
				this.record = await store.model.OpenData.findById(this.id, {
					include: [
						'dataCategory',
						'agency',
						'labels',
						'attachmentsDataSet',
						'attachmentsPassport',
						'attachmentsStructure',
						'attachmentsTable',
						'attachmentsAPI',
						'organization',
					],
				});
				const userRating = (await store.model.Rating.find({ where: { visitorId: store.visitorId, openDataId: this.record.id }, limit: 1 }))[0];
				this.userRating = userRating || new store.model.Rating({ visitorId: store.visitorId, openDataId: this.record.id });
			} catch (e) {}
		}
		this.isLoading = false;
	};

	onChangeRating = async (value) => {
		this.userRating.value = value;
		const { visitorId, openDataId } = this.userRating;
		const { averageRating, ratingsNumber } = await this.record.createOrUpdateRating({ visitorId, value, openDataId });
		this.record.averageRating = averageRating;
		this.record.ratingsNumber = ratingsNumber;
	};

	render() {
		if (this.isLoading) return null;
		if (!this.userRating) return <PageNotFound />;

		const crumbs = [];
		if (this.record && this.record.categoryId === 1) {
			crumbs.push({ title: this.record.category?.name, path: '/open-data' });
		} else {
			crumbs.push({
				title: this.record && this.record.countyId !== 1 ? 'Открытые данные района' : 'Открытые данные',
				path: '/open-data',
			});
		}
		crumbs.push({ title: this.record && this.record.text });
		const stringWithoutTags = this.record && this.record.text.replace(/(<([^>]+)>)/gi, '');
		const labels = this.record.labels();

		setTimeout(() => {
			if (this.record && this.record.evaluation > 0) {
				this.ratingDisplayDelay = true;
			}
		}, 2000);

		if (this.record) {
			return (
				<div className='page-content-single open-data-page'>
					<Phone>
						<Link to='/open-data' className='wrapper-back'>
							<SvgIcon name='left-arrow' />
							{t('openData.title')}
						</Link>
					</Phone>
					<div className='open-data-breadcrumb'>
						<Breadcrumbs key={'crumbs-' + this.record.id} crumbs={crumbs} />
					</div>
					<div className='brief'>
						<div className='brief-text'>
							<div className='theme p4'>{this.record.dataCategory?.name}</div>
							<div className='text h6' dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
							<div className='initiator p2'>{this.record.organization?.name}</div>
						</div>
					</div>

					<div className='tags-wrapper'>
						<div className='tags'>
							{labels.map((label) => (
								<p className='p2' key={label.id}>
									{'#' + label.name}
								</p>
							))}
						</div>
						<Rating
							onClick={this.onChangeRating}
							rating={this.userRating.value}
							averageRating={this.record.averageRating}
							ratingsNumber={this.record.ratingsNumber}
						/>
					</div>
					<div className="wrapper-data-list">
						<p className="head p4">Данные</p>
						<div className="data">
							<OpenDataItem result={this.record} />
						</div>
					</div>
					<OpenDataIntelligenceTable record={this.record} />
					{this.ratingDisplayDelay && (
						<div className='rating-star p4'>
							{t('openData.generalAssessment')}: {this.record.evaluation}
						</div>
					)}
				</div>
			);
		}
	}
}
