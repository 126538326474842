import React from 'react';
import { inject, observer } from 'mobx-react';
import { EventComponent } from './EventComponent';
import { formatDate } from 'client/helpers';
import { capitalize } from 'lodash';

@inject('store')
@observer
export class CalendarDay extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	get dayRender() {
		const { filter, eventsByDate } = this.store;
		const calendarCells = [];

		const date = formatDate(filter.date);
		const eventsForDate = eventsByDate[date] || [];
		for (const event of eventsForDate) {
			calendarCells.push(
				<tr key={event.id}>
					<EventComponent event={event} onChangePopup={this.onChangePopup} />
				</tr>
			);
		}

		return calendarCells;
	}

	render() {
		const { isLoading, filter } = this.store;
		const dayOfWeek = capitalize(formatDate(filter.date, 'EEEE'));

		return (
			<table className='event-calendar day-view' style={{ height: '300px' }}>
				<thead>
					<tr>
						<th>{dayOfWeek}</th>
					</tr>
				</thead>
				<tbody>
					{!isLoading && (
						<>
							<tr className='days-cell'>
								<span className='h6 day-cell'>{filter.date.getDate()}</span>
							</tr>
							{this.dayRender}
						</>
					)}
				</tbody>
			</table>
		);
	}
}
