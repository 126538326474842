import { inject, observer } from 'mobx-react';
import React from 'react';
import { DatePicker, RecordSelect, Select } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';
import { Desktop, Mobile, NotPhone, Phone } from 'components';

@inject('store')
@observer
export default class Filters extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	renderSortField = (addProps = {}) => {
		const { filter, onChangeFilter } = this.store;
		const { order } = filter;

		return (
			<Select
				items={[
					{ label: 'По дате (по возрастанию)', value: 'date0 asc' },
					{ label: 'По дате (по убыванию)', value: 'date0 desc' },
				]}
				value={order}
				isRequired
				onChange={(r) => onChangeFilter('order', r)}
				className='drop-down-sm'
				placeholder={'По дате'}
				{...addProps}
			/>
		);
	}

	render() {
		const { filter, onChangeFilter, tab } = this.store;
		const { date, type, status, county, organizer, executionType, order } = filter;

		return (
			<div className='event-filters'>
				<div className='selectors'>
					<div className='filter-field-wrapper'>
						<DatePicker value={date} onChange={(r) => onChangeFilter('date', r)} isRequired={tab === 'calendar'} />
						<RecordSelect model={store.model.EventType} property='name' placeholder={'Тип события'} value={type} onChange={(r) => onChangeFilter('type', r)} />
						<RecordSelect model={store.model.EventStatus} property='name' placeholder={'Статус'} value={status} onChange={(r) => onChangeFilter('status', r)} />
						<RecordSelect model={store.model.County} property='name' placeholder={'Район'} value={county} onChange={(r) => onChangeFilter('county', r)} />
						<RecordSelect
							model={store.model.EventOrganizer}
							property='name'
							placeholder={'Организатор'}
							value={organizer}
							onChange={(r) => onChangeFilter('organizer', r)}
						/>
						<RecordSelect
							model={store.model.EventExecutionType}
							property='name'
							placeholder={'Тип проведения'}
							width={160}
							value={executionType}
							onChange={(r) => onChangeFilter('executionType', r)}
						/>
					</div>
					<div className='sort'>
						<span>Сортировка: </span>
						<NotPhone>
							{this.renderSortField()}
						</NotPhone>
						<Phone>
							{this.renderSortField({position: 'bottomRight'})}
						</Phone>
					</div>
				</div>
			</div>
		);
	}
}
