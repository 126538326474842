import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Formik, Form } from 'formik';
import t from 'i18n';
import './style.scss';
import store from 'client/store';
import { Button } from 'components';

@observer
export default class Poll extends React.Component {
	@observable usefulInfoCount = null;
	@observable record = null;
	@observable counter = null;
	@observable check = false;
	@observable voted = false;
	@observable isLoading = false;

	constructor(props) {
		super(props);
		this.checkVisitorId();
	}

	componentDidMount() {
		this.checkVisitorId();
	}

	submit = async () => {
		this.record = this.props.record;
		try {
			store.setVisitorCookie();
			this.record.usefulInfoCount = this.record.usefulInfoCount + this.usefulInfoCount;
			await this.record.save();
		} catch (e) {
			this.error = e.message;
		}
	};

	increment = async () => {
		this.isLoading = true;
		this.usefulInfoCount++;
		const counter = new store.model.SupportMeasureCounter();
		counter.visitorId = store?.visitorId;
		counter.supportMeasureId = this.props.record?.id;
		await counter.save();
		this.check = true;
		this.voted = true;
		this.submit();
		this.isLoading = false;
	};

	decrement = async () => {
		this.isLoading = true;
		this.usefulInfoCount--;
		const counter = new store.model.SupportMeasureCounter();
		counter.visitorId = store?.visitorId;
		counter.supportMeasureId = this.props.record?.id;
		await counter.save();
		this.check = true;
		this.voted = true;
		this.submit();
		this.isLoading = false;
	};

	checkVisitorId = async () => {
		this.isLoading = true;
		const counter = await store.model.SupportMeasureCounter.find({
			include: { relation: 'supportMeasure' },
			where: { supportMeasureId: this.props.record?.id },
		});
		counter.find((rec) => {
			if (rec?.visitorId === store?.visitorId) {
				if (this.props.record?.id === rec?.supportMeasureId) {
					return (this.check = true);
				} else {
					return (this.check = false);
				}
			} else {
				return null;
			}
		});
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		const pollForm = (
			<Formik
				initialValues={{
					usefulInfoCount: null,
				}}
				className='poll'
				onSubmit={async (values) => {
					this.submit(values);
				}}
			>
				{({ values }) => (
					<Form>
						<div className='support-measure-poll-form'>
							<Button className='useful-info-button' color='deep-blue' onClick={this.increment} type='submit' disabled={this.check}>
								{t('yes')}
							</Button>
							<Button className='useful-info-button' color='deep-blue' onClick={this.decrement} type='submit' disabled={this.check}>
								{t('no')}
							</Button>
						</div>
						{this.check && <div className='support-measure-info'>Спасибо что проголосовали</div>}
						{this.voted && !this.check && <div className='support-measure-info'>Спасибо что проголосовали</div>}
					</Form>
				)}
			</Formik>
		);
		return <>{pollForm}</>;
	}
}
