import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import store from 'client/store';
import { observable } from 'mobx';
import Filters from './filters/Filters';
import SearchCategory from './SearchCategory';
import { Loader } from '@smartplatform/ui';
import { debounce } from 'lodash';
import t from 'i18n';
import { SearchPage } from '../../components/search-page';

@observer
export default class Main extends React.Component {
	@observable records = null;
	@observable isLoading = true;
	@observable totalCount = 0;
	@observable filter = {
		county: null,
		order: 'createdAt desc',
		search: '',
		limit: 4,
	};

	constructor(props) {
		super(props);
		this.filter.search = new URLSearchParams(props.location.search).get('search') ?? '';
		this.doSearch = debounce(this.init, 250, { leading: false, trailing: true });
		this.init();
	}

	init = async () => {
		this.isLoading = true;

		this.records = await store.model.Site.siteSearch({ ...this.filter, organizationId: store.ui.site.organizationId, county: this.filter.county?.id ?? null });
		this.totalCount = Object.values(this.records).reduce((acc, cur) => acc + cur.totalCount, 0);

		this.isLoading = false;
	};

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.doSearch();
	};

	renderSection = (section) => {
		const { totalCount } = section[1];
		if (!totalCount) return;

		return <SearchCategory key={section[0]} section={section} goToCategory={this.goToCategory} />;
	};

	goToCategory = (path) => {
		store.route.push({ path, params: { search: this.filter.search } });
	};

	render() {
		return (
			<>
				<SearchPage
					headerName={t('searchPage.searchResult')}
					placeholder={t('searchPage.portalSearch')}
					onChangeSearch={(r) => this.onChangeFilter('search', r)}
					search={this.filter.search}
				/>
				<div className='search-site-page'>
					<Filters
						filter={this.filter}
						onChangeFilter={this.onChangeFilter}
						records={this.records}
						totalCount={this.totalCount}
						goToCategory={this.goToCategory}
					/>
					{this.isLoading ? <Loader /> : Object.entries(this.records).map((section) => this.renderSection(section))}
				</div>
			</>
		);
	}
}
