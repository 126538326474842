import React, { Component } from 'react';
import { observer } from 'mobx-react';
import IconSearch from '-!svg-react-loader?name=Icon!client/img/icon-search-blue.svg';
import { Input } from '@smartplatform/ui';
import debounce from 'lodash/debounce';
import { observable } from 'mobx';

@observer
export default class SearchInput extends Component {
	@observable search;

	constructor(props) {
		super(props);
		this.search = props.search;
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	onChange = (search) => {
		this.search = search;
		this.doSearch();
	};

	doSearch = () => {
		this.props.onChangeSearch(this.search);
	};
	render() {
		const { tags, onChangeTag, placeholder } = this.props;

		return (
			<div className='search-container'>
				<div className='search'>
					<IconSearch className='search-icon' />
					<Input type='text' placeholder={placeholder} className='search-input' onChange={this.onChange} value={this.search} />
				</div>
				{
					!!tags?.length && (
						<div className='search-filter p2'>
							<span onClick={() => onChangeTag('')}>
								<span className='search-filter_item'>Все</span>
							</span>
							{tags.map((item) => (
								<span onClick={() => onChangeTag(item)} key={item.id}>
									<span className='search-filter_item'>{item.name}</span>
								</span>
							))}
						</div>
					)
				}
			</div>
		);
	}
}
