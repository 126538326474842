import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import store from 'client/store';
import { observable } from 'mobx';
import Filters from 'client/pages/question-answer/filters/Filters';
import QuestionAnswerStore from 'client/pages/question-answer/store';
import './style.scss';
import { SearchPage } from '../../components/search-page';
import { AutoAnimate, NotPhone, Phone, QuestionAnswerCard, ShowMoreButton } from 'components';
import Sidebar from 'client/pages/question-answer/Sidebar/Sidebar';
import { FiltersMobile } from 'client/pages/question-answer/filters/FiltersMobile';
import { Loader } from '@smartplatform/ui';
import { PER_PAGE } from './constants';
@observer
export default class QuestionAnswers extends Component {
	@observable records = [];
	@observable search = '';
	@observable labels = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.store = new QuestionAnswerStore(new URLSearchParams(this.props.location.search).get('search'), this.props.match.params?.subCategoryCode);
	}
	componentDidMount() {
		this.questionAnswerLabels();
	}

	componentDidUpdate(prevProps) {
		this.records = this.store.records;
		if (prevProps.match.params.subCategoryCode !== this.props.match.params.subCategoryCode) {
			if (this.props.match.url === '/question-answer') this.store.subCategoryCode = null;
			this.store.initStartFilter(this.props.match.params.subCategoryCode);
		}
	}

	questionAnswerLabels = async () => {
		this.labels = await store.model.QuestionAnswerLabel.find({
			where: {
				label: { neq: null },
				questionAnswerId: { neq: null },
			},
			includes: { relation: 'label', scope: { fields: ['id', 'name'] } },
			limit: 15,
			distinct: ['id'],
		});

		this.isLoading = false;
	};

	resetAllTags = () => {
		this.store = new QuestionAnswerStore();
		this.records = [];
		this.records = this.store.records;
		this.store.isFilterActive = false;
	};

	render() {
		const { onChangeFilter, isLoading, filter, records, recordsAll, showMoreQuestionAnswer, totalCountQuestionAnswer } = this.store;
		const tags = [...new Set(this.labels.map((r) => r?.label).filter((rec) => rec !== null))];
		const desktopContent = (
			<>
				<div className='answer-questions-item'>
					<div className='selectors'>
						<Filters store={this.store} />
					</div>
					<AutoAnimate>{this.records && this.records.map((rec) => <QuestionAnswerCard key={rec.id} record={rec} />)}</AutoAnimate>
					{totalCountQuestionAnswer > PER_PAGE && (
						<ShowMoreButton
							isLoading={isLoading}
							showMore={showMoreQuestionAnswer}
							records={this.records}
							formsArray={['вопрос-ответ', 'вопрос-ответа', 'вопрос-ответов']}
							totalCount={totalCountQuestionAnswer}
						/>
					)}
				</div>
				{!this.isLoading ? (
					<AutoAnimate>
						<Sidebar tags={tags} onChangeFilter={onChangeFilter} resetAllTags={this.resetAllTags} records={recordsAll} />
					</AutoAnimate>
				) : (
					<Loader />
				)}
			</>
		);

		const mobileContent = (
			<>
				<FiltersMobile store={this.store} />
				{!this.isLoading ? (
					<AutoAnimate className='mobile-sidebar'>
						<Sidebar tags={tags} onChangeFilter={onChangeFilter} records={this.store.records} resetAllTags={this.resetAllTags} />
					</AutoAnimate>
				) : (
					<Loader />
				)}
				<AutoAnimate className='wrapper-content'>{!isLoading && records.map((rec) => <QuestionAnswerCard key={rec.id} record={rec} />)}</AutoAnimate>
				{totalCountQuestionAnswer > PER_PAGE && (
					<ShowMoreButton
						isLoading={isLoading}
						showMore={showMoreQuestionAnswer}
						records={this.records}
						formsArray={['вопрос-ответ', 'вопрос-ответа', 'вопрос-ответов']}
						totalCount={totalCountQuestionAnswer}
					/>
				)}
			</>
		);

		return (
			<Provider store={this.store}>
				<>
					<SearchPage headerName='Вопрос-ответ' placeholder={'Поиск по вопросам'} search={filter.search} onChangeSearch={(r) => onChangeFilter('search', r)} />
					<div className='question-answer-container'>
						<div className='answer-questions'>
							<NotPhone>{desktopContent}</NotPhone>
							<Phone>{mobileContent}</Phone>
						</div>
					</div>
				</>
			</Provider>
		);
	}
}
