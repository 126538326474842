import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import './style.scss';
import { AutoAnimate } from 'components';

@observer
export class AccordionContent extends React.Component{

	@observable show = false;

	constructor(props) {
		super(props);
	}

	toggle = () => {
		this.show = !this.show;
	}

	render(){

		return(
			<div className='accordion-wrapper'>
				<div className='accordion-show-control' onClick={this.toggle}>
					{this.props.control}
				</div>
				<AutoAnimate>{this.show && <div className='accordion-show-content'>{this.props.children}</div> }</AutoAnimate>
			</div>
		);
	}
}