import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

import { relative } from 'client/helpers';

export default ({ article }) => {
	if (!article) return <div className='small-article' />;
	return (
		<Link to={`/news/${article.id}`} className='small-article'>
			<div className='title h7'>{article.title}</div>
			<div className='info'>
				<div className='date p3'>{article.topics?.name}</div>
				<div className='date p3'>{relative(article.publishAt)}</div>
			</div>
		</Link>
	);
};
