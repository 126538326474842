import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Info, ViewsCountActive } from '../';
import './style.scss';
import mobilePause from 'client/img/mobile-podcast-pause.svg?url';
import mobilePlay from 'client/img/mobile-podcast-play.svg?url';
import { relative } from 'client/helpers';

@observer
export default class PhonePodcastPlayer extends React.Component {
	static propTypes = {
		podcast: PropTypes.object.isRequired,
	};

	@observable audio = null;
	@observable isPlaying = false;
	@observable error = null;
	@observable isPlayed = false;

	play = async () => {
		document.querySelectorAll('audio').forEach(audio => audio.pause());
		document.querySelectorAll('.podcast-play-icon').forEach(icon => (icon.src = mobilePlay));

		if (this.audio) {
			try {
				this.isPlaying ? await this.audio.pause() : await this.audio.play();
				this.isPlaying = !this.isPlaying;
				this.isPlayed = true;
			} catch (e) {
				this.error = 'Файл был удален';
			}
		}
	};

	render() {
		const { podcast } = this.props;

		return (
			<div className="podcast">
				<div className="image">
					<img src={podcast.downloadFile('image')} alt="" />
					<div className="bg">
						<img
							className="podcast-play-icon"
							onClick={() => this.play()}
							src={this.isPlaying ? mobilePause : mobilePlay}
							alt=""
						/>
					</div>
				</div>
				<div className="title">{podcast.name}</div>
				<div className="info">
					{relative(podcast.createdAt)}
					<div className="stats">
						<Info record={podcast} />
					</div>
					{/*активация счетчика просмотров */}
					<div className="active-count">{this.isPlayed && <ViewsCountActive record={podcast} />}</div>
				</div>
				<audio
					src={podcast.downloadFile('audioFile')}
					ref={el => (this.audio = el)}
					onEnded={() => (this.isPlaying = false)}
				/>
				{this.error && <div className="podcast-error">{this.error}</div>}
			</div>
		);
	}
}
