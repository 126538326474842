import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { observable } from 'mobx';
import './style.scss';
import store from 'client/store';
import { t } from 'i18next';
import iconGisMap from 'client/img/widget/icon-gis-map.png?url';
import iconNews from 'client/img/widget/icon-news.png?url';
import ExternalLinkIcon from 'client/img/widget/icon-external-link.svg';

import { intervalToDuration } from 'date-fns';
import { dayTitle } from 'client/helpers/dateTools';
import { Button } from 'components';
import ExternalLink from 'helpers/externalLink';

@withRouter
@observer
class QuickAccessBar extends Component {
	@observable record = null;
	@observable siteCount = null;
	@observable vacancyCount = null;
	@observable appealsCount = null;
	@observable error = null;
	@observable isLoading = true;
	@observable duration = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model.QuickAccessBar.find({ where: { siteId: store.ui.site?.id } });
		this.vacancyCount = await store.model.Vacancy.count();
		this.siteCount = await store.model.Site.count();
		this.appealsCount = await store.model.CitizenAppeal.count();
		this.isLoading = false;
	};

	// Timer
	thisTime = () => {
		if (this.record) {
			this.duration = intervalToDuration({
				start: new Date(this.record[0].broadcastDate),
				end: new Date(),
			});
		}
	};

	renderButton = (color, link, buttonText, className, appendIcon) => {
		return (
			<ExternalLink to={link} className='wrapper-button'>
				<Button className={className} color={color} size='lg' appendIcon={appendIcon}>
					{buttonText}
				</Button>
			</ExternalLink>
		);
	};

	// Рендер одного пользовательского виджета
	renderUserWidget = (index, image, number, description, link, buttonText) => {
		return (
			<div className='user-widget-container' key={index}>
				{image ? (
					<div className='icon-user-widget-container'>
						<img
							src={this.record[0]?.downloadFile(`image${number}` + `?image=[{"resize":{"width":48, "height":48}}]`)}
							style={{ width: 48, height: 48, borderRadius: 100, overflow: 'hidden' }}
							className='icon-user-widget'
							alt={`image${number}`}
						/>
					</div>
				) : (
					<div className='icon-user-widget-container'>
						<div className='photo empty'>{t('banner.noImage')}</div>
					</div>
				)}
				<div className='description'>{description}</div>
				{this.renderButton('light-blue', link, buttonText, 'other-button', <ExternalLinkIcon />)}
			</div>
		);
	};

	// Рендер всех пользовательских виджетов
	renderAllUserWidget = () => {
		return (
			<>
				{[1, 2, 3, 4, 5].map((num, index) => {
					if (this.record[0][`showOnBarUserWidget${num}`]) {
						return this.renderUserWidget(
							index,
							this.record[0][`image${num}`],
							`${num}`,
							this.record[0][`userWidgetDescription${num}`],
							this.record[0][`userWidgetLink${num}`],
							this.record[0][`userWidgetButtonText${num}`]
						);
					}
				})}
			</>
		);
	};

	render() {
		if (this.isLoading || !this.record[0]) return null;
		const normalizeTime = (value) => (value?.length === 1 ? `0${value}` : value);

		const normalizeDays = normalizeTime(this.duration?.days.toString());
		const normalizeHours = normalizeTime(this.duration?.hours.toString());
		const normalizeMinutes = normalizeTime(this.duration?.minutes.toString());
		const normalizeSeconds = normalizeTime(this.duration?.seconds.toString());

		const nameDayDays = dayTitle(normalizeDays);

		// Timer interval
		if (this.record.length > 0 && this.record[0].broadcastDate !== null && this.record[0].broadcastDate !== undefined) {
			setInterval(this.thisTime, 1000);
		}

		const quickAccessBarItems = (
			<>
				{this.record.length > 0 ? (
					<>
						<div className='widget-quick-access-bar'>
							{this.record[0].showOnBarAppeal && (
								<div className='appeal-container'>
									<div className='number-request'>{this.appealsCount}</div>
									<div className='description'>{this.record[0].appealDescription}</div>
									{this.renderButton('deep-blue', 'citizens-appeals', t('appeals.submitAppeal'), 'appeal-button')}
								</div>
							)}
							{this.record[0].showOnBarVacancy && (
								<div className='vacancy-container'>
									<div className='number-vacancy'>{this.vacancyCount}</div>
									<div className='description'>{this.record[0].vacancyDescription}</div>
									{this.renderButton('light-blue', 'vacancy', t('watch'), 'other-button')}
								</div>
							)}
							{this.record[0].showOnBarGisMap && (
								<div className='gis-map-container'>
									<img src={iconGisMap} className='icon-gis-map' alt='gis-map' />
									<div className='description'>ГИС-карта Республики</div>
									{this.renderButton('light-blue', 'map', t('open'), 'other-button')}
								</div>
							)}
							{this.record[0].showOnBarImportantNews && (
								<div className='news-container'>
									<img src={iconNews} className='icon-news' alt='news' />
									<div className='description'>Все важные новости республики</div>
									{this.renderButton('light-blue', 'news', t('read'), 'other-button')}
								</div>
							)}
							{this.record[0].showOnBarNumberOfSites && (
								<div className='sites-container'>
									<div className='number-sites'>{this.siteCount}</div>
									<div className='description'>сайтов на Портале</div>
									{this.renderButton('light-blue', 'all-sites', t('watch'), 'other-button')}
								</div>
							)}
							{this.record[0].showOnBarBroadcast && (
								<div className='broadcast-container'>
									<div className='broadcast-date'>
										{normalizeDays} {nameDayDays} {normalizeHours}:{normalizeMinutes}:{normalizeSeconds}
									</div>
									<div className='description'>{this.record[0].broadcastDescription}</div>
									{this.renderButton('light-blue', this.record[0].broadcastLink, t('see'), 'other-button', <ExternalLinkIcon />)}
								</div>
							)}
							{this.renderAllUserWidget()}
						</div>
					</>
				) : null}
			</>
		);

		return <>{quickAccessBarItems}</>;
	}
}

export default QuickAccessBar;
