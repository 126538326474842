import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import { Button } from 'components';
import GlassIcon from 'client/img/icon-glasses.svg';
import t from 'i18n';
import classNames from 'classnames';

@observer
export class BadVision extends React.Component {
	toggleBadVision = () => {
		store.local.badVision = !store.local.badVision;
		store.local.save();
		store.local.badVision ? document.documentElement.classList.add('poor-vision') : (document.documentElement.className = '');
		store.ui.onClosePortalMap();
	};

	render() {
		const className = classNames('bad-vision', { selected: store.local.badVision });
		return (
			<Button size='small' prependIcon={<GlassIcon />} className={className} onClick={this.toggleBadVision}>
				{t('badVision')}
			</Button>
		);
	}
}
