import React from 'react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Announcement } from './tabs/announcement/Announcement';
import { Calendar } from './tabs/calendar/Calendar';
import { List } from './tabs/list/List';
import { Popup, Tab, Tabs } from '@smartplatform/ui';
import EventsStore from '../store';
import { t } from 'i18next';
import Filters from './filters/Filters';
import './style.scss';
import Single from 'client/pages/events/single/Single';
import { SearchPage } from '../../../components/search-page';

@observer
export default class Events extends React.Component {
	@observable records = [];
	@observable page = 1;

	constructor(props) {
		super(props);
		this.store = new EventsStore(props.match.params.id, new URLSearchParams(this.props.location.search).get('search'));
	}

	render() {
		const { setTab, tab, event, onClosePopup, filter, onChangeFilter } = this.store;
		const { search } = filter;

		return (
			<Provider store={this.store}>
				<>
					<SearchPage headerName='События' placeholder={'Поиск по событиям'} search={search} onChangeSearch={(r) => onChangeFilter('search', r)} />
					<div className='events-page'>
						<Filters />
						<Tabs type='manual'>
							<Tab title={t('events.announcement')} onClick={() => setTab('announcement')} />
							<Tab title={t('events.calendar')} onClick={() => setTab('calendar')} />
							<Tab title={t('events.list')} onClick={() => setTab('list')} />
						</Tabs>
						{tab === 'announcement' && <Announcement />}
						{tab === 'calendar' && <Calendar />}
						{tab === 'list' && <List />}
						{event && (
							<Popup onClose={onClosePopup} showCloseButton={false} className='event-popup'>
								<Single event={event} onClosePopup={onClosePopup} />
							</Popup>
						)}
					</div>
				</>
			</Provider>
		);
	}
}
