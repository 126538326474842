import React, { Component } from 'react';
import { observer } from 'mobx-react';
import * as echarts from 'echarts';
import './style.scss';
import store from 'client/store';
import SearchPage from '../../components/search-page/SearchPage';
import { observable } from 'mobx';
import TextWithDocuments from 'components/text-with-documents/TextWithDocuments';
import t from 'i18n';
import ItemStructure from 'client/pages/structure/ItemStructure';
import classNames from 'classnames';
import { DEFAULT_STRUCTURE_STYLE } from 'root/admin/pages/organization/constants';

@observer
export default class Structure extends Component {
	refWidget = React.createRef();
	refWrapper = React.createRef();
	colorBranch = DEFAULT_STRUCTURE_STYLE.colorBranch;
	@observable scale = 1;
	@observable isDragging = false;
	@observable startPos = { x: 0, y: 0 };
	@observable offset = { x: 0, y: 0 };

	componentDidMount() {
		if (this.refWidget.current && this.refWrapper.current) {
			const widthWidget = this.refWidget.current.offsetWidth;
			const widthWrapper = this.refWrapper.current.offsetWidth;
			const heightWrapper = this.refWrapper.current.offsetHeight + 20;
			const scaleWidth = widthWidget / widthWrapper;
			this.refWidget.current.style.height = scaleWidth < 1 ? `${heightWrapper * scaleWidth}px` : `${heightWrapper}px`;
			if (scaleWidth < 1) this.scale = scaleWidth;
		}
	}

	buildTree() {
		const structure = store.ui.organization.structure();
		const tree = {};
		const result = [];

		const itemWithColorBranch = structure.find((record) => record.style && record.style.colorBranch);
		if (itemWithColorBranch) this.colorBranch = itemWithColorBranch.style.colorBranch;

		structure.forEach((item) => {
			// Создание объектов исходя из parentId
			const value = item.fromPhonebook ? (item.phonebook ? '\n' + item.phonebook?.fullName : '') : item.fio;
			if (!tree[item.id]) {
				tree[item.id] = { name: item.title, value, children: [], link: item.link, style: item.style };
			} else {
				tree[item.id] = { name: item.title, value, children: tree[item.id].children, link: item.link, style: item.style };
			}
			// Добавление объектов в children родителя
			if (item.parentId === null) {
				result.push(tree[item.id]);
			} else {
				if (!tree[item.parentId]) {
					tree[item.parentId] = { children: [] };
				}
				tree[item.parentId].children.push(tree[item.id]);
			}
		});

		return result;
	}

	zoomPlus = () => (this.scale = this.scale >= 2 ? this.scale : this.scale + 0.1);
	zoomMinus = () => (this.scale = this.scale <= 0.2 ? this.scale : this.scale - 0.1);

	handleMouseDown = (e) => {
		this.setStartMove(e);
	};

	handleTouchStart = (e) => {
		const touch = e.touches[0];
		this.setStartMove(touch);
	};

	setStartMove = (el) => {
		this.isDragging = true;
		this.startPos = { x: el.clientX, y: el.clientY };
		this.offset = { x: this.refWrapper.current.offsetLeft, y: this.refWrapper.current.offsetTop };
	};

	handleEndMove = () => (this.isDragging = false);

	handleMouseMove = (e) => {
		this.setProcessMove(e);
	};

	handleTouchMove = (e) => {
		const touch = e.touches[0];
		this.setProcessMove(touch);
	};

	setProcessMove = (el) => {
		const { isDragging, startPos, offset } = this;
		if (isDragging) {
			const deltaX = el.clientX - startPos.x;
			const deltaY = el.clientY - startPos.y;
			this.refWrapper.current.style.left = offset.x + deltaX + 'px';
			this.refWrapper.current.style.top = offset.y + deltaY + 'px';
		}
	};

	renderTree = () => {
		const organization = store.ui.organization;
		const treeStructure = this.buildTree();

		const styles = `
			.widget-structure *:before {
					background-color: ${this.colorBranch};
			}			
			.widget-structure *:after {
					background-color: ${this.colorBranch};
			}
		`;

		return (
			<div className='widget-structure' ref={this.refWidget}>
				<style dangerouslySetInnerHTML={{ __html: styles }} />
				<div
					className='wrapper-tree'
					ref={this.refWrapper}
					style={{ transform: `scale(${this.scale})` }}
					onMouseDown={this.handleMouseDown}
					onMouseUp={this.handleEndMove}
					onMouseLeave={this.handleEndMove}
					onMouseMove={this.handleMouseMove}
					onTouchStart={this.handleTouchStart}
					onTouchMove={this.handleTouchMove}
					onTouchEnd={this.handleEndMove}
				>
					{treeStructure.map((firstLevel) => {
						const itemsWithoutChildren = firstLevel.children.filter((r) => !r.children.length);
						const itemsWithChildren = firstLevel.children.filter((r) => r.children.length);

						return (
							<div className='wrapper-supervisor'>
								<div className='structure-level-1'>
									<ItemStructure record={firstLevel} level={1} />
								</div>
								{!!firstLevel.children.length && (
									<div
										className={classNames('structure-level-2 horizontal-branch', {
											'horizontal-branch': (itemsWithoutChildren.length && itemsWithChildren.length) || itemsWithChildren.length > 1,
										})}
									>
										{!!itemsWithoutChildren.length && (
											<div className='col-structure without-children'>
												{itemsWithoutChildren.map((record) => (
													<ItemStructure record={record} level={2} />
												))}
											</div>
										)}
										{!!itemsWithChildren.length &&
											itemsWithChildren.map((record) => (
												<div className='col-structure with-children'>
													<ItemStructure record={record} level={2} />
												</div>
											))}
									</div>
								)}
							</div>
						);
					})}
				</div>

				<div className='controls-zoom'>
					<button onClick={this.zoomPlus}>+</button>
					<button onClick={this.zoomMinus}>–</button>
				</div>
			</div>
		);
	};

	render() {
		const organization = store.ui.organization;

		return (
			<>
				<SearchPage headerName={t('sites.structure')} noSearch={true} />
				{organization.isEnabledStructureImage && organization.structureFilename ? (
					<div className='wrapper-image-structure'>
						<img src={organization.downloadFile('structureFilename')} />
					</div>
				) : (
					this.renderTree()
				)}
				<div className='structure-text-with-documents'>
					<TextWithDocuments content={organization.structureDescription} title={t('organization.additional')} />
				</div>
			</>
		);
	}
}
