import store from 'client/store';

export const getModelWhere = (model, isMainShowAll = true) => {
	const { PROPERTIES } = model;
	const where = { and: [] };
	const showAll = isMainShowAll && store.ui.site.isMain;

	if (!showAll && store.ui.site.organizationId) {
		PROPERTIES.organizationId && where.and.push({ organizationId: store.ui.site.organizationId });
		(PROPERTIES.siteId && !PROPERTIES.sitesIds) && where.and.push({ or: [{ siteId: store.ui.site.id }, { siteId: null }] });
		PROPERTIES.sitesIds && where.and.push({or: [{ sitesIds: { ilike: `%"${store.ui.site.id}"%` } }, {sitesIds: null}]});
	}

	PROPERTIES.languageId && where.and.push({ languageId: store.local.languageId });
	PROPERTIES.publish && where.and.push({ publish: true });
	return { ...where };
};
