import t from 'i18n';

export const RESULT_ITEMS = [
	{ id: 1, label: t('citizensAppeals.supported'), value: t('citizensAppeals.supported') },
	{ id: 2, label: t('citizensAppeals.partialSupported'), value: t('citizensAppeals.partialSupported') },
	{ id: 3, label: t('citizensAppeals.explained'), value: t('citizensAppeals.explained') },
];

export const TERM_ITEMS = [
	{ id: 1, label: t('yes'), value: t('yes') },
	{ id: 2, label: t('no'), value: t('no') },
];

export const EXECUTED_ITEMS = [
	{ id: 1, label: t('citizensAppeals.measuresTaken'), value: t('citizensAppeals.measuresTaken') },
	{ id: 2, label: t('citizensAppeals.measuresBeingTaken'), value: t('citizensAppeals.measuresBeingTaken') },
	{ id: 3, label: t('citizensAppeals.explained'), value: t('citizensAppeals.explained') },
];
