import { SectionCard } from 'components';
import t from 'i18n';
import React from 'react';
import { MaterialCardSidebar } from './MaterialCardSidebar';
import './latestMaterials.scss';

export class LatestMaterials extends React.Component {
	render() {
		const { records } = this.props;
		if (!records.length) return;
		return (
			<SectionCard title={t('aboutRepublic.newOnMaterials')} className='latest-materials'>
				{records.map((material) => (
					<MaterialCardSidebar record={material} key={material.id} />
				))}
			</SectionCard>
		);
	}
}
