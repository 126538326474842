import React from 'react';
import { Select } from '@smartplatform/ui';
import { addMonths, subMonths, addWeeks, subWeeks, addDays, subDays } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';
import { capitalize } from 'lodash';
import { inject, observer } from 'mobx-react';
import { formatDate } from 'client/helpers';
import { Button, Desktop, Mobile, NotPhone, Phone } from 'components';

@inject('store')
@observer
export class Toolbar extends React.Component {
	onChangeDate = (move) => {
		const { filter, onChangeFilter } = this.props.store;

		switch (filter.calendar) {
			case 'month': {
				if (move === 'next') {
					onChangeFilter('date', addMonths(filter.date, 1));
				} else {
					onChangeFilter('date', subMonths(filter.date, 1));
				}
				break;
			}
			case 'week': {
				if (move === 'next') {
					onChangeFilter('date', addWeeks(filter.date, 1));
				} else {
					onChangeFilter('date', subWeeks(filter.date, 1));
				}
				break;
			}
			default: {
				if (move === 'next') {
					onChangeFilter('date', addDays(filter.date, 1));
				} else {
					onChangeFilter('date', subDays(filter.date, 1));
				}
				break;
			}
		}

	};

	onDateNow = () => {
		const { onChangeFilter } = this.props.store;

		onChangeFilter('date', new Date());
	};

	render() {
		const { filter, onChangeFilter } = this.props.store;

		const desktopContent = (
			<>
				<Select
					items={[
						{ label: 'День', value: 'day' },
						{ label: 'Неделя', value: 'week' },
						{ label: 'Месяц', value: 'month' },
					]}
					value={filter.calendar}
					isRequired
					onChange={(x) => onChangeFilter('calendar', x)}
					className='drop-down-sm'
				/>
				<label>{capitalize(formatDate(filter.date, 'LLLL yyyy'))}</label>
				<div>
					<Button className='calendar-navigate' onClick={() => this.onChangeDate('back')} color='grey' size='small'>
						<FontAwesomeIcon icon={faLessThan} />
					</Button>
					<Button className='calendar-navigate' onClick={() => this.onChangeDate('next')} color='grey' size='small'>
						<FontAwesomeIcon icon={faGreaterThan} />
					</Button>
					<Button onClick={this.onDateNow} color='grey' size='small'>Сегодня</Button>
				</div>
			</>
		);

		const mobileContent = (
			<>
				<div className="row-wrapper">
					<label>{capitalize(formatDate(filter.date, 'LLLL yyyy'))}</label>
					<div>
						<Button className='calendar-navigate' onClick={() => this.onChangeDate('back')} color='grey' size='small'>
							<FontAwesomeIcon icon={faLessThan} />
						</Button>
						<Button className='calendar-navigate' onClick={() => this.onChangeDate('next')} color='grey' size='small'>
							<FontAwesomeIcon icon={faGreaterThan} />
						</Button>
					</div>
				</div>
				<div className='row-wrapper'>
					<Select
						items={[
							{ label: 'День', value: 'day' },
							{ label: 'Неделя', value: 'week' },
							{ label: 'Месяц', value: 'month' },
						]}
						value={filter.calendar}
						isRequired
						onChange={(x) => onChangeFilter('calendar', x)}
						className='drop-down-sm'
					/>
					<Button onClick={this.onDateNow} color='grey' size='small'>Сегодня</Button>
				</div>
			</>
		);

		return (
			<div className="calendar-toolbar">
				<NotPhone>{desktopContent}</NotPhone>
				<Phone>{mobileContent}</Phone>
			</div>
		);
	}
}
