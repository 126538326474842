import React from 'react';
import { Switch, Route } from 'react-router-dom';

import MediaPage from './list/Media';

export const Media = () => (
	<Switch>
		<Route path='/media' exact component={MediaPage} />
		<Route path='/media/:id' component={MediaPage} />
	</Switch>
);
