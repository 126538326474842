import React from 'react';
import './style.scss';
import { VacancyCard } from './VacancyCard';
import { Popup } from '@smartplatform/ui';
import { observer, Provider } from 'mobx-react';
import Filters from './filters/Filters';
import { observable } from 'mobx';
import VacancyStore from './store';
import Sidebar from './Sidebar';
import { t } from 'i18next';
import VacancyPage from './VacancyPage';
import { SearchPage } from '../../components/search-page';
import { AutoAnimate, Desktop, Mobile, NotPhone, Phone, ShowMoreButton } from 'components';
import { FiltersMobile } from 'client/pages/vacancy/filters/FiltersMobile';

@observer
export default class Vacancys extends React.Component {
	@observable search = '';

	constructor(props) {
		super(props);
		this.store = new VacancyStore(props.match.params.id, new URLSearchParams(this.props.location.search).get('search'));
	}

	onRowClick = (id) => () => this.store.loadVacancy(id);

	render() {
		const {
			vacancy,
			onClosePopup,
			records,
			filter,
			onChangeFilter,
			sidebarFilteredRecord,
			isLoading,
			showMoreVacancy,
			totalCountVacancies,
			isSideBarFilterActive,
		} = this.store;

		if (isLoading) return;
		const isFilterActiveOnSideBar = this.store.sideBarFilter?.experience !== null || isSideBarFilterActive;

		const showTotalCountVacancies = isFilterActiveOnSideBar ? sidebarFilteredRecord.length : totalCountVacancies;

		return (
			<Provider store={this.store}>
				<>
					<SearchPage
						headerName={t('vacancy.unifiedDatabaseOfVacancies')}
						placeholder={'Поиск по Вакансиям'}
						search={filter.search}
						onChangeSearch={(r) => onChangeFilter('search', r)}
					/>
					<div className='vacancy-container'>
						<NotPhone>
							<Filters to={'vacancy'} />
							<div className='vacancy-items'>
								<div className='vacancy'>
									<span className='vacancy-header p2'>
										{t('vacancy.totalVacancy')} {showTotalCountVacancies}
									</span>
									<AutoAnimate>
										{sidebarFilteredRecord.map((vacancy) => (
											<VacancyCard vacancy={vacancy} onClick={this.onRowClick(vacancy.id)} key={vacancy.id} />
										))}
									</AutoAnimate>
									<ShowMoreButton
										isLoading={isLoading}
										records={records}
										showMore={showMoreVacancy}
										formsArray={['вакансия', 'вакансии', 'вакансий']}
										totalCount={showTotalCountVacancies}
									/>
								</div>
								<div className='vacancy-right-part'>
									<Sidebar />
								</div>
							</div>
						</NotPhone>
						<Phone>
							<FiltersMobile />
							<div className='vacancy-items'>
								<div className='vacancy'>
									<span className='vacancy-header p2'>
										{t('vacancy.totalVacancy')} {showTotalCountVacancies}
									</span>
									<AutoAnimate>
										{sidebarFilteredRecord.map((vacancy) => (
											<VacancyCard vacancy={vacancy} onClick={this.onRowClick(vacancy.id)} key={vacancy.id} />
										))}
									</AutoAnimate>
									<ShowMoreButton
										isLoading={isLoading}
										records={records}
										showMore={showMoreVacancy}
										formsArray={['вакансия', 'вакансии', 'вакансий']}
										totalCount={showTotalCountVacancies}
									/>
								</div>
							</div>
						</Phone>
					</div>

					{vacancy && (
						<Popup onClose={onClosePopup} showCloseButton={false} className='event-popup'>
							<VacancyPage vacancy={vacancy} />
						</Popup>
					)}
				</>
			</Provider>
		);
	}
}
