import { observable } from 'mobx';
import store from 'client/store';
import { getModelWhere } from 'helpers';
import { PER_PAGE } from './constants';

export default class QuestionAnswerStore {
	@observable filter = {
		branchCategory: null,
		subCategory: null,
		label: null,
		order: 'publishAt asc',
		search: '',
	};

	@observable records = null;
	@observable recordsAll = null;
	@observable isLoading = false;
	@observable PER_PAGE = PER_PAGE;
	@observable totalCountQuestionAnswer = 0;
	@observable isFilterActive = false;
	@observable subCategoryCode = null;

	constructor(search, subCategoryCode) {
		this.filter.search = search ?? '';
		this.subCategoryCode = subCategoryCode || null;
		this.initStartFilter();
	}

	initStartFilter = async (code) => {
		this.isLoading = true;
		const subCategoryCodeToUse = code || this.subCategoryCode;
		if (subCategoryCodeToUse) {
			this.filter.subCategory = (await store.model.QuestionAnswerSubCategory.find({ where: { code: subCategoryCodeToUse } }))?.[0] || null;
		} else {
			this.filter.subCategory = null;
		}
		this.init();
	};

	init = async () => {
		const model = store.model.ViewQuestionAnswer;
		const { branchCategory, subCategory, label } = this.filter;
		const where = getModelWhere(model);
		this.isFilterActive = Boolean(branchCategory || subCategory || label);
		if (branchCategory) where.and.push({ branchCategoryId: branchCategory.id });
		if (subCategory) where.and.push({ subCategoryId: subCategory.id });
		if (label) where.and.push({ labelIds: { ilike: `%${label.id}%` } });

		const trimmed = this.filter.search.trim();
		this.records = await model.find({
			search: trimmed.length > 0 ? trimmed : undefined,
			where,
			include: [{ relation: 'labels', scope: { fields: ['id', 'name', 'code'] } }],
			order: this.filter.order,
			limit: this.PER_PAGE,
		});

		this.recordsAll = await model.find();
		this.totalCountQuestionAnswer = this.records.totalCount;
		this.isLoading = false;
	};

	showMoreQuestionAnswer = () => {
		this.PER_PAGE += PER_PAGE;
		this.init();
	};

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.PER_PAGE = PER_PAGE;
		this.init();
	};
}
