import { BigArticle, MidArticle } from 'components';
import './style.scss';
import React from 'react';

export default ({ articles }) => {
	if (!articles) return null;

	return (
		<div className='acrticles-item'>
			<div className='articles'>
				{articles.map((article, i) => {
					if (i === 0) return <BigArticle key={article.id} article={article} />;
					if (i === 1) return <MidArticle key={article.id} article={article} />;
					if (i === 2) {
						return (
							<React.Fragment key='desktop'>
								<MidArticle key={article.id} article={article} />
							</React.Fragment>
						);
					}
					return <MidArticle key={article.id} article={article} />;
				})}
			</div>
		</div>
	);
};
