import { observable } from 'mobx';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { geoJSON } from '@smartplatform/map/client';
import store from 'client/store';
import { CountiesLayer } from './filters/layers/counties';
import { WeatherLayer, WeatherTip } from './filters/layers/weather';
import { WindLayer } from './filters/layers/wind';

class MapStore {
	@observable mapInitialized = false;
	@observable mapMaximized = false;
	@observable changed = false;
	@observable weatherImage = null;

	onMapInit = async (mapStore) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;
		this.map.on('pointermove', this.onPointerMove);
		this.countiesLayer = new CountiesLayer(mapStore, store.local.counties);
		this.weatherLayer = new WeatherLayer(mapStore, store.local.weather, {
			onLoad: this.onWebglImageLoad,
			onSetLayer: this.onSetWeatherLayer,
		});
		this.weatherTip = new WeatherTip(this);
		this.windLayer = new WindLayer(mapStore, store.local.wind);
		this.countiesLayer.init();
		this.weatherLayer.init();
		this.windLayer.init();

		this.source = new VectorSource();
		this.layer = new VectorLayer({
			format: geoJSON,
			source: this.source,
		});

		mapStore.addLayer(this.layer);
		this.layer.setZIndex(10);

		this.mapMaximized = store.local.filtersMaximized;
		this.mapInitialized = true;
	};

	onMouseLeave = () => {
		if (this.weatherTip) this.weatherTip.hide();
	};

	onPointerMove = (e) => {
		if (e.dragging || this.mapStore.drawing) {
			if (this.weatherTip) this.weatherTip.hide();
			return;
		}

		if (store.local.weather.show) {
			if (this.weatherImage && this.weatherLayerConfig) {
				const coords = this.map.getCoordinateFromPixel([e.pixel[0], e.pixel[1] - 20]);
				this.weatherTip.update(coords, this.weatherImage, this.weatherLayerConfig, this.colorRamp);
			}
		} else {
			if (this.weatherTip) this.weatherTip.hide();
		}
	};

	getColorRamp = (colors) => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		const width = 300;
		const gradient = ctx.createLinearGradient(0, 0, width, 20);

		canvas.width = width;
		canvas.height = 20;

		if (Array.isArray(colors)) {
			for (let i = 0; i < colors.length; i++) {
				gradient.addColorStop(i / colors.length, colors[i]);
				ctx.fillStyle = gradient;
				ctx.fillRect(0, 0, width, 20);
			}
		} else {
			for (const breakpoint of colors.scale) {
				const [value, pos, [r, g, b, a]] = breakpoint;
				gradient.addColorStop(pos, `rgba(${r},${g},${b},${a / 255})`);
			}
			ctx.fillStyle = gradient;
			ctx.fillRect(0, 0, width, 20);
		}

		return canvas;
	};

	onWebglImageLoad = (image, colorRamp) => {
		// console.log('onWebglImageLoad (card)', image);
		this.weatherImage = image;
		this.colorRamp = colorRamp;
	};

	onSetWeatherLayer = async (weatherLayerConfig) => {
		this.weatherLayerConfig = weatherLayerConfig;
		let { type, code, colors, min, max, measure, convert } = weatherLayerConfig;

		if (type === 'silam') {
			const meatUrl = `/api/silammeta?date=${new Date().toISOString()}&layer=${code}`;
			try {
				const res = await fetch(meatUrl);
				const json = await res.json();
				this.silamMin = min = json.min;
				this.silamMax = max = json.max;
			} catch (e) {
				console.warn('/api/silammeta', e);
			}
		}
		if (colors && this.scaleElement) {
			this.scaleElement.innerHTML = '';
			this.scaleElement.appendChild(this.getColorRamp(colors));
			if (min !== undefined && max !== undefined) {
				const minDiv = document.createElement('div');
				minDiv.className = 'min-value';
				minDiv.innerHTML = Math.floor(convert ? convert(min) : min) + (measure ? ' ' + measure : '');
				this.scaleElement.appendChild(minDiv);

				const maxDiv = document.createElement('div');
				maxDiv.className = 'max-value';
				maxDiv.innerHTML = Math.floor(convert ? convert(max) : max) + (measure ? ' ' + measure : '');
				this.scaleElement.appendChild(maxDiv);
			}
		}
	};

	onScaleMount = (el) => {
		this.scaleElement = el;
	};

	switchFilters = (maximize) => {
		this.mapMaximized = maximize !== undefined ? maximize : !store.local.filtersMaximized;
		store.local.filtersMaximized = this.mapMaximized;
		store.local.save();
	};
}

export default new MapStore();
