import React from 'react';
import { t } from 'i18next';
import './style.scss';

export default class WeatherContainer extends React.Component {
	render() {
		const { widget } = this.props;
		return (
			<div className='weather-container'>
				{widget.city && widget.city.weather && (
					<div className='weather-items'>
						<div className='pressure-wind'>
							<div>
								{t('weather.pressureDescription')}:&nbsp;{widget.city.weather.pressure}
								{t('weather.pressureMeasure')}
							</div>
							<div>
								{t('wind.title')}:&nbsp;{widget.city.weather.wind}
							</div>
						</div>
						<div className='temperature'>{widget.city.weather.temperature}</div>
					</div>
				)}
			</div>
		);
	}
}
