import { observable } from 'mobx';
import store from 'client/store';

const MATERIAL_RELATION = { relation: 'materials', scope: { fields: ['id', 'title', 'description', 'image'] } };

export default class AboutRepublicStore {
	latestMaterials = [];
	infoAboutRepublicCategory;
	@observable categoryMaterials = [];
	@observable categoryAllMaterials = [];
	@observable isLoading = true;
	@observable filter = { category: null };
	@observable search = '';
	@observable PER_PAGE = 4;

	constructor() {
		this.init();
	}

	init = async () => {
		this.infoAboutRepublicCategory = (
			await store.model.AboutRepublicCategory.find({
				where: { code: 'info-about-republic' },
				include: [
					{
						relation: 'materials',
						scope: { where: { languageId: store.local.languageId }, fields: ['id', 'title', 'description', 'image'], order: 'title asc' },
					},
				],
			})
		)[0];

		await Promise.all([this.fetchAboutRepublicRecord(), this.fetchLatestMaterials(), this.fetchCategories()]);

		this.isLoading = false;
	};

	fetchAboutRepublicRecord = async () =>
		(this.aboutRepublicRecord = (
			await store.model.PageDescription.find({ where: { languageId: store.local.languageId, code: 'ABOUT_REPUBLIC' }, limit: 1 })
		)[0]);

	fetchLatestMaterials = async () =>
		(this.latestMaterials = await store.model.AboutRepublicMaterial.find({
			where: { languageId: store.local.languageId, catetogyId: { neq: this.infoAboutRepublicCategory?.id } },
			limit: 8,
		}));

	fetchCategories = async () => {
		const trimmed = this.search.trim();
		const categories = await store.model.AboutRepublicCategory.find({
			where: { id: { neq: this.infoAboutRepublicCategory?.id } },
			include: [
				{
					relation: 'materials',
					scope: {
						where: { languageId: store.local.languageId },
						limit: this.PER_PAGE,
						fields: ['id', 'title', 'description', 'image'],
						search: trimmed.length ? trimmed : undefined,
					},
				},
			],
		});
		this.categoryMaterials = categories.filter(({ materials }) => materials().length);
	};

	showMoreMaterials = async (category) => {
		const categories = await store.model.AboutRepublicCategory.findById(category.id, {
			where: { id: { neq: this.infoAboutRepublicCategory?.id } },
			include: [
				{
					relation: 'materials',
					scope: {
						where: { languageId: store.local.languageId },
						fields: ['id', 'title', 'description', 'image'],
					},
				},
			],
		});
		this.totalMaterialsCount = categories.materials().length;
		const categoriesCopy = [...this.categoryMaterials];
		const indexToUpdate = categoriesCopy.findIndex((cat) => cat.id === category.id);
		categoriesCopy[indexToUpdate] = categories;
		this.categoryMaterials = categoriesCopy;
		const currentMaterialsCount = categories.materials().length;

		this.categoryMaterials.forEach((cat) => {
			if (cat.id === category.id) {
				cat.showButton = currentMaterialsCount < this.totalMaterialsCount;
			}
		});

		if (currentMaterialsCount >= this.totalMaterialsCount) {
			category.showButton = true;
		}
	};

	get where() {
		const { category } = this.filter;
		const where = {
			and: [],
		};
		if (category) {
			where.and.push({ categoryId: category.id });
		}
		return where;
	}

	onSearch = (search) => {
		this.search = search;
		this.fetchCategories();
	};

	setTab = async (tab) => {
		this.tab = tab;
		await this.init();
	};

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.fetchCategories();
	};

	get materialWhere() {
		return {
			languageId: store.local.languageId,
			catetogyId: { neq: this.infoAboutCategory?.id },
		};
	}
}
