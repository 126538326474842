import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { format, parse } from 'date-fns';
import { ru } from 'date-fns/locale';

import './style.scss';
import t from 'i18n';
import CalendarLabel from 'img/calendar-label.svg';

import EventsSmallStore from './store';
import { AutoAnimate, CalendarSlider, EventCard, SectionCard } from 'components';
import { observable } from 'mobx';
import store from 'client/store';

@observer
export default class EventsSmall extends Component {
	@observable currentPage = 1;

	constructor(props) {
		super(props);
		this.store = new EventsSmallStore();
	}

	render() {
		const itemsPerPage = 3;
		const totalItems = this.store.events.length;
		const totalPages = Math.ceil(totalItems / itemsPerPage);

		const itemsToDisplay = this.store.events.slice((this.currentPage - 1) * itemsPerPage, this.currentPage * itemsPerPage);

		const handleClick = (e) => {
			let value = e.target.getAttribute('data-value');
			if (value !== null) {
				this.selected = value;
			}
		};

		const pagination = Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
			<li
				className={`events-pagination-item ${this.currentPage === pageNumber ? 'active' : ''}`}
				key={pageNumber}
				onClick={() => {
					this.currentPage = pageNumber;
					handleClick;
				}}
			/>
		));

		const { changeDate, selectedDate, events } = this.store;

		const formatDate = (date) => {
			const parsedDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date());
			return format(parsedDate, 'dd.MMM', { locale: ru }).replace(/\./g, ' ');
		};

		return (
			<div className='events-small-container'>
				<SectionCard title={t('events.events')} icon='event' className='events-small-left'>
					<CalendarSlider changeDate={changeDate} selectedDate={selectedDate} />
					<div className='events-container'>
						<AutoAnimate className='events-items-container'>
							{!events.length && <div>{t('events.noEvents')}</div>}
							{itemsToDisplay.map((item) => (
								<EventCard record={item} key={item.id} onClick={() => store.route.push({ path: `/events/${item.id}` })} />
							))}
						</AutoAnimate>
						{events.length > 3 && <div className='pagination-container'>{pagination}</div>}
					</div>
				</SectionCard>
				<SectionCard title={t('events.holidays')} icon='event' className='events-small-right'>
					<AutoAnimate className='events-small-holidays-container'>
						{this.store.holidays.length ? (
							this.store.holidays.map((item) => (
								<div className='events-small-holidays-item' onClick={() => store.route.push({ path: `/events/${item.id}` })} key={item.id}>
									<div className='events-small-holidays-item-container'>
										<div className='events-small-holidays-item-title'>{formatDate(item.date0)}</div>
										<div className='events-small-holidays-item-description'>{item.title}</div>
									</div>
									<div className='events-small-holidays-item-icon'>
										<CalendarLabel />
									</div>
								</div>
							))
						) : (
							<div>{t('events.noHolidayEvents')}</div>
						)}
					</AutoAnimate>
				</SectionCard>
			</div>
		);
	}
}
