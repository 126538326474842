import React, { Component } from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import { formatDate } from 'client/helpers';
import './style.scss';
import { MediaQueryTextWidget } from 'components/widget/variant-1/MediaQueryTextWidget';
import WeatherContainer from 'components/widget/weather-container/WeatherContainer';

@observer
export default class WidgetVariant1 extends Component {
	render() {
		const widget = store.ui.siteWidgets.find((widget) => widget.variantId === 1);
		if (!widget) return;
		const data = new Date();

		return (
			<div className='widget'>
				<div className='widget-weather'>
					<div className='city-date'>
						<div className='city-weather'>
							{widget.city && (
								<>
									<div className='city'>{widget.city.name}</div>
									<div className='city-county'>{widget.city.county && widget.city.county.name}</div>
								</>
							)}
						</div>
						<div>
							<div className='date-weather-day-of-week'>{formatDate(data, 'EEEE')}</div>
							<div className='date-weather'>{formatDate(data, 'dd MMMM yyyy')}</div>
						</div>
					</div>
					<WeatherContainer widget={widget} />
				</div>
				<MediaQueryTextWidget header={widget.header} text={widget.text} />
			</div>
		);
	}
}
