import { observable } from 'mobx';
import store from 'client/store';

export default class SiteMapStore {
	@observable record = null;
	@observable portals = [];
	@observable section = [];
	@observable flattenMenuData = [];
	@observable menu = [];
	@observable modules = [];

	portalsMapStart = [];
	portalsMapAll = [];
	flattenMenuDataStart = [];
	flattenMenuDataAll = [];
	isShowAll = false;

	constructor() {
		this.id = store.ui.site.templateId;
		this.init();
	}

	init = async () => {
		if (this.id) {
			this.record = await store.model.SiteTemplate.findById(this.id, {
				include: [
					{
						relation: 'menu',
						scope: {
							include: [{ relation: 'subCategory' }, { relation: 'subject' }],
						},
					},
				],
			});
		} else {
			this.record = new store.model.SiteTemplate();
		}

		const menu = [];
		for (const record of this.record.menu()) {
			menu.push({ ...record, order: record.order, subCategory: record.subCategory, subject: record.subject });
		}

		this.menu = menu;
		for (const itemMenu of menu) {
			this.portalsMapAll.push(itemMenu);
		}
		this.section = menu.filter((cat) => cat.tab !== 'portal' && cat.showMore).sort((a, b) => a.order - b.order);

		this.portals = menu.filter((cat) => cat.tab === 'portal').sort((a, b) => a.order - b.order);
		const treeStart = this.buildTree(this.section);
		const treeAll = this.buildTree(this.portalsMapAll);

		this.flattenMenuData = this.flattenData(treeStart);
		this.flattenMenuDataStart = this.flattenMenuData;
		this.flattenMenuDataAll = this.flattenData(treeAll);
	};

	showAll = async () => {
		if (!this.isShowAll) {
			this.flattenMenuData = this.flattenMenuDataAll;
		} else {
			this.flattenMenuData = this.flattenMenuDataStart;
		}
		this.isShowAll = !this.isShowAll;
	};

	buildTree(data, parentId = null) {
		const tree = [];
		data
			.filter((item) => item.parentId === parentId)
			.forEach((item) => {
				const children = this.buildTree(data, item.id);
				if (children.length > 0) {
					item.children = children;
				}
				tree.push(item);
			});
		return tree;
	}

	flattenData(tree) {
		const result = [];

		tree.forEach((item) => {
			result.push({ ...item, children: item.children?.sort((a, b) => a.order - b.order) || [] });
		});
		return result;
	}

	goBack = () => {
		store.route.push({ path: `/admin/organizations` });
	};
}
