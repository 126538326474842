import React from 'react';
import { AccordionContent } from 'components/AccordionContent';
import Sidebar from 'client/pages/vacancy/Sidebar';
import Filters from 'client/pages/vacancy/filters/Filters';
import { MobileFilters } from 'components/Filters';

export class FiltersMobile extends React.Component{

	constructor(props) {
		super(props);
	}

	render() {

		return (
			<div className='vacancy-left-part'>
				<MobileFilters>
					<Sidebar />
				</MobileFilters>
				<Filters to={'vacancy'} />
			</div>
		);
	}

}