import { format, formatRelative, add } from 'date-fns';
import { ru } from 'date-fns/locale';
import t from 'i18n';

export const fixedTimezone = (date) => add(new Date(date), { minutes: new Date().getTimezoneOffset() });

export const relative = (date) => {
	return date ? formatRelative(new Date(date), new Date(), { locale: ru, weekStartsOn: 1 }) : null;
};

export const formatDate = (date, _format = 'dd.MM.yyyy') => (date ? format(new Date(date), _format, { locale: ru, weekStartsOn: 1 }) : '');
export const formatTime = (int, _format = 'dd.MM.yyyy HH:mm:ss') => formatDate(new Date(int), _format);

export const getYearArray = (n = 5) => {
	const currentYear = new Date().getFullYear();
	return new Array(n).fill(null).map((item, index) => currentYear - index);
};

export const MONTHES = [
	{ value: 1, label: t('month.january') },
	{ value: 2, label: t('month.february') },
	{ value: 3, label: t('month.march') },
	{ value: 4, label: t('month.april') },
	{ value: 5, label: t('month.may') },
	{ value: 6, label: t('month.june') },
	{ value: 7, label: t('month.july') },
	{ value: 8, label: t('month.august') },
	{ value: 9, label: t('month.september') },
	{ value: 10, label: t('month.october') },
	{ value: 11, label: t('month.november') },
	{ value: 12, label: t('month.december') },
];

export const getMonthLabel = (value) => MONTHES.find((month) => month.value === value).label;
