import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CitizenAppeal from './CitizensAppeals';
import AppealSentPage from './AppealSentPage';
import FormEvaluationOfResults from './FormEvaluationOfResults';

export const CitizensAppeals = () => (
	<Switch>
		<Route path='/citizens-appeals' exact component={CitizenAppeal} />
		<Route path='/citizens-appeals/appeal-sent' component={AppealSentPage} />
		<Route path='/citizens-appeals/form-evaluation-of-results/:id' component={FormEvaluationOfResults} />
	</Switch>
);
