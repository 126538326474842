import React, { Component } from 'react';
import './style.scss';
import { PopularServices, SliderPlacement, VacancyWidget } from 'components';

class SummaryBlock extends Component {
	render() {
		return (
			<>
				<div className='summary-block-container'>
					<div className='summary-block'>
						<div className='vacancy-block'>
							<VacancyWidget />
						</div>
						<div className='popular-services-slider-block'>
							<div className='popular-services'>
								<PopularServices />
							</div>
							<div className='slider-block'>
								<SliderPlacement />
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default SummaryBlock;
