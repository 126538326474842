import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Vacancys from './Vacancys';
import { Desktop, Mobile } from 'components';
import MobileSinglePage from 'client/pages/vacancy/MobileSinglePage';
import SwitchSinglePage from 'client/pages/vacancy/SwitchSinglePage';

export const Vacancy = () => (
	<Switch>
		<Route path='/vacancy' exact component={Vacancys} />
		<Route path='/vacancy/:id' component={SwitchSinglePage} />
	</Switch>
);
