import React from 'react';
import { Link } from 'react-router-dom';
import t from 'i18n';
import ArrowLeft from 'client/img/icon-arrow_left-black.svg';
import './style.scss';

export const BackLink = ({ path }) => (
	<Link to={path} className='back-link'>
		<ArrowLeft />
		<span className='link-text'>{t('backToSection')}</span>
	</Link>
);
