import React from 'react';
import './style.scss';
import { Button, Picture } from 'components';
import { observer } from 'mobx-react';
import { formatDate } from 'client/helpers';
import imageNull from 'client/img/vacancy-no-image-big.png?url';
import IconUpload from 'img/icon-upload.svg';
import t from 'i18n';
import store from 'client/store';
import ExternalLink from 'helpers/externalLink';

@observer
export default class Vacancy extends React.Component {
	render() {
		const { vacancy } = this.props;
		const {
			organization,
			title,
			branch,
			status,
			county,
			education,
			workExp,
			image,
			description,
			basis,
			primarySourceSite,
			contacts,
			contactPerson,
			publishAt,
			updatedAt,
			linkBasis,
			linkPrimarySourceSite,
			linkContactPerson,
			linkButton,
			attachments,
		} = vacancy;

		return (
			<>
				<div className='vacancy-page'>
					<div className='text-vacancy'>
						<div className='vacancy-item'>
							<div>
								<p className='initiator p2'>{organization?.name}</p>
							</div>
							<div>
								<p className='title h7'>{title}</p>
							</div>
							<div className='category p2'>{branch?.name}</div>
							<div className='status-county'>
								<div className='status-county-item'>
									<div className='title-status-county p4'>{t('status')}</div>
									<p className='p2'>{status?.name}</p>
								</div>
								<div className='status-county-item2'>
									<div className='title-status-county p4'>{t('county.title')}</div>
									<p className='p2'>{county?.name}</p>
								</div>
							</div>
							<div className='education-work_experience'>
								<div className='education-work_experience-item'>
									<div className='title-education-work_experience p4'>{t('vacancy.education')}</div>
									<p className='p2'>{education?.name}</p>
								</div>
								<div className='education-work_experience-item2'>
									<div className='title-education-work_experience p4'>{t('vacancy.workExp')}</div>
									<p className='p2'>{workExp?.name}</p>
								</div>
							</div>
						</div>
						{image ? (
							<Picture src={vacancy.downloadFile('image')} width={142} height={142} className='image-vacancy' alt='no-image' />
						) : (
							<div className='image-vacancy'>
								<Picture src={imageNull} width={142} height={142} alt='no-image' />
							</div>
						)}
					</div>
					<div className='other-vacancy'>
						<div className='margin_bottom'>
							<div className='description p4'>{t('description')}</div>
							<div className='p2' dangerouslySetInnerHTML={{ __html: description }} />
						</div>
						<div className='margin_bottom'>
							<div className='footing p4'>{t('vacancy.basis')}</div>
							<div className='footing-item p2'>
								<ExternalLink to={linkBasis}>{basis}</ExternalLink>
							</div>
						</div>
						<div className='margin_bottom'>
							<div className='origin-source p4'>{t('vacancy.primarySourceSite')}</div>
							<div className='origin-source-item p2'>
								<ExternalLink to={linkPrimarySourceSite}>{primarySourceSite}</ExternalLink>
							</div>
						</div>
						<div className='margin_bottom'>
							<div className='contacts p4'>{t('contacts')}</div>
							<div className='p2'>{contacts}</div>
						</div>

						<div className='button margin_bottom'>
							<ExternalLink to={linkButton}>
								<Button className='button-1 p2'>{t('vacancy.respond')}</Button>
							</ExternalLink>
						</div>

						<div className='margin_bottom'>
							<div className='contacts p4'>{t('vacancy.contactPerson')}</div>
							<p className='status p2'>
								<ExternalLink to={linkContactPerson}>{contactPerson}</ExternalLink>
							</p>
						</div>
						<div className='margin_bottom'>
							<div className='app p4'>{t('application')}</div>
							<div className='application'>
								{attachments().map((a) => (
									<a className='docs' target='_blank' href={a.downloadFile('filename')} download key={a.id}>
										<div className='file-name p2'>{a.filename}</div>
										<IconUpload />
									</a>
								))}
							</div>
						</div>
						<div className='date margin_bottom'>
							<div className='p2'>
								{t('publishAt')}: {formatDate(publishAt, 'dd.MM.yyyy')}
							</div>
							<div className='p3'>
								{t('updatedAt')}: {formatDate(updatedAt, 'dd.MM.yyyy')}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
