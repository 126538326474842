import React, { Component } from 'react';
import './style.scss';
import family from 'client/img/popular-services/family.png?url';
import iconExternalLink from 'client/img/popular-services/external-link.svg?url';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { Link } from 'react-router-dom';
import { getModelWhere } from 'helpers';
import ExternalLink from 'helpers/externalLink';

@observer
export default class PopularServices extends Component {
	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const isMainShowAll = false;
		const where = getModelWhere(store.model.PopularServicesBig, isMainShowAll);
		this.records = await store.model.PopularServices.find({
			where,
			order: 'id desc',
			include: [
				{
					relation: 'module',
					scope: { include: [{ relation: 'icon', scope: { fields: ['id', 'name', 'code'] } }] },
				},
			],
		});
		this.record = this.records[0];
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		const module = this.record && this.record.module();
		const newArrModule = module && module.totalCount >= 4 && module.totalCount <= 6;
		return (
			<>
				{newArrModule && (
					<div className='popular-services-container'>
						<div className='popular-services-header h6'>Популярные услуги</div>
						<div className='popular-services'>
							<div className='popular-services-image'>
								<img src={family} alt='family' />
							</div>
							<div className='popular-services-blocks'>
								<div className='block-column'>
									{module &&
										module.map((rec) => (
											<ExternalLink to={rec.link} className='block' key={rec.id}>
												{rec.iconId ? (
													<img src={rec.icon.downloadFile('icon')} alt='icon' className='icon-block' />
												) : <div className='empty'>{t('banner.noImage')}</div> ? (
													<img src={rec.downloadFile('image')} alt='image' className='icon-block' />
												) : (
													<div className='empty'>{t('banner.noImage')}</div>
												)}
												<div className='text-block'>{rec.header}</div>
												<img src={iconExternalLink} alt='external-link' className='external-link-block' />
											</ExternalLink>
										))}
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}
