import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AboutRepublic as _AboutRepublic } from './AboutRepublic';
// import AboutRepublicSearchPage from './AboutRepublicSearchPage';
import { GeneralPage } from './GeneralPage';
import { MaterialPage } from './MaterialPage';
import './style.scss';

export const AboutRepublic = ({ match }) => {
	const { path } = match;
	return (
		<Switch>
			<Route path={path} exact component={_AboutRepublic} />
			{/* <Route path={`${path}/search-page/:category`} component={AboutRepublicSearchPage} /> */}
			<Route path={`${path}/general-information`} exact render={({ match }) => <GeneralPage path={path} match={match} />} />
			<Route path={`${path}/:id`} render={({ match }) => <MaterialPage path={path} match={match} />} />
		</Switch>
	);
};
