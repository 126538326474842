import { observable } from 'mobx';
import store from 'client/store';

export default class QuestionAnswerStore {
	@observable records = [];
	@observable categories = [];
	@observable category = null;

	constructor() {
		this.init();
	}

	onCategoryChange = (category) => {
		this.category = category;
		this.fetchRecords();
	};

	init = async () => {
		this.categories = await store.model.QuestionAnswerCategory.find();
		const poll = await store.model.ViewDiscussion.find({
			where: {
				startDate: { lt: Date.now() },
				showMain: true,
				siteDomain: store.ui.site.domain,
			},
			order: 'id DESC',
			limit: 1,
		});
		this.poll = poll[0];
		await this.fetchRecords();
	};

	fetchRecords = async () => {
		this.records = await store.model.ViewQuestionAnswer.find({
			where: {
				organizationId: store.ui.site.organizationId,
				or: [{ siteId: store.ui.site.id }, { siteId: null }],
				branchCategoryId: this.category?.id,
				publish: true,
			},
			include: [
				{ relation: 'branchCategory', scope: { fields: ['id', 'name'] } },
				{ relation: 'subCategory', scope: { fields: ['id', 'name'] } },
			],
			fields: ['id', 'title', 'description', 'image', 'publishAt', 'branchCategoryId', 'subCategoryId'],
			order: 'publishAt asc',
			limit: 5,
		});
	};
}
