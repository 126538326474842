import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';

@observer
export default class WeatherScale extends React.Component {
	
	render() {
		const { onMount, hidden, className } = this.props;
		
		return <div className={'weather-scale-container ' + (className || '') + (hidden ? ' hidden' : '')}>
			<div className="weather-scale" ref={onMount} />
		</div>;
	}
	
}
