import { observable } from 'mobx';
import store from 'client/store';
import { endOfDay, startOfDay } from 'date-fns';
import { getModelWhere } from 'helpers';

export default class OpenDataStore {
	@observable filter = {
		dataCategory: null,
		organization: null,
		chapter: null,
		label: null,
		actualDataAt: null,
		order: 'updatedAt asc',
		search: '',
	};

	@observable records = null;
	@observable recordsAll = null;
	@observable totalCountOpenData = 0;
	@observable isLoading = true;
	@observable PER_PAGE = 5;
	@observable isFilterActive = false;

	constructor(search) {
		this.filter.search = search ?? '';
		this.init();
	}

	init = async () => {
		const { dataCategory, organization, actualDataAt, chapter, label } = this.filter;
		const model = store.model.ViewOpenData;
		const firstDate = startOfDay(actualDataAt);
		const lastDate = endOfDay(firstDate);
		const where = getModelWhere(model);
		this.isFilterActive = Boolean(dataCategory || organization || chapter || label || actualDataAt);
		if (dataCategory) where.and.push({ dataCategoryId: dataCategory.id });
		if (organization) where.and.push({ organizationId: organization.id });
		if (chapter) where.and.push({ chapterId: chapter.id });
		if (label) where.and.push({ labelIds: { ilike: `%${label.id}%` } });
		if (actualDataAt) where.and.push({ actualDataAt: { between: [firstDate, lastDate] } });

		const trimmed = this.filter.search.trim();
		this.records = await store.model.ViewOpenData.find({
			search: trimmed.length > 0 ? trimmed : undefined,
			where,
			include: ['chapter', { relation: 'labels', scope: { fields: ['id', 'name', 'code'] } }, 'organization'],
			order: this.filter.order,
			limit: this.PER_PAGE,
		});

		this.recordsAll = await model.find({
			where,
			include: ['chapter', { relation: 'labels', scope: { fields: ['id', 'name', 'code'] } }, 'organization'],
			order: this.filter.order,
		});
		this.totalCountOpenData = this.recordsAll.totalCount;
		this.isLoading = false;
	};

	showMoreOpenData = () => {
		this.PER_PAGE += 6;
		this.init();
	};

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.init();
	};
}
