import React from 'react';
import { inject, observer } from 'mobx-react';
import { startOfWeek, isToday } from 'date-fns';
import { EventComponent } from './EventComponent';
import { formatDate } from 'client/helpers';

@inject('store')
@observer
export class CalendarWeek extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	onShowDay = (currentDate) => {
		this.store.filter.date = currentDate;
		this.store.onChangeFilter('calendar', 'day')
	}

	get weekRender() {
		const { filter, eventsByDate } = this.store;
		const calendarCells = [];
		const currentMonth = formatDate(filter.date, 'LLLL');

		for (let i = 0; i < 7; i++) {
			const currentDate = startOfWeek(filter.date, { weekStartsOn: 1 });
			currentDate.setDate(currentDate.getDate() + i);
			const day = String(currentDate.getDate()).padStart(2, '0');
			const date = formatDate(currentDate);
			const month = formatDate(currentDate, 'LLLL');
			const eventsForDate = eventsByDate[date] || [];

			calendarCells.push(
				<td key={i}>
					<div className={`event-day ${isToday(currentDate) ? 'today' : ''}`}>
						<div className='days-cell'>
							<span className={`h6 day-cell ${currentMonth !== month ? 'day-cell-not-current' : ''}`}>{day}</span>
							<span className='p5 current-day-cell'>{isToday(currentDate) ? 'СЕГОДНЯ' : ''}</span>
						</div>
						{eventsForDate.map((event, index) => (
							<EventComponent key={index} event={event} onChangePopup={this.onChangePopup} />
						))}
						{!!eventsForDate.length && <div onClick={() => this.onShowDay(currentDate)} className='show-more'>Посмотреть все</div>}
					</div>
				</td>
			);
		}

		return calendarCells;
	}

	render() {
		const { isLoading } = this.store;

		return (
			<table className='event-calendar' style={{ height: '300px' }}>
				<thead>
					<tr>
						<th>Понедельник</th>
						<th>Вторник</th>
						<th>Среда</th>
						<th>Четверг</th>
						<th>Пятница</th>
						<th>Суббота</th>
						<th>Воскресенье</th>
					</tr>
				</thead>
				<tbody>{!isLoading && <tr>{this.weekRender}</tr>}</tbody>
			</table>
		);
	}
}
