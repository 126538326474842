export default {
	translation: {
		name: 'Name',
		all: 'All',
		organization: {
			title: 'Organization',
			plural: 'Organizations',
			name: 'Name',
			fullName: 'Name',
			shortName: 'Short name',
			ownershipForm: 'Form of ownership ',
			address: 'Legal address',
			headName: 'Full name of the Head',
			headPosition: 'Position of the Head',
			inn: 'TIN',
			ogrn: 'PSRN',
			kpp: 'TRRC',
			comment: 'Comment',
			externalId: 'ID of organization in the external system',
			externalData: 'Data of organization in the external system',
			position: 'Position',
			description: 'Organization description',
			info: 'Information',
			factAddress: 'Address',
			secondaryInfo: 'Secondary info',
			headInfo: 'Info of the Head',
			okato: 'ОКАТО',
			oktmo: 'ОКТМО',
			okved: 'ОКВЭД',
			okpo: 'ОКПО',
			noStaff: 'No staff found',
			noRequests: 'No requests found',
			noBankAccounts: 'No bank accounts found',
			contractor: 'Contractor',
			activityType: 'Activity type',
			structure: {
				style: 'Styling elements',
				background: 'Background color',
				colorText: 'Text color',
				colorBranch: 'Color of branches',
				apply: 'Apply',
				imageStructure: 'Image of structure',
				useImage: 'Use image',
				structureDescription: 'Additional description',
				saveStructureDescription: 'Save description',
				defaultStyles: 'Default styles',
			},
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'E-mail',
			input: 'Enter your E-mail',
			exist: 'E-mail already exist',
		},
		search: {
			article: 'Articles',
			event: 'Events',
			vacancy: 'Vacancys',
			questionAnswer: 'Question-answer',
			linksFeedLink: 'Useful materials',
			album: 'Media',
			document: 'Documents',
			goToSection: 'Go to section',
			allResults: 'All results in section',
		},
		article: {
			priority: 'Output priority',
		},
		file: {
			moveOrSelectFile: 'Move or select file',
		},
		checklist: {
			number: '№',
			createByMenu: 'Create a checklist',
			error: 'Error updating the checklist',
			successCreate: 'The checklist has been updated',
			dataSection: 'Information Sections',
			dataSubsection: 'Subsections of information',
			data: 'Intelligence',
			dataType: 'Type of information',
			publishPeriods: 'Publication periods',
			controls: 'Controls',
			responsibleSpecialists: 'Information on responsible specialists',
			variableLegalBasis: 'Regulatory and legal justification',
			link: 'Link',
			createdAt: 'Created date',
			errorUpdateSites: 'Error updating site checklists',
			successUpdateSites: 'The sites have been successfully updated',
			isRequirePage: 'Required page',
			nextDateUpdate: 'Next expected update date',
			textPublishPeriods: 'The text of the update periodicity',
			nextCheckDate: 'Next verification date',
			updateFrequency: 'Update frequency',
		},
		export: 'Export',
		reportingYear: 'The reporting year',
		currentYear: 'Current year',
		nextReportingYear: 'Following the reporting year',
		lastYear: 'Last year',
	},
};
