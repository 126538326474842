import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Social } from 'components';
import { observable } from 'mobx';
import Copyright from './Copyright';
import store from 'client/store';
import './styles.scss';
import { t } from 'i18next';
import { CookiePopUp } from 'components/cookiePopUp';
import SiteMapStore from 'components/header/menu/portal-map/store';
import ListItem from 'components/header-footer-list/ListItem';
import IMAGES_MAP from 'constants/MenuPortalFooterImages';

@observer
export default class Footer extends React.Component {
	@observable record = null;
	@observable portals = [];
	@observable portalsMap = [];
	@observable flattenMenuData = [];
	@observable menu = [];
	@observable isLoading = false;

	constructor(props) {
		super(props);
		this.id = store.ui.site.templateId;
		this.store = new SiteMapStore();
	}

	renderPortalColumn = (catColumn, images) =>
		catColumn.map((item, index) => <ListItem item={item} key={index} images={images} countChildItem={0} isFooter={true} />);

	render() {
		const { flattenMenuData, portals } = this.store;

		const columns = [[], [], [], []];
		flattenMenuData.forEach((cat) => {
			columns[cat.column ? cat.column - 1 : 0].push(cat);
		});

		return (
			<>
				<footer>
					<div className='footer'>
						<div className='footer-top'>
							<div className='portal-sakhagov'>
								<p className='footer-site-name p4'>{store.ui.site.name}</p>
							</div>
							<div className='social'>
								<Social magazine={store.ui.magazine} />
							</div>
						</div>
						<div className='border'></div>
						<div className='footer-menu'>
							<div className='all-portals-map'>
								<span className='portal-block-header'>{t('footer.siteMapTitle')}</span>
								<div className='portals-map-container'>
									{columns.map((column, index) => (
										<div key={index} className='col-3 portals-map-column'>
											{this.renderPortalColumn(column, IMAGES_MAP)}
										</div>
									))}
								</div>
							</div>
							<div className='portals-list-container'>
								<div className='portals-list-title'>{t('footer.portalsTitle')}</div>
								{portals &&
									portals.map((portal) => (
										<div className='portals-list-item-container' key={portal.id}>
											{portal.link ? (
												<a href={`${portal.link}`} className='portals-list-item'>
													<div className='portals-list-item-image'>{IMAGES_MAP[portal.module] || IMAGES_MAP['all-sites']}</div>
													<div className='portals-map-item-title'>{portal.module ? t(`sites.${portal.module}`) : portal.name}</div>
												</a>
											) : (
												<Link to={`/${portal.module}`} className='portals-list-item'>
													<div className='portals-list-item-image'>{IMAGES_MAP[portal.module]}</div>
													<div className='portals-map-item-title'>{t(`sites.${portal.module}`)}</div>
												</Link>
											)}
										</div>
									))}
							</div>
						</div>
						<div className='border'></div>
						<Copyright />
					</div>
				</footer>
				<CookiePopUp link='/cookie' />
			</>
		);
	}
}
