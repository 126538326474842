import t from 'i18n';

export const ARCHIVE_ITEMS = [
	{ value: 'false', label: t('actual') },
	{ value: 'true', label: t('archive') },
];

export const ORDER_ITEMS = [
	{ value: 'publishAt', label: t('publishAt') },
	{ value: 'acceptAt', label: t('acceptAt') },
];

export const DATEPICKERS_CONFIG = [
	{ key: 'acceptAt', placeholder: t('document.signinAcceptApprovalAt') },
	{ key: 'publishAt', placeholder: t('publishAt') },
	{ key: 'effectiveStartAt', placeholder: t('document.effectiveStartAt') },
	{ key: 'expirationAt', placeholder: t('document.expirationAt') },
];
