import React, { Component } from 'react';
import './style.scss';
import dollarIcon from 'client/img/icon-dollar.svg?url';
import euroIcon from 'client/img/icon-euro.svg?url';

export default class exchangeRate extends Component {
	render() {
		const dollarIconImg = <img src={dollarIcon} alt='dollar' />;
		const euroIconImg = <img src={euroIcon} alt='dollar' />;
		return (
			<>
				<div>
					<p className='exchange-name p6'>Курс валют</p>
				</div>
				<div className='currencies'>
					<div className='currencies-item'>
						<div>{dollarIconImg}</div>
						<div className='course'>
							<div>
								<p className='dollar-course'>81.65</p>
							</div>
							<div>
								<p className='count p1'>+0,05</p>
							</div>
						</div>
					</div>
					<div className='currencies-item euro'>
						<div>{euroIconImg}</div>
						<div className='course'>
							<div>
								<p className='euro-course'>89.37</p>
							</div>
							<div>
								<p className='count p1'>-0,20</p>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
