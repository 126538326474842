import { observer } from 'mobx-react';
import React from 'react';
import { Input, RecordSelect, Select } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';

@observer
export default class Filters extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { filter, onChangeFilter } = this.props;
		const { site, order, registeredOnly } = filter;

		return (
			<div className='discussions-filters'>
				<div className='selectors'>
					{store.ui.site.isMain && (
						<RecordSelect model={store.model.Site} property='name' placeholder={'Источник'} value={site} onChange={(r) => onChangeFilter('site', r)} />
					)}
					<div className='d-flex align-items-center'>
						<Input type='checkbox' onChange={(r) => onChangeFilter('registeredOnly', !registeredOnly)} />
						<span>Только открытые</span>
					</div>
					<div className='sort'>
						<span>Сортировка: </span>
						<Select
							items={[
								{ label: 'По дате (по возрастанию)', value: 'startDate asc' },
								{ label: 'По дате (по убыванию)', value: 'startDate desc' },
							]}
							value={order}
							isRequired
							onChange={(r) => onChangeFilter('order', r)}
							className='drop-down-sm'
							placeholder={'По дате'}
						/>
					</div>
				</div>
			</div>
		);
	}
}
