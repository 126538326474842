import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import MediaQuery from 'react-responsive';

import { SmallArticle } from 'components';
import store from 'client/store';

@observer
export default class News extends React.Component {
	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.records = await store.model.ViewLabel.getArticles({
			// exclude: [currentArticle?.id],
			category: 1,
			willBePublished: true,
			fields: ['id', 'image', 'title', 'publishAt', 'commentsCount', 'viewsCount'],
			limit: 7,
			skip: 0,
			order: 'publishAt desc',
		});
		/*
		this.records = await store.model.ViewArticle.find({
			where: {
				category: 1,
				id: { neq: this.props.currentArticle.id },
				image: { neq: null },
				publish: true,
			},
			fields: ['id', 'title', 'subtitle', 'image', 'countyId', 'countyName', 'publishAt'],
			include: [
				{ relation: 'county', scope: { fields: ['id', 'name'] }},
			],
			limit: 7,
			order: 'publishAt desc',
		});
*/
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) {
			return null;
		}
		return (
			<div className='news-more'>
				<div className='articles-grid'>
					{this.records &&
						this.records
							.map((article, i) => {
								if (i === 0) {
									return (
										<React.Fragment key={article.id}>
											<MediaQuery minWidth={768}>
												<SmallArticle article={article} />
											</MediaQuery>
											{/* <MediaQuery maxWidth={767}><MidArticle article={article} /></MediaQuery> */}
										</React.Fragment>
									);
								}
								return <SmallArticle key={article.id} article={article} />;
							})
							.slice(0, 6)}
				</div>
			</div>
		);
	}
}
