import { observable } from 'mobx';
import store from 'client/store';
import { addDays, endOfDay, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { FILTER_VACANCY } from 'client/pages/vacancy/constants';
import { getModelWhere } from 'helpers';

export default class VacancyStore {
	@observable filter = {
		date: new Date(),
		createdAt: null,
		calendar: null,
		status: null,
		organization: null,
		order: 'publishAt asc nulls last',
		search: '',
	};

	@observable sideBarFilter = {
		counties: new Set(),
		branches: new Set(),
		educations: new Set(),
		experience: null,
	};

	onChangeCheckbox = (prop) => (record, value) => {
		if (!record) {
			this.sideBarFilter[prop]?.clear();
			return;
		}
		value ? this.sideBarFilter[prop].add(record.id) : this.sideBarFilter[prop].delete(record.id);
		this.checkFilterConditions();
	};

	checkFilterConditions = () => {
		this.isSideBarFilterActive = Object.values(this.sideBarFilter).some((filter) => {
			return filter && filter.size > 0;
		});
	};

	@observable records = null;
	@observable vacancy = null;
	@observable selectCounty = [];
	@observable isLoading = true;
	@observable PER_PAGE = 5;
	@observable totalCountVacancies = 0;
	@observable isSideBarFilterActive = false;

	constructor(id, search) {
		this.filter.search = search ?? '';
		if (id) {
			this.loadVacancy(id);
		}
		this.init();
	}

	init = async () => {
		const trimmed = this.filter.search.trim();
		const model = store.model.Vacancy;
		this.records = await model.find({
			...FILTER_VACANCY,
			search: trimmed.length > 0 ? trimmed : undefined,
			where: this.where,
			order: this.filter.order,
			limit: this.PER_PAGE,
		});

		this.recordsAll = await model.find({
			...FILTER_VACANCY,
			search: trimmed.length > 0 ? trimmed : undefined,
			where: this.where,
			order: this.filter.order,
		});

		this.totalCountVacancies = this.recordsAll.totalCount;
		this.isLoading = false;
	};

	showMoreVacancy = () => {
		this.PER_PAGE += 6;
		this.init();
	};

	get where() {
		const { date, calendar, createdAt, status, organization } = this.filter;
		const where = getModelWhere(store.model.Vacancy);

		if (date) {
			switch (calendar) {
				case 'month': {
					const firstDate = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
					const lastDate = addDays(firstDate, 35);
					where.and.push({ createdAt: { between: [firstDate, lastDate] } });
					break;
				}
				case 'week': {
					const firstDate = startOfWeek(date, { weekStartsOn: 1 });
					const lastDate = endOfWeek(firstDate, { weekStartsOn: 1 });
					where.and.push({ createdAt: { between: [firstDate, lastDate] } });
					break;
				}
				case 'day': {
					const firstDate = startOfDay(date);
					const lastDate = endOfDay(firstDate);
					where.and.push({ createdAt: { between: [firstDate, lastDate] } });
					break;
				}
			}
		}

		if (createdAt) where.and.push({ createdAt: { gt: createdAt } });
		if (status) where.and.push({ statusId: status.id });
		if (organization) where.and.push({ organizationId: organization.id });

		return where;
	}

	get sidebarFilteredRecord() {
		const { experience, counties, branches, educations } = this.sideBarFilter;
		return this.records?.filter((record) => {
			if (experience && record.workExpId !== experience.id) return false;
			if (counties.size && !counties.has(record.countyId)) return false;
			if (branches.size && !branches.has(record.branchId)) return false;
			if (educations.size && !educations.has(record.educationId)) return false;
			return true;
		});
	}

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		// из сайдбар компонента
		this.resetAllSidebarFilters();
		this.checkFilterConditions();
		this.init();
	};

	loadVacancy = async (id) => {
		store.history.replace(`/vacancy/${id}`);
		this.vacancy = await store.model.Vacancy.findById(id, {
			...FILTER_VACANCY,
		});
	};

	onClosePopup = () => {
		store.history.replace('/vacancy');
		this.vacancy = null;
	};
}
