import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import './style.scss';
import MoreLogo from 'img/icon-more.svg';
import { PortalMap } from './portal-map';
import classNames from 'classnames';

@observer
export class MobileMenu extends React.Component {
	isActive = (path) => (path === store.route.path ? 'active' : '');

	render() {
		const { className, deepBlue } = this.props;
		const { onOpenPortalMap, showPortalMap } = store.ui;
		const classNameMenu = className !== 'text-black' ? 'link' : 'link blue-border-background';
		const desktopClassName = classNames('mobile-menu', { 'deep-blue': deepBlue });

		return (
			<>
				<div className={desktopClassName}>
					<div className='right links'>
						<div className={classNameMenu + this.isActive('/still')} onClick={onOpenPortalMap}>
							<div className='link-content'>
								<MoreLogo />
								<div className='show-more'>ЕЩЕ</div>
							</div>
						</div>
					</div>
				</div>
				{showPortalMap && <PortalMap />}
			</>
		);
	}
}
