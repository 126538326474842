import React from 'react';
import { observable } from 'mobx';
import { Provider, observer } from 'mobx-react';
import './style.scss';
import NewsStore from 'client/pages/main/news/store';
import { Tab, Tabs } from '@smartplatform/ui';
import { t } from 'i18next';
import Articles from 'components/news/Articles';
import { SmallArticle, AdditionalBanner } from 'components';
import newsIcon from 'client/img/icon-news.svg?url';
import Filters from './filters/Filters';
import { Button } from 'components';
import store from 'client/store';
import More from 'client/pages/news/article/More';
import { SvgIcon } from 'components/svgIcon';

@observer
export default class News extends React.Component {
	@observable isLoading = true;
	@observable appurtenance = [];

	constructor(props) {
		super(props);
		this.store = new NewsStore();
		this.newsAppurtenance();
	}

	newsAppurtenance = async () => {
		const appurtenance = await store.model.Article.find({
			include: [{ relation: 'appurtenance', scope: { fields: ['id', 'name', 'code'] } }],
		});
		this.appurtenance = [...new Set(appurtenance.map((rec) => rec?.appurtenance))];
	};

	goToNews = () => {
		store.route.push({ path: `/news` });
	};

	render() {
		const { setTab, isLoading, smallTab, onChangeFilter, setTabSmallArticle } = this.store;
		if (isLoading) return null;
		const recordsShowOnMain = this.store.records && this.store.records.filter((record) => record.showOnMain === true);
		return (
			<Provider store={this.store}>
				<div className='news-main'>
					<div className='top'>
						<div className='left'>
							<span>{t('Главное')}</span>
							<SvgIcon name='main-news' />
							<div className='line' />
						</div>
						<div className='right'>
							<span>{t('Последние новости')}</span>
							<div className='line' />
						</div>
					</div>
					<div className='news-nav'>
						<Tabs type='manual' className='tabs p1'>
							<Tab title={t('news.last')} onClick={() => setTab('all')} />
							{this.appurtenance &&
								this.appurtenance.map((tab) => {
									if (!tab) return;
									return <Tab title={tab.name} key={tab.id} onClick={() => onChangeFilter('appurtenance', tab)} />;
								})}
						</Tabs>
						<Filters />
					</div>
					<div className='news-container'>
						<div className='left'>
							{this.store?.records && <Articles articles={recordsShowOnMain.slice(0, 8)} />}
							{recordsShowOnMain && recordsShowOnMain.length > 8 && (
								<Button color='grey' onClick={this.goToNews} size='large' className>
									{t('news.showMore')}
								</Button>
							)}
						</div>
						<div className='right'>
							<AdditionalBanner />
							<Tabs type='manual'>
								<Tab title={t('news.last')} onClick={() => setTabSmallArticle('last')} />
							</Tabs>
							{smallTab === 'last' && this.store.records && (
								<More key={'more-' + this.store.records[0]?.id} currentArticle={this.store.records[0]} className='more-articles' />
							)}
						</div>
					</div>
				</div>
			</Provider>
		);
	}
}
