import React from 'react';
import './card.scss';
import classNames from 'classnames';
import EarthArrowIcon from 'img/sections/earth-arrow.svg';
import QuestionIcon from 'img/sections/question.svg';
import PollIcon from 'img/sections/poll.svg';
import EventIcon from 'img/sections/event.svg';
import ArticleImage from 'img/sections/article.svg';

const ICONS = {
	'earth-arrow': <EarthArrowIcon />,
	question: <QuestionIcon />,
	poll: <PollIcon />,
	event: <EventIcon />,
	article: <ArticleImage />,
};

// карточка для блока во всю ширину
export const SectionCard = ({ children, className, title, icon }) => {
	className = classNames('section-card', className);
	icon = typeof icon === 'string' ? ICONS[icon] : icon;
	return (
		<div className={className}>
			<div className='section-card__title'>
				<div className='p4'>{title}</div>
				{icon && <div className='section-card__icon'>{icon}</div>}
				<div className='section-card__line'></div>
			</div>
			<div className='content'>{children}</div>
		</div>
	);
};
