import React from 'react';
import './recordCard.scss';
import classNames from 'classnames';
import { BackLink, PortalLink } from 'components';
import { Link } from 'react-router-dom';
import t from 'i18n';

export const RecordCard = ({ children, className, backUrl, backTitle, currentTitle }) => {
	className = classNames('record-card', className);

	return (
		<div className={className}>
			<div className='header-wrapper'>
				<div className='record-breadcrumbs'>
					<Link to={backUrl} className='back-title'>
						{backTitle}
					</Link>
					<div className='current-title'>{currentTitle}</div>
				</div>
				<PortalLink />
			</div>
			{children}
			{backUrl && <BackLink path={backUrl} />}
		</div>
	);
};
