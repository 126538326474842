import React from 'react';
import { observer } from 'mobx-react';
import { Field, Form } from '@smartplatform/ui';
import store from '../../store';
import { Attachments } from '../../components';
import t from 'i18n';
import { isValidEmail } from 'tools/isValidEmail';
import { observable } from 'mobx';

@observer
export default class Comments extends React.Component {
	@observable validEmail = true;
	constructor(props) {
		super(props);
		this.record = new store.model.Comment({ discussionId: this.props.discussionId });
	}

	handleEmailChange = (email) => {
		this.validEmail = isValidEmail(email);
	};

	render() {
		const { onSend } = this.props;
		const { name, text, email } = this.record;
		const disableSave = !name || !text || !email || !this.validEmail;

		return (
			<Form record={this.record} onSave={onSend} className='npa-comments' disableSave={disableSave} stay>
				<Field property='name' label={t('fio')} isRequired />
				<Field property='organization' label={t('organization.name')} />
				<Field property='email' label={t('email.field')} className='email-valid' isRequired>
					<input className='ui-input text-input' type={email} value={email} onChange={this.handleEmailChange} />
					{!this.validEmail && <div>{t('email.invalidEmailAddress')}</div>}
				</Field>
				<Field property='text' label={t('comment.text')} isRequired>
					<textarea rows={5} />
				</Field>
				<Attachments record={this.record} canUpload={true} withFormContext={true} />
			</Form>
		);
	}
}
