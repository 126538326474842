import React, { Component } from 'react';
import './style.scss';
import { observer } from 'mobx-react';
import store from 'client/store';
import iconGo from 'client/img/widget/icon-go.svg?url';
import ExternalLink from 'helpers/externalLink';

@observer
export default class WidgetVariant4 extends Component {
	render() {
		const widget = store.ui.siteWidgets.find((widget) => widget.variantId === 4);
		if (!widget) return;
		return (
			<div className='widget4'>
				<div className='text-container'>
					<ExternalLink to={widget.link1} className='link-header-container'>
						<div className='link-header'>{widget.linkHeader1}</div>
						<img src={iconGo} alt='go' className='go' />
					</ExternalLink>
					<ExternalLink to={widget.link2} className='link-header-container'>
						<div className='link-header'>{widget.linkHeader2}</div>
						<img src={iconGo} alt='go' className='go' />
					</ExternalLink>
					<ExternalLink to={widget.link3} className='link-header-container'>
						<div className='link-header'>{widget.linkHeader3}</div>
						<img src={iconGo} alt='go' className='go' />
					</ExternalLink>
					<ExternalLink to={widget.link4} className='link-header-container'>
						<div className='link-header'>{widget.linkHeader4}</div>
						<img src={iconGo} alt='go' className='go' />
					</ExternalLink>
					<ExternalLink to={widget.link5} className='link-header-container'>
						<div className='link-header'>{widget.linkHeader5}</div>
						<img src={iconGo} alt='go' className='go' />
					</ExternalLink>
				</div>
			</div>
		);
	}
}
