import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './style.scss';
import { formatDate } from 'helpers';

export default class SmallQuestionAnswer extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { questionAnswer } = this.props;
		if (!questionAnswer) return <div className='question-answer-page-container-isLoading'>...</div>;
		return (
			<Link to={`/question-answer/${questionAnswer.id}`} className='small-question'>
				<div className='category-subcategory'>
					{questionAnswer.branchCategory && <div className='category date p6'>{questionAnswer.branchCategory?.name}</div>}
					{questionAnswer.branchCategory ? (
						<div className='subcategory date p6'>{questionAnswer.subCategory?.name}</div>
					) : (
						<div className='date p6'>{questionAnswer.subCategory?.name}</div>
					)}
				</div>
				<div className='title h7'>{questionAnswer.title}</div>
				<div className='info'>
					<div className='date p3'>{formatDate(questionAnswer.publishAt, 'dd.MM.yyyy')}</div>
				</div>
			</Link>
		);
	}
}
