import { observable } from 'mobx';
import store from 'client/store';
import { addDays, addMonths, endOfDay, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { formatDate } from 'client/helpers';
import { FILTER_EVENTS } from 'client/pages/events/constants';
import { getModelWhere } from 'helpers';

export default class EventsStore {
	@observable filter = {
		date: null,
		calendar: 'week',
		type: null,
		status: null,
		location: null,
		county: null,
		organizer: null,
		executionType: null,
		order: 'date0 asc',
		search: '',
	};

	@observable tab = 'announcement';
	@observable records = [];
	@observable recordsAll = [];
	@observable event = null;
	@observable eventsByDate = null;
	@observable isLoading = false;
	@observable PER_PAGE = 8;

	constructor(id, search) {
		this.filter.search = search ?? '';
		if (id) {
			this.loadEvent(id);
		}
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		const trimmed = this.filter.search.trim();
		this.records = await store.model.Event.find({
			where: this.where,
			search: trimmed.length > 0 ? trimmed : undefined,
			...FILTER_EVENTS,
			order: this.filter.order,
			limit: this.PER_PAGE,
		});

		this.recordsAll = await store.model.Event.find({
			where: this.where,
		});

		const eventsByDate = {};
		this.records.forEach((event) => {
			const date = formatDate(event.date0);
			if (!eventsByDate[date]) {
				eventsByDate[date] = [];
			}
			eventsByDate[date].push(event);
		});
		this.eventsByDate = eventsByDate;
		this.isLoading = false;
	};

	showMoreEvents = () => {
		this.PER_PAGE += 8;
		this.init();
	};

	get where() {
		const { date, type, status, county, organizer, executionType, calendar } = this.filter;
		const where = getModelWhere(store.model.Event);

		if (date) {
			if (this.tab === 'calendar') {
				switch (calendar) {
					case 'month': {
						const firstDate = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
						const lastDate = addDays(firstDate, 35);
						where.and.push({ date0: { between: [firstDate, lastDate] } });
						break;
					}
					case 'week': {
						const firstDate = startOfWeek(date, { weekStartsOn: 1 });
						const lastDate = endOfWeek(firstDate, { weekStartsOn: 1 });
						where.and.push({ date0: { between: [firstDate, lastDate] } });
						break;
					}
					default: {
						const firstDate = startOfDay(date);
						const lastDate = endOfDay(firstDate);
						where.and.push({ date0: { between: [firstDate, lastDate] } });
					}
				}
			} else {
				const firstDate = startOfDay(date);
				const lastDate = addMonths(date, 1);
				where.and.push({ date0: { between: [firstDate, lastDate] } });
			}
		}
		if (type) {
			where.and.push({ typeId: type.id });
		}
		if (status) {
			where.and.push({ statusId: status.id });
		}
		if (county) {
			where.and.push({ countyId: county.id });
		}
		if (organizer) {
			where.and.push({ organizerId: organizer.id });
		}
		if (executionType) {
			where.and.push({ executionTypeId: executionType.id });
		}

		return where;
	}

	setTab = (tab) => {
		this.tab = tab;
		this.PER_PAGE = 8;
		this.init();
	};

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.init();
	};

	loadEvent = async (id) => {
		store.history.replace(`/events/${id}`);
		this.event = await store.model.Event.findById(id, {
			...FILTER_EVENTS,
		});
	};

	onClosePopup = () => {
		store.history.replace('/events');
		this.event = null;
	};
}
