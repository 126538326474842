import CanvasLayerRenderer from 'ol/renderer/canvas/Layer';
import ViewHint from 'ol/ViewHint.js';
import { compose as composeTransform } from 'ol/transform';

import { glTools } from '@smartplatform/map/client';
import particlesVertex from './particles.vert.glsl';
import particlesFragment from './particles.frag.glsl';
import windImgVertex from './windImg.vert.glsl';
import windImgFragment from './windImg.frag.glsl';
import testVertex from './test.vert.glsl';
import testFragment from './test.frag.glsl';
import screenVert from './screen.vert.glsl';
import screenFrag from './screen.frag.glsl';
import updateVert from './update.vert.glsl';
import updateFrag from './update.frag.glsl';
import shadeFrag from './shade.frag.glsl';
import lifeVert from './life.vert.glsl';
import lifeFrag from './life.frag.glsl';

const worldExtent = [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244];
const ZOOM1_RESOLUTION = 19567.87924100512;

export default class WebglWindRenderer extends CanvasLayerRenderer {

	points = [];
	initialized = false;
	pointsRendered = false;

	constructor(imageLayer) {
		super(imageLayer);

		this.options = imageLayer.getProperties();

		this.canvas = document.createElement('canvas');
		this.canvas.className = 'webgl-wind-layer';
		this.canvas.style.position = 'absolute';
		this.canvas.style.zIndex = this.options.zIndex || 0;
		this.canvas.style.opacity = this.options.opacity || 0.8;
		this.pointSizeFunc = this.options.pointSizeFunc;

		this.fadeOpacity = this.options.fadeOpacity || 0.995;
		this.speedFactor = this.options.speedFactor || 5;
		this.particleLife = 255;
	}

	initGL() {
		this.gl = this.canvas.getContext('webgl', { premultipliedAlpha: false });
		const gl = this.gl;

		// this.gl.enable(this.gl.DEPTH_TEST);
		// this.gl.blendFunc(this.gl.ONE, this.gl.ONE_MINUS_SRC_ALPHA);
		// this.gl.depthMask(this.gl.FALSE);
		// this.gl.enable(this.gl.BLEND);

		this.gl.viewportWidth  = this.canvas.width;
		this.gl.viewportHeight = this.canvas.height;

		this.windImgProgram = glTools.createProgram(this.gl, windImgVertex, windImgFragment);
		this.particlesProgram = glTools.createProgram(this.gl, particlesVertex, particlesFragment);
		this.testProgram = glTools.createProgram(this.gl, testVertex, testFragment);
		this.screenProgram = glTools.createProgram(gl, screenVert, screenFrag);
		this.shadeProgram = glTools.createProgram(gl, screenVert, shadeFrag);
		this.updateProgram = glTools.createProgram(gl, updateVert, updateFrag);
		this.lifeProgram = glTools.createProgram(gl, lifeVert, lifeFrag);

		this.createRectBuffer();
		this.vertexBuffer = this.gl.createBuffer();

		this.screenTextureSize = [ this.canvas.width, this.canvas.height];

		const emptyPixels = new Uint8Array(this.screenTextureSize[0] * this.screenTextureSize[1] * 4);

		const params = { filter: this.gl.NEAREST, width: this.screenTextureSize[0], height: this.screenTextureSize[1] };
		this.screenTexture = glTools.createTexture(gl, emptyPixels, params);
		this.backgroundTexture = glTools.createTexture(gl, emptyPixels, params);
		this.targetTexture = glTools.createTexture(gl, emptyPixels, params);

		this.setParticlesCount(65536);

		this.targetBuffer = gl.createFramebuffer();
		this.framebuffer = gl.createFramebuffer();

		this.glInitialized = true;
	}

	setSpeedFactor = value => this.speedFactor = value;
	setFadeOpacity = value => this.fadeOpacity = value;
	setParticleLife = value => this.particleLife = value;

	remove = () => {
		if (this.canvas.parentNode) this.canvas.parentNode.removeChild(this.canvas);
		this.canvas = null;
		this.glInitialized = false;
	};

	prepareFrame(frameState) {
		if (!this.canvas) return false;

		const { size, pixelRatio, viewState, viewHints } = frameState;

		const width = Math.round(size[0] * pixelRatio);
		const height = Math.round(size[1] * pixelRatio);

		if (this.canvas.width !== width || this.canvas.height !== height) {
			this.canvas.width = width;
			this.canvas.height = height;
			this.glInitialized = false;
			this.initGL();
		}

		const imageSource = this.getLayer().getSource();

		if (imageSource && !this.windImage) {
			const projection = viewState.projection;
			const image = imageSource.getImage(worldExtent, ZOOM1_RESOLUTION, pixelRatio, projection);
			const loaded = this.loadImage(image);
			if (image && loaded) {
				if (this.gl) this.gl.bindTexture(this.gl.TEXTURE_2D, null);
				this.windImage = image;
				if (this.options.onLoad) this.options.onLoad(this.windImage.getImage(), this.colorRamp);
				if (!this.windTexture) {
					this.windTexture = glTools.createTexture(this.gl, this.windImage.getImage(), { repeat: true, width, height });
				}
			}
		}

		// if (!viewHints[ViewHint.ANIMATING] && !viewHints[ViewHint.INTERACTING]) {
		// }

		return true;
	}

	renderFrame(frameState, target) {
		if (!this.glInitialized || !this.windTexture || !this.windImgProgram || !this.particlesProgram) {
			// console.log('! skip', this.windTexture, this.windImgProgram);
			return;
		}

		const { viewState, pixelRatio, viewHints, size } = frameState;
		const { resolution, zoom } = viewState;

		const gl = this.gl;

		const width = size[0] * pixelRatio / 2;
		const height = size[1] * pixelRatio / 2;

		// if (viewHints[ViewHint.ANIMATING] || viewHints[ViewHint.INTERACTING]) {
		// 	console.log('>', scale, coordinateToPixelTransform, frameState);
		// }

		gl.disable(gl.DEPTH_TEST);
		gl.disable(gl.STENCIL_TEST);

		glTools.bindFramebuffer(gl, this.framebuffer, this.targetTexture);
		gl.viewport(0, 0, this.screenTextureSize[0], this.screenTextureSize[1]);

		{
			const windImgProgram = this.windImgProgram.program;
			gl.useProgram(windImgProgram);

			const uResolution = gl.getUniformLocation(windImgProgram, 'u_resolution');
			gl.uniform2f(uResolution, this.canvas.width, this.canvas.height);

			const uBlur = gl.getUniformLocation(windImgProgram, 'u_blur');
			// gl.uniform1i(uBlur, zoom > 3 ? 0 : 1);
			gl.uniform1i(uBlur, 0);

			const imageResolution = this.windImage.getResolution();
			const imagePixelRatio = this.windImage.getPixelRatio();
			const imageExtent = this.windImage.getExtent();
			const viewCenter = viewState.center;
			const viewResolution = viewState.resolution;
			const scale = (pixelRatio * imageResolution) / (viewResolution * imagePixelRatio) / 2;

			const transform = composeTransform(
				this.tempTransform,
				width / 2,
				height / 2,
				scale,
				scale,
				0,
				(imagePixelRatio * (imageExtent[0] - viewCenter[0])) / imageResolution,
				(imagePixelRatio * (viewCenter[1] - imageExtent[3])) / imageResolution
			);

			const img = this.windImage.getImage();

			const scaledWidth = transform[0] * img.width;
			const scaledHeight = transform[3] * img.height;

			const dx = -transform[4] / scaledWidth;
			const dy = -transform[5] / scaledHeight;
			const dw = width / scaledWidth * pixelRatio;
			const dh = height / scaledHeight * pixelRatio;

			const uTextureSize = this.gl.getUniformLocation(windImgProgram, 'u_textureSize');
			this.gl.uniform2f(uTextureSize, img.width, img.height);

			const uTopLeft = this.gl.getUniformLocation(windImgProgram, 'topLeft');
			this.gl.uniform2f(uTopLeft, dx, dy);

			const uRectSize = this.gl.getUniformLocation(windImgProgram, 'rectSize');
			this.gl.uniform2f(uRectSize, dw, dh);

			glTools.bindTexture(gl, this.windTexture, 0);
			gl.uniform1i(windImgProgram.windImage, 0);

			this.drawRect(windImgProgram);
			this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);
		}

		glTools.bindFramebuffer(gl, this.framebuffer, this.screenTexture);
		gl.viewport(0, 0, this.screenTextureSize[0], this.screenTextureSize[1]);

		{
			const program = this.screenProgram;
			gl.useProgram(program.program);
			// gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
			glTools.bindTexture(gl, this.backgroundTexture, 0);
			gl.uniform1i(program.texture, 0);
			gl.uniform1f(program.alpha, this.fadeOpacity);
			// gl.uniform1f(program.alpha, 0.1);
			gl.uniform1f(program.flip, 1);
			this.drawRect(program.program);
			this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);
		}

		// draw particles
		{
			const program = this.particlesProgram;
			gl.useProgram(program.program);

			glTools.bindAttribute(gl, this.particleIndexBuffer, program.a_index, 1);

			glTools.bindTexture(gl, this.targetTexture, 0);
			glTools.bindTexture(gl, this.particleStateTexture0, 1);
			glTools.bindTexture(gl, this.colorRampTexture, 2);

			gl.uniform1i(program.u_wind, 0);
			gl.uniform1i(program.u_particles, 1);
			gl.uniform1i(program.u_color_ramp, 2);

			gl.uniform1f(program.u_particles_res, this.particleStateResolution);
			// gl.uniform2f(program.u_wind_minmax, -100, 100);
			// gl.uniform2f(program.v_wind_minmax, -100, 100);
			
			const shiftX = 0.5 / width;
			const shiftY = 0.5 / height;
			
			gl.uniform2f(program.u_repeat, 0, 0);
			gl.drawArrays(gl.POINTS, 0, this.particlesCount);

			gl.uniform2f(program.u_repeat, shiftX, 0);
			gl.drawArrays(gl.POINTS, 0, this.particlesCount);

			gl.uniform2f(program.u_repeat, 0, shiftY);
			gl.drawArrays(gl.POINTS, 0, this.particlesCount);

			gl.uniform2f(program.u_repeat, shiftX, shiftY);
			gl.drawArrays(gl.POINTS, 0, this.particlesCount);
		}

		// test
/*
		{
			const program = this.testProgram;
			gl.useProgram(program.program);
			glTools.bindTexture(gl, this.targetTexture, 0);
			gl.uniform1i(program.texture, 0);
			gl.uniform1f(program.a_ratio, height / width);
			this.drawTestRect(program.program);
			this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);
		}
*/

		glTools.bindFramebuffer(gl, null);

		{
			// gl.enable(gl.BLEND);
			// gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
			const program = this.shadeProgram;
			gl.useProgram(program.program);
			this.drawRect(program.program);
			this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);
			// gl.disable(gl.BLEND);
		}

		gl.viewport(0, 0, this.canvas.width, this.canvas.height);
		gl.enable(gl.BLEND);
		gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);


		{
			const program = this.screenProgram;
			gl.useProgram(program.program);
			glTools.bindTexture(gl, this.screenTexture, 0);
			gl.uniform1i(program.texture, 0);
			gl.uniform1f(program.alpha, 1);
			gl.uniform1f(program.flip, 0);
			this.drawRect(program.program);
			this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);
		}

		gl.disable(gl.BLEND);
		const temp = this.backgroundTexture;
		this.backgroundTexture = this.screenTexture;
		this.screenTexture = temp;

		// update lifes
		{
			glTools.bindFramebuffer(gl, this.framebuffer, this.particleLifeTexture1);
			gl.viewport(0, 0, this.particleStateResolution, this.particleStateResolution);

			const program = this.lifeProgram;
			gl.useProgram(program.program);

			gl.uniform1i(program.u_particle_life, this.particleLife);
			glTools.bindTexture(gl, this.particleLifeTexture0, 0);
			gl.uniform1i(program.u_lifes, 0);
			gl.uniform1f(program.u_zoom, zoom);
			gl.uniform1f(program.u_speed_factor, this.speedFactor / 10000);

			this.drawRect(program.program);
			gl.drawArrays(this.gl.TRIANGLES, 0, 6);

			const temp = this.particleLifeTexture0;
			this.particleLifeTexture0 = this.particleLifeTexture1;
			this.particleLifeTexture1 = temp;
		}

		// update particles
		{
			glTools.bindFramebuffer(gl, this.framebuffer, this.particleStateTexture1);
			gl.viewport(0, 0, this.particleStateResolution, this.particleStateResolution);

			const program = this.updateProgram;
			gl.useProgram(program.program);

			glTools.bindTexture(gl, this.targetTexture, 0);
			glTools.bindTexture(gl, this.particleStateTexture0, 1);
			glTools.bindTexture(gl, this.particleLifeTexture0, 2);
			glTools.bindTexture(gl, this.initialParticleStateTexture, 3);

			gl.uniform1i(program.u_wind, 0);
			gl.uniform1i(program.u_particles, 1);
			gl.uniform1i(program.u_lifes, 2);
			gl.uniform1i(program.u_initial, 3);
			gl.uniform1f(program.u_zoom, zoom);
			// gl.uniform1f(program.u_speed_factor, this.speedFactor / resolution);
			gl.uniform1f(program.u_speed_factor, this.speedFactor / 10000);

			this.drawRect(program.program);
			gl.drawArrays(this.gl.TRIANGLES, 0, 6);

			// swap the particle state textures so the new one becomes the current one
			const temp = this.particleStateTexture0;
			this.particleStateTexture0 = this.particleStateTexture1;
			this.particleStateTexture1 = temp;
		}

		return this.canvas;
	}

	defaultSetup = (gl, program) => {
		const aPosition = gl.getAttribLocation(program, 'a_position');
		gl.vertexAttribPointer(
			aPosition,
			2,                                  // number of elements per attribute
			gl.FLOAT,                      // type of elements
			false,                              // normalize
			2 * Float32Array.BYTES_PER_ELEMENT, // size of an element in bytes
			0                                   // offset to this attribute
		);
		gl.enableVertexAttribArray(aPosition);
	};

	createRectBuffer = () => {
		const vertices = [
			-1,  1,  0,  0,
 			 1,  1,  1,  0,
			-1, -1,  0,  1,
			 1,  1,  1,  0,
			 1, -1,  1,  1,
			-1, -1,  0,  1,
		];

		this.rectBuffer = this.gl.createBuffer();
		this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.rectBuffer);
		this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(vertices), this.gl.STATIC_DRAW);
	};

	drawRect = (program) => {
		this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.rectBuffer);

		const aPosition = this.gl.getAttribLocation(program, 'a_position');
		this.gl.vertexAttribPointer(
			aPosition,
			2,                                  // number of elements per attribute
			this.gl.FLOAT,                      // type of elements
			false,                              // normalize
			4 * Float32Array.BYTES_PER_ELEMENT, // size of an element in bytes
			0                                   // offset to this attribute
		);
		this.gl.enableVertexAttribArray(aPosition);

		const aTexCoord = this.gl.getAttribLocation(program, 'a_texCoord');
		this.gl.vertexAttribPointer(
			aTexCoord,
			2,                                  // number of elements per attribute
			this.gl.FLOAT,                      // type of elements
			false,                              // normalize
			4 * Float32Array.BYTES_PER_ELEMENT, // size of an element in bytes
			2 * Float32Array.BYTES_PER_ELEMENT, // offset to this attribute
		);

		this.gl.enableVertexAttribArray(aTexCoord);
	}

	drawTestRect = (program) => {
		const vertices = [
			-1,  1,  0,  0,
			 0,  1,  1,  0,
			-1,  0,  0,  1,
			 0,  1,  1,  0,
			 0,  0,  1,  1,
			-1,  0,  0,  1,
		];

		const rectBuffer = this.gl.createBuffer();
		this.gl.bindBuffer(this.gl.ARRAY_BUFFER, rectBuffer);
		this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(vertices), this.gl.STATIC_DRAW);

		const aPosition = this.gl.getAttribLocation(program, 'a_position');
		this.gl.vertexAttribPointer(
			aPosition,
			2,                                  // number of elements per attribute
			this.gl.FLOAT,                      // type of elements
			false,                              // normalize
			4 * Float32Array.BYTES_PER_ELEMENT, // size of an element in bytes
			0                                   // offset to this attribute
		);
		this.gl.enableVertexAttribArray(aPosition);

		const aTexCoord = this.gl.getAttribLocation(program, 'a_texCoord');
		this.gl.vertexAttribPointer(
			aTexCoord,
			2,                                  // number of elements per attribute
			this.gl.FLOAT,                      // type of elements
			false,                              // normalize
			4 * Float32Array.BYTES_PER_ELEMENT, // size of an element in bytes
			2 * Float32Array.BYTES_PER_ELEMENT, // offset to this attribute
		);

		this.gl.enableVertexAttribArray(aTexCoord);
	}

	setParticlesCount = (count) => {
		const gl = this.gl;

		// we create a square texture where each pixel will hold a particle position encoded as RGBA
		const particleRes = this.particleStateResolution = Math.ceil(Math.sqrt(count));
		this.particlesCount = particleRes * particleRes;

		const particleState = new Uint8Array(this.particlesCount * 4);
		for (let i = 0; i < particleState.length; i ++) {
			particleState[i] = Math.floor(Math.random() * 256); // randomize the initial particle positions
		}

		// textures to hold the particle state for the current and the next frame
		const params = { filter: gl.NEAREST, width: particleRes, height: particleRes };

		this.initialParticleStateTexture = glTools.createTexture(gl, particleState, params);
		this.particleStateTexture0 = glTools.createTexture(gl, particleState, params);
		this.particleStateTexture1 = glTools.createTexture(gl, particleState, params);

		const lifeState = new Uint8Array(this.particlesCount * 4);
		for (let i = 0; i < lifeState.length; i += 4) {
			lifeState[i + 0] = Math.floor(Math.random() * this.particleLife);
			lifeState[i + 1] = 0;
			lifeState[i + 2] = 0;
			lifeState[i + 3] = 255;
		}
		this.particleLifeTexture0 = glTools.createTexture(gl, lifeState, params);
		this.particleLifeTexture1 = glTools.createTexture(gl, lifeState, params);

		const particleIndices = new Float32Array(this.particlesCount);
		for (let i = 0; i < this.particlesCount; i++) particleIndices[i] = i;
		this.particleIndexBuffer = glTools.createBuffer(gl, particleIndices);
	}

	setOpacity = (value) => {
		this.canvas.style.opacity = value;
	};

}

function testImage(image, width, height) {
	image.style.position = 'absolute';
	image.style.zIndex = 1000;
	image.style.left = '100px';
	image.style.top = '100px';
	image.style.width = width + 'px';
	image.style.height = height + 'px';
	document.body.appendChild(image);
}

