import React from 'react';

export class SvgIcon extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		switch (this.props.name) {
			case 'main-news':
				return (
					<svg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' fill='none'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M12.25 1.33817C12.25 0.596596 11.4415 0.162942 10.8248 0.534232C8.02495 2.21983 4.77363 3.21596 1.08679 3.24926C0.628384 3.2534 0.25 3.62527 0.25 4.09318V8.40657C0.25 8.87677 0.63146 9.24764 1.08937 9.25037C1.87268 9.25503 2.61307 9.27188 3.31724 9.31195L3.95108 13.4319C3.97922 13.6148 4.13663 13.7499 4.32172 13.7499H5.125C5.35483 13.7499 5.53059 13.545 5.49564 13.3179L4.90022 9.4851C6.80025 9.81002 9.14534 10.5336 10.7094 11.8132C11.3028 12.2987 12.25 11.9074 12.25 11.0867V1.33817ZM2.40901 4.27188C5.15216 3.9759 8.87331 2.96504 11.125 1.74988V10.7499C8.84412 9.2111 5.18815 8.36709 2.40901 8.22284V4.27188Z'
							fill='#CCE3F0'
						/>
						<path
							d='M14.125 5.49988C13.9179 5.49988 13.75 5.66777 13.75 5.87488V6.62488C13.75 6.83198 13.9179 6.99988 14.125 6.99988H15.625C15.8321 6.99988 16 6.83198 16 6.62488V5.87488C16 5.66777 15.8321 5.49988 15.625 5.49988H14.125Z'
							fill='#CCE3F0'
						/>
						<path
							d='M13.1519 3.4851C13.0483 3.30574 13.1098 3.07639 13.2891 2.97284L14.5882 2.22284C14.7675 2.11929 14.9969 2.18074 15.1004 2.3601L15.4754 3.00962C15.579 3.18898 15.5175 3.41832 15.3382 3.52188L14.0391 4.27188C13.8598 4.37543 13.6304 4.31398 13.5269 4.13462L13.1519 3.4851Z'
							fill='#CCE3F0'
						/>
						<path
							d='M15.3359 8.97284C15.5152 9.07639 15.5767 9.30574 15.4731 9.4851L15.0981 10.1346C14.9946 10.314 14.7652 10.3754 14.5859 10.2719L13.2868 9.52188C13.1075 9.41832 13.046 9.18898 13.1496 9.00962L13.5246 8.3601C13.6281 8.18074 13.8575 8.11929 14.0368 8.22284L15.3359 8.97284Z'
							fill='#CCE3F0'
						/>
					</svg>
				);
				break;
			case 'close':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<path
							d="M18.7026 6.00022L17.9997 5.29733C17.8045 5.10206 17.4879 5.10206 17.2926 5.29733L11.9997 10.5902L6.70684 5.29733C6.51158 5.10206 6.195 5.10206 5.99974 5.29733L5.29684 6.00022C5.10158 6.19548 5.10158 6.51206 5.29684 6.70733L10.5897 12.0002L5.29685 17.2931C5.10159 17.4884 5.10159 17.805 5.29685 18.0002L5.99974 18.7031C6.195 18.8984 6.51158 18.8984 6.70685 18.7031L11.9997 13.4102L17.2926 18.7031C17.4879 18.8983 17.8045 18.8983 17.9997 18.7031L18.7026 18.0002C18.8979 17.8049 18.8979 17.4883 18.7026 17.2931L13.4097 12.0002L18.7026 6.70733C18.8979 6.51206 18.8979 6.19548 18.7026 6.00022Z"
							fill="#464A51" />
					</svg>
				);
				break;
			case 'left-arrow':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
						<path
							d="M5.56931 8.26425L8.43124 5.40232C8.56141 5.27214 8.77247 5.27214 8.90264 5.40232L9.37157 5.87124C9.50161 6.00129 9.50176 6.21209 9.3719 6.34232L7.22027 8.49995L9.3719 10.6576C9.50176 10.7878 9.50161 10.9986 9.37157 11.1287L8.90264 11.5976C8.77247 11.7278 8.56141 11.7278 8.43124 11.5976L5.56931 8.73565C5.43914 8.60548 5.43914 8.39442 5.56931 8.26425Z"
							fill="#A8AFB8" />
					</svg>
				);
				break;
			case 'down-arrow':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
						<path
							d="M4.64637 6.05644L0.353478 1.76354C0.158216 1.56828 0.158216 1.2517 0.353478 1.05644L1.05686 0.353051C1.25193 0.157981 1.56814 0.157761 1.76348 0.352558L4.99992 3.57999L8.23637 0.352558C8.43171 0.157761 8.74792 0.157981 8.94299 0.353051L9.64637 1.05644C9.84163 1.2517 9.84163 1.56828 9.64637 1.76354L5.35348 6.05644C5.15822 6.2517 4.84163 6.2517 4.64637 6.05644Z"
							fill="#464A51" />
					</svg>
				);
				break;
			case 'external-link':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
						<path
							d="M9.83317 2.5C9.55703 2.5 9.33317 2.72386 9.33317 3V3.33333C9.33317 3.60948 9.55703 3.83333 9.83317 3.83333H11.7265L6.19339 9.36645C5.99813 9.56171 5.99813 9.87829 6.19339 10.0736L6.42628 10.3064C6.62155 10.5017 6.93813 10.5017 7.13339 10.3064L12.6665 4.77333V6.66667C12.6665 6.94281 12.8904 7.16667 13.1665 7.16667H13.4998C13.776 7.16667 13.9998 6.94281 13.9998 6.66667V3C13.9998 2.72386 13.776 2.5 13.4998 2.5H9.83317ZM11.9998 12.5H3.99984V4.5H6.1665C6.44265 4.5 6.6665 4.27614 6.6665 4V3.66667C6.6665 3.39052 6.44265 3.16667 6.1665 3.16667H3.6665C3.11422 3.16667 2.6665 3.61438 2.6665 4.16667V12.8333C2.6665 13.3856 3.11422 13.8333 3.6665 13.8333H12.3332C12.8855 13.8333 13.3332 13.3856 13.3332 12.8333V10.3333C13.3332 10.0572 13.1093 9.83333 12.8332 9.83333H12.4998C12.2237 9.83333 11.9998 10.0572 11.9998 10.3333V12.5Z"
							fill="#A8AFB8" />
					</svg>
				);
				break;
			default:
				return null;
				break;
		}
	}
}
