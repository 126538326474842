export const FILTER_EVENTS = {
	include: [
		{ relation: 'type', scope: { fields: ['id', 'name'] } },
		{ relation: 'status', scope: { fields: ['id', 'name'] } },
		{ relation: 'county', scope: { fields: ['id', 'name'] } },
		{ relation: 'organizer', scope: { fields: ['id', 'name'] } },
		{ relation: 'executionType', scope: { fields: ['id', 'name'] } },
		{ relation: 'attachments', scope: { fields: ['id', 'filename'] } },
	],
};
