import { observable } from 'mobx';
import store from 'client/store';
import { getModelWhere } from 'helpers';
import { endOfDay, startOfDay } from 'date-fns';

export default class EventsSmallStore {
	@observable events = [];
	@observable filteredEvents = [];
	@observable holidays = [];
	@observable data = [];
	@observable selectedDate = new Date();

	constructor() {
		this.init();
	}

	init = async () => {
		const where = getModelWhere(store.model.Event);

		if (this.selectedDate) {
			where.and.push({ date0: { between: [startOfDay(this.selectedDate), endOfDay(this.selectedDate)] } });
		}

		this.events = await store.model.Event.find({ where, include: ['type', 'organizer'], limit: 50 });

		this.holidays = this.events.filter((item) => item.type.name === 'Праздник').slice(0, 4);
	};

	changeDate = (date) => {
		this.selectedDate = date;
		this.init();
	};
}
