import { observable } from 'mobx';
import store from 'client/store';
import { getModelWhere } from 'helpers';

export default class MediaStore {
	@observable filter = {
		site: null,
		label: null,
		order: 'date asc',
		search: '',
	};

	@observable tab = 'video';
	@observable records = [];
	@observable record = null;
	@observable tags = null;
	@observable isLoading = false;
	@observable PER_PAGE = 8;
	@observable totalCount = 0;

	constructor(id, search) {
		this.filter.search = search ?? '';
		if (id) {
			this.loadRecord(id);
		}
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		const model = store.model.ViewAlbum;
		const trimmed = this.filter.search.trim();
		this.records = await model.find({
			where: this.where,
			search: trimmed.length > 0 ? trimmed : undefined,
			include: ['media', 'labels'],
			order: this.filter.order,
			limit: this.PER_PAGE,
		});
		if (!this.tags) {
			this.tags = [...new Set(this.records.map((x) => x.labels().flat()).flat())];
		}
		this.totalCount = this.records.totalCount;
		this.isLoading = false;
	};

	get where() {
		const { label, site, search } = this.filter;
		const where = getModelWhere(store.model.ViewAlbum);
		where.and.push({ type: this.tab });

		if (search.length > 0) {
			where.and.push({ name: { ilike: `%${search}%` } });
		}
		if (label) {
			where.and.push({ labelIds: { ilike: `%${label.id}%` } });
		}
		if (site) {
			where.and.push({ siteId: site.id });
		}

		return where;
	}

	showMoreMedia = () => {
		this.PER_PAGE += 6;
		this.init();
	};

	setTab = (tab) => {
		this.tab = tab;
		this.init();
	};

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.init();
	};

	loadRecord = async (id) => {
		store.history.replace(`/media/${id}`);
		this.record = await store.model.ViewAlbum.findById(id, {
			include: ['media', 'labels'],
		});
	};

	onClosePopup = () => {
		store.history.replace('/media');
		this.record = null;
	};
}
