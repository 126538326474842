import React from 'react';
import { Desktop, Mobile, NotPhone, Phone } from 'components';
import Vacancys from 'client/pages/vacancy/Vacancys';
import MobileSinglePage from 'client/pages/vacancy/MobileSinglePage';

export default class SwitchSinglePage extends React.Component{

	constructor(props) {
		super(props);
	}

	render(){

		return(
			<>
				<NotPhone>
					<Vacancys { ...this.props } />
				</NotPhone>
				<Phone>
					<MobileSinglePage { ...this.props } />
				</Phone>
			</>
		);

	}

}