import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Single from './Single';
import './style.scss';

export default () => (
    <Switch>
        <Route path="/map" exact component={Single} />
    </Switch>
);
