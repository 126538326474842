import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Select } from '@smartplatform/ui';
import FilterLayout from '../FilterLayout';
import Hint from '../Hint';
import { silamLayers, gfsLayers } from '.';
import t from 'i18n';
import store from 'client/store';

@observer
export default class WeatherFilter extends React.Component {

	static propTypes = {
		layer: PropTypes.object.isRequired,
		onChange: PropTypes.func,
		isLoading: PropTypes.bool,
		error: PropTypes.any,
	};

	constructor(props) {
		super(props);
		this.settings = props.settings;
	}

	setLayer = (layer) => {
		this.props.layer.settings.layer = layer;
		store.local.save();
		this.props.layer.update();
		this.props.onChange && this.props.onChange({
			...this.props.layer.settings,
			layer,
		});
	};

	onToggle = (value, props) => {
		const { layer } = props;
		layer.settings.show = value;
		store.local.save();
		layer.setVisible && layer.setVisible();
		this.setLayer(layer.settings.layer);
	}

	render() {
		const { layer, isLoading, error } = this.props;
		const silamItems = Object.keys(silamLayers).map(code => ({ label: silamLayers[code].name, value: code }));
		const isSilamLayer = !!silamLayers[layer.settings.layer];

		return <FilterLayout title={t('weather.title')} {...{ layer, isLoading, error }} onToggle={this.onToggle}>
			{Object.keys(gfsLayers).map(code => <div key={code} className="radio-select">
				<input
					type="radio"
					name="weather-layer"
					checked={layer.settings.layer === code}
					disabled={!layer.settings.show}
					onChange={() => this.setLayer(code)}
				/>
				<span>{gfsLayers[code].name}</span>
				{' '}<Hint text={gfsLayers[code].description}/>
			</div>)}

			<div className="radio-select">
				<input
					type="radio"
					name="weather-layer"
					checked={isSilamLayer}
					disabled={!layer.settings.show}
					onChange={() => this.setLayer('pm25')}
				/>
				<span>{t('silam.title')}</span>
				{isSilamLayer && <div className="sub-select">
					<Select noTotal maxItems={10} itemsPerPage={1000}
					        items={silamItems}
					        value={layer.settings.layer}
					        onChange={this.setLayer}
					        isRequired
					        size="sm"
					/>
					{' '}<Hint text={silamLayers[layer.settings.layer].description}/>
				</div>}
			</div>
		</FilterLayout>;
	}

}
