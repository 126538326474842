import { observable } from 'mobx';
import store from 'client/store';
import { endOfDay, startOfDay } from 'date-fns';
import { ORDER_ITEMS } from './constants';
import { getModelWhere } from 'helpers';

export default class DocumentsStore {
	@observable records = [];
	@observable recordsAll = [];
	@observable isLoading = true;
	@observable selectedType = null;
	@observable order = ORDER_ITEMS[0].value;
	@observable publishAt = null;
	@observable acceptAt = null;
	@observable effectiveStartAt = null;
	@observable expirationAt = null;
	@observable status = null;
	@observable search = '';
	@observable PER_PAGE = 4;
	@observable totalCountDocuments = 0;
	@observable showMobileType = false;
	@observable subject = null;
	@observable subjectCode = null;
	@observable types = [];
	page = 1;

	constructor(search, subject) {
		this.search = search ?? '';
		this.subjectCode = subject;
		this.init();
	}

	init = async (code) => {
		if (code) this.subjectCode = code;
		if (this.subjectCode) {
			const [subject] = await store.model.DocumentSubject.find({ where: { code: this.subjectCode }, limit: 1 });
			this.subject = subject;
		}
		await this.fetchRecords();
		await this.setTypes();
		this.isLoading = false;
	};

	setTypes = async () => {
		const where = getModelWhere(store.model.Document);
		this.publishAt && where.and.push({ publishAt: { between: [startOfDay(this.publishAt), endOfDay(this.publishAt)] } });
		this.acceptAt && where.and.push({ acceptAt: { between: [startOfDay(this.acceptAt), endOfDay(this.acceptAt)] } });
		this.effectiveStartAt && where.and.push({ effectiveStartAt: { between: [startOfDay(this.effectiveStartAt), endOfDay(this.effectiveStartAt)] } });
		this.expirationAt && where.and.push({ expirationAt: { between: [startOfDay(this.expirationAt), endOfDay(this.expirationAt)] } });
		this.subject && where.and.push({ subjectId: this.subject.id });

		const documents = await store.model.ViewDocument.find({ where, fields: ['id', 'typeId', 'typeName'] });
		this.types = documents.reduce((acc, record) => {
			if (!record.type) return acc || {};
			if (acc.hasOwnProperty(record.typeName)) {
				acc[record.typeName].count = acc[record.typeName].count + 1;
			} else {
				acc[record.typeName] = {
					count: 1,
					id: record.typeId,
				};
			}
			return acc;
		}, {});
	};

	showAllTypesMobile = () => {
		this.showMobileType = true;
	};

	fetchRecords = async (filters) => {
		const where = getModelWhere(store.model.Document);
		this.publishAt && where.and.push({ publishAt: { between: [startOfDay(this.publishAt), endOfDay(this.publishAt)] } });
		this.acceptAt && where.and.push({ acceptAt: { between: [startOfDay(this.acceptAt), endOfDay(this.acceptAt)] } });
		this.effectiveStartAt && where.and.push({ effectiveStartAt: { between: [startOfDay(this.effectiveStartAt), endOfDay(this.effectiveStartAt)] } });
		this.expirationAt && where.and.push({ expirationAt: { between: [startOfDay(this.expirationAt), endOfDay(this.expirationAt)] } });
		this.selectedType && where.and.push({ typeId: this.selectedType.id });
		this.subject && where.and.push({ subjectId: this.subject.id });

		if (this.status) {
			const or = [{ archive: this.status }];
			if (this.status === 'false') or.push({ archive: null });
			where.and.push({ or });
		}

		const trimmed = this.search.trim();
		const records = await store.model.Document.find({
			where,
			search: trimmed.length > 0 ? trimmed : undefined,
			...filters,
			include: ['organization', 'type', { relation: 'children', scope: { order: 'acceptAt desc' } }],
			order: this.order + ' desc nulls last',
			limit: this.PER_PAGE,
			skip: (this.page - 1) * this.PER_PAGE,
		});

		for (const parent of records) {
			if (!parent.children()) continue;
			for (const children of parent.children()) {
				const [relation] = await store.model.DocumentRelation.find({
					where: { parentId: parent.id, childId: children.id },
					include: [{ relation: 'ligament', fields: ['id', 'name'] }],
					limit: 1,
					fields: ['ligamentId', 'parentId', 'childId'],
				});
				if (relation && relation.ligament) {
					children.__ligamentName = relation.ligament.name;
				}
			}
		}

		this.totalCountDocuments = records.totalCount;
		this.records = this.page === 1 ? records : [...this.records, ...records];
	};

	showMoreDocuments = () => {
		this.page = this.page + 1;
		this.fetchRecords();
	};

	onTypeClick = (type) => {
		if (this.selectedType && this.selectedType.id === type.id) {
			this.selectedType = null;
		} else {
			this.selectedType = type;
		}
		this.page = 1;
		this.fetchRecords();
	};

	onSubmitSearch = async (value) => {
		this.page = 1;
		await this.fetchRecords({ search: value });
	};

	onKeyDown = (e) => {
		if (e.keyCode === 13) {
			this.page = 1;
			this.fetchRecords();
		}
	};

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.selectedType = null;
		this.page = 1;
		this.fetchRecords();
		this.setTypes();
	};
}
