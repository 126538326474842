import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { ManagementCard } from 'client/pages/managements/ManagementCard';

@observer
export class ManagementGroup extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return this.props.managements?.length ? (
			<div className='management-page-group-item'>
				<div className='management-page-group-title p4'>{this.props.title}</div>
				{!!this.props.managements?.length && (
					<div className='management-page-group-person-list'>
						{this.props.managements.map((person) => (
							<ManagementCard person={person} key={person.id} />
						))}
					</div>
				)}
			</div>
		) : (
			''
		);
	}
}
