import React, { Component } from 'react';

class OpenDataIntelligenceTable extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { record } = this.props;
		const stringWithoutTags = record.text?.replace(/(<([^>]+)>)/gi, '');
		const intelligenceTable = (
			<div>
				{record ? (
					<table className='passport-table'>
						<tbody>
							<tr className='table-header'>
								<td className='head'>№</td>
								<td className='head'>Характеристики</td>
								<td className='head'>Значения</td>
							</tr>
						</tbody>
						<tbody>
							<tr className='table-row'>
								<td>1</td>
								<td>Идентификационный номер</td>
								<td>{record.identificationNumber}</td>
							</tr>
						</tbody>
						<tbody>
							<tr className='table-row'>
								<td>2</td>
								<td>Версия методических рекомендаций</td>
								<td>{record.versionGuidelines}</td>
							</tr>
						</tbody>
						<tbody>
							<tr className='table-row'>
								<td>3</td>
								<td>Описание набора данных</td>
								<td dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
							</tr>
						</tbody>
						<tbody>
							<tr className='table-row'>
								<td>4</td>
								<td>Ответственное лицо</td>
								<td>{record.responsiblePerson}</td>
							</tr>
						</tbody>
						<tbody>
							<tr className='table-row'>
								<td>5</td>
								<td>Телефон ответственного лица</td>
								<td>{record.phoneResponsiblePerson}</td>
							</tr>
						</tbody>
						<tbody>
							<tr>
								<td>6</td>
								<td>Адрес электронной почты ответственного лица</td>
								<td>{record.emailResponsiblePerson}</td>
							</tr>
						</tbody>
						<tbody>
							<tr>
								<td>7</td>
								<td>Формат набора открытых данных</td>
								<td>CSV</td>
							</tr>
						</tbody>
						<tbody>
							<tr>
								<td>8</td>
								<td>Описание структуры набора открытых данных</td>
								<td dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
							</tr>
						</tbody>
						<tbody>
							<tr>
								<td>9</td>
								<td>Гиперссылка (URL) на набор</td>
								<td dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
							</tr>
						</tbody>
						<tbody>
							<tr>
								<td>10</td>
								<td>Гиперссылки (URL) на версии открытых данных</td>
								<td>Нет</td>
							</tr>
						</tbody>
						<tbody>
							<tr>
								<td>11</td>
								<td>Гиперссылки (URL) на версии структуры набора данных</td>
								<td>Нет</td>
							</tr>
						</tbody>
					</table>
				) : null}
			</div>
		);
		return (
			<>
				<div className='passport'>
					<p className='head p4'>Сведения</p>
					{intelligenceTable}
				</div>
			</>
		);
	}
}

export default OpenDataIntelligenceTable;
