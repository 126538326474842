import { observable } from 'mobx';

export default class AllProjectsStore {
	@observable statusCode = null;
	@observable filterCounts = {
		inDiscussionCount: 0,
		expiresSoonCount: 0,
		discussionDoneCount: 0,
		orvDoneCount: 0,
	}
}