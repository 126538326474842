import React from 'react';
import { inject, observer } from 'mobx-react';
import './style.scss';
import { Loader } from '@smartplatform/ui';
import MediaCard from './MediaCard';
import { ShowMoreButton } from 'components';
import t from 'i18n';
import { observable } from 'mobx';

@inject('store')
@observer
export class List extends React.Component {
	@observable formsArray = [];
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { records, isLoading, loadRecord, tab, totalCount, showMoreMedia } = this.store;

		if (isLoading) return <Loader />;

		if (!records.length) return <div className='p1 no-record'>{t('media.nothingFound')}</div>;

		return (
			<>
				<div className='media-list'>
					{records.map((record) => (
						<MediaCard key={record.id} record={record} onClick={() => loadRecord(record.id)} type={tab} />
					))}
				</div>
				{records.totalCount > 8 && (
					<ShowMoreButton
						isLoading={isLoading}
						records={records}
						totalCount={totalCount}
						showMore={showMoreMedia}
						formsArray={[t('media.formsArrayFirst'), t('media.formsArraySecond'), t('media.formsArrayThird')]}
					/>
				)}
			</>
		);
	}
}
