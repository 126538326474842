import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { mediaQueryBreakPoints } from 'constants';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { faTentArrowDownToLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrowDown from '../../../img/arrows/arrow-down.svg';
import { getSvgIcon } from 'helpers';
import { AutoAnimate, Desktop, Mobile, NotPhone, Phone } from 'components';

@observer
export class MediaQueryTextWidget extends React.Component{

	@observable slideActive = false;
	@observable showControl = true;
	constructor(props) {
		super(props);
	}

	handleSlide = () => {
		this.slideActive = true;
		this.showControl = false;
	}
	render(){

		const stringWithoutTags = this.props.text?.replace(/(<([^>]+)>)/gi, '');

		return(
			<>
				<NotPhone>
					<div className="widget-text">
						<div className="text-header">{this.props.header}</div>
						<div className="text" dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
					</div>
				</NotPhone>
				<Phone>
					<div className="wrapper-slide-text">
						<AutoAnimate>
							{ this.slideActive ? (
								<div className="content-slide-text">
									<div className="widget-text">
										<div className="text-header">{this.props.header}</div>
										<div className="text" dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
									</div>
								</div>
							) : ''
							}
						</AutoAnimate>
						{this.showControl &&
							<div className="control-slide-text" onClick={this.handleSlide}>
								<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
									<g id="icon">
									<path id="icon_2"
													d="M10.2053 12.5472L6.6279 8.96981C6.46518 8.80709 6.46518 8.54328 6.6279 8.38056L7.21405 7.7944C7.37661 7.63184 7.64011 7.63166 7.8029 7.79399L10.4999 10.4835L13.197 7.79399C13.3598 7.63166 13.6233 7.63184 13.7858 7.7944L14.372 8.38056C14.5347 8.54328 14.5347 8.80709 14.372 8.96981L10.7946 12.5472C10.6318 12.7099 10.368 12.7099 10.2053 12.5472Z"
													fill="white" />
									</g>
								</svg>
							</div>
						}
					</div>
				</Phone>
			</>
		)
	}
}