import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button as _Button } from '@smartplatform/ui';
import './button.scss';

export const Button = ({ color, prependIcon, appendIcon, ...buttonProps }) => {
	buttonProps.className = classNames(buttonProps.className, color);
	if (prependIcon || appendIcon)
		buttonProps.children = (
			<div className='icon-content'>
				{prependIcon} {buttonProps.children} {appendIcon}
			</div>
		);

	return <_Button {...buttonProps} />;
};

Button.propTypes = {
	color: PropTypes.oneOf(['deep-blue', 'light-blue', 'green', 'white', 'blue', 'transparent', 'grey', 'transparent-underline']),
	prependIcon: PropTypes.node,
	appendIcon: PropTypes.node,
};

Button.defaultProps = {
	color: 'deep-blue',
};
