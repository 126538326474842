import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Cancel from 'img/cancel.svg'
import './blind.scss';
import store from 'client/store';

@observer
export default class BadVisionPopup extends React.Component {
	@observable params = {
		size: localStorage.getItem('poorVision-size')
			? localStorage.getItem('poorVision-size').trim()
			: localStorage.setItem('poorVision-size', 'font-size-small') || 'font-size-small',
		style: localStorage.getItem('poorVision-style')
			? localStorage.getItem('poorVision-style').trim()
			: localStorage.setItem('poorVision-style', 'font-style-serif') || 'font-style-serif',
		color: localStorage.getItem('poorVision-color')
			? localStorage.getItem('poorVision-color').trim()
			: localStorage.setItem('poorVision-color', 'color-normal') || 'color-normal',
		string: localStorage.getItem('poorVision-string')
			? localStorage.getItem('poorVision-string').trim()
			: localStorage.setItem('poorVision-string', 'string-interval-small') || 'string-interval-small',
		word: localStorage.getItem('poorVision-word')
			? localStorage.getItem('poorVision-word').trim()
			: localStorage.setItem('poorVision-word', 'word-interval-small') || 'word-interval-small',
		images: localStorage.getItem('poorVision-images')
			? localStorage.getItem('poorVision-images').trim()
			: localStorage.setItem('poorVision-images', 'true') || true,
	};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		document.documentElement.className = `poor-vision ${this.params.size.trim()} ${this.params.style.trim()} ${this.params.color.trim()} ${this.params.string.trim()} ${this.params.word.trim()} ${
			JSON.parse(this.params.images) ? 'images-on' : 'images-off'
		}`;
	}

	componentDidUpdate() {
		document.documentElement.className = `poor-vision ${this.params.size.trim()} ${this.params.style.trim()} ${this.params.color.trim()} ${this.params.string.trim()} ${this.params.word.trim()} ${
			JSON.parse(this.params.images) ? 'images-on' : 'images-off'
		}`;
	}

	setCurrentValue = (evt, key) => {
		localStorage.setItem(`poorVision-${key}`, evt.currentTarget.className.trim());
		if (!evt.currentTarget.classList.contains(`active`)) {
			this.params[key] = evt.currentTarget.className;
		}
	};

	onClose = () => {
		store.local.badVision = false;
		store.local.save();
		document.documentElement.className = '';
	};

	render() {
		return (
			<section className='blind-popup'>
				<div className='blind-popup-font-size-wrapper'>
					<p className='font-size-header blind-header-name'>Размер:</p>
					<div className='label-wrapper'>
						<div className={`${this.params.size.trim() === 'font-size-big' ? 'active' : ''}`}>
							<label className={`font-size-big`} onClick={(evt) => this.setCurrentValue(evt, `size`)} title='Крупный размер шрифта'>
								<input className='visually-hidden' type='radio' name='font-size' />
							</label>
						</div>
						<div className={`${this.params.size.trim() === 'font-size-medium' ? 'active' : ''}`}>
							<label className={`font-size-medium`} onClick={(evt) => this.setCurrentValue(evt, `size`)} title='Увеличенный размер шрифта'>
								<input className='visually-hidden' type='radio' name='font-size' />
							</label>
						</div>

						<div className={`${this.params.size.trim() === 'font-size-small' ? 'active' : ''}`}>
							<label className={`font-size-small`} onClick={(evt) => this.setCurrentValue(evt, `size`)} title='Стандартный размер шрифта'>
								<input className='visually-hidden' type='radio' name='font-size' />
							</label>
						</div>
					</div>
				</div>

				<div className='blind-popup-font-style-wrapper'>
					<p className='font-style-header blind-header-name'>Тип:</p>
					<div className='label-wrapper'>
						<div className={`${this.params.style.trim() === 'font-style-serif' ? 'active' : ''}`}>
							<label className={`font-style-serif`} onClick={(evt) => this.setCurrentValue(evt, `style`)} title='Шрифт с засечками'>
								<input className='visually-hidden' type='radio' name='font-style' />
							</label>
						</div>
						<div className={`${this.params.style.trim() === 'font-style-sans-serif' ? 'active' : ''}`}>
							<label className={`font-style-sans-serif`} onClick={(evt) => this.setCurrentValue(evt, `style`)} title='Шрифт без засечек'>
								<input className='visually-hidden' type='radio' name='font-style' />
							</label>
						</div>
					</div>
				</div>

				<div className='blind-popup-color-wrapper'>
					<p className='color-header blind-header-name'>Цвета:</p>
					<div className='label-wrapper'>
						<div className={`${this.params.color.trim() === 'color-normal' ? 'active' : ''}`}>
							<label className={`color-normal`} onClick={(evt) => this.setCurrentValue(evt, `color`)} title='Черный текст на белом фоне'>
								<input className='visually-hidden' type='radio' name='color' />
							</label>
						</div>
						<div className={`${this.params.color.trim() === 'color-inversion' ? 'active' : ''}`}>
							<label className={`color-inversion`} onClick={(evt) => this.setCurrentValue(evt, `color`)} title='Белый текст на черном фоне'>
								<input className='visually-hidden' type='radio' name='color' />
							</label>
						</div>
						<div className={`${this.params.color.trim() === 'color-blue' ? 'active' : ''}`}>
							<label className={`color-blue`} onClick={(evt) => this.setCurrentValue(evt, `color`)} title='Черный текст на голубом фоне'>
								<input className='visually-hidden' type='radio' name='color' />
							</label>
						</div>
					</div>
				</div>

				<div className='blind-popup-string-interval-wrapper'>
					<p className='string-interval-header blind-header-name'>Строки:</p>
					<div className='label-wrapper'>
						<div className={`${this.params.string.trim() === 'string-interval-small' ? 'active' : ''}`}>
							<label className={`string-interval-small`} onClick={(evt) => this.setCurrentValue(evt, `string`)} title='Стандартный интервал между строк'>
								<input className='visually-hidden' type='radio' name='string-interval' />
							</label>
						</div>
						<div className={`${this.params.string.trim() === 'string-interval-medium' ? 'active' : ''}`}>
							<label className={`string-interval-medium`} onClick={(evt) => this.setCurrentValue(evt, `string`)} title='Увеличенный интервал между строк'>
								<input className='visually-hidden' type='radio' name='string-interval' />
							</label>
						</div>
						<div className={`${this.params.string.trim() === 'string-interval-big' ? 'active' : ''}`}>
							<label className={`string-interval-big`} onClick={(evt) => this.setCurrentValue(evt, `string`)} title='Крупный интервал между строк'>
								<input className='visually-hidden' type='radio' name='string-interval' />
							</label>
						</div>
					</div>
				</div>

				<div className='blind-popup-word-interval-wrapper'>
					<p className='word-interval-header blind-header-name'>Буквы:</p>
					<div className='label-wrapper'>
						<div className={`${this.params.word.trim() === 'word-interval-small' ? 'active' : ''}`}>
							<label className={`word-interval-small`} onClick={(evt) => this.setCurrentValue(evt, `word`)} title='Стандартный интервал между букв'>
								<input className='visually-hidden' type='radio' name='word-interval' />
							</label>
						</div>
						<div className={`${this.params.word.trim() === 'word-interval-medium' ? 'active' : ''}`}>
							<label className={`word-interval-medium`} onClick={(evt) => this.setCurrentValue(evt, `word`)} title='Увеличенный интервал между букв'>
								<input className='visually-hidden' type='radio' name='word-interval' />
							</label>
						</div>
						<div className={`${this.params.word.trim() === 'word-interval-big' ? 'active' : ''}`}>
							<label className={`word-interval-big`} onClick={(evt) => this.setCurrentValue(evt, `word`)} title='Крупный интервал между букв'>
								<input className='visually-hidden' type='radio' name='word-interval' />
							</label>
						</div>
					</div>
				</div>

				<div className='blind-popup-images-wrapper'>
					<p className='images-header blind-header-name'>Изображения:</p>
					<input
						className='images-input'
						type='checkbox'
						checked={JSON.parse(this.params.images)}
						onChange={() => {
							this.params.images = !JSON.parse(this.params.images);
							localStorage.setItem('poorVision-images', this.params.images);
						}}
						title='Включить/выключить изображения'
					/>
				</div>
				<div className='close-wrapper' onClick={this.onClose}>
					<Cancel/>
				</div>
			</section>
		);
	}
}
