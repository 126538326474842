import React from 'react';
import { MobileFilters } from 'components/Filters';
import store from 'client/store';
import { Input, RecordSelect, Select } from '@smartplatform/ui';
import { InputFilter } from 'components/Filters/MobileFilters/InputFilter';

export class FiltersMobile extends React.Component{

	constructor(props) {
		super(props);
	}

	render(){
		const { filter, onChangeFilter } = this.props;
		const { site, order, registeredOnly } = filter;

		const placeholder = 'Поиск по параметру';

		return (
			<div className='wrapper-discussions-mobile-filters'>
				<MobileFilters>
					{store.ui.site.isMain && (
						<InputFilter
							model={store.model.Site}
							property="name"
							onChange={(r) => onChangeFilter('site', r)}
							label={'Источник'}
							placeholder={placeholder}
							nameInput='Site'
						/>
					)}
					<div className="wrapper-group-fields">
						<div className="d-flex align-items-center">
							<Input type="checkbox" onChange={(r) => onChangeFilter('registeredOnly', !registeredOnly)} />
							<span>Только открытые</span>
						</div>
					</div>
				</MobileFilters>
				<div className="sort">
					<span>Сортировка: </span>
					<Select
						items={[
							{ label: 'По дате (по возрастанию)', value: 'startDate asc' },
							{ label: 'По дате (по убыванию)', value: 'startDate desc' },
						]}
						value={order}
						isRequired
						onChange={(r) => onChangeFilter('order', r)}
						className='drop-down-sm'
						placeholder={'По дате'}
					/>
				</div>
			</div>
		);
	}
}