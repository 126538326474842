import React, { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { Tab, Tabs } from '@smartplatform/ui';
import { t } from 'i18next';
import Articles from 'components/news/Articles';
import { ShowMoreButton, SmallArticle } from 'components';
import store from 'client/store';
import { observable } from 'mobx';
import './style.scss';
import More from 'client/pages/news/article/More';

@inject('store')
@observer
export default class newsLists extends Component {
	@observable appurtenance = [];
	@observable recordSmall = [];
	constructor(props) {
		super(props);
		this.store = props.store;
		this.newsAppurtenance();
	}

	componentDidUpdate() {
		this.recordSmall = this.store.recordSmall;
	}

	newsAppurtenance = async () => {
		const appurtenance = await store.model.Article.find({
			include: [{ relation: 'appurtenance', scope: { fields: ['id', 'name', 'code'] } }],
		});
		this.appurtenance = [...new Set(appurtenance.map((rec) => rec?.appurtenance))];
	};
	render() {
		const { articles, store, onChangeFilter } = this.props;
		const { setTab, tab, smallTab, isLoading, showMoreNews, recordsAll, filter } = store;
		const articlesChoice = filter !== '' ? articles : recordsAll;
		return (
			<>
				<Provider store={this.store}>
					<div className='news-page'>
						<Tabs type='manual' className='tabs p1'>
							<Tab title={t('news.last')} onClick={() => setTab('all')} />
							{this.appurtenance &&
								this.appurtenance.map((tab, index) => {
									return <Tab title={tab?.name} key={index} onClick={() => onChangeFilter('appurtenance', tab)} />;
								})}
						</Tabs>
						{tab === 'all' && articles && <Articles articles={articlesChoice} />}
						{articles.totalCount === 0 && <div>{t('news.noNewsFound')}</div>}
						<ShowMoreButton
							isLoading={isLoading}
							records={articles}
							showMore={showMoreNews}
							formsArray={[t('news.formsArrayFirst'), t('news.formsArraySecond'), t('news.formsArrayThird')]}
							totalCount={articles && articles.totalCount}
						/>
					</div>
				</Provider>

				<Provider store={this.store}>
					<div className='right'>
						<Tabs className='tabs p1' type='manual'>
							<Tab title={t('news.newsTop')} onClick={() => this.store.setTabSmallArticle('top')} />
							<Tab title={t('actual')} onClick={() => this.store.setTabSmallArticle('actual')} />
						</Tabs>
						{smallTab === 'top' && this.recordSmall && <More key={'more-' + this.recordSmall[0]?.id} currentArticle={this.recordSmall[0]} />}
						{smallTab === 'actual' &&
							this.recordSmall &&
							this.recordSmall.map((article) => {
								return <SmallArticle key={article.id} article={article} />;
							})}
					</div>
				</Provider>
			</>
		);
	}
}
