import React from 'react';
import './style.scss';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Desktop, Mobile, NotPhone, Phone, SmallArticle, TabBar } from '../../components';
import store from 'client/store';
import Column from './Column';
import { withRouter } from 'react-router-dom';
import Filters from 'client/pages/columns/filters/Filter';
import ColumnsStore from 'client/pages/columns/store';
import { FiltersMobile } from 'client/pages/columns/filters/FiltersMobile';

const INIT_COUNT = 18;

@withRouter
@observer
export default class Columns extends React.Component {
	constructor(props) {
		super(props);
		this.init();
		store.ui.title = 'Статьи';
		this.store = new ColumnsStore();
	}
	@observable records = [];

	componentDidUpdate() {
		this.init();
	}

	init = async () => {
		if (this.props.label && this.isLoading) {
			const label = await store.model.ViewLabel.find({
				where: {
					name: { ilike: this.props.match.params.label },
				},
			});
			this.label = label.length > 0 ? label[0] : this.props.history.push('/' + this.props.category.path);
			this.labelInRoute = this.label;
		}
		if (this.props.topic && this.isLoading) {
			const topic = await store.model.Topic.find({
				where: {
					name: { ilike: this.props.match.params.topic },
				},
			});
			this.topic = topic.length > 0 ? topic[0] : this.props.history.push('/' + this.props.category.path);
		}

		const params = {
			willBePublished: true,
			labels: this.label ? [this.label.id] : [],
			topics: this.topic ? [this.topic.id] : [],
			magazine: this.props.magazineId,
			county: store.local.countyId,
			showOnMain: !store.local.countyId,
			fields: ['id', 'image', 'title', 'publishAt', 'commentsCount', 'viewsCount', 'countyName'],
			limit: INIT_COUNT,
			skip: 0,
			order: 'publishAt desc',
		};

		this.records = await store.model.ViewLabel.getArticles(params);
	};

	render() {
		return (
			<Provider store={this.store}>
				<div className='page-content-single columns-container'>
					<TabBar />
					<div className='columns'>
						<div className='columns-item'>
							{/*<div className='selects'>*/}
							<NotPhone>
								<Filters />
							</NotPhone>
							<Phone>
								<FiltersMobile />
							</Phone>
							{/*</div>*/}
							<div>
								{this.store.records &&
									this.store.records.map((article) => {
										return <Column key={article.id} article={article} />;
									})}
							</div>
						</div>

						{/* SideBar */}
						<div>
							<div className='sidebar'>
								<div className='sidebar-news'>
									<span className='p4'>Теги</span>
									<span className='line'></span>
								</div>
								<div className='p1'>#интернет #выплаты #страховка #школа #ЖКХ #документы #здоровыйотдых #район</div>
								<div className='right p1'>все теги</div>
							</div>
							<div className='sidebar'>
								<div className='sidebar-news'>
									<span className='p4'>Популярные</span>
									<span className='line'></span>
								</div>
								<div className='list-articles'>
									{this.store.records &&
										this.store.records.map((article) => {
											return <SmallArticle key={article.id} article={article} />;
										})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Provider>
		);
	}
}
