import React from 'react';
import classNames from 'classnames';
import { formatDate } from 'helpers';
import { Picture } from 'components';
import noImage from 'client/img/events-no-image.svg?url';

export const EventCard = ({ record, className, onClick }) => {
	className = classNames('event-card', className);

	return (
		<div onClick={onClick} className={className}>
			<div className='p3'>{record.type ? record.type.name : '-'}</div>
			<div className='image'>
				{record.image ? <Picture src={record?.downloadFile('image')} width={260} height={200} /> : <Picture src={noImage} width={260} height={200} />}
			</div>
			<div className='info'>
				<p className='p2 mt-2 mb-2'>
					{record.date0 ? formatDate(record.date0, 'dd MMMM') : ''}
					{record.location && ', '}
					{record.location?.name}
				</p>
				<p className='h6 mt-2 mb-2 event-title'>{record.title}</p>
				<div className='p3'>{record.organizer?.name}</div>
			</div>
		</div>
	);
};
