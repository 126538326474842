import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import { t } from 'i18next';
import { Button } from 'components';
import { Link } from 'react-router-dom';
import { CATEGORY_URL } from 'client/pages/search/constants';
import store from 'client/store';

@observer
export default class SearchCategory extends React.Component {
	goToRecord = (record) => {
		const { section } = this.props;
		const [category] = section;
		if (category === 'linksFeedLink') {
			window.location.href = record.link;
			return;
		}
		store.route.push({ path: `${CATEGORY_URL[category]}/${record.id}`, params: {} });
	};

	render() {
		const { section, goToCategory } = this.props;
		const [category, records] = section;
		const { records: models } = records;

		return (
			<div className='search-category'>
				<div className='header'>
					<div className='h5'>{t(`searchPage.${category}`)}</div>
					<div className='p2 goSection'>
						<Link to={CATEGORY_URL[category]}>{t(`searchPage.goToSection`)}</Link>
					</div>
				</div>

				{models.map((x) => (
					<div key={x.id} className='model' onClick={() => this.goToRecord(x)}>
						<div className='h6 title'>{x.title || x.name}</div>
						<div
							className='p2 description'
							dangerouslySetInnerHTML={{ __html: x.text?.replace(/(<([^>]+)>)/gi, '') || x.description?.replace(/(<([^>]+)>)/gi, '') || x.note }}
						/>
					</div>
				))}

				<Button onClick={() => goToCategory(CATEGORY_URL[category])} color='grey'>{`${t('searchPage.allResults')} ${t('searchPage.' + category)}`}</Button>
			</div>
		);
	}
}
