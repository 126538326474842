import { observer } from 'mobx-react';
import React from 'react';
import { DatePicker, Select } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';
import t from 'i18n';
import { MobileFilters } from 'components/Filters';
import { InputFilter } from 'components/Filters/MobileFilters/InputFilter';

@observer
export default class FiltersMobile extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}
	render() {
		const { filter, onChangeFilter } = this.props.store;
		const { order, actualDataAt } = filter;

		const placeholder = 'Поиск по параметру';

		return (
			<div className='open-data-mobile-sidebar'>
				<MobileFilters>
					{this.props.libraryContent ? this.props.libraryContent : ''}
					<InputFilter
						model={store.model.OpenDataCategory}
						property='name'
						onChange={(r) => onChangeFilter('dataCategory', r)}
						label={t('category')}
						placeholder={placeholder}
						nameInput='OpenDataCategory'
					/>
					<InputFilter
						model={store.model.Organization}
						property='name'
						onChange={(r) => onChangeFilter('organization', r)}
						label={t('organization.title')}
						placeholder={placeholder}
						nameInput='Organization'
					/>
					<div className='wrapper-datepicker'>
						<DatePicker
							className='date-picker'
							value={actualDataAt}
							placeholder={t('openData.actualDataAt')}
							onChange={(r) => onChangeFilter('actualDataAt', r)}
						/>
					</div>
				</MobileFilters>
				<div className='sort'>
					<span className='p2'>Сортировка по: </span>
					<Select
						items={[
							{ label: t('openData.byUpdateDateAscending'), value: 'updatedAt asc' },
							{ label: t('openData.byUpdateDateDescending'), value: 'updatedAt desc' },
						]}
						value={order}
						isRequired
						width={270}
						onChange={(r) => onChangeFilter('order', r)}
						className='drop-down-sm'
					/>
				</div>
			</div>
		);
	}
}
