import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import { DatePicker, RecordSelect } from '@smartplatform/ui';
import downloadIcon from 'client/img/data-open/icon-download.svg?url';
import IconNews from 'client/img/icon-news-color.svg';
import IconHistory from 'client/img/icon-history-color.svg';
import IconAnalytics from 'client/img/icon-analytics-color.svg';
import CheckCircle from 'client/img/check-circle.svg';
import { t } from 'i18next';
import AllProjectsStore from './store';
import { SearchPage } from '../../components/search-page';
import { getModelWhere } from 'helpers';
import { addDays } from 'date-fns';
import { NotPhone, Phone, AutoAnimate, ShowMoreButton } from 'components';
import FiltersMobile from 'client/pages/npa/filters/FiltersMobile';

const STATUSES = ['discussionGoingOn', 'discussionExpiresSoon', 'discussionEnd', 'developmentEnd'];
const STATUS_ICONS = {
	discussionGoingOn: <IconNews />,
	discussionExpiresSoon: <IconHistory />,
	discussionEnd: <IconAnalytics />,
	developmentEnd: <CheckCircle />,
};

@observer
export default class AllProjects extends React.Component {
	@observable records = [];
	@observable isLoading = true;
	@observable totalCount = 0;
	@observable dateFrom = null;
	@observable dateTo = null;
	@observable filter = {
		procedure: '',
		type: '',
		status: '',
		date: '',
		search: '',
	};
	@observable statusCount = null;
	@observable PER_PAGE = 6;
	@observable totalRecordsCount = 0;

	constructor(props) {
		super(props);
		this.initStatus();
		this.init();
		this.store = new AllProjectsStore();
	}

	initStatus = async () => {
		const domain = store.ui.site.isMain ? null : store.domain;
		this.statusCount = await store.model.DiscussionsNPA.getCountByStatus({ domain });
	};

	init = async () => {
		const model = store.model.DiscussionsNPA;
		const records = await model.find({
			where: this.where,
			include: ['type', 'attachments', 'procedure'],
			limit: this.PER_PAGE,
		});
		this.totalRecordsCount = await model.count();
		this.totalCount = records ? records.totalCount : 0;
		this.records = records;
		this.isLoading = false;
	};

	get where() {
		const { status, procedure, search, type, date } = this.filter;
		const where = getModelWhere(store.model.DiscussionsNPA);

		if (date) {
			where.and.push({ createdAt: { gt: date } });
		}
		if (procedure) {
			where.and.push({ procedureId: procedure.id });
		}
		if (status) {
			if (status.code === 'discussionExpiresSoon') {
				where.and.push({ statusId: status.id, endDate: { between: [new Date(), addDays(new Date(), 3)] } });
			} else {
				where.and.push({ statusId: status.id });
			}
		}
		if (type) {
			where.and.push({ typeId: type.id });
		}
		if (search.length > 0) {
			where.and.push({ name: { ilike: `%${search}%` } });
		}

		return where;
	}

	showMoreDiscussionsNPA = () => {
		this.PER_PAGE += 6;
		this.init();
	};

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.init();
	};

	render() {
		if (this.isLoading) {
			return <div className='memory-list'>...</div>;
		}

		const { search, procedure, type, status, date } = this.filter;

		return (
			<>
				<SearchPage
					headerName={t('discussionsNPA.title')}
					placeholder={t('discussionsNPA.searchByDocument')}
					search={search}
					onChangeSearch={(r) => this.onChangeFilter('search', r)}
				/>
				<div className='npa-container'>
					<Phone>
						<FiltersMobile onChangeFilter={this.onChangeFilter} filter={this.filter} />
					</Phone>
					<div className='main'>
						<div className='current'>
							<div className='cards'>
								{this.statusCount &&
									STATUSES.map((x, index) => (
										<div className='card-wrapper' key={index} onClick={() => this.onChangeFilter('status', { id: this.statusCount[x]?.statusId, code: x })}>
											<CurrentCard title={t(`discussionsNPA.${x}`)} icon={STATUS_ICONS[x]} count={this.statusCount[x] && this.statusCount[x].count} />
										</div>
									))}
							</div>
						</div>
					</div>
					<div className='filters'>
						<NotPhone>
							<DatePicker value={date} onChange={(r) => this.onChangeFilter('date', r)} placeholder={t('publishAt')} />
							<RecordSelect
								model={store.model.DiscussionsNPAProcedure}
								property='name'
								placeholder={t('discussionsNPA.procedure')}
								value={procedure}
								onChange={(r) => this.onChangeFilter('procedure', r)}
							/>
							<RecordSelect
								model={store.model.DiscussionsNPAStatus}
								property='name'
								placeholder={t('discussionsNPA.status')}
								value={status}
								onChange={(r) => this.onChangeFilter('status', r)}
							/>
							<RecordSelect
								model={store.model.DiscussionsNPAType}
								property='name'
								placeholder={t('type')}
								value={type}
								onChange={(r) => this.onChangeFilter('type', r)}
							/>
						</NotPhone>
						<div>
							{t('discussionsNPA.documentsFound')} {this.totalCount}
						</div>
					</div>
					<AutoAnimate className='projects'>
						{this.records.map((record) => (
							<Entry key={record.id} record={record} />
						))}
					</AutoAnimate>
					{this.records.totalCount > 6 && (
						<ShowMoreButton
							isLoading={this.isLoading}
							records={this.records}
							totalCount={this.totalRecordsCount}
							showMore={this.showMoreDiscussionsNPA}
							formsArray={[t('discussionsNPA.formsArrayFirst'), t('discussionsNPA.formsArraySecond'), t('discussionsNPA.formsArrayThird')]}
						/>
					)}
				</div>
			</>
		);
	}
}

@observer
class Entry extends React.Component {
	@observable isLoading = true;
	@observable type;
	@observable record;
	@observable attachments;
	@observable procedure;

	constructor(props) {
		super(props);
		this.record = props.record;
		this.init();
	}

	init = async () => {
		this.type = this.record.type;
		this.attachments = this.record?.attachments();
		this.procedure = this.record.procedure;
		this.isLoading = false;
	};
	render() {
		if (this.isLoading) return '...';

		const date = new Date(this.record.updatedAt);
		let year = new Intl.DateTimeFormat('ru', { year: '2-digit' }).format(date);
		let month = new Intl.DateTimeFormat('ru', { month: '2-digit' }).format(date);
		let day = new Intl.DateTimeFormat('ru', { day: '2-digit' }).format(date);
		const re = /(?:\.([^.]+))?$/;
		return (
			<div className='entry'>
				<div className='left'>
					<NotPhone>
						{this.attachments[0] && (
							<>
								<a className='download p2' href={this.attachments?.[0]?.downloadFile('filename')} download>
									<img src={downloadIcon} alt='download' />
									<p className='extention'>{re.exec(this.attachments[0]?.filename)[1]}</p>
								</a>
								<p className='p3'>{this.attachments[0]?.fileSize}</p>
							</>
						)}
					</NotPhone>
					<Phone>
						{this.attachments[0] && (
							<>
								<a className='download p2' href={this.attachments?.[0]?.downloadFile('filename')} download>
									<img src={downloadIcon} alt='download' />
									<p className='extention'>{re.exec(this.attachments[0]?.filename)[1]}</p>
									<p className='p3'>{this.attachments[0]?.fileSize}</p>
								</a>
							</>
						)}
					</Phone>
				</div>
				<Link to={`/npa/${this.record.id}`} className='right'>
					<div className='info'>
						<p className='p4 grey2'>{this.procedure?.name}</p>
						{/* <p className="p2 grey2">{"№ 01-27/12"}</p> */}
					</div>
					<p className='p2 text'>{this.record.name}</p>
					<div className='info'>
						<p className='p2 grey2'>{this.record.department}</p>
						<p className='p2 date'>{`${day}.${month}.${year}`}</p>
					</div>
					{false && (
						<div className='note grey2' href={this.record.annotationLink}>
							{t('discussionsNPA.note')}
						</div>
					)}
				</Link>
			</div>
		);
	}
}

function CurrentCard({ title, count, icon }) {
	return (
		<div className='current-card'>
			<h4>{count}</h4>
			<div className='title'>
				{icon}
				<p className='p4'>{title}</p>
			</div>
		</div>
	);
}
