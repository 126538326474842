import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import './style.scss';
import store from 'client/store';
import MediaSlide from './MediaSlide';
import PropTypes from 'prop-types';
import { Popup } from '@smartplatform/ui';
import SliderMediaArticle from 'client/pages/news/article/SliderMediaArticle';
import { Desktop, Mobile, Phone } from 'components';

@observer
export default class MediaSlider extends React.Component {
	static propTypes = {
		modelName: PropTypes.string,
		articleId: PropTypes.number,
	};

	@observable isLoading = true;
	@observable record = null;
	@observable selected = 0;
	@observable showPopUpSlider = false;
	@observable activeSlide = 0;
	@observable attachments = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { modelName, articleId } = this.props;
		this.record = await store.model[modelName].findById(articleId, {
			include: ['attachments'],
		});
		this.attachments = this.record.attachments();
		this.isLoading = false;
	};

	handleClick = (e) => {
		let value = e.target.getAttribute('data-value');
		if (value !== null) {
			this.selected = value;
		}
	};

	onClosePopup = () => {
		this.showPopUpSlider = false;
	}

	onChangeSlide = (index) => {
		this.showPopUpSlider = true;
		this.activeSlide = index;
	}

	render() {
		if (this.isLoading) return null;
		const { modelName, path, articleId } = this.props;
		const style = this.selected >= 1 ? { transform: `translateX(-${this.selected * 100}%)` } : {};
		const totalCount = this.attachments.length;
		const renderIndicator = [];
		for (let i = 0; i < totalCount / 4; i++) {
			renderIndicator.push(<li data-value={i} key={i} className={this.selected == i ? 'active' : ''}></li>);
		}
		return (
			<>
				<div className='media-slider'>
					<div className='slide-items' style={style}>
						{this.attachments.map((attachment, index) => (
							<div className='media-slider-item' onClick={() => this.onChangeSlide(index)} key={index}>
								<MediaSlide setActiveSlide={this.setActiveSlide} attachment={attachment} />
							</div>
						))}
					</div>

					{totalCount > 4 && (
						<ul onClick={this.handleClick} className='indicators'>
							{renderIndicator}
						</ul>
					)}
				</div>
				{this.showPopUpSlider && (
					<>
						<Desktop>
							<Popup onClose={this.onClosePopup} showCloseButton={false} className="media-popup-slider-article" width={600}>
								<SliderMediaArticle attachments={this.attachments} activeSlide={this.activeSlide} />
							</Popup>
						</Desktop>
						<Phone>
							<Popup onClose={this.onClosePopup} showCloseButton={false} className="media-popup-slider-article">
								<SliderMediaArticle attachments={this.attachments} activeSlide={this.activeSlide} />
							</Popup>
						</Phone>
					</>
				)}
			</>
		);
	}
}
