import React from 'react';
import store from 'client/store';
import { InputFilter } from 'components/Filters/MobileFilters/InputFilter';
import { MobileFilters } from 'components/Filters';

export default class FiltersMobile extends React.Component{

	constructor(props) {
		super(props);

	}

	render(){

		const { onChangeFilter } = this.props;
		const placeholder = 'Поиск по параметру';

		return(
			<div className='wrapper-search-page-mobile-filters'>
				<MobileFilters>
					<InputFilter
						model={store.model.County}
						property="name"
						onChange={(r) => onChangeFilter('county', r)}
						label={'Районы'}
						placeholder={placeholder}
						nameInput='County'
					/>
				</MobileFilters>

			</div>
		);
	}

}