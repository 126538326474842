import React from 'react';
import './style.scss';
import { inject, observer } from 'mobx-react';
import { CalendarWeek } from './calendar-view/CalendarWeek';
import { CalendarMonth } from './calendar-view/CalendarMonth';
import { Toolbar } from './Toolbar';
import { CalendarDay } from './calendar-view/CalendarDay';

@inject('store')
@observer
export class Calendar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.filter.date = new Date();
	}

	render() {
		const { calendar } = this.store.filter;

		return (
			<div className='event-calendar'>
				<Toolbar />
				<div className='wrapper-content-calendar'>
					{calendar === 'day' && <CalendarDay />}
					{calendar === 'week' && <CalendarWeek />}
					{calendar === 'month' && <CalendarMonth />}
				</div>
			</div>
		);
	}
}
