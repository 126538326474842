import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';
import { observable } from 'mobx';
import './style.scss';
import { WeatherFilter } from './layers/weather';
import { WindFilter } from './layers/wind';
import mapStore from '../mapStore';
import FilterLayout from './layers/FilterLayout';

@observer
export default class Filters extends React.Component {
	@observable isLoading = true;
	constructor(props) {
		super(props);
		this.localSettings = store.local;
	}

	render() {
		return mapStore.mapMaximized ? (
			<div className='filters-wrapper'>
				<div className='minimize-btn' onClick={() => mapStore.switchFilters(false)}>
					<FontAwesomeIcon icon={faFilter} />
				</div>
				<div className='map-filters'>
					<FilterLayout title='Районы' layer={mapStore.countiesLayer} />
					<WeatherFilter layer={mapStore.weatherLayer} settings={this.localSettings.weather} />
					<WindFilter layer={mapStore.windLayer} settings={this.localSettings.wind} />
				</div>
			</div>
		) : (
			<div className='filters-minimized' onClick={() => mapStore.switchFilters(true)}>
				<FontAwesomeIcon icon={faFilter} />
			</div>
		);
	}
}
