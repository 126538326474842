import Layer from 'ol/layer/Layer.js';

import WebglWindRenderer from './WebglWindRenderer';

export default class WebglWindLayer extends Layer {

	constructor(options) {
		const baseOptions = Object.assign({}, options);
		super(baseOptions);
	}

	refreshSource = () => {
		const renderer = this.getRenderer();
		renderer.windImage = null;
		renderer.windTexture = null;
	};

	remove = () => {
		const renderer = this.getRenderer();
		if (renderer) renderer.remove();
	};

	setSpeedFactor = (value) => {
		const renderer = this.getRenderer();
		if (renderer) renderer.setSpeedFactor(value);
	};

	setFadeOpacity = (value) => {
		const renderer = this.getRenderer();
		if (renderer) renderer.setFadeOpacity(value);
	};

	setParticleLife = (value) => {
		const renderer = this.getRenderer();
		if (renderer) renderer.setParticleLife(value);
	};

	setOpacity = (value) => {
		const renderer = this.getRenderer();
		if (renderer) renderer.setOpacity(value);
	};

	createRenderer() {
		return new WebglWindRenderer(this);
	}

}
