import { Route, Switch } from 'react-router-dom';
import Lenta from './Lenta';
import React from 'react';
import Article from './article/Article';


export const News = () => (
	<Switch>
		<Route path='/news' exact component={Lenta} />
		<Route path='/news/:id' exact component={Article} />
	</Switch>
);