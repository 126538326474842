import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import store from '../../store';

// const SITE_KEY = '6LdcZNMUAAAAAE--QuBdF6bIN5e4QaF-J56s3ZeK';
const SITE_KEY = '6Lc6itUUAAAAADRuLT9Qu803L4fg9kGkV-UXrgdT';

export default class ReCaptcha extends React.Component {
	static propTypes = {
		onChange: PropTypes.func,
		onExpired: PropTypes.func,
	};

	recaptchaRef = React.createRef();

	onCaptchaChange = async value => {
		if (value) {
			const res = await store.model.Discussion.checkCaptcha({ value });
			console.log('onCaptchaChange', res);
			this.props.onChange && this.props.onChange(res);
		}
	};

	onCaptchaExpired = () => {
		console.log('recaptcha expired');
		this.props.onExpired && this.props.onExpired();
	};

	render() {
		return (
			<ReCAPTCHA
				ref={this.recaptchaRef}
				sitekey={SITE_KEY}
				onChange={this.onCaptchaChange}
				onExpired={this.onCaptchaExpired}
				hl="ru"
			/>
		);
	}
}
