import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from '../../../store';
import Filters from './filters/Filters';
import Poll from './Poll';
import PollIcon from 'img/poll.svg';
import './style.scss';
import { SearchPage } from '../../../components/search-page';
import { getModelWhere } from 'helpers';
import t from 'i18n';
import { NotPhone, Phone, AutoAnimate, ShowMoreButton } from 'components';
import { FiltersMobile } from 'client/pages/discussions/list/filters/FiltersMobile';

@observer
export default class List extends React.Component {
	@observable records = null;
	@observable recordsAll = null;
	@observable dailyPoll = null;
	@observable tags = null;
	@observable filter = {
		site: '',
		registeredOnly: false,
		label: '',
		search: '',
		order: 'startDate desc',
	};
	@observable isLoading = true;
	@observable isLoadingDailyPoll = true;
	@observable PER_PAGE = 5;
	@observable isFilterActive = false;
	@observable totalCountDiscussions = 0;

	constructor(props) {
		super(props);
		this.init();
		this.initDailyPoll();
	}

	init = async () => {
		this.isLoading = true;
		const model = store.model.ViewDiscussion;
		this.records = await model.find({
			where: this.where,
			order: this.filter.order,
			include: ['labels', 'site'],
			limit: this.PER_PAGE,
		});
		this.recordsAll = await model.find({ where: this.where, order: this.filter.order, include: ['labels', 'site'] });
		this.totalCountDiscussions = this.recordsAll.totalCount;
		if (!this.tags) {
			this.tags = [...new Set(this.records.map((x) => x.labels().flat()).flat())];
		}

		this.isLoading = false;
	};

	initDailyPoll = async () => {
		this.dailyPoll = await store.model.ViewDiscussion.findOne({
			where: { publish: true, startDate: { lt: Date.now() } },
			order: 'votedCount DESC',
		});
		this.isLoadingDailyPoll = false;
	};

	get where() {
		const { registeredOnly, site, search, label } = this.filter;
		const where = getModelWhere(store.model.ViewDiscussion);
		this.isFilterActive = Boolean(site || registeredOnly || label);
		where.and.push({ startDate: { lt: Date.now() } });
		if (site) where.and.push({ siteId: site.id });
		if (registeredOnly) where.and.push({ registeredonly: !registeredOnly });
		if (search.length > 0) where.and.push({ name: { ilike: `%${search}%` } });
		if (label) where.and.push({ labelsId: { ilike: `%${label.id}%` } });
		return where;
	}

	showMoreDisscussions = () => {
		this.PER_PAGE += 6;
		this.init();
	};
	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.init();
	};

	render() {
		return (
			<>
				<SearchPage
					headerName='Опросы'
					placeholder={'Поиск опроса'}
					search={this.filter.search}
					onChangeSearch={(r) => this.onChangeFilter('search', r)}
					onChangeTag={(r) => this.onChangeFilter('label', r)}
					tags={this.tags}
				/>
				<Phone>
					<FiltersMobile filter={this.filter} tags={this.tags} onChangeFilter={this.onChangeFilter} />
				</Phone>
				<div className='discussions'>
					<div className='left-discussions'>
						<NotPhone>
							<Filters filter={this.filter} tags={this.tags} onChangeFilter={this.onChangeFilter} />
						</NotPhone>
						{!this.isLoading && (
							<AutoAnimate className='discussions-polls'>
								{this.records.map((poll) => (
									<Poll poll={poll} key={poll.id} />
								))}
							</AutoAnimate>
						)}
						{!this.isFilterActive && (
							<ShowMoreButton
								isLoading={this.isLoading}
								records={this.records}
								showMore={this.showMoreDisscussions}
								formsArray={[t('discussions.formsArrayFirst'), t('discussions.formsArraySecond'), t('discussions.formsArrayThird')]}
								totalCount={this.totalCountDiscussions}
							/>
						)}
					</div>
					<div className='right-discussions'>
						<div className='right-header'>
							<span>Опрос дня</span>
							<PollIcon />
							<div className='line'></div>
						</div>
						{!this.isLoadingDailyPoll && <Poll poll={this.dailyPoll} />}
					</div>
				</div>
			</>
		);
	}
}
