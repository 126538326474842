import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import { SlideInput } from '@smartplatform/ui';
import FilterLayout from '../FilterLayout';
import t from 'i18n';
import store from "client/store";

@observer
export default class WindFilter extends React.Component {

	static propTypes = {
		layer: PropTypes.object.isRequired,
		onChange: PropTypes.func,
		isLoading: PropTypes.bool,
		error: PropTypes.any,
	};

	constructor(props) {
		super(props);
		this.settings = props.settings;
	}

	onSpeedFactorChange = (value) => {
		this.props.layer.layer.setSpeedFactor(value);
		this.settings.speedFactor = value;
		store.local.save();
	};

	onFadeOpacityChange = (value) => {
		this.props.layer.layer.setFadeOpacity(value);
		this.settings.fateOpacity = value;
		store.local.save();
	};

	onParticleLifeChange = (value) => {
		this.props.layer.layer.setParticleLife(value);
		this.settings.particleLife = value;
		store.local.save();
	};

	onOpacityChange = (value) => {
		this.props.layer.layer.setOpacity(value);
		this.settings.opacity = value;
		store.local.save();
	};

	render() {
		const { layer, isLoading, error } = this.props;

		return <FilterLayout title={t('wind.title')} layer={layer} error={error} isLoading={isLoading}>
			<div>
				<label>{t('wind.speedFactor')}</label>
				<SlideInput
					value={layer.settings.speedFactor}
					onChange={this.onSpeedFactorChange}
					min={1}
					max={10}
					step={1}
					disabled={!layer.settings.show}
				/>
			</div>
			<div style={{ marginTop: -15 }}>
				<label>{t('wind.fadeOpacity')}</label>
				<SlideInput
					value={layer.settings.fadeOpacity}
					onChange={this.onFadeOpacityChange}
					min={0.980}
					max={0.999}
					step={0.001}
					disabled={!layer.settings.show}
				/>
			</div>
			<div style={{ marginTop: -15 }}>
				<label>{t('wind.opacity')}</label>
				<SlideInput
					value={layer.settings.opacity}
					onChange={this.onOpacityChange}
					min={0.4}
					max={0.9}
					step={0.05}
					disabled={!layer.settings.show}
				/>
			</div>
{/*
			<div style={{ marginTop: -15 }}>
				<label>{t('wind.particleLife')}</label>
				<SlideInput
					value={layer.settings.particleLife}
					onChange={this.onParticleLifeChange}
					min={64}
					max={255}
					step={1}
					disabled={!layer.settings.show}
				/>
			</div>
*/}
		</FilterLayout>;
	}

}
