import React, { useState, useRef, useEffect } from 'react';
import Slider from "react-slick";
import ArrowRight from 'img/arrow-right_1.svg';
import ArrowLeft from 'img/arrow-left_1.svg';
import { Picture } from 'components';

const SliderMediaArticle = ({attachments, activeSlide}) => {

	useEffect(() => {
		if(sliderRef?.current){
			sliderRef.current.slickGoTo(activeSlide);
		}
	}, []);

	const sliderRef = useRef(null);

	const settingsSlider = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		fade: true,
		adaptiveHeight: true,
		nextArrow: <div className='castom-arrow-right'><ArrowRight /></div>,
		prevArrow: <div className='castom-arrow-left'><ArrowLeft /></div>,
	};

	return(
		<Slider className='media-slider-arcticle' ref={sliderRef} {...settingsSlider}>
			{attachments.map((x) => (
				<div key={x.id} className='media-slide-content'>
					<Picture src={x.downloadFile('filename')} />
				</div>
			))}
		</Slider>
	);
}

export default  SliderMediaArticle;