import React from 'react';
import { observer } from 'mobx-react';
import MoreLogo from 'img/icon-more.svg';
import { Menu } from './Menu';

import store from 'client/store';
import './style.scss';

@observer
export class SecondaryHeaderBottom extends React.Component {
	isActive = (path) => (path === store.route.path ? 'active' : '');

	render() {
		const { onOpenPortalMap } = store.ui;

		return (
			<div className='inner-page-header'>
				<div className='secondary-page-header-wrapper desktop-menu deep-blue'>
					<Menu className='link blue-border-background'/>
					<div className='links'>
						<div className={'link blue-border-background ' + this.isActive('/still')} onClick={onOpenPortalMap}>
							<div className='link-content'>
								<MoreLogo/>
								<div className='show-more'>ЕЩЕ</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
