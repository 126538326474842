import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import viewsIcon from 'client/img/data-open/icon-view.png?url';
import downloadIcon from 'client/img/data-open/icon-download.png?url';
// import commentsIcon from 'client/img/data-open/icon-comments.png?url';
// import likeIcon from 'client/img/data-open/icon-like.png?url';
// import disLikeIcon from 'client/img/data-open/icon-dislike.png?url';
import ImageNull from 'client/img/data-open/icon-folder-csv.png?url';
import { formatDate } from 'client/helpers';

export default class OpenData extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { data, isLoading } = this.props;
		if (isLoading) return null;
		const stringWithoutTags = data.text?.replace(/(<([^>]+)>)/gi, '');
		return (
			<>
				<Link to={`/open-data/${data.id}`} className='open-data-container'>
					{data.image ? (
						<img className='images' src={vacancy.downloadFile('image') + '?image=[{"resize":{"width":64}}]'} alt='' />
					) : (
						<div className='images'>
							<img src={ImageNull} alt='no-image' />
						</div>
					)}
					<div className='mid-data-items'>
						{data && <div className='theme p6'>{data.dataCategory.name}</div>}
						<div className='text h7' dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
						<div className='initiator p3'>{data.agency?.name}</div>
						<div className='info'>
							<div className='date p3'>Дата актуальности: {formatDate(data.actualDataAt, 'dd.MM.yyyy')}</div>
							<div className='date p3'>Обновлено: {formatDate(data.updatedAt, 'dd.MM.yyyy')}</div>
							<div className='date p3'>Опубликовано: {formatDate(data.publishAt, 'dd.MM.yyyy')}</div>
						</div>
						{/*<div className='statistic'>
							<div className='views p3'>
								<img src={viewsIcon} alt='view' />
								348
								{data.views}
							</div>
							<div className='download p3'>
								<img src={downloadIcon} alt='download' />
								164
								{data.download}
							</div>
							<div className='comm p3'>
								<img src={commentsIcon} alt='comments' />
								23
								{data.comments}
							</div>
							<div className='like p3'>
								<img src={likeIcon} alt='like' />+ 56
								{data.like}
							</div>
							<div className='dislike p3'>
								<img src={disLikeIcon} alt='dislike' />- 3{data.disLike}
							</div>
						</div>*/}
					</div>
				</Link>
			</>
		);
	}
}
