import React from 'react';
import './style.scss';

export default class DropdownMenu extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { items } = this.props;

		return (
			<ul className='drop-down-menu'>
				{items?.map((item, i) =>
					<li key={i}>{item}</li>
				)}
			</ul>
		);
	}
}
