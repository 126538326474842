import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import './style.scss';
import t from 'i18n';
import OpenData from './OpenData';
import Filters from 'client/pages/open-data/filters/Filters';
import OpenDataStore from './store';
import { observable } from 'mobx';
import store from 'client/store';
import { SearchPage } from '../../components/search-page';
import { AutoAnimate, NotPhone, Phone, ShowMoreButton } from 'components';
import FiltersMobile from 'client/pages/open-data/filters/FiltersMobile';

@observer
export default class OpenDatas extends Component {
	@observable chapter = null;
	@observable dataCategory = null;
	@observable search = '';
	@observable labels = [];
	@observable activeItem = null;
	@observable recordFiltered = [];
	@observable records = [];

	constructor(props) {
		super(props);
		this.store = new OpenDataStore();
	}

	componentDidMount() {
		this.openDataLabels();
		this.openDataChapter();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.records = this.store.records;
	}

	openDataLabels = async () => {
		this.labels = await store.model.OpenDataLabel.find({
			where: {
				label: { neq: null },
				questionAnswerId: { neq: null },
			},
			includes: { relation: 'label', scope: { fields: ['id', 'name'] } },
			limit: 15,
			distinct: ['id'],
		});
	};

	openDataChapter = async () => {
		this.chapter = await store.model.OpenDataChapter.find();
	};

	handleClick = (item) => {
		if (this.activeItem === item) {
			this.activeItem = null;
		} else {
			this.activeItem = item;
		}
	};

	handleSearchQuery = (record) => {
		this.search = record.toLowerCase();
		this.filterRecords();
	};

	filterRecords = () => {
		this.recordFiltered = this.records.filter((data) => data.text.toLowerCase().includes(this.search));
	};

	resetAllFilters = () => {
		this.store = new OpenDataStore();
		this.records = [];
		this.activeItem = null;
		this.records = this.store.records;
		this.store.isFilterActive = false;
		this.openDataLabels();
	};

	render() {
		const { onChangeFilter, isLoading, filter, records, recordsAll, showMoreOpenData, totalCountOpenData, isFilterActive } = this.store;
		// if (isLoading) return null;
		const recordsChoice = isFilterActive ? recordsAll : records;
		const tags = [...new Set(this.labels.map((r) => r?.label).slice(0, 10))];

		const libraryContent = (
			<AutoAnimate className='sidebar-data-library'>
				<div className={`data-library-item p2 ${this.activeItem === null ? 'active' : ''}`} onClick={this.resetAllFilters}>
					{t('openData.dataLibrary')}
				</div>
				{this.chapter &&
					this.chapter.map((item, index) => (
						<div
							className={`data-library-item p2 ${this.activeItem === index ? 'active' : ''}`}
							key={item.id}
							onClick={() => {
								onChangeFilter('chapter', item);
								this.handleClick(index);
							}}
						>
							{item?.name}
						</div>
					))}
			</AutoAnimate>
		);

		return (
			<Provider store={this.store}>
				<>
					<SearchPage
						headerName={t('openData.title')}
						placeholder={t('openData.openDataSearch')}
						search={this.search}
						onChangeSearch={(r) => this.handleSearchQuery(r)}
					/>
					<div className='open-datalist-container'>
						<div className='open-data-items'>
							<NotPhone>
								{/* SideBar */}
								<div>
									<div className='sidebar'>{libraryContent}</div>
									<div className='sidebar'>
										<div className='sidebar-data'>
											<span className='p4'>{t('tag.plural')}</span>
											<span className='line'></span>
										</div>
										<AutoAnimate className='open-data-tags-container p1'>
											{tags &&
												tags.map((tag, index) => (
													<div className='tags p1' key={index} onClick={() => onChangeFilter('label', tag)}>
														{'#' + tag?.name}&nbsp;
													</div>
												))}
										</AutoAnimate>
										<div className='right p1' onClick={this.resetAllFilters}>
											{t('tag.all')}
										</div>
									</div>
								</div>
							</NotPhone>
							<Phone>
								<FiltersMobile store={this.store} libraryContent={libraryContent} />
							</Phone>
							{/* OpenData Items */}
							<div className='open-data-records'>
								<NotPhone>
									<Filters store={this.store} />
								</NotPhone>
								<AutoAnimate>
									{!isLoading && this.search === '' && records
										? recordsChoice.map((data) => <OpenData key={data?.id} data={data} isLoading={isLoading} />)
										: this.recordFiltered && this.recordFiltered.map((data) => <OpenData key={data?.id} data={data} isLoading={isLoading} />)}
								</AutoAnimate>
								{!isFilterActive && (
									<ShowMoreButton
										isLoading={isLoading}
										records={this.records}
										showMore={showMoreOpenData}
										formsArray={['открытые данные', 'открытых данных', 'открытое дынное']}
										totalCount={totalCountOpenData}
									/>
								)}
							</div>
						</div>

						{/* Modal Block */}
						{/* <div id='modal-block' className='invisible'>
						<div className='modal-content'>
							<a className='close-block' href='#close-block'>
								<img src={iconCancel} alt='iconCancel'/>
							</a>
							<OpenDataPageModal data={openDataItems}/>
						</div>
					</div> */}
					</div>
				</>
			</Provider>
		);
	}
}
