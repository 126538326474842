import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'client/store';
import { DocumentCard } from 'components';
import { Loader } from '@smartplatform/ui';

@observer
export default class PageDocuments extends React.Component {
	@observable documents = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { documentIds } = this.props;

		if (!documentIds || documentIds.length === 0) {
			return;
		}

		try {
			const documents = await store.model.Document.find({
				where: {
					id: { inq: documentIds },
				},
				include: ['organization', 'type', 'children'],
			});
			this.documents = documents;
		} catch (error) {
			console.error(`Failed to fetch document with ID ${id}`, error);
		}

		this.isLoading = false;
	};

	replaceDocumentIdsWithComponent = (htmlContent) => {
		const regex = /\[document id: (\d+)\]/g;
		const parts = [];
		let lastIndex = 0;
		let match;

		while ((match = regex.exec(htmlContent)) !== null) {
			const id = Number(match[1]);
			const index = match.index;

			if (index > lastIndex) {
				parts.push(<div dangerouslySetInnerHTML={{ __html: htmlContent.slice(lastIndex, index) }} />);
			}

			const document = this.documents.find((doc) => doc.id === id);
			if (document) {
				parts.push(<DocumentCard key={`document-card-${id}`} record={document} />);
			}

			lastIndex = index + match[0].length;
		}

		if (lastIndex < htmlContent.length) {
			parts.push(<div dangerouslySetInnerHTML={{ __html: htmlContent.slice(lastIndex) }} />);
		}

		return parts;
	};

	render() {
		if (this.isLoading) return <Loader />;
		const { htmlContent } = this.props;
		const content = this.replaceDocumentIdsWithComponent(htmlContent);
		return <div className='documents-page'>{content}</div>;
	}
}
