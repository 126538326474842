import React from 'react';
import { Link } from 'react-router-dom';
import ExchangeRate from '../../../components/exchange-rate/exchangeRate';

import './style.scss';
import { Provider } from 'mobx-react';
import store from 'client/store';

const PATHS = [
	{
		path: '/news',
		title: 'Новости'
	},
	{
		path: '/columns',
		title: 'Статьи'
	},
	{
		path: '/media',
		title: 'Видео'
	}
];

export default class TabBar extends React.Component {
	render() {

		const pathPage = store.route.path;

		const items = (
			<Provider store={store}>
				<>
					{PATHS.map((item, index) => (
						<div className={`link p4 ${pathPage === item.path ? 'active' : ''}`} key={index}>
							<Link to={item.path}>
								<span>{item.title}</span>
							</Link>
						</div>
					))}
				</>
			</Provider>
		);
		return (
			<>
				<div className='tab-bar'>
					<div className='items'>{items}</div>
					<div className='exchange-item'>
						<ExchangeRate />
					</div>
				</div>
			</>
		);
	}
}
