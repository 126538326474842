import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { Input, Loader, RecordSelect } from '@smartplatform/ui';
import DropDown from '@smartplatform/ui/src/components/drop-down/DropDown';
import Select from '@smartplatform/ui/src/components/inputs/Select';
import PropTypes from 'prop-types';
import { SvgIcon } from 'components/svgIcon';
import { AutoAnimate } from 'components';
import ScrollBar from 'components/scroll-bar/ScrollBar';

@observer
export class InputFilter extends React.Component{

	@observable searchValue = '';
	@observable records = [];
	@observable recordsSearch = [];
	@observable isLoading = false;
	@observable show = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { model, property, filters } = this.props;

		this.isLoading = true;

		this.records = await model.find({
			order: `${property || 'id'} asc`,
			...filters
		});

		this.recordsSearch = this.records;

		this.isLoading = false;
	}

	toggleShow = () => {
		this.show = !this.show;
	}

	onSearch = (value) => {
		this.searchValue = value;
		this.recordsSearch = this.records.filter((item) => item?.name?.toLowerCase()?.includes(this.searchValue?.toLowerCase()));
	}

	onChange = (experience) => {
		const {onChange} = this.props;
		if(onChange) onChange(experience);
	}

	onReset = (e) => {
		this.searchValue = '';
		const formBlock = e.target.parentNode;
		if (formBlock) {
			const inputArr = formBlock?.querySelectorAll('input[type=radio]');
			inputArr.forEach(function (el) {
				el.checked = false;
			});
		}
		const {onChange} = this.props;
		if(onChange) onChange(null);
	}

	render(){

		const { label, placeholder, nameInput } = this.props;

		return(
			<div className='wrapper-input-item-mobile-filter'>
				{this.isLoading ?
					<Loader />
					:
					(
						<>
							<div className={`wrapper-head  ${this.show ? 'open' : ''}`} onClick={this.toggleShow}>
								<p className='text-head'>{label ? label : 'Параметр'}</p>
								<SvgIcon name='down-arrow' />
							</div>
							<AutoAnimate>
								<div className={`wrapper-content-mobile-filter ${this.show ? 'active' : ''}`}>
									<Input value={this.searchValue} onChange={(value) => this.onSearch(value)}
												 placeholder={placeholder} />
									<div className='wrapper-radio-buttons'>
										<ScrollBar
											records={this.recordsSearch}
											h={'169'}
											typeCheckbox={'radio'}
											onChange={(value) => this.onChange(value)}
											nameInput={nameInput}
										/>
									</div>
									<p className='reset-control' onClick={(e) => this.onReset(e)}>{t('reset')}</p>
								</div>
							</AutoAnimate>
						</>
					)
				}
			</div>
		);
	}
}

/*
<RecordSelect
	model={store.model.OpenDataCategory}
	property="name"
	className="data-category-select"
	value={dataCategory}
	placeholder={t('category')}
	onChange={(r) => onChangeFilter('dataCategory', r)}
/>*/
