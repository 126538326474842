import React, { Component } from 'react';
import './style.scss';
import { Checkbox, Field, Form, RelationSelect, Row } from '@smartplatform/ui';
import store from 'client/store';
import { observer } from 'mobx-react';
import t from 'i18n';
import { observable } from 'mobx';
import { Attachments, Button } from 'components';
import LegalEntityItems from './LegalEntityItems';
import AuthorAppeal from 'client/pages/citizens-appeals/AuthorAppeal';
import Captcha from './captcha/Captcha';

@observer
class CitizensAppeals extends Component {
	@observable record = null;
	@observable records = null;
	@observable appealLog = null;
	@observable organizationId = null;
	@observable isLoading = true;
	@observable naturalPerson = true;
	@observable legalEntity = false;
	@observable withoutMiddleName = false;
	@observable checkRules = false;
	@observable checkApproval = false;
	@observable isActive = false;
	@observable captchaSuccess = false;

	constructor(props) {
		super(props);
		store.ui.title = t('citizensAppeals.title');
		this.init();
	}

	init = async () => {
		this.records = await store.model.CitizenAppeal.count();
		this.record = new store.model.CitizenAppeal({
			includes: [
				{
					relation: 'recipient',
					scope: { fields: ['id', 'fullName', 'official'], include: [{ relation: 'organization', scope: { fields: ['id', 'name'] } }] },
				},
			],
		});
		this.appealLog = new store.model.CitizenAppealLog();
		if (store.ui.organization) {
			this.onChangeOrganization(store.ui.organization);
		}
		this.isLoading = false;
	};

	coAuthorDelete = () => {
		this.legalEntity = false;
		this.record.coAuthorFirstName = undefined;
		this.record.coAuthorName = undefined;
		this.record.coAuthorMiddleName = undefined;
		this.record.coAuthorWithoutMiddleName = false;
		this.record.coAuthorPhone = undefined;
		this.record.coAuthorEmail = undefined;
	};

	goToQuestionAnswer = () => {
		store.route.push({ path: '/question-answer' });
	};

	changeFullName = async (phonebook) => {
		if (phonebook && phonebook.organization) {
			const organization = await phonebook.organization;
			this.organizationId = organization.id;
			this.onChangeOrganization(organization);
		}
	};

	onChangeOrganization = (organization) => {
		if (organization && organization.id && organization.name) {
			this.organizationId = organization.id;
			this.record.executor = organization.name;
			this.record.organizationId = this.organizationId;
			this.checkOrganizationId(organization);
		}
	};

	checkOrganizationId = (organization) => {
		if (organization.id !== this.organizationId) {
			this.record.recipient = null;
		}
	};

	handleCopyPaste = (e) => {
		e.preventDefault();
	};

	checkedRules = (check) => {
		this.checkRules = check;
	};

	checkedApproval = (check) => {
		this.checkApproval = check;
	};

	onSave = async () => {
		this.record.receiptAt = new Date();
		this.record.statusId = 1;
		await this.record.save();
		this.appealLog.nameAppeal = this.record.text;
		this.appealLog.statusId = 1;
		this.appealLog.changeStatusDate = new Date();
		this.appealLog.changeExecutorDate = new Date();
		this.appealLog.executor = this.record.executor;
		await this.appealLog.save();
		store.route.push({ path: 'citizens-appeals/appeal-sent' });
	};

	renderShowValue = (property, position) =>
		position
			? `${property} - ${position}` || <div className='p2'>{t('citizensAppeals.selectFromTheList')}</div>
			: property || <div className='p2'>{t('citizensAppeals.selectFromTheList')}</div>;

	toggleNaturalPerson = () => {
		this.naturalPerson = !this.naturalPerson;
		this.toggleActive();
	};

	toggleLegalEntity = () => (this.legalEntity = !this.legalEntity);

	toggleActive = () => (this.isActive = !this.isActive);

	render() {
		if (this.isLoading) return null;
		const requiredFields = ['text', 'firstName', 'name', 'email', 'executor'];
		const disableSave = !!requiredFields.find((prop) => !this.record[prop]);
		return (
			<div className='page-content-single citizens-appeals-container'>
				<div className='citizens-appeals'>
					<div className='p1'>{t('citizensAppeals.fillFormCitizensElectronicAppeal')}</div>
					<div className='citizens-appeals-form'>
						<Form record={this.record} disableSave={disableSave || !this.checkRules || !this.checkApproval || !this.captchaSuccess} onSave={this.onSave} noDelete stay>
							<Field relation='recipient' property='fullName' label={t('citizensAppeals.surnameFirstNamePatronymicTheOfficial')} className='field-label'>
								<RelationSelect
									searchFields={['fullName']}
									showValue={this.renderShowValue(
										this.record.recipient && this.record.recipient.fullName,
										this.record.recipient && this.record.recipient.position
									)}
									filter={
										this.record.organizationId
											? { where: { organizationId: this.record.organization && this.record.organization.id, official: true } }
											: { where: { official: true } }
									}
									onChange={this.changeFullName}
								/>
							</Field>
							<Field relation='organization' property='name' label={t('citizensAppeals.recipient')} className='field-label' isRequired>
								<RelationSelect
									searchFields={['name']}
									showValue={
										this.record.recipientId
											? this.record.recipient.organization.name
											: this.renderShowValue(this.record.organization && this.record.organization.name)
									}
									onChange={this.onChangeOrganization}
								/>
							</Field>
							<Field relation='subject' property='name' label={t('citizensAppeals.subjectAppeals')} className='field-label'>
								<RelationSelect searchFields={['name']} showValue={this.renderShowValue(this.record.subject?.name)} />
							</Field>
							<div>
								<Field property='text' label={t('text')} className='field-label field-text' isRequired>
									<textarea
										rows={6}
										onCopy={this.handleCopyPaste}
										placeholder={t('citizensAppeals.enterMessageOnTheQuestionOfInterest')}
										onPaste={this.handleCopyPaste}
									/>
								</Field>
								<div className='text-info p3'>{t('citizensAppeals.inorderProtectAgainstThePossibleIntroductionOfVirusPrograms')}</div>
							</div>
							<Field className='attach'>
								<Attachments record={this.record} canUpload={true} withFormContext={!this.record.id} />
								<span className='text-info p3'>{t('citizensAppeals.fileSizeNotExceed10Mb')}</span>
							</Field>

							{/*Author Appeal*/}
							<AuthorAppeal withoutMiddleName={this.record && this.record.withoutMiddleName} />

							{/*Organization Button*/}
							<Field className='organization-button'>
								<Button size='lg' color='grey' onClick={this.toggleNaturalPerson} className={`button p2 ${this.isActive ? 'active' : ''}`}>
									{t('citizensAppeals.legalEntity')}
								</Button>
								<Button size='lg' onClick={this.toggleNaturalPerson} color='grey' className={`button p2 ${this.isActive ? '' : 'active'}`}>
									{t('citizensAppeals.naturalPerson')}
								</Button>
							</Field>
							{!this.naturalPerson && <Field property='nameLegalEntity' className='field-label' label={t('citizensAppeals.organization')} />}
							{this.legalEntity && (
								<LegalEntityItems
									record={this.record}
									coAuthorDelete={this.coAuthorDelete}
									coAuthorWithoutMiddleName={this.record?.coAuthorWithoutMiddleName}
								/>
							)}
							{!this.legalEntity && (
								<Field>
									<Button size='lg' onClick={this.toggleLegalEntity} className='add-coauthor-button' color='grey'>
										{t('citizensAppeals.addCoAuthor')}
									</Button>
									<div className='add-co-author-info p3'>{t('citizensAppeals.buttonForCollectiveAppeals')}</div>
								</Field>
							)}

							{/*Post address*/}
							<div className='p2'>{t('citizensAppeals.postalAddress')}</div>
							<Row>
								<Field relation='county' property='name' className='field-label' label={t('county.title')}>
									<RelationSelect searchFields={['name']} showValue={this.renderShowValue(this.record.county && this.record.county.name)} />
								</Field>
								<Field relation='city' property='name' className='field-label' label={t('locality')}>
									<RelationSelect
										searchFields={['name']}
										showValue={this.renderShowValue(this.record.city && this.record.city.name)}
										filter={this.record.county ? { where: { countyId: this.record.county.id } } : null}
									/>
								</Field>
							</Row>
							<div className='post-address'>
								<Field property='postcode' className='field-label postcode' label={t('postcode')} />
								<Field property='street' className='field-label street' label={t('street')} />
								<Field property='house' className='field-label house' label={t('house')} />
								<Field property='apartment' className='field-label apartment' label={t('apartment')} />
							</div>
							<div className='checkbox-approval'>
								<Checkbox value={this.checkRules} onChange={this.checkedRules} />
								<div className='p2'>{t('citizensAppeals.agreementWithTheRules')}</div>
							</div>
							<div className='checkbox-approval'>
								<Checkbox value={this.checkApproval} onChange={this.checkedApproval} />
								<div className='p2'>{t('citizensAppeals.consentToDataProcessing')}</div>
							</div>
							<p className='foot-info p5'>
								{t('citizensAppeals.personalInformationIsStoredAndProcessed')}
								<br />
								{t('citizensAppeals.isNotDisclosureOfInformation')}
							</p>
						</Form>
						{!this.captchaSuccess && <Captcha onSuccess={() => this.captchaSuccess = true} />}
					</div>
				</div>

				{/*	Sidebar*/}
				<div className='sidebar'>
					<div className='sidebar-citizens-appeals'>
						<span className='p4'>{t('statistics')}</span>
						<span className='line'></span>
					</div>
					<div className='sidebar-header p2'>{t('citizensAppeals.totalApplicationsSubmitted')}</div>
					<div className='appeals-count-container'>
						<div className='counter h1'>{this.records}</div>
					</div>
					<Button color='light-blue' onClick={this.goToQuestionAnswer} className='sidebar-button'>
						{t('citizensAppeals.responsesToRequests')}
					</Button>
				</div>
			</div>
		);
	}
}

export default CitizensAppeals;
