import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { PopularSiteCard, SectionCard } from 'components';

// cекция выводит топ5 популярных сайтов
export const PopularSitesSections = (props) => {
	return (
		store.ui.popularSites.length > 0 && (
			<SectionCard title={t('allSites.popularSitesLineTitle')} {...props}>
				{store.ui.popularSites.map((record, index) => (
					<PopularSiteCard key={record.id} record={record} number={index + 1} />
				))}
			</SectionCard>
		)
	);
};
