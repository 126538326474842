import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from '@smartplatform/ui';
import store from 'client/store';
import { Link, withRouter } from 'react-router-dom';
import './style.scss';

import caretLeft from 'client/img/icon-caret-left.svg?url';
import caretRight from 'client/img/icon-caret-right.svg?url';

const LINE_HEIGHT = 10; // высота метки 30px + 10px нижний margin
const MORE_LINES = 5;
@observer
export default class Labels extends React.Component {
	static propTypes = {
		relation: PropTypes.string,
	};

	static defaultProps = {
		relation: 'ViewArticleLabel',
	};

	@observable labels = [];
	@observable visibleCount = 0;
	@observable isLoading = true;

	hideOthers = false;
	lineHeight = LINE_HEIGHT;

	constructor(props) {
		super(props);
		this.init();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.categoryId !== this.props.categoryId || prevProps.countyId !== this.props.countyId) {
			this.visibleCount = 0;
			this.init();
		}
	}
	init = async () => {
		this.isLoading = true;

		const categoryId = this.props.categoryId;
		const countyId = store.local.countyId;
		// const showOnMain = countyId ? null : true;
		const recordId = this.props.recordId;
		const type = this.props.type;
		const labelInRoute = this.props.labelInRoute;

		let labels = await store.model[this.props.relation].getFiltered({
			type,
			recordId,
			categoryId,
			countyId,
			// showOnMain,
			exclude: labelInRoute && labelInRoute.id,
		});
		labels = labels.sort((a, b) => b.mainPriority || a.name.localeCompare(b.name));
		//двигаем метку в роуте вперед
		if (labelInRoute) {
			this.labels = [labelInRoute, ...labels];
		} else this.labels = labels;

		this.isLoading = false;
	};

	// записываем в this.container ссылку на контейнер меток (labels-filter)
	onMount = (el) => (this.container = el);

	onLabelMount = (el) => {
		if (el) {
			// положение top относительно контейнера (первая строка - 0, вторая - LINE_HEIGHT, и т.д.)
			const top = el.offsetTop - el.parentNode.offsetTop;

			// эта метка уже на 3-й строке, надо прятать всё что после неё
			if (top > this.lineHeight && !this.hideOthers) {
				this.hideOthers = true;
				// также прячем пару предыдущих элементов, чтобы освободить место для кнопки
				if (el.previousSibling) {
					el.previousSibling.style.display = 'none';
					if (el.previousSibling.previousSibling) el.previousSibling.previousSibling.style.display = 'none';
				}
			}

			// прячем все последующие элементы, по мере их появления в DOM-дереве документа
			this.hideOthers ? (el.style.display = 'none') : (this.visibleCount += 1);
		}
	};

	onSelect = (label) => {
		this.props.onSelect && this.props.onSelect(label);
	};

	loadMore = () => {
		this.hideOthers = false;
		this.lineHeight += LINE_HEIGHT * MORE_LINES;
		this.visibleCount = 0;
		if (this.container) {
			const labelElements = this.container.getElementsByClassName('label');
			for (const label of labelElements) {
				label.style.display = 'inline-block';
				const top = label.offsetTop - label.parentNode.offsetTop;
				if (top > this.lineHeight && !this.hideOthers) {
					this.hideOthers = true;
					if (label.previousSibling) {
						label.previousSibling.style.display = 'none';
						if (label.previousSibling.previousSibling) label.previousSibling.previousSibling.style.display = 'none';
					}
				}
				this.hideOthers ? (label.style.display = 'none') : (this.visibleCount += 1);
			}
		}
	};

	render() {
		const { selected } = this.props;

		if (this.labels.length === 0) return null;
		return (
			<>
				<div className='labels-filter' ref={this.onMount}>
					<div>
						{this.labels.map((label) => {
							//если на листе кликнули в метку,то она становится selected
							const className = 'label p1' + (selected && selected.id === label.id ? ' selected' : '');

							//общие пропсы
							const generalProps = { className, ref: this.onLabelMount };
							//1ый кейс - страница материала , 2ой - страница листа с материалами(c функцией для селекта метки)
							const component = this.props.recordId ? (
								<Link to={`/${this.props.categoryPath}/label/${label.name}`} {...generalProps}>
									{label.name}
								</Link>
							) : (
								<div {...generalProps} onClick={() => this.onSelect(label)}>
									{label.name}
								</div>
							);
							return <React.Fragment key={label.id}>{component}</React.Fragment>;
						})}
					</div>

					{/*{!this.isLoading && this.visibleCount !== this.labels.length && (*/}
					{/*	<Button className='labels-more' onClick={this.loadMore}>*/}
					{/*		Показать ещё*/}
					{/*	</Button>*/}
					{/*)}*/}
					{!this.isLoading && this.visibleCount !== this.labels.length && (
						<div className='caret-left_right'>
							<div>
								<img src={caretLeft} className='labels-more' alt='carretLeftIcon' />
							</div>
							<div>
								<img src={caretRight} className='labels-more' onClick={this.loadMore} alt='carretRightIcon' />
							</div>
						</div>
					)}
				</div>
			</>
		);
	}
}
