import React, { Component } from 'react';
import DownloadIcon from 'client/img/data-open/icon-download.svg';
import t from 'i18n';
import { Button } from 'components';

class OpenDataItem extends Component {
	constructor(props) {
		super(props);
	}

	downloadAttachmentsByClick = (attach) => {
		if (attach) {
			const link = document.createElement('a');
			link.href = attach.downloadFile('filename');
			link.download = attach.filename;
			link.click();
		}
	};

	render() {
		const { result } = this.props;
		const attachmentsDataSet = result.attachmentsDataSet()[0] || null;
		const attachmentsPassport = result.attachmentsPassport()[0] || null;
		const attachmentsStructure = result.attachmentsStructure()[0] || null;
		const attachmentsTable = result.attachmentsTable()[0] || null;
		const attachmentsAPI = result.attachmentsAPI()[0] || null;

		const dataItems = [
			{ id: 1, downloadedDataName: t('openData.dataSet'), attach: attachmentsDataSet },
			{ id: 2, downloadedDataName: t('openData.passport'), attach: attachmentsPassport },
			{ id: 3, downloadedDataName: t('openData.structure'), attach: attachmentsStructure },
			{ id: 4, downloadedDataName: t('openData.table'), attach: attachmentsTable },
			{ id: 5, downloadedDataName: 'API', attach: attachmentsAPI },
		];

		return (
			<>
				{dataItems.map((item) => (
					<React.Fragment key={item.id}>
						{item.attach && (
							<div className='data-item'>
								<Button
									onClick={() => this.downloadAttachmentsByClick(item.attach)}
									className='data-item-button'
									prependIcon={<DownloadIcon />}
									color={'grey'}
									size={'md'}
								></Button>
								<div className='data-item-text'>
									<p className='p2'>{item.downloadedDataName}</p>
									<p className='meta p5'>{item.attach && item.attach.filename}</p>
								</div>
							</div>
						)}
					</React.Fragment>
				))}
			</>
		);
	}
}

export default OpenDataItem;
