import React from 'react';
import { SlidePlacement } from 'components';
import './style.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import { getModelWhere } from 'helpers';
@observer
export default class SliderPlacement extends React.Component {
	@observable records = null;
	@observable isLoading = null;
	@observable selected = 0;
	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const isMainShowAll = false;
		const where = getModelWhere(store.model.Slide, isMainShowAll);
		this.records = await store.model.Slide.find({
			where,
			order: 'id desc',
		});
		this.isLoading = false;
	};

	componentDidMount() {
		this.autoSlide(); // Запускаем автоматический перелистыватель при монтировании компонента
	}

	componentWillUnmount() {
		clearInterval(this.intervalId); // Очищаем интервал при размонтировании компонента
	}

	autoSlide = () => {
		this.intervalId = setInterval(() => {
			const totalCount = this.records.totalCount;
			if (totalCount > 0) {
				let nextSlide = this.selected + 1;
				if (nextSlide >= totalCount) {
					nextSlide = 0; // Вернуться к первому слайду после последнего
				}
				this.selected = nextSlide;
			}
		}, 5000); // Перелистывание каждые 3 секунды
	};

	handleClick = (e) => {
		let value = e.target.getAttribute('data-value');
		if (value !== null) {
			this.selected = value;
		}
	};
	render() {
		if (this.isLoading) return null;
		const totalCount = this.records?.totalCount;
		const style = this.selected >= 1 ? { transform: `translateX(-${this.selected * 894}px)` } : {};
		const renderIndicator = [];
		for (let i = 0; i < totalCount; i++) {
			renderIndicator.push(<li data-value={i} key={i} className={this.selected == i ? 'active' : ''}></li>);
		}
		return (
			<div className='slider'>
				<div className='slide-items' style={style}>
					{this.records?.map((record, index) => (
						<div className='item' key={record.id}>
							<SlidePlacement number={index} record={record} />
						</div>
					))}
				</div>
				{renderIndicator.length > 1 && (
					<ul onClick={this.handleClick} className='indicators'>
						{renderIndicator}
					</ul>
				)}
			</div>
		);
	}
}
