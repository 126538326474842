import React, { Component } from 'react';
import './style.scss';

export default class ScrollButton extends Component {
	handleScrollTopClick = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	render() {
		return (
			<button className='scroll-top-button' onClick={this.handleScrollTopClick}>
				<svg width='14' height='18' viewBox='0 0 14 18' fill='#F1F7FA' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M13.0091 8.13644C12.8139 8.33171 12.4973 8.33171 12.302 8.13644L7.99979 3.8342V17.5C7.99979 17.7761 7.77594 18 7.49979 18H6.49979C6.22365 18 5.99979 17.7761 5.99979 17.5V3.83207L1.69543 8.13644C1.50016 8.3317 1.18358 8.3317 0.988319 8.13644L0.281212 7.42933C0.08595 7.23407 0.08595 6.91749 0.281212 6.72222L6.64518 0.358269C6.84044 0.163007 7.15702 0.163007 7.35229 0.358269L13.7162 6.72223C13.9115 6.91749 13.9115 7.23407 13.7162 7.42934L13.0091 8.13644Z'
						fill='#CCE3F0'
					/>
				</svg>
			</button>
		);
	}
}
