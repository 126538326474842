import { inject, observer } from 'mobx-react';
import React from 'react';
import { RecordSelect, Select } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';

@inject('store')
@observer
export default class Filters extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}
	render() {
		const { filter, onChangeFilter } = this.store;
		const { category, order } = filter;

		return (
			<div className='columns-filters'>
				<div className='selectors'>
					<div>
						<RecordSelect
							model={store.model.Category}
							property='name'
							value={category}
							placeholder={'Категория'}
							onChange={(r) => onChangeFilter('category', r)}
						/>
					</div>
					<div className='sort'>
						<span className='p2'>Сортировка: </span>
						<Select
							items={[
								{ label: 'По дате (по возрастанию)', value: 'createdAt asc' },
								{ label: 'По дате (по убыванию)', value: 'createdAt desc' },
							]}
							value={order}
							isRequired
							width={208}
							onChange={(r) => onChangeFilter('order', r)}
							className='drop-down-sm'
							placeholder={'По дате'}
						/>
					</div>
				</div>
			</div>
		);
	}
}
