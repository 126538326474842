import React from 'react';
import { Link } from 'react-router-dom';
import { relative } from 'client/helpers';
import { JPEG_QUALITY } from 'client/constants';
import noImage from 'client/img/news-no-image.png?url';
import { Picture } from 'components';

const IMAGE_SIZES = {
	desktopWidth: 400,
	tabletWidth: 400,
	mobileWidth: 160
};

export default ({ article, showCategory = false, showCounty = true }) => {
	if (!article)
		return (
			<div className='mid-article'>
				<div className='image' />
			</div>
		);
	const stringWithoutTags = article.text?.replace(/(<([^>]+)>)/gi, '');
	return (
		<Link to={`/news/${article.id}`} className='mid-article'>
			<div className='image'>
				{article.image ? (
					<Picture src={article.downloadFile('image')} {...IMAGE_SIZES} alt='' />
				) : (
					<Picture src={noImage} alt='no-image' className='no-image' />
					// <span className='no-image'>{t('noImage')}</span>
				)}
			</div>
			<div className='wrapper-content'>
				<div className='title h6'>{article.title}</div>
				<div className='text-middle p2' dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
				<div className='info'>
					<div className='date p3'>{article.topics?.name}</div>
					<div className='date p3'>{relative(article.publishAt)}</div>
				</div>
			</div>
		</Link>
	);
};
