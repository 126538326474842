import React, { Component } from 'react';
import logoInner from 'client/img/logo-inner.svg?url';
import logoBlue from 'client/img/logo-blue.svg?url';
import store from 'client/store';
import './style.scss';
import { Link } from 'react-router-dom';

export default class Logo extends Component {
	constructor(props) {
		super(props);
		this.emblem = store.ui.site.emblem;
	}

	render() {
		const _logo = (
			<Link to={'/'}>
				<div className='logo-container'>
					<img className='logo-minus' src={logoInner} alt='logo' />
					<img className='logo-domain' src={this.emblem ? store.ui.site?.downloadFile('emblem') : logoBlue} alt='' />
				</div>
			</Link>
		);
		return <>{_logo}</>;
	}
}
