import { observable } from 'mobx';
import store from 'client/store';

export default class AllSitesStore {
	@observable records = store.ui.allSites;
	type = store.ui.siteTypes[0];
	search = '';

	onSiteTypeChange = (type) => {
		this.type = type;
		this.fetchRecords();
	};

	fetchRecords = async () => {
		const trimmedSearch = this.search.trim();
		this.records = await store.model.Site.find({
			where: {
				typeId: this.type?.id,
				publish: true,
				domain: { neq: store.ui.site.domain },
			},
			limit: 18,
			search: trimmedSearch || undefined,
		});
	};

	onSearchSubmit = async (value) => {
		this.search = value;
		this.fetchRecords();
	};
}
