import React from 'react';
import defaultIconUrl from 'img/site-icon.svg?url';
import ArrowRightCircle from 'img/arrows/arrow-right-circle.svg';
import classNames from 'classnames';
import 'img/pattern-stars-position.svg?url';
import './siteCard.scss';

export const SiteCard = ({ record, className, children }) => {
	const imgSrc = record.emblem ? record.downloadFile('emblem') : defaultIconUrl;
	className = classNames('site-card', className);
	return (
		<a className={className} href={'//' + record.domain} target='__blank'>
			<img src={imgSrc} className='icon' alt='site-icon' />
			<div className='name'>{record.name}</div>
			{children}
		</a>
	);
};

SiteCard.Arrow = () => (
	<div className='arrow'>
		<ArrowRightCircle />
	</div>
);

SiteCard.Position = ({ number }) => <div className='position'>{number}</div>;

export const BasicSiteCard = (props) => {
	return (
		<SiteCard {...props}>
			<SiteCard.Arrow />
		</SiteCard>
	);
};

export const PopularSiteCard = (props) => {
	const className = classNames('popular', props.className);
	return (
		<SiteCard {...props} className={className}>
			<SiteCard.Position number={props.number} />
		</SiteCard>
	);
};
