import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SupportMeasures from './list/SupportMeasure';
import Single from './single/Single';

export const SupportMeasure = () => (
	<Switch>
		<Route path='/support-measure' exact component={SupportMeasures} />
		<Route path='/support-measure/:id' exact component={Single} />
	</Switch>
);
