import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import ArrowRight from 'img/calendar-arrow-right.svg';
import ArrowLeft from 'img/calendar-arrow-left.svg';
import { observable } from 'mobx';
import { addDays } from 'date-fns';
import { AutoAnimate } from 'components';
import { formatDate } from 'helpers';
import PropTypes from 'prop-types';

@observer
export default class CalendarSlider extends Component {
	static propTypes = {
		selectedDate: PropTypes.object,
		changeDate: PropTypes.func,
		dateCount: PropTypes.number,
	};

	static defaultProps = {
		dateCount: 16,
	};

	@observable dateObjects = [];
	@observable startDate = new Date();
	@observable selectedDate = null;

	constructor(props) {
		super(props);
		this.selectedDate = formatDate(props.selectedDate);
		this.init();
	}

	init = () => {
		const { dateCount } = this.props;
		let dateArray = [];

		for (let i = 0; i < dateCount; i++) {
			const nextDate = addDays(this.startDate, i);
			const [dayOfWeek, monthName, day] = formatDate(nextDate, 'E, LLLL, dd').split(', ');
			dateArray.push({
				dayOfWeek: dayOfWeek.slice(0, -1),
				date: nextDate,
				dateString: formatDate(nextDate),
				day,
				monthName,
			});
		}
		this.dateObjects = dateArray;
	};

	changeSelectedDate = (date) => {
		const { changeDate } = this.props;
		this.selectedDate = date.dateString;
		this.startDate = date.date;
		if (changeDate) {
			changeDate(date.date);
		}
		this.init();
	};

	dateForward = () => {
		const { dateCount } = this.props;

		this.startDate = addDays(this.startDate, dateCount - 1);
		this.init();
	};

	dateBackward = () => {
		const { dateCount } = this.props;

		this.startDate = addDays(this.startDate, 1 - dateCount);
		this.init();
	};

	render() {
		return (
			<div className='calendar-slider-container'>
				<div className='p3'>{formatDate(this.startDate, 'LLLL')}</div>
				<div className='calendar-slider'>
					<div className='date-backward' onClick={() => this.dateBackward()}>
						<ArrowLeft />
					</div>
					<AutoAnimate className='calendar-slider-dates'>
						{this.dateObjects.length &&
							this.dateObjects.map((item, index) => (
								<div
									className={`day-container ${this.selectedDate === item.dateString ? 'current-day' : ''}`}
									key={item.day + item.monthName}
									onClick={() => this.changeSelectedDate(item)}
								>
									{item.day === '01' && index !== 0 && <div className='month p3'>{item.monthName}</div>}
									<div className='day-of-week'>{item.dayOfWeek}</div>
									<div className='day'>{item.day}</div>
								</div>
							))}
					</AutoAnimate>
					<div className='date-forward' onClick={() => this.dateForward()}>
						<ArrowRight />
					</div>
				</div>
			</div>
		);
	}
}
