import React from 'react';
import { Route, Switch } from 'react-router-dom';
import QuestionAnswers from './QuestionAnswers';
import QuestionAnswerPage from './QuestionAnswerPage';

export const QuestionAnswer = () => (
	<Switch>
		<Route path='/question-answer' exact component={QuestionAnswers} />
		<Route path='/question-answer/subcategory/:subCategoryCode' exact component={QuestionAnswers} />
		<Route path='/question-answer/:id' exact component={QuestionAnswerPage} />
	</Switch>
);
