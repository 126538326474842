import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import { LatestMaterials } from './latest-materials';
import t from 'i18n';
import { MaterialRecordCard } from './material-card';
import { PageNotFound } from '../404';
@observer
export class GeneralPage extends React.Component {
	@observable record = null;
	@observable latestMaterials = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const infoAboutRepublicCategory = (await store.model.AboutRepublicCategory.find({ where: { code: 'info-about-republic' } }))[0];
		this.latestMaterials = await store.model.AboutRepublicMaterial.find({
			where: { languageId: store.local.languageId, catetogyId: { neq: infoAboutRepublicCategory?.id } },
			limit: 8,
		});
		this.record = (await store.model.PageDescription.find({ where: { languageId: store.local.languageId, code: 'ABOUT_REPUBLIC' }, limit: 1 }))[0];
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return;
		if (!this.record) return <PageNotFound />;
		return (
			<div className='material-page-wrapper'>
				<MaterialRecordCard
					record={this.record}
					path={this.props.path}
					currentTitle={t('aboutRepublic.generalInformation')}
					headerTitle={t('aboutRepublic.generalInformation')}
				/>
				<LatestMaterials records={this.latestMaterials} />
			</div>
		);
	}
}
