import { observable } from 'mobx';
import store from 'client/store';
import { formatDate } from 'client/helpers';

const PER_PAGE = 10;
export default class ColumnsStore {
	@observable filter = {
		branchCategory: null,
		order: 'createdAt asc',
	};

	@observable records = null;
	@observable eventsByDate = null;
	@observable isLoading = false;

	constructor() {
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		this.records = await store.model.Article.find({
			include: ['category', 'topics'],
			where: this.where,
			order: this.filter.order,
			limit: PER_PAGE,
		});

		this.isLoading = false;
	};

	get where() {
		const { category, topic } = this.filter;
		const where = {
			and: [],
		};

		if (category) {
			where.and.push({ categoryId: category.id });
		}

		if (topic) {
			where.and.push({ topicId: topic.id });
		}

		return where;
	}

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.init();
	};
}
