import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from '../../store';
import t from 'i18n';
import { LatestMaterials } from './latest-materials';
import { PageNotFound } from '../404';
import { MaterialRecordCard } from './material-card';

@observer
export class MaterialPage extends React.Component {
	@observable record = null;
	@observable latestMaterials = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.fetchRecord();
		}
	}

	init = async () => {
		const infoAboutRepublicCategory = (await store.model.AboutRepublicCategory.find({ where: { code: 'info-about-republic' } }))[0];
		this.latestMaterials = await store.model.AboutRepublicMaterial.find({
			where: { languageId: store.local.languageId, catetogyId: { neq: infoAboutRepublicCategory?.id } },
			limit: 8,
		});
		await this.fetchRecord();
		this.isLoading = false;
	};

	fetchRecord = async () => {
		try {
			this.id = parseInt(this.props.match.params.id);
			if (this.id) this.record = await store.model.AboutRepublicMaterial.findById(this.id);
		} catch (e) {}
	};

	render() {
		if (this.isLoading) return null;
		if (!this.record) return <PageNotFound />;

		return (
			<div className='material-page-wrapper'>
				<MaterialRecordCard record={this.record} path={this.props.path} currentTitle={this.record.title} />
				<LatestMaterials records={this.latestMaterials} />
			</div>
		);
	}
}
