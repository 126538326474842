import IconSearch from '-!svg-react-loader?name=Icon!client/img/icon-search-blue.svg';
import { Input } from '@smartplatform/ui';
import { Button, Desktop, Mobile, NotPhone, Phone } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import t from 'i18n';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import './submitSearchInput.scss';
import MediaQuery from 'react-responsive';
import { mediaQueryBreakPoints } from 'constants';

@observer
export class SubmitSearchInput extends React.Component {
	@observable value = '';
	@observable focusInput = false;

	constructor(props) {
		super(props);
		this.value = props.value || '';
	}

	static propTypes = {
		onChange: PropTypes.func,
		placeholder: PropTypes.string,
		onSubmit: PropTypes.func.isRequired,
		submitOnEnter: PropTypes.bool,
		value: PropTypes.string,
		mobileIconBtn: PropTypes.bool,
		className: PropTypes.string
	};

	static defaultProps = {
		placeholder: t('searchInput.placeholder'),
		submitOnEnter: true,
		mobileIconBtn: false,
	};

	onChange = (value = '') => {
		this.value = value;
		this.props.onChange?.(value);
	};

	onSubmit = () => this.props.onSubmit(this.value?.trim());

	onKeyDown = (e) => {
		if (this.props.submitOnEnter && e.keyCode === 13) {
			this.onSubmit();
		}
	};

	@action onFocus = (mobileIconBtn) => {
		console.log('focus', mobileIconBtn);
		if(!mobileIconBtn) return;
		this.focusInput = true;
	}

	@action onBlur = (mobileIconBtn) => {
		console.log('blur', mobileIconBtn);
		if(!mobileIconBtn) return;
		this.focusInput = false;
	}

	render() {
		const { placeholder, mobileIconBtn, className } = this.props;
		const { value, onChange, onSubmit, onKeyDown, onFocus, onBlur } = this;
		return (
			<div className={`submit-search-input ${className ? className : ''}`}>
				<IconSearch className='search-icon' />
				<Input onKeyDown={onKeyDown} type='text' onFocus={() => onFocus(mobileIconBtn)} onBlur={() => onBlur(mobileIconBtn)} placeholder={placeholder} onChange={onChange} value={value} />
				<Button size='small' onClick={onSubmit} className={this.focusInput && 'active-focus'}>
					{
						mobileIconBtn ?
							<>
								<NotPhone>
									{t('searchInput.submit')}
								</NotPhone>
								<Phone>
									<IconSearch className='search-icon' />
								</Phone>
							</>
							: t('searchInput.submit')
					}
				</Button>
			</div>
		)
			;
	}
}
