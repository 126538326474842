import React from 'react';
import { getCookie, setCookie } from 'helpers/Cookie';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { SvgIcon } from 'components/svgIcon';
import './style.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export class CookiePopUp extends React.Component{

	@observable hide = '';

	constructor(props) {
		super(props);
		this.hide = getCookie('accept-cookie');
	}

	onAccept = () => {
		setCookie('accept-cookie', true);
		this.hide = true;
	}

	render(){

		if(this.hide) return null;

		return(
			<div className='wrapper-cookie-message-popup'>
				<div className='left'>
					<p className='head'>{t('cookie.title')}</p>
					<p className='additional-text'>
						{t('cookie.additionalText')} <Link to={this.props.link}>{t('cookie.linkText')}</Link>
						<SvgIcon name='external-link' />
					</p>
				</div>
				<div className='right'>
					<p className='button-accept' onClick={this.onAccept}>{t('cookie.buttonText')}</p>
				</div>
			</div>
		);
	}

}