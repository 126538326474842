import React from 'react';
import { inject, observer } from 'mobx-react';
import { Popup } from '@smartplatform/ui';
import Single from '../../../../single/Single';

@inject('store')
@observer
export class EventComponent extends React.Component {

	constructor(props) {
		super(props);
		this.store = props.store;
	}

	onRowClick = () => {
		const { event } = this.props;
		this.store.loadEvent(event.id);
	};

	render() {
		const { index, event } = this.props;

		return (
			<>
				<div key={index} className='single-event' onClick={this.onRowClick}>
					<div className='title p5'>{event.type?.name}</div>
					<div className='text p2'>{event.title}</div>
					<div className='more p6'>ПОДРОБНЕЕ</div>
				</div>
				{this.eventPopup && (
					<Popup onClose={this.onChangePopup} showCloseButton={false} className='event-popup'>
						<Single event={event}/>
					</Popup>
				)}
			</>
		);
	}
}
