import React from 'react';
import { observer } from 'mobx-react';
import { Map } from '@smartplatform/map/client';
import './style.scss';
import store from 'client/store';
import mapStore from './mapStore';
import Filters from './filters/Filters';
import Scale from 'client/pages/map/filters/layers/weather/scale/Scale';

const params = {
	center: [129.73230712115767, 62.02715995914573],
	zoom: 12,
	minZoom: 2,
	maxZoom: 18,
};

@observer
export default class Single extends React.Component {
	onLayerToggle = (layers) => {
		store.local.dynamicLayers = layers;
		store.local.save();
	};
	render() {
		return (
			<div className='page-content-single gis-map'>
				<Map
					onInit={mapStore.onMapInit}
					params={params}
					visibleLayers={store.local.dynamicLayers}
					onLayerToggle={this.onLayerToggle}
					modelStore={store.model}
					className='map'
				>
					{mapStore.mapInitialized && <Filters />}
					<Scale store={mapStore} hidden={!store.local.weather.show} />
				</Map>
			</div>
		);
	}
}
