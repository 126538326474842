import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logoMain from 'client/img/logo-main.svg?url';
import logoWhite from 'client/img/logo-white.svg?url';
import './style.scss';
import store from 'client/store';

export default class LogoRoot extends Component {
	constructor(props) {
		super(props);
		this.emblem = store.ui.site.emblem;
	}

	render() {
		const _logoRoot = (
			<Link to='/'>
				<div className='logo-container'>
					<img className='logo-minus' src={logoMain} alt='' />
					<img className='logo-root' src={this.emblem ? store.ui.site?.downloadFile('emblem') : logoWhite} alt='' />
				</div>
			</Link>
		);
		return <>{_logoRoot}</>;
	}
}
