import { observable } from 'mobx';
import store from 'client/store';
import { getModelWhere } from 'helpers';
import { endOfMinute, startOfMonth, startOfDay, endOfDay } from 'date-fns';

export default class NewsStore {
	@observable filter = {
		county: null,
		topic: null,
		createdAt: null,
		appurtenance: null,
		date0: null,
		date1: null,
		order: 'createdAt asc',
		search: '',
	};

	@observable records = [];
	@observable recordsAll = [];
	@observable topics = null;
	@observable recordSmall = null;
	@observable isLoading = false;
	@observable tab = 'all';
	@observable smallTab = 'top';
	@observable PER_PAGE = 5;

	constructor(search) {
		this.filter.search = search ?? '';
		this.init();
		this.initSmall();
	}

	init = async () => {
		this.isLoading = true;
		const { county, topic, appurtenance, date0, date1 } = this.filter;
		const model = store.model.ViewArticle;
		const where = getModelWhere(model);
		if (county) where.and.push({ countyId: this.filter.county.id });
		if (topic) where.and.push({ topicId: this.filter.topic.id });
		if (appurtenance) where.and.push({ appurtenanceId: this.filter.appurtenance.id });
		if (date0) where.and.push({ publishAt: { gte: startOfDay(date0) } });
		if (date1) where.and.push({ publishAt: { lte: endOfDay(date1) } });
		where.and.push({ publishAt: { lt: endOfMinute(new Date()) } });

		const trimmed = this.filter.search.trim();

		this.records = await model.find({
			include: ['labels', 'magazine', 'county', 'topics', 'category', 'appurtenance'],
			where,
			search: trimmed.length > 0 ? trimmed : undefined,
			order: 'publishAt desc',
			limit: this.PER_PAGE,
		});

		this.recordsAll = await model.find({
			where,
			include: ['labels', 'magazine', 'county', 'topics', 'category', 'appurtenance'],
		});

		this.topics = [...new Set(this.recordsAll && this.recordsAll.map((top) => top?.topics))].filter((topic) => topic !== null);
		this.isLoading = false;
	};

	showMoreNews = () => {
		this.PER_PAGE += 6;
		this.init();
	};

	initSmall = async () => {
		this.isLoading = true;
		const model = store.model.Article;
		const isMainShowAll = false;
		const where = getModelWhere(model, isMainShowAll);
		this.recordSmall = await model.find({
			where,
			order: this.filter.order,
			limit: 8,
		});
		this.isLoading = false;
	};

	setTab = async (tab) => {
		this.tab = tab;
		if (tab === 'all') {
			this.filter.appurtenance = null;
		}
		await this.init();
	};

	setTabSmallArticle = async (smallTab) => {
		this.smallTab = smallTab;
		await this.init();
	};

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.init();
	};
}
