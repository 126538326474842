import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import PollChart from './PollChart';

@observer
export default class PollResults extends React.Component {
	@observable fields;
	@observable data;

	constructor(props) {
		super(props);
		this.poll = props.poll;
		this.votes = this.poll.votes();
		this.init();
	}

	init() {
		this.formatData();
	}

	formatData = () => {
		let groupedVote = {};
		this.votes.forEach((a) => {
			groupedVote[a.answer] = groupedVote[a.answer] || [];
			groupedVote[a.answer].push(a);
		});

		this.data = Object.entries(groupedVote).map(([key, value]) => {
			const name = key;
			const persent = ((value.length * 100) / this.votes.length).toFixed(1);
			return value.length !== 0 && { label: name, id: `${name} (${persent} %)`, value: value.length };
		});
	};

	render() {
		return (
			<div className='discussion-poll discussion-result'>
				<div className='discussion-poll-name'>{this.poll.name}</div>
				<div className="poll-chart">{this.data && <PollChart data={this.data} />}</div>
			</div>
		);
	}
}
