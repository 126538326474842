import store from '../../store';

export default (el) => {
	const links = el.getElementsByTagName('a');
	for (let link of links) {
		const openNewTab = link.target === '_blank';
		const sameOrigin = new RegExp(location.origin).test(link.href);
		const api = /api\//.test(link.href);
		if (/api/.test(link.href)) {
			link.href = link.href.replace(/^.+(\/api.+)$/, '$1');
		}
		if (sameOrigin && !api && !openNewTab) {
			link.onclick = (e) => {
				e.preventDefault();
				store.route.path = link.href.replace(location.origin, '');
				store.route.push();
			};
		}
	}
};
