export const JPEG_QUALITY = '';
// ,{"webp":{"quality":80}}'

export const PERIODS = {
	DAY: 'day',
	WEEK: 'week',
	MONTH: 'month',
	QUARTER: 'quarter',
	YEAR: 'year',
};

export const ZOOM_WIDTH = 1280;

export const TABLET_WIDTH = 1247;
export const MOBILE_WIDTH = 640;
export const DESKTOP_WIDTH = 1920;
export const PICTURE_FULL_PROPS = {
	tabletWidth: TABLET_WIDTH,
	mobileWidth: MOBILE_WIDTH,
	desktopWidth: DESKTOP_WIDTH,
};
