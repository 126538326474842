import { formatDate } from 'client/helpers';
import React from 'react';

export const dateAndLocation = (record) => {
	if (!record.date0 && !record.location) {
		return '-';
	}

	return (
		<div className='date-location p2'>
			<div>{formatDate(record.date0, 'd MMMM, HH:mm')}</div>
			{record.location && <div>{record.location?.name}</div>}
		</div>
	);
};

export const eventAndOrganizer = (record) => {
	return (
		<div className='event-organizer'>
			<div className='title h6'>{record.title}</div>
			<div>{record.organizer?.name}</div>
		</div>
	);
};
