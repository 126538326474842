import React from 'react';
import punycode from 'punycode';
import './style.scss';
const Social = ({ title }) => {
	const url = `${window.location.protocol}//${punycode.toUnicode(window.location.host)}${window.location.pathname}`;

	return (
		<div className='share-links'>
			<a target='_blank' rel='noopener noreferrer' href={`https://telegram.me/share/url?url=${url}&text=${title || ''}`}>
				<div className='telegram'>
					<svg width='32' height='32' viewBox='-2 -3 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<g id='icon'>
							<path
								id='Vector'
								d='M16.4304 3.42705L2.40517 9.19785C1.44793 9.60757 1.45361 10.1771 2.23069 10.4311L5.72862 11.5958L7.06701 15.9732C7.22971 16.4523 7.14951 16.6423 7.62112 16.6423C7.98508 16.6423 8.14654 16.4653 8.34925 16.2541C8.47816 16.1195 9.24352 15.3257 10.0981 14.4393L13.7366 17.3071C14.4061 17.7012 14.8896 17.4971 15.0563 16.6438L17.4447 4.63713C17.6892 3.59129 17.0709 3.1169 16.4304 3.42705ZM6.27758 11.3282L14.1619 6.02172C14.5555 5.76708 14.9164 5.90399 14.6201 6.18458L7.86904 12.6825L7.60617 15.6734L6.27758 11.3282Z'
								fill='#8291A4'
							/>
						</g>
					</svg>
				</div>
			</a>
			<a target='_blank' rel='noopener noreferrer' href={`whatsapp://send?text=${title} ${url}`}>
				<div className='whatsapp'>
					<svg width='32' height='32' viewBox='-2 -3 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<g id='icon'>
							<path
								id='Vector'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M10.4487 1.67684C12.5125 1.77783 14.4356 2.62524 15.9044 4.08874C17.4711 5.64974 18.3335 7.72473 18.3327 9.93139C18.3308 14.485 14.6078 18.1901 10.0343 18.1901C8.31438 18.1901 6.94766 17.6639 6.06517 17.1849L1.66602 18.3333L2.84333 14.0536C2.11713 12.8012 1.735 11.3805 1.7356 9.92483C1.73743 5.37131 5.46013 1.66663 10.0341 1.66663L10.4487 1.67684ZM6.26897 15.6897L6.52087 15.8385C7.57986 16.4639 8.79375 16.7948 10.0314 16.7953H10.0342C13.8359 16.7953 16.93 13.7159 16.9315 9.93091C16.9322 8.09677 16.2155 6.37212 14.9133 5.07465C13.611 3.7771 11.8793 3.06211 10.037 3.06151C6.2324 3.06151 3.13823 6.14061 3.13671 9.92531C3.13619 11.2224 3.50083 12.4856 4.19127 13.5784L4.35527 13.8382L3.65848 16.3712L6.26897 15.6897ZM14.2149 11.8953C14.1631 11.8092 14.0249 11.7575 13.8176 11.6542C13.6102 11.5509 12.5908 11.0517 12.4007 10.9828C12.2106 10.914 12.0724 10.8796 11.9342 11.0861C11.796 11.2927 11.3986 11.7575 11.2776 11.8953C11.1567 12.033 11.0357 12.0502 10.8284 11.9469C10.6211 11.8437 9.95294 11.6257 9.16094 10.9227C8.54452 10.3755 8.12838 9.69976 8.00742 9.49316C7.8865 9.28661 7.99452 9.17488 8.09832 9.07202C8.19162 8.97953 8.30571 8.83098 8.40939 8.71048C8.51306 8.59001 8.54759 8.50388 8.61674 8.36621C8.68584 8.22847 8.65127 8.10801 8.59945 8.00471C8.5476 7.90137 8.13293 6.88563 7.96015 6.47243C7.79181 6.07008 7.62086 6.12456 7.49359 6.1182C7.37279 6.11224 7.23443 6.11093 7.09622 6.11093C6.95797 6.11093 6.73333 6.16254 6.54327 6.36917C6.3532 6.57573 5.81754 7.07502 5.81754 8.09068C5.81754 9.10647 6.56056 10.0876 6.66423 10.2254C6.76791 10.3632 8.12634 12.4474 10.2064 13.3414C10.7011 13.554 11.0873 13.6809 11.3884 13.776C11.8852 13.9331 12.3372 13.911 12.6944 13.8578C13.0928 13.7986 13.9212 13.3586 14.094 12.8766C14.2668 12.3945 14.2668 11.9813 14.2149 11.8953Z'
								fill='#8291A4'
							/>
						</g>
					</svg>
				</div>
			</a>
			<a target='_blank' rel='noopener noreferrer' href={`https://vk.com/share.php?url=${url}&title=${title || ''}`}>
				<div className='vk'>
					<svg width='32' height='32' viewBox='-2 -3 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<g id='icon'>
							<path
								id='Vector'
								d='M17.1561 6.39771C17.2609 6.07213 17.1561 5.83337 16.6605 5.83337H15.0189C14.6012 5.83337 14.4104 6.03992 14.3056 6.26677C14.3056 6.26677 13.4709 8.17052 12.2882 9.4049C11.9064 9.76409 11.732 9.87751 11.5239 9.87751C11.4199 9.87751 11.2686 9.76409 11.2686 9.43851V6.39771C11.2686 6.00701 11.1481 5.83337 10.8 5.83337H8.2205C7.96 5.83337 7.80281 6.01402 7.80281 6.18626C7.80281 6.55594 8.39417 6.64136 8.4548 7.6832V9.94333C8.4548 10.4383 8.35974 10.5287 8.15014 10.5287C7.59396 10.5287 6.24056 8.61792 5.43735 6.43061C5.2809 6.00561 5.12296 5.83407 4.70376 5.83407H3.06292C2.59357 5.83407 2.5 6.04062 2.5 6.26748C2.5 6.67497 3.05618 8.69144 5.09077 11.3584C6.44716 13.1795 8.35674 14.1667 10.0964 14.1667C11.1391 14.1667 11.2679 13.9476 11.2679 13.5695V12.1929C11.2679 11.7546 11.3675 11.6664 11.6976 11.6664C11.9401 11.6664 12.3578 11.7813 13.3317 12.6586C14.444 13.699 14.6282 14.166 15.2532 14.166H16.8941C17.3627 14.166 17.597 13.9469 17.4622 13.5142C17.3148 13.0836 16.7833 12.4569 16.0781 11.7154C15.6949 11.2925 15.1222 10.8374 14.9478 10.6099C14.7045 10.3165 14.7749 10.187 14.9478 9.92652C14.9486 9.92722 16.948 7.29321 17.1561 6.39771Z'
								fill='#8291A4'
							/>
						</g>
					</svg>
				</div>
			</a>
			<a target='_blank' rel='noopener noreferrer' href={`https://ok.com/share.php?url=${url}&title=${title || ''}`}>
				<div className='ok'>
					<svg width='32' height='32' viewBox='-2 -3 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<g id='icon'>
							<path
								id='Vector'
								d='M9.98804 9.88186C7.54199 9.88186 5.50896 7.82909 5.50896 5.40006C5.50896 2.88702 7.54199 0.833374 9.98891 0.833374C12.519 0.833374 14.468 2.88615 14.468 5.40006C14.4636 6.59274 13.9909 7.73488 13.1536 8.57545C12.3163 9.41601 11.183 9.88622 10.0028 9.88274L9.98804 9.88186ZM9.98804 3.47328C8.95248 3.47328 8.16455 4.35267 8.16455 5.40093C8.16455 6.44744 8.95248 7.2437 9.98891 7.2437C11.0669 7.2437 11.8133 6.44744 11.8133 5.40093C11.8141 4.35179 11.0669 3.47328 9.98804 3.47328ZM11.7717 13.6112L14.3026 16.0831C14.8005 16.6264 14.8005 17.4227 14.3026 17.9258C13.7641 18.4692 12.9337 18.4692 12.519 17.9258L9.98891 15.4119L7.54199 17.9258C7.29349 18.177 6.961 18.3021 6.58695 18.3021C6.29689 18.3021 5.96526 18.1761 5.67434 17.9258C5.17647 17.4227 5.17647 16.6264 5.67434 16.0822L8.24594 13.6103C7.31725 13.3324 6.42636 12.9388 5.59381 12.4386C4.97126 12.1035 4.84744 11.2661 5.17906 10.637C5.59381 10.0087 6.34018 9.84161 7.00429 10.2607C7.90357 10.8153 8.9367 11.1087 9.99021 11.1087C11.0437 11.1087 12.0768 10.8153 12.9761 10.2607C13.6402 9.84161 14.4273 10.0087 14.8005 10.637C15.1745 11.2661 15.0074 12.1026 14.4264 12.4386C13.6394 12.9418 12.7268 13.3189 11.7726 13.612L11.7717 13.6112Z'
								fill='#8291A4'
							/>
						</g>
					</svg>
				</div>
			</a>
		</div>
	);
};
export default Social;
