import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { formatDate } from 'helpers';
import { Picture } from 'components';
import emptyImage from 'client/img/questions-answers/image-question.png?url';

export const QuestionAnswerCard = ({ record, className, children }) => {
	className = classNames('question-answer-card', className);
	const stringWithoutTags = record?.description.replace(/(<([^>]+)>)/gi, '');

	return (
		<Link to={`/question-answer/${record.id}`} className={className}>
			<Picture src={record.image ? record?.downloadFile('image') : emptyImage} pictureClassName='images' width={125} height={125} />

			<div className='question-answer-card-items'>
				<div className='category-subcategory'>
					{record.branchCategory && <div className='category date p6'>{record.branchCategory?.name}</div>}
					{record.branchCategory ? (
						<div className='subcategory date p6'>{record.subCategory?.name}</div>
					) : (
						<div className='date p6'>{record.subCategory?.name}</div>
					)}
				</div>

				<div className='question-answer-title h6'>{record?.title}</div>
				<div className='text-middle p2' dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
				<div className='info'>
					<div className='date p3'>{formatDate(record?.publishAt, 'dd.MM.yyyy')}</div>
				</div>
			</div>
		</Link>
	);
};
