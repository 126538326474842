import { inject, observer } from 'mobx-react';
import React from 'react';
import store from 'client/store';
import { RecordSelect } from '@smartplatform/ui';
import './style.scss';

@inject('store')
@observer
export default class Filters extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { filter, onChangeFilter } = this.store;
		const { county } = filter;

		return (
			<div className='news-filters'>
				<div className='selectors'>
					<RecordSelect model={store.model.County} property='name' placeholder={'По районам'} value={county} onChange={(r) => onChangeFilter('county', r)} />
				</div>
			</div>
		);
	}
}
