import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import iconGo from 'client/img/widget/icon-go.svg?url';
import store from 'client/store';
import { formatDate } from 'client/helpers';
import ExternalLink from 'helpers/externalLink';
import WeatherContainer from '../weather-container/WeatherContainer';

@observer
class WidgetVariant5 extends Component {
	render() {
		const widget = store.ui.siteWidgets.find((widget) => widget.variantId === 5);
		if (!widget) return;
		const data = new Date();
		return (
			<div className='background-sample'>
				<div className='widget5'>
					<div className='widget-weather'>
						<div className='city-date'>
							<div className='city-weather'>
								{widget.city && (
									<>
										<div className='city'>{widget.city.name}</div>
										<div className='city-county'>{widget.city.county && widget.city.county.name}</div>
									</>
								)}
							</div>
							<div>
								<div className='date-weather-day-of-week'>{formatDate(data, 'EEEE')}</div>
								<div className='date-weather'>{formatDate(data, 'dd MMMM yyyy')}</div>
							</div>
						</div>
						<WeatherContainer widget={widget} />
					</div>
					<div className='widget-links'>
						<ExternalLink to={widget.link1} className='link-header-container'>
							<div className='link-header'>{widget.linkHeader1}</div>
							<img src={iconGo} alt='go' className='go' />
						</ExternalLink>
						<ExternalLink to={widget.link2} className='link-header-container'>
							<div className='link-header'>{widget.linkHeader2}</div>
							<img src={iconGo} alt='go' className='go' />
						</ExternalLink>
						<ExternalLink to={widget.link3} className='link-header-container'>
							<div className='link-header'>{widget.linkHeader3}</div>
							<img src={iconGo} alt='go' className='go' />
						</ExternalLink>
						<ExternalLink to={widget.link4} className='link-header-container'>
							<div className='link-header'>{widget.linkHeader4}</div>
							<img src={iconGo} alt='go' className='go' />
						</ExternalLink>
					</div>
				</div>
			</div>
		);
	}
}

export default WidgetVariant5;
