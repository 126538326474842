import React from 'react';
import { Desktop, Mobile, NotPhone, Phone, SlideSmall } from 'components';
import './style.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import { getModelWhere } from 'helpers';
import MediaQuery from 'react-responsive';
import { mediaQueryBreakPoints } from 'constants';

@observer
export default class SliderSmall extends React.Component {
	@observable records = [];
	@observable isLoading = null;
	@observable selected = 0;
	@observable totalGroups = 0;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const isMainShowAll = false;
		const where = getModelWhere(store.model.SlideSmall, isMainShowAll);
		this.records = await store.model.SlideSmall.find({
			where,
			order: 'id desc',
		});
		const slidesPerGroup = 3; // Количество слайдов в группе
		this.totalGroups = Math.ceil(this.records.totalCount / slidesPerGroup);

		if (this.totalGroups > 1) {
			this.autoSlide(); // Запускаем автоматический перелистыватель при наличии более чем одной группы слайдов
		}
		this.isLoading = false;
	};

	autoSlide = () => {
		this.intervalId = setInterval(() => {
			let nextGroup = this.selected + 1;
			if (nextGroup >= this.totalGroups) {
				nextGroup = 0; // Вернуться к первой группе слайдов после последней
			}
			this.selected = nextGroup;
		}, 5000); // Перелистывание каждые 3 секунды
	};

	componentWillUnmount() {
		clearInterval(this.intervalId); // Очищаем интервал при размонтировании компонента
	}

	handleClick = (e) => {
		let value = e.target.getAttribute('data-value');
		if (value !== null) {
			this.selected = value;
		}
	};
	render() {
		if (this.isLoading || this.records.length === 0) return;
		const totalCount = this.records.totalCount;
		const style = this.selected >= 1 ? { transform: `translateX(-${this.selected * 1020}px)` } : {};

		const renderIndicator = [];

		for (let i = 0; i < totalCount / 3; i++) {
			renderIndicator.push(<li data-value={i} key={i} className={this.selected == i ? 'active' : ''}></li>);
		}

		const contentItems = (
			<>
				{this.records.map((record, index) => (
					<div className='small-item' key={record.id}>
						<SlideSmall number={index} record={record} />
					</div>
				))}
			</>
		);

		return (
			<div className='slider-small'>
				<NotPhone>
					<div className="small-slide-items" style={style}>
						{contentItems}
					</div>
				</NotPhone>
				<Phone>
					<div className="small-slide-items">
						{contentItems}
					</div>
				</Phone>
				{renderIndicator.length > 1 && (
					<ul onClick={this.handleClick} className='small-indicators'>
						{renderIndicator}
					</ul>
				)}
			</div>
		);
	}
}
