import React, { lazy } from 'react';
const Phonebook = lazy(() => import('./phonebook'));
const AllSites = lazy(() => import('./all-sites'));
import * as Pages from '.';

export const INNER_PAGE_ROUTES = [
	{ path: '/all-sites', component: AllSites },
	{ path: '/podcasts', component: Pages.Podcasts },
	{ path: '/media', component: Pages.Media },
	{ path: '/columns', component: Pages.Columns },
	{ path: '/events', component: Pages.Events },
	{ path: '/open-data', component: Pages.OpenData },
	{ path: '/documents', component: Pages.DocumentsRoute },
	{ path: '/phonebook', component: Phonebook },
	{ path: '/about-republic', component: Pages.AboutRepublic },
	{ path: '/vacancy', component: Pages.Vacancy },
	{ path: '/question-answer', component: Pages.QuestionAnswer },
	{ path: '/support-measure', component: Pages.SupportMeasure },
	{ path: '/citizens-appeals', component: Pages.CitizensAppeals },
	{ path: '/feedback', component: Pages.Feedback },
	{ path: '/discussions', component: Pages.Discussions },
	{ path: '/map', component: Pages.Map },
	{ path: '/npa', component: Pages.NpaDocuments },
	{ path: '/structure', component: Pages.Structure },
	{ path: '/managements', component: Pages.Managements },
	{ path: '/search', component: Pages.Search },
	{ path: '/news', component: Pages.News },
	{ path: '/404', component: Pages.PageNotFound },
];
