import React, { Component } from 'react';
import './style.scss';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import { Link } from 'react-router-dom';

import noImage from 'client/img/articles-no-image.png?url';
import { getModelWhere } from 'helpers';

@observer
class SupportMeasureWidget extends Component {
	@observable recordsSupport = null;
	@observable recordsNews = null;
	@observable isLoading = true;
	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const modelSupportMeasure = store.model.SupportMeasure;
		const modelArticle = store.model.Article;
		if (store.ui.site.isMain) {
			this.recordsSupport = await modelSupportMeasure.find();
			this.recordsNews = await modelArticle.find({ where: { showOnSupportWidget: true }, limit: 3, order: 'publishAt desc' });
		} else {
			const isMainShowAll = false;
			const whereSupportMeasure = getModelWhere(modelSupportMeasure, isMainShowAll);
			this.recordsSupport = await modelSupportMeasure.find({
				where: whereSupportMeasure,
			});
			this.recordsNews = await modelArticle.find({ where: { siteId: store.ui.site.id, showOnSupportWidget: true }, limit: 3, order: 'publishAt desc' });
		}
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		return (
			<>
				<div className='support-measure-widget-container'>
					<div className='support-measure'>
						<div className='head-title-support-measure'>
							<span className='title p4'>{t('supportMeasure.title')}</span>
							<span className='line'></span>
						</div>
						<div className='support-measure-all-items'>
							{this.recordsSupport &&
								this.recordsSupport
									.map((rec) => (
										<Link to={`/support-measure/${rec.id}`} key={rec.id}>
											<div className='support-measure-items'>
												<div className='topic'>{rec.topics?.name}</div>
												<div className='title'>{rec?.title}</div>
												<div className='description' dangerouslySetInnerHTML={{ __html: rec?.description.replace(/(<([^>]+)>)/gi, '') }} />
											</div>
										</Link>
									))
									.slice(0, 6)}
							<Link to='/support-measure' className='button-support-measure'>
								{t('supportMeasure.goToOtherMeasures')}
							</Link>
						</div>
					</div>
					<div className='interesting-news'>
						<div className='head-title-interesting-news'>
							<span className='title p4'>{t('supportMeasure.interestingAndUseful')}</span>
							<span className='line'></span>
						</div>
						<div className='interesting-news-all'>
							<div className='interesting-news-list'>
								{this.recordsNews &&
									this.recordsNews.map((rec) => (
										<Link to={`news/${rec.id}`} key={rec.id}>
											{/*{rec?.showOnSupportWidget && (*/}
											<div className='interesting-news-items'>
												<div className='text'>
													<div className='news-title h7'>{rec?.title}</div>
													<div className='news-subtitle'>{rec?.subtitle}</div>
												</div>
												<div className='image'>
													{rec.image ? (
														<img src={rec?.downloadFile('image') + '?image=[{"resize":{"width":80}}]'} alt='image' />
													) : (
														<img src={noImage} alt='no-image' className='empty-image' />
													)}
												</div>
											</div>
											{/*)}*/}
										</Link>
									))}
							</div>

							<Link to='/news' className='button-news'>
								{t('supportMeasure.anotherColumns')}
							</Link>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default SupportMeasureWidget;
