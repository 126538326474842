import React, { Component } from 'react';
import './style.scss';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { AutoAnimate } from 'components';

@observer
export default class ScrollBar extends Component {
	static propTypes = {
		records: PropTypes.any,
		h: PropTypes.string,
		typeCheckBox: PropTypes.string,
	};

	render() {
		const { records, h, typeCheckbox, nameInput } = this.props;

		return (
			<div className='scrollableContainer'>
				<AutoAnimate type='ul' className='list' style={{ height: h }}>
					{records.length > 0 &&
						records.map((record) => (
							<div className='list-items' key={record?.id}>
								<input
									type='checkbox'
									className='custom-checkbox'
									id={record.id}
									onChange={(value) => this.props.onChange?.(record, value.target.checked)}
									{...(typeCheckbox === 'radio' ? { name: nameInput ? nameInput : 'radio', type: 'radio' } : {})}
								/>
								<div className='items'>
									<li className='item p2'>{record?.name}</li>
									<span className='item-id p2'>{Number.isInteger(record.total) ? record.total : record?.id}</span>
								</div>
							</div>
						))}
				</AutoAnimate>
			</div>
		);
	}
}
