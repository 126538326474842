import React from 'react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Popup, Tab, Tabs } from '@smartplatform/ui';
import { t } from 'i18next';
import Filters from './filters/Filters';
import './style.scss';
import Single from '../single/Single';
import MediaStore from '../store';
import { List } from './List';
import { SearchPage } from '../../../components/search-page';
import { NotPhone, Phone } from 'components';
import { FiltersMobile } from 'client/pages/media/list/filters/FiltersMobile';

@observer
export default class Media extends React.Component {
	@observable records = [];
	@observable page = 1;

	constructor(props) {
		super(props);
		this.store = new MediaStore(props.match.params.id, new URLSearchParams(this.props.location.search).get('search'));
	}

	render() {
		const { setTab, record, onClosePopup, onChangeFilter, tags, filter } = this.store;

		return (
			<Provider store={this.store}>
				<>
					<SearchPage
						headerName={t('media.plural')}
						placeholder={t('media.searchByMedia')}
						onChangeTag={(r) => onChangeFilter('label', r)}
						tags={tags}
						onChangeSearch={(r) => onChangeFilter('search', r)}
						search={filter.search}
					/>
					<div className='media-page'>
						<NotPhone>
							<Filters />
						</NotPhone>
						<Phone>
							<FiltersMobile />
						</Phone>
						<Tabs type='manual'>
							<Tab title={t('media.video')} onClick={() => setTab('video')} />
							<Tab title={t('media.audio')} onClick={() => setTab('audio')} />
							<Tab title={t('media.image')} onClick={() => setTab('photo')} />
						</Tabs>
						<List />
						{record && (
							<Popup onClose={onClosePopup} showCloseButton={false} className='record-popup'>
								<Single record={record} onClose={onClosePopup} />
							</Popup>
						)}
					</div>
				</>
			</Provider>
		);
	}
}
