export default {
	translation: {
		ipPhone: 'Факс',
		addRecord: 'Добавить запись',
		editRecord: 'Изменить запись',
		noData: 'Ничего не найдено',
		all: 'Все',
		fio: 'ФИО',
		from: 'от',
		find: 'Найти',
		publishAt: 'Дата публикации',
		publish: 'Опубликовано',
		note: 'Примечание',
		archive: 'В архиве',
		pressCenter: 'Пресс-центр',
		actual: 'Актуальные',
		acceptAt: 'Дата принятия',
		number: 'Номер',
		datePickerPlaceholder: 'ДД.ММ.ГГ',
		byCounty: 'По районам',
		showNote: 'Показать примечание',
		hideNote: 'Скрыть примечание',
		showNoteLigament: 'Показать примечание / связку',
		hideNoteLigament: 'Скрыть примечание / связку',
		orderBy: 'Сортировать по',
		layers: 'Слои',
		area: 'Площадь',
		flag: 'Флаг',
		close: 'Закрыть',
		portal: 'Портал',
		portalMap: 'Карта портала',
		enter: 'Войти',
		reset: 'Сбросить',
		resetAll: 'Сбросить все',
		showMore: 'Показать еще',
		adminCenter: 'Административный центр',
		oktmo: 'ОКТМО',
		badVision: 'Версия для слабовидящих',
		banners: 'Баннеры',
		author: 'Автор',
		code: 'Код',
		default: 'По умолчанию',
		upload: 'Загрузить',
		material: 'Материал',
		selectImage: 'Выберите изображение',
		addImage: 'Добавление изображения',
		add: 'Добавить',
		save: 'Сохранить',
		rating: {
			one: 'оценка',
			two: 'оценки',
			five: 'оценок',
		},
		date1: 'Дата (от)',
		date2: 'Дата (до)',
		info: 'Информация',
		putRating: 'Пожалуйста, поставьте свою оценку',
		view: {
			title: 'Просмотр',
			plural: 'Просмотры',
		},
		county: {
			title: 'Район',
			plural: 'Районы',
			select: 'Выберите район',
			administrativeCenter: 'Административный центр',
			admCenter: 'Адм. центр',
			square: 'Площадь',
			includes: 'Включает',
			population: 'Население',
			sitesOfThisArea: 'Сайты этого района',
		},
		searchPage: {
			article: 'Новости',
			event: 'События',
			vacancy: 'Вакансии',
			questionAnswer: 'Вопрос-ответ',
			linksFeedLink: 'Полезные материалы',
			album: 'Медиа',
			document: 'Документы',
			searchResult: 'Результаты поиска',
			portalSearch: 'Поиск по порталу',
			goToSection: 'Перейти в раздел',
			chapter: 'раздел',
			allResults: 'Все результаты в разделе',
		},
		password: {
			title: 'Пароль',
		},
		record: {
			saved: 'Запись сохранена',
			deleted: 'Запись удалена',
		},
		audit: {
			title: 'Журналирование',
			model: 'Модель',
			action: 'Действие',
			create: 'Создание',
			delete: 'Удаление',
			update: 'Обновление',
			select: 'Выберите запись',
			data: 'Данные записи',
			owner: 'Пользователь',
			hidden: '*скрыто*',
			objectId: 'ID Объекта',
		},
		user: {
			title: 'Пользователь',
			plural: 'Пользователи',
			blocked: 'Заблокирован',
			position: 'Должность',
			lastAuthDate: 'Дата последней авторизации',
			create: 'Новый пользователь',
			profile: 'Профиль',
			email: 'E-mail',
			emailVerified: 'E-mail подтвержден',
			name: 'Логин',
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			leftComment: 'оставил(а) комментарий',
			createdTask: 'создал задачу',
			telegram: 'Telegram ID',
			username: 'Логин',
			password: 'Пароль',
			avatar: 'Аватар',
			fullName: 'ФИО',
			organizationName: 'Организация',
			searchFioPositionOrganization: 'Поиск по ФИО, должности, организиации',
			canAccess: 'Пользователю будут доступны',
			allData: 'все данные',
			limitRegion: 'данные по региону:',
			limitCounty: 'данные по району:',
			languages: 'Редактор языков',
			limitCity: 'данные по городу:',
			countyName: 'Район',
			cityName: 'Город/нас.пункт',
			error: {
				email: {
					custom: {
						email: 'Неверный e-mail',
					},
					uniqueness: 'Пользователь с этим e-mail уже существует',
					verifySent: 'На e-mail отправлено письмо для подтвержения',
				},
			},
		},
		role: {
			title: 'Роль',
			plural: 'Роли',
			create: 'Новая роль',
			created: 'Созданo',
			modified: 'Изменено',
			name: 'Код',
			description: 'Название',
			isSaving: 'Права сохраняются...',
		},
		footer: {
			siteMapTitle: 'Карта сайта',
			portalsTitle: 'Портал',
		},
		siteType: {
			plural: 'Типы сайта',
			title: 'Тип сайта',
			parent: 'Родительский тип',
		},
		copyright: {
			sendAppeal: 'Подать обращение',
			login: 'Войти',
			policy: 'Политика конфиденциальности',
			userAgreement: 'Пользовательское соглашение',
			allRightsReserved: '© Все права защищены',
		},
		openData: {
			title: 'Открытые данные',
			dataLibrary: 'Библиотека данных',
			dataSetsFound: 'Найдено наборов данных:',
			openDataSearch: 'Поиск по открытым данным',
			responsiblePerson: 'Отвественное лицо',
			phoneResponsiblePerson: 'Телефон отвественного лица',
			emailResponsiblePerson: 'E-mail отвественного лица',
			agency: 'Орган',
			identificationNumber: 'Идентификационный номер',
			publishAt: 'Опубликовано',
			updatedAt: 'Последнее изменение',
			versionGuidelines: 'Версия методических рекомендаций',
			chapter: 'Раздел',
			actualDataAt: 'Дата актуальности',
			dataSet: 'Набор данных',
			passport: 'Паспорт',
			structure: 'Структура',
			table: 'Таблица',
			generalAssessment: 'Общая оценка',
			pleaseYourRating: 'Пожалуйста поставьте свою оценку',
			byUpdateDateAscending: 'По дате обновления (по возрастанию)',
			byUpdateDateDescending: 'По дате обновления (по убыванию)',
		},
		aboutRepublic: {
			title: 'О Республике',
			material: 'Материалы',
			searchMaterial: 'Поиск по материалам',
			infoAboutRepublic: 'Информация о республике',
			geography: 'География',
			historyTime: 'История и время',
			personality: 'Персоналии',
			cultureArt: 'Культура и искусство',
			religion: 'Религия',
			humanSociety: 'Человек и общество',
			scienceNature: 'Наука и Естествознание',
			technics: 'Техника',
			sport: 'Спорт',
			economy: 'Экономика',
			education: 'Образование',
			newOnMaterials: 'Новое в материалах',
			generalInformation: 'Общие сведения',
		},
		aboutrepubliccategory: {
			title: 'Категория',
		},
		republicMaterial: {
			title: 'Республиканский материал',
			plural: 'Республиканские материалы',
		},
		citizensAppeals: {
			title: 'Обращения граждан',
			appeal: 'Обращение',
			firstName: 'Фамилия',
			name: 'Имя',
			middleName: 'Отчество',
			withoutMiddleName: 'Отчество отсутсвует',
			coAuthorWithoutMiddleName: 'Отчество соавтора отсутсвует',
			theme: 'Тема',
			recipient: 'Адресат',
			result: 'Результат',
			phone: 'Контактный телефон',
			email: 'Адрес электронной почты',
			enterEmail: 'Введите адрес электронной почты',
			organization: 'Наименование организации',
			addCoAuthor: 'Добавить соавтора',
			subjectAppeals: 'Тема обращения',
			statusAppeals: 'Статус обращения',
			textAppeals: 'Текст обращения',
			legalEntity: 'Юридическое лицо',
			supported: 'Поддержано',
			partialSupported: 'Частично поддержано (если в обращении содержится несколько вопросов)',
			explained: 'Разъяснено',
			hasTheDeadline: 'Определен ли срок исполнения решения, принятого по итогам рассмотрения Вашего обращения?',
			specifyDeadline: 'Укажите срок исполнения решения, принятого по итогам рассмотрения Вашего обращения (при наличии)',
			evaluateTheWork: 'Оцените работу исполнительного органа государственной власти (органа местного самоуправления) по расмотрению Вашего обращения:',
			naturalPerson: 'Физическое лицо',
			appealSent: 'Обращение отправлено',
			authorAppeal: 'Автор обращения',
			buttonForCollectiveAppeals: 'Кнопка для коллективных обращений',
			postalAddress: 'Почтовый адрес',
			thankYouVeryMuch: 'Большое спасибо!',
			yourLetterSent: '«Ваше письмо отправлено и подлежит обязательной регистрации в течение трех дней с момента поступления»',
			answerTheQuestions: 'Ответьте на вопросы по итогам рассмотрения Вашего обращения:',
			answerTheQuestionsAdmin: 'Ответьты на вопросы по итогам рассмотрения обращения:',
			whatDecisionWasMade: 'Какое решение принято по результатам рассмотрения Вашего обращения?',
			fileSizeNotExceed10Mb: 'Размер файла не должен превышать 10Мб.',
			agreementWithTheRules: 'Я согласен с правилами подачи обращений.',
			formEvaluationOfResults: 'Форма оценки результатов рассмотрения обращения',
			coAuthorAppeal: 'Соавтор обращения',
			removeCoAuthor: 'Убрать соавтора',
			fillFormCitizensElectronicAppeal: 'Заполните форму электронного ображения граждан для решения вашей проблемы',
			surnameFirstNamePatronymicTheOfficial: 'Фамилия Имя Отчество должностного лица',
			selectFromTheList: 'Выберите из списка',
			inorderProtectAgainstThePossibleIntroductionOfVirusPrograms:
				'В целях защиты от возможного внедрения вирусных программ. Установлен запрет на копирование и перенос текста в данное поле.',
			consentToDataProcessing:
				'Я подтверждаю свое согласие на обработку своих персональных данных. Подробнее о том как будут храниться и использоваться мои данные',
			personalInformationIsStoredAndProcessed:
				'Информация персонального характера хранится и обрабатывается с соблюдением требований российского законодательства о персональных данных. При рассмотрении обращения не допускается разглашение сведений, содержащихся в обращении, а также сведений, касающихся частной жизни гражданина, без его согласия.',
			isNotDisclosureOfInformation:
				'Не является разглашением сведений, содержащихся в обращении, направление письменного обращения в государственный орган, орган местного самоуправления или должностному лицу, в компетенцию которых входит решение поставленных в обращении вопросов.',
			totalApplicationsSubmitted: 'Всего обращений подано через официальный информационный портал РС(Я)',
			responsesToRequests: 'Ответы на обращения',
			hasTheDecisionMade: 'Исполнено ли решение принятое по итогам рассмотрения Вашего обращения?',
			measuresTaken: 'Меры приняты',
			measuresBeingTaken: 'Меры принимаются (срок продлен)',
			registeredAtRK: 'Дата регистрации РК',
			numberRKinESED: '№ РК в ЕСЭД',
			dateOriginRKinESED: 'Дата исх РК в ЕСЭД',
			outgoingNumberRKinESED: '№ исх РК в ЕСЭД',
			typeResponse: 'Вид ответа',
			electronically: 'Электронно',
			inWriting: 'Письменно',
			enterMessageOnTheQuestionOfInterest: 'Введите сообщение по интересующему вопросу',
			enterYorFamily: 'Введите вашу фамилию',
			socialStatus: 'Социальное положение',
			executor: 'Исполнитель',
			exportInExcel: 'Экспорт в Excel',
			summaryReport: 'Сводный отчет',
			sendToESED: 'Отправить в ЕСЭД',
		},
		pageDescription: {
			plural: 'Описание страниц',
			title: 'Описание страницы',
		},
		managements: {
			pageTitle: 'Руководство',
			tabGroup: 'Группы руководителей',
			tabList: 'Руководство',
			photo: 'Фотография',
			plural: 'Руководство',
			title: 'Руководитель',
			fio: 'ФИО',
			compositionGovernment: 'Состав правительства',
			dateOfBirth: 'Дата рождения',
			position: 'Должность',
			education: 'Образование',
			biography: 'Биография',
			phone: 'Телефон приемной',
			supervisedDirections: 'Курируемые направления',
			legalActs: 'Правовые акты',
			otherInformation: 'Иные сведения',
			preRegistrationPhone: 'Предварительная запись по тел. IP',
			email: 'E-mail для официальной переписки',
			laborActivity: 'Трудовая деятельность',
			descriptionOfActivity: 'Описание деятельности',
			departmentalAwards: 'Государственные и иные награды, звания',
			contacts: 'Контакты',
			address: 'Почтовый адрес',
			schedule: 'График приёма',
			assistant: 'Помощник',
			notFound: 'Руководитель не найден',
		},
		month: {
			title: 'Месяц',
			january: 'Январь',
			february: 'Февраль',
			march: 'Март',
			april: 'Апрель',
			may: 'Май',
			june: 'Июнь',
			july: 'Июль',
			august: 'Август',
			september: 'Сентябрь',
			october: 'Октябрь',
			november: 'Ноябрь',
			december: 'Декабрь',
		},
		supportMeasure: {
			title: 'Меры поддержки',
			plural: 'Меры поддержки',
			all: 'Все',
			searchBySupportMeasure: 'Поиск по мерам поддержки',
			descriptionMeasure: 'Описание меры',
			additionalInfo: 'Доп. информация',
			evaluateMeasure: 'Оценить меру',
			usefulInfo: 'Была ли эта информация полезна для вас?',
			interestingAndUseful: 'Интересно и полезно',
			anotherColumns: 'Другие статьи',
			goToOtherMeasures: 'Перейти к другим мерам',
			formsArrayFirst: 'мера поддержки',
			formsArraySecond: 'меры поддержки',
			formsArrayThird: 'мер поддержки',
		},
		siteManagment: {
			plural: 'Управление сайтом',
			title: 'Управление сайтом',
		},
		event: {
			plural: 'Календарь событий',
			title: 'Календарь событий',
		},
		eventtype: {
			title: 'Тип события',
		},
		poster: {
			title: 'Афиша',
		},
		events: {
			announcement: 'Анонсы',
			calendar: 'Календарь',
			list: 'Список',
			events: 'События',
			holidays: 'Праздники',
			noEvents: 'Событий не найдено',
			noHolidayEvents: 'Праздников не найдено',
			formsArrayFirst: 'событие',
			formsArraySecond: 'события',
			formsArrayThird: 'событий',
			organizer: 'Организатор',
			executionType: 'Тип проведения',
		},
		eventstatus: { title: 'Статус' },
		media: {
			image: 'Изображение',
			video: 'Видео',
			audio: 'Аудио',
			plural: 'Медиахранилище',
			searchByMedia: 'Поиск по медиахранилищу',
			formsArrayFirst: 'медиаматериал',
			formsArraySecond: 'медиаматериала',
			formsArrayThird: 'медиаматериалов',
			nothingFound: 'К сожалению, по вашему запросу ничего не найдено',
		},
		questionAnswer: {
			title: 'Вопрос-ответ',
			plural: 'Вопрос-ответ',
			source: 'Источник',
			noDeleteSubCategory: 'Данную подкатегорию нельзя удалить, потому что она указана в записях Вопрос-Ответ',
		},
		allSites: {
			lineTitle: 'Все сайты',
			plural: 'Все сайты',
			short: 'Все',
			popularTitle: 'Популярные сайты портала',
			goToDepartment: 'Перейти в ведомство',
			allPortalSites: 'Все сайты портала',
			popularSitesLineTitle: 'Популярные сайты портала',
			aboutPart: 'О разделе',
			aboutPartTitle: 'В этом разделе вы сможете перейти на сайты внутри Портала.',
			aboutPartText:
				'Вот так можно ознакомительный текст написать для тех, кто Порталом раньше не пользовался. Типа так, для пользователей: Портал состоит из основного сайта Портала Sakha.gov.ru и сайтов Субъектов - организаций, должностных лиц, юридических лиц, имеющих сайты на Официальном информационном Портале Республики Саха (Якутия). Сайтами Субъектов являются сайт Главы РС(Я), сайт Правительства РС(Я), сайт Председателя Правительства РС(Я), сайты заместителей Председателя Правительства РС(Я), портала Открытое правительство, сайты ИОГВ, сайты ОГВ и ОМСУ РС(Я), сайты организаций подведомственных ОГВ, сайты организаций подведомственных ОМСУ. Портал должен обеспечивать доступ к существующим поддоменам отдельных ведомств и сайтам организаций, подведомственных ОГВ и ОМСУ.',
			populars: 'Популярные',
		},
		usefulLinks: {
			title: 'Полезные ресурсы',
		},
		superBlock: {
			title: 'Супер блок',
			unit: 'Блок',
			tabTitle: 'Заголовок вкладки',
			icon: 'Выберите иконку',
			selectTheList: 'Выбрать из списка',
			selectTheNews: 'Новость',
			selectTheEvent: 'Событие',
			selectTheQuestion: 'Вопрос-ответ',
		},
		acl: {
			search: 'Поиск по названию или описанию модели',
			title: 'Право',
			plural: 'Права',
			create: 'Новое право',
			principalType: 'Вид субъекта',
			app: 'Приложение',
			role: 'Роль',
			user: 'Пользователь',
			accessType: 'Действие',
			read: 'Чтение',
			write: 'Запись',
			execute: 'Выполнение',
			all: 'Все',
			principalId: 'Субъект',
			model: {
				title: 'Модель',
				user: 'Пользователи',
				project: 'Проекты',
				person: 'Персоны',
				description: 'Описание',
			},
			permission: 'Право',
			allow: 'Разрешить',
			deny: 'Запретить',
			property: 'Свойство',
			allowAll: 'Разрешить все',
			list: 'Список прав',
		},
		roleMapping: {
			title: 'Назначение роли',
			plural: 'Назначенния ролей',
			create: 'Новое назначение роли',
		},
		news: {
			title: 'Новости',
			last: 'Последние',
			capital: 'Столица',
			county: 'Районы',
			republic: 'Республика',
			inCountry: 'В стране',
			inWorld: 'В мире',
			ulus: 'Улус',
			rubric: 'Рубрики',
			appurtenance: 'Принадлежность',
			importantNews: 'Важные новости',
			allImportantNews: 'Все важные новости республики',
			showImportantNewsOnPanel: 'Показывать "Важные Новости" в панели',
			showMore: 'Показать еще',
			newsTop: 'Топ новостей',
			newsByTheme: 'Новости по теме',
			searchByNews: 'Поиск по новостям',
			noNewsFound: 'Новостей не найдено',
			mainThing: 'Главное',
			signatureAuthorPhoto: 'Подпись или автор фото',
			showOnSupportWidget: 'Отображать в виджете - "Меры поддержки"',
			formsArrayFirst: 'материал',
			formsArraySecond: 'материала',
			formsArrayThird: 'материалов',
		},
		vacancy: {
			unifiedDatabaseOfVacancies: 'Единая база вакансий',
			title: 'Вакансия',
			searchByParameter: 'Поиск по параметру',
			organizationInitiatorTitle: 'Организация / Инициатор',
			titles: 'Вакансии',
			plural: 'Вакансии',
			basis: 'Основание',
			branch: 'Отрасль организации',
			contactPerson: 'Контактное лицо',
			linkContactPerson: 'Ссылк на Контактное лицо',
			linkButton: 'Ссылка на кнопку "Откликнуться"',
			education: 'Образование',
			primarySourceSite: 'Сайт-первоисточник',
			information: 'Информация',
			linkBasis: 'Ссылка на Основание',
			respond: 'Откликнуться',
			totalVacancy: 'Всего вакансий: ',
			shortDescription: 'Короткое описание вакансий',
			showVacancyOnPanel: 'Показывать "Вакансии" в панели',
			workExp: 'Опыт работы',
			showAllVacancies: 'Смотреть все вакансии',
			lastVacancies: 'Последние вакансии',
		},
		vacancystatus: { title: 'Статус' },
		searchInput: {
			searchByName: 'Поиск по названию',
			submit: 'Искать',
			advanced: 'Расширенный поиск',
			fast: 'Быстрый поиск',
			placeholder: 'Поиск',
			placeholderPhonebook: 'Поиск по телефонному справочнику',
			placeholderDocumentsAcceptBody: 'Поиск по документам, его номеру и принимающему органу',
			placeholderAllSites: 'Поиск сайта организации или ведомства',
		},
		apartment: 'Квартира/офис',
		startDate: 'Дата начала',
		endDate: 'Дата окончания',
		information: 'Информация',
		settings: 'Настройки',
		delete: 'Удалить?',
		day: 'День',
		year: 'Год',
		tag: {
			title: 'Тег',
			plural: 'Теги',
			all: 'Все теги',
		},
		page: {
			plural: 'Страницы',
			title: 'Страница',
		},
		interactiveMap: {
			title: 'Интерактивная карта',
			goToSite: 'Перейти на сайт',
			lastNewsCounty: 'Последние новости района',
		},
		analytics: 'Аналитика',
		backToSection: 'Вернуться в раздел',
		backToMainPage: 'Вернуться на главную страницу',
		category: 'Категория',
		categories: 'Категории',
		subCategory: 'Подкатегория',
		contacts: 'Контакты',
		cover: 'Обложка',
		color: 'Цвет',
		colorRGB: 'Цвет (RGB)',
		colorHEX: 'Цвет (HEX)',
		colorTextRGB: 'Цвет текста (RGB)',
		colorTextHEX: 'Цвет текста (HEX)',
		downloadFile: 'Скачать файл',
		fileName: 'Название файла',
		fileSize: 'Размер файла',
		goPortal: 'Перейти в портал',
		house: 'Дом',
		popular: 'Популярные',
		pupularServices: 'Популярные сервисы',
		open: 'Открыть',
		yes: 'Да',
		no: 'Нет',
		share: 'Поделиться',
		interesting: 'Интересно',
		application: 'Приложения',
		watch: 'Посмотреть',
		link: 'Ссылка',
		linkURL: 'URL-ссылки',
		linkHeaderURL: 'Заголовок ссылки на страницу/раздел',
		linkHeader: 'Заголовок ссылки',
		locality: 'Населенный пункт',
		pageChapter: 'на страницу/раздел',
		postcode: 'Индекс',
		textButton: 'Текст кнопки',
		button: 'Кнопка',
		date: 'Дата',
		date0: 'Дата публикации',
		updatedAt: 'Последнее изменение',
		attachFile: 'Прикрепить файл',
		description: 'Описание',
		descriptionNecessarily: 'Описание (обязательно)',
		fullName: 'Фамилия Имя Отчество',
		image: 'Изображение',
		initiator: 'Инициатор',
		icon: 'Иконка',
		photo: 'Фото',
		primarySourceSite: 'Сайт-первоисточник',
		linkPrimarySourceSite: 'Ссылка на Сайт-первоисточник',
		loading: 'Загрузка...',
		main: 'Главная',
		read: 'Читать',
		readMore: 'Читать далее',
		registered: 'Зарегестрировано',
		received: 'Поступило',
		receivedWith: 'Поступило (с)',
		receivedBy: 'Поступило (по)',
		showOnMain: 'Показывать на главной',
		see: 'Смотреть',
		select: 'Выбрать',
		send: 'Отправить',
		status: 'Статус',
		street: 'Улица',
		statistics: 'Стастистика',
		size: 'Размер',
		back: 'Назад',
		title: 'Название',
		theme: 'Тема',
		type: 'Тип',
		types: 'Типы',
		city: 'Город',
		cityCountyWeatherIndex: 'Город - Район - Индекс метеостанции',
		header: 'Заголовок',
		headerNecessarily: 'Заголовок (обязательно)',
		text: 'Текст',
		position: 'Должность',
		fax: 'Факс',
		site: {
			title: 'Сайт',
			isMain: 'Главный сайт',
			isMain3: 'В трех главных',
			allOrganizationSites: 'На всех сайтах организации',
			creating: 'Создание сайтов',
			error: 'Ошибка при создании сайта',
		},
		appeals: {
			title: 'Обращения',
			submitAppeal: 'Подать обращение',
			numberOfRequest: 'Количество обращений',
			shortDescription: 'Короткое описание обращений',
			showAppealsOnPanel: 'Показывать "Обращения" в панели',
		},
		broadcast: {
			title: 'Трансляция',
			titles: 'Трансляции',
			dateStartBroadcast: 'Дата до начала трансляции',
			linkBroadcast: 'Ссылка на трансляцию',
			shortDescription: 'Короткое описание трансляции',
			showBroadcastOnPanel: 'Показывать "Трансляции" в панели',
		},
		gisMap: {
			title: 'Гис-карта',
			showMapOnPanel: 'Показывать "Гис-карту" в панели',
			mapOfRepublic: 'ГИС-карта Республики',
		},
		comment: {
			title: 'Комментарий',
			postTitle: 'Написать комментарий',
			post: 'Отправить',
			text: 'Текст комментария',
			plural: 'Комментарии',
			confirmDelete: 'Удалить комментарий?',
			emptyAuthor: 'Введите ваше имя',
			emptyText: 'Введите текст (минимум 3 символа)',
		},
		widget: {
			title: 'Виджет',
			overviewPanel: 'Панель обзора',
			quickAccessBar: 'Панель быстрого доступа',
			systemWidget: 'Системные виджеты',
			usersWidget: 'Пользовательские виджеты',
			userWidget: 'Пользовательский виджет',
			showUserWidgetOnPanel: 'Показывать "Пользовательский виджет" в панели',
			iconUserWidget: 'Иконка',
			shortDescription: 'Короткое описание',
			imageForSample: 'Изображение как пример',
			numberOfSitesOnPortal: 'Количество сайтов на Портале',
			sitesOnPortal: 'сайтов на Портале',
			showSitesOnPanel: 'Показывать "Количество сайтов на Портале" в панели',
			plural: 'Виджеты',
			variant: 'Вариант',
			widget1: 'Вариант 1',
			widget2: 'Вариант 2',
			widget3: 'Вариант 3',
			widget4: 'Вариант 4',
			widget5: 'Вариант 5',
			selectWidgetVersion: 'Выберите версию виджета',
			version: 'Версия',
			backgroundUnderWidget: 'Бекграунд под виджетом для лучшей видимости содержимого',
			warningOneWidget: 'Если видите это сообщение, значит какой-то виджет уже показан на главной странице (более одного выбрать нельзя)',
		},
		feedback: {
			post: 'Отправить',
			emptyAuthor: 'Введите ваше имя',
			emptyEmail: 'Введите ваш e-mail адрес',
			emptyReason: 'Выберите причину сообщения',
			emptyTitle: 'Введите тему (минимум 3 символа)',
			emptyText: 'Введите текст (минимум 3 символа)',
			plural: 'Обратная связь',
		},
		banner: {
			plural: 'Баннеры',
			title: 'Баннер',
			noImage: 'нет изображения',
			attention: 'Если к сайту уже привязан баннер, то отображаться будет только последний созданный.',
		},
		wind: {
			title: 'Ветер',
			speedFactor: 'Скорость',
			fadeOpacity: 'Длина шлейфа',
			particleLife: 'Жизнь частицы',
			opacity: 'Прозрачность',
		},
		weather: {
			title: 'Погода',
			temp: 'Температура',
			tempDescription: 'Температура на высоте 2 м над землей',
			tempMeasure: '°C',
			pressure: 'Давление',
			pressureDescription: 'Атмосферное давление',
			pressureMeasure: 'мм рт.ст.',
			precipitation: 'Осадки',
			precipitationDescription: 'Накопление осадков за 3 часа',
			precipitationMeasure: 'кг/м²',
			humidity: 'Влажность',
			humidityDescription: 'Относительная влажность на высоте 2 м над землей',
			humidityMeasure: '%',
			pm25: 'PM2.5',
			pm25Description: 'Взвешенные твердые микрочастицы и мельчайшие капельки жидкости диаметром 2.5 мкм и менее',
			pm25Measure: 'мкг/м³',
			pm10: 'PM10',
			pm10Description: 'Взвешенные твердые микрочастицы и мельчайшие капельки жидкости диаметром 10 мкм и менее',
			pm10Measure: 'мкг/м³',
			co: 'CO',
			coDescription: 'Описание слоя CO',
			coMeasure: 'мкг/м³',
			no: 'NO',
			noDescription: 'Описание слоя NO',
			noMeasure: 'мкг/м³',
			no2: 'NO2',
			no2Description: 'Описание слоя NO2',
			no2Measure: 'мкг/м³',
			ozone: 'Озон',
			ozoneDescription: 'Описание слоя O3',
			ozoneMeasure: 'DU',
			so2: 'SO2',
			so2Description: 'Описание слоя SO2',
			so2Measure: 'кг/м²',
			feeling: 'Ощущается как',
		},
		silam: {
			title: 'Загрязнение воздуха',
			on: 'Показывать',
		},
		sites: {
			news: 'Новости',
			events: 'События',
			vacancy: 'Вакансии',
			'question-answer': 'Вопрос-ответ',
			map: 'ГИС-карта',
			'all-sites': 'Все сайты',
			'support-measure': 'Меры поддержки',
			'about-republic': 'О республике',
			'open-data': 'Открытые данные',
			null: '',
			organization: 'Организация',
			template: 'Шаблон',
			documents: 'Документы',
			media: 'Медиахранилище',
			npa: 'Проекты НПА',
			discussions: 'Опросы',
			'citizens-appeals': 'Обращения граждан',
			managements: 'Руководство',
			structure: 'Структура',
			phonebook: 'Телефонный справочник',
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'Электронная почта',
			input: 'Введите E-mail',
			exist: 'E-mail уже занят',
			invalidEmailAddress: 'Неверный адрес электронной почты',
		},
		language: {
			title: 'Язык',
			plural: 'Языки',
		},
		document: {
			title: 'Документ',
			plural: 'Документы',
			registry: 'Реестр документов',
			acceptBody: 'Принимающий орган',
			found: 'Найдено документов',
			file: 'Файл документа',
			findDocuments: 'Найти документы',
			noFinds: 'Документы не найдены',
			ligament: 'Связка с документом',
			addLigament: 'Связать с документом',
			parentDocument: 'Вышестоящий документ',
			parentDocuments: 'Вышестоящие документы',
			childDocuments: 'Подчиненные',
			ligamentAdd: 'Добавить связку',
			noDocuments: 'Нет доступных документов',
			signinAcceptApprovalAt: 'Дата подписания / принятия / утверждения',
			signinAcceptApprovalAtBefore: 'Дата подписания / принятия / утверждения от',
			signinAcceptApprovalAtAfter: 'Дата подписания / принятия / утверждения до',
			effectiveStartAt: 'Дата вступления в силу / начала действия',
			expirationAt: 'Дата окончания действия',
			warningLigament: 'ВНИМАНИЕ: В публичной части в связках документа отображаются только записи с блока “Подчиненные документы”',
		},
		documentType: {
			title: 'Тип документа',
			plural: 'Типы документов',
		},
		documenttype: {
			title: 'Тип документа',
		},
		documentSubject: {
			title: 'Тематика документа',
			plural: 'Тематики документов',
		},
		documentLigamentType: {
			title: 'Тип связки',
			plural: 'Типы связок',
		},
		organization: {
			importPhonebook: 'Импортировать',
			phonebook: 'Телефонный справочник',
			phonebookSize: 'Размер телефонного справочника',
			phonebookFormsArrayFirst: 'запись',
			phonebookFormsArraySecond: 'записи',
			phonebookFormsArrayThird: 'записей',
			official: 'Должностное лицо (обр. граждан)',
			rcitOrganization: 'Название в телефонном справочнике',
			mainInfo: 'Основная информация',
			documents: 'Документы',
			contacts: 'Контакты',
			title: 'Организация',
			plural: 'Организации',
			name: 'Название организации',
			fullName: 'Полное название организации',
			shortName: 'Краткое наименование',
			ownershipForm: 'Форма собственности',
			address: 'Юридический адрес',
			inputAddressPlaceholder: 'Введите улицу, дом',
			inputFioPlaceholder: 'Введите фамилию, имя и отчество',
			inputPhonePlaceholder: 'Введите контактный номер',
			headName: 'ФИО Руководителя',
			headPosition: 'Должность руководителя',
			inn: 'ИНН',
			ogrn: 'ОГРН',
			kpp: 'КПП',
			comment: 'Комментарий',
			externalId: 'ID организации во внешней системе',
			externalData: 'Содержимое организации из внешней системы',
			position: 'Должность',
			description: 'Информация об организации',
			info: 'Основные сведения',
			factAddress: 'Фактический адрес',
			secondaryInfo: 'Дополнительные сведения',
			headInfo: 'Данные о руководителе',
			okato: 'ОКАТО',
			oktmo: 'ОКТМО',
			okved: 'ОКВЭД',
			okpo: 'ОКПО',
			okopf: 'ОКОПФ',
			okogu: 'ОКОГУ',
			okfs: 'ОКФС',
			rngi: 'РНГИ / реестровый номер',
			noStaff: 'Сотрудники не найдены',
			noRequests: 'Заявки не найдены',
			noBankAccounts: 'Банковские счета не найдены',
			contractor: 'Подрядчик',
			activityType: 'Вид деятельности',
			group: 'Группа организаций',
			contragentType: 'Вид контрагента',
			type: 'Тип объекта учета',
			status: 'Статус организации',
			postAddress: 'Почтовый адрес',
			linkTop: 'Ссылка на вершину',
			inputPlaceholder: 'Например: Управление',
			inputPositionPlaceholder: 'Например: Руководитель',
			higherLevelOrganization: 'Вышестоящая организация',
			additional: 'Дополнительно',
			structure: {
				style: 'Стилизация элементов',
				background: 'Цвет фона',
				colorText: 'Цвет текста',
				colorBranch: 'Цвет ветвей',
				apply: 'Применить',
				imageStructure: 'Изображение структуры',
				useImage: 'Использовать изображение',
				structureDescription: 'Дополнительное описание',
				saveStructureDescription: 'Сохранить описание',
				defaultStyles: 'Стили по умолчанию',
				parentItem: 'Родительский элемент',
			},
		},
		topic: {
			title: 'Рубрика',
			plural: 'Рубрики',
		},
		history: {
			organizationCreate: 'создал(а) организацию',
			organizationUpdate: 'изменил(а) поля',
			update: 'изменил(а)',
			to: 'на',
		},
		discussionsNPA: {
			title: 'Проекты нормативно-правовых актов',
			discussionGoingOn: 'Идет обсуждение',
			discussionExpiresSoon: 'Обсуждение скоро истекает',
			discussionEnd: 'Обсуждение завершено',
			searchByDocument: 'Поиск по документам и его номеру',
			developmentEnd: 'Завершена процедура ОРВ',
			documentsFound: 'Найдено документов:',
			note: 'Примечание',
			name: 'Наименование НПА',
			status: 'Статус',
			procedure: 'Процедура',
			stage: 'Этап',
			text: 'Описание',
			department: 'Ведомство',
			urlFiles: 'Ссылка на прикрепленные файлы',
			urlResources: 'Ссылка на ресурс, где проводится обсуждение',
			type: 'Вид проекта',
			address: 'Почтовый адрес для направления заключения',
			contactPerson: 'Контактное лицо',
			plural: 'Проекты НПА',
			formsArrayFirst: 'документ',
			formsArraySecond: 'документа',
			formsArrayThird: 'документов',
		},
		discussionsnpastatus: {
			title: 'Статус',
		},
		discussionsNPAProcedure: {
			stages: 'Этапы',
		},
		discussions: {
			title: 'Опрос',
			titlePlural: 'Опросы',
			plural: 'Опросы',
			goToOther: 'Перейти к другим опросам',
			formsArrayFirst: 'опрос',
			formsArraySecond: 'опросы',
			formsArrayThird: 'опросов',
		},
		guidelinesRegulations: {
			title: 'Руководство и регламент',
			manualsInstructions: 'Руководства, инструкции',
			checklist: 'Контрольные листы',
			externalRegulations: 'Внешние регламенты',
			nameGuideline: 'Название руководства',
			nameChecklist: 'Название контрольного листа',
			nameRegulation: 'Название регламента',
			checklistChapter: 'Контрольные листы разделов',
			addressUrl: 'Адрес URL',
		},
		LOGIN_FAILED: 'Неверный логин/пароль',
		LOGIN_WAIT: 'Превышен лимит попыток',
		LOGIN_EMPTY_CAPTCHA: 'Не введен код',
		LOGIN_INVALID_CAPTCHA: 'Неверный код',
		captcha: 'Код на картинке',
		checkCaptcha: 'Проверить капчу',
		inputCaptcha: 'Введите капчу',
		wait: 'Подождите',
		seconds: 'секунд',
		noImage: 'Фото нет',
		linksFeed: {
			plural: 'Лента ссылок',
		},
		linksfeedcategory: {
			title: 'Категория',
		},
		cookie: {
			title: 'Мы используем файлы «cookie» для улучшения сервиса и скорости загрузки',
			buttonText: 'Понятно',
			additionalText: 'Оставаясь с нами, вы соглашаетесь на использование',
			linkText: 'файлов cookie',
		},
		article: {
			priority: 'Приоритет вывода',
		},
		subMenu: {
			more: 'Еще',
			hide: 'Скрыть',
		},
		module: 'Модуль',
		pageToolbar: {
			requiredPages: 'Обязательные страницы',
			expandPages: 'Раскрыть все',
		},
		templateToolbar: {
			updateSites: 'Обновить на сайтах',
			sites: 'Сайты',
			selectedCountSites: 'Выбрано: {{countSites}}',
			successMessage: 'Сайты обновлены',
			error: 'Произошла ошибка',
		},
		selectAll: 'Выбрать все',
		file: {
			moveOrSelectFile: 'Переместите или выберите файл',
		},
		checklist: {
			number: '№',
			createByMenu: 'Сформировать контрольный лист',
			error: 'Ошибка обновления контрольного листа',
			successCreate: 'Контрольный лист обновлен',
			dataSection: 'Разделы сведений',
			dataSubsection: 'Подразделы сведений',
			data: 'Сведения',
			dataType: 'Вид сведений',
			publishPeriods: 'Периоды публикаций',
			controls: 'Средства контроля',
			responsibleSpecialists: 'Сведения по ответственным специалистам',
			variableLegalBasis: 'Нормативно-правовое обоснование',
			link: 'Ссылка',
			createdAt: 'Дата ввода пункта',
			errorUpdateSites: 'Ошибка обновления чеклистов сайтов',
			successUpdateSites: 'Сайты успешно обновлены',
			isRequirePage: 'Обязательная страница',
			nextDateUpdate: 'Следующая ожидаемая дата обновления',
			textPublishPeriods: 'Текст периодичности обновления',
			nextCheckDate: 'Следующая дата проверки',
			updateFrequency: 'Периодичность обновления',
		},
		export: 'Экспорт',
		reportingYear: 'Отчетный год',
		currentYear: 'Текущий год',
		nextReportingYear: 'Следующий за отчетным годом',
		lastYear: 'Прошлый год',
		documentExists: 'Документ с таким типом, принимающим органом, датой подписания и номером уже существует.',
	},
};
