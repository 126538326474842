export const FILTER_VACANCY = {
	include: [
		{ relation: 'county', scope: { fields: ['id', 'name'] } },
		{ relation: 'status', scope: { fields: ['id', 'name'] } },
		{ relation: 'branch', scope: { fields: ['id', 'name'] } },
		{ relation: 'education', scope: { fields: ['id', 'name'] } },
		{ relation: 'workExp', scope: { fields: ['id', 'name'] } },
		{ relation: 'organization', scope: { fields: ['id', 'name'] } },
		{ relation: 'attachments', scope: { fields: ['id', 'filename'] } },
		{ relation: 'workExp', scope: { fields: ['id', 'name'] } },
	],
};
