import React from 'react';
import { Router, Route, Switch, StaticRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { InnerPage, ScrollButton } from './components';
import { MainPage } from './pages/main';
import store, { isBrowser } from './store';
import { clipboardCopyrights } from './helpers';
import { INNER_PAGE_ROUTES } from './pages/routes';
import { PageNotFound, PageForRoute } from './pages';
import '@smartplatform/ui/src/style/vars.scss';
import './styles/main.scss';
import 'bootstrap/dist/css/bootstrap-grid.css';
import { observable } from 'mobx';
import { MOBILE_WIDTH, TABLET_WIDTH, ZOOM_WIDTH } from 'constants';

@observer
export default class App extends React.Component {
	@observable showScrollTopButton = false;
	constructor(props) {
		super(props);
		store.history.listen(this.onRouteChange);
		this.setZoomCSS();
	}

	onRouteChange = (location, action) => {
		store.ui.onClosePortalMap();
		if (action === 'PUSH') {
			store.ui.title = '';
			window.scrollTo(0, 0);
		}
	};

	setZoomCSS = () => {
		document.querySelector('body').style.zoom = window.innerWidth <= TABLET_WIDTH && window.innerWidth > MOBILE_WIDTH ? window.innerWidth / ZOOM_WIDTH : 1;
	};

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		const scrollTop = window.scrollY;
		const showThreshold = 200;
		this.showScrollTopButton = scrollTop > showThreshold;
	};

	render() {
		document.oncopy = clipboardCopyrights;
		const _Router = isBrowser ? Router : StaticRouter;
		return (
			<_Router history={store.history} location={this.props.location} key={store.appKey}>
				{this.showScrollTopButton && <ScrollButton />}
				{
					store.isInitialized ? (
						<Switch>
							{!store.ui.site.publish ? <PageNotFound /> : <Route path='/' exact component={MainPage} />}
							<Route>
								<InnerPage>
									{INNER_PAGE_ROUTES.map(({ path, component }) => (
										<Route key={path} path={path} component={component} />
									))}
									<Route key='/:text' path='/:text'>
										<PageForRoute route={store.route.path} />
									</Route>
								</InnerPage>
							</Route>
						</Switch>
					) : (
						'...'
					) // временно, индикация того что приложение работает, но store.isInitialized = false
				}
			</_Router>
		);
	}
}
