import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import AboutRepublicStore from 'client/pages/about-republic/store';
import { Link } from 'react-router-dom';
import { SearchPage } from '../../components/search-page';
import { Picture, SectionCard, AutoAnimate, ShowMoreButton } from 'components';
import { LatestMaterials } from './latest-materials';
import t from 'i18n';

@observer
export class AboutRepublic extends Component {
	constructor(props) {
		super(props);
		this.store = new AboutRepublicStore();
	}

	render() {
		const {
			aboutRepublicRecord,
			latestMaterials,
			categoryMaterials,
			infoAboutRepublicCategory,
			isLoading,
			onSearch,
			search,
			showMoreMaterials,
			showMoreButtonVisible,
		} = this.store;
		if (isLoading) return null;
		return (
			<Provider store={this.store}>
				<>
					<SearchPage headerName={t('aboutRepublic.title')} placeholder={t('aboutRepublic.searchMaterial')} search={search} onChangeSearch={onSearch} />
					<AutoAnimate className='about-republic-container'>
						{!search && (
							<div className='about-republic-info'>
								{aboutRepublicRecord && (
									<div className='about-republic'>
										<Picture src={aboutRepublicRecord.downloadFile('image')} width={400} />
										<div className='about-republic-description'>
											<div className='description'>{aboutRepublicRecord.description?.replace(/(<([^>]+)>)/gi, '')}</div>
											<Link to='about-republic/general-information' className='read-more' children={t('readMore')} />
										</div>
									</div>
								)}
								{infoAboutRepublicCategory.materials().length > 0 && (
									<SectionCard title={infoAboutRepublicCategory.name} className='info-about-republic-materials' icon='article'>
										{infoAboutRepublicCategory.materials().map((item) => (
											<Link to={`/about-republic/${item.id}`} className='items' key={item.id}>
												{item.title}
											</Link>
										))}
									</SectionCard>
								)}
							</div>
						)}

						<div className='materials-list'>
							<AutoAnimate className='categories'>
								{categoryMaterials.length > 0 ? (
									categoryMaterials?.map((category) => (
										<React.Fragment key={category.id}>
											<SectionCard title={category.name} icon='article' className='category-section-wrapper'>
												<div className='category-materials-wrapper'>
													{category.materials()?.map((record) => (
														<Link to={`/about-republic/${record.id}`} className='material' key={record.id}>
															<Picture src={record.downloadFile('image') || noImage} alt='image' />
															<div className='title-description'>
																<div className='title'>{record.title}</div>
																<div className='description' dangerouslySetInnerHTML={{ __html: record.description?.replace(/(<([^>]+)>)/gi, '') }} />
															</div>
														</Link>
													))}
												</div>
											</SectionCard>
											{!category.showButton && (
												<ShowMoreButton
													isLoading={isLoading}
													records={category.materials()}
													showMore={() => showMoreMaterials(category)}
													recordsToDisplay={4}
													noTotal
												/>
											)}
										</React.Fragment>
									))
								) : (
									<span>{t('noData')}</span>
								)}
							</AutoAnimate>
							<LatestMaterials records={latestMaterials} />
						</div>
					</AutoAnimate>
				</>
			</Provider>
		);
	}
}
