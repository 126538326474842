import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import PageTree from './PageTree';
import proccessLinks from './links';
import store from '../../store';
import { PageNotFound } from '../404';

@observer
export default class PageForRoute extends React.Component {
	@observable page = null;
	@observable error = null;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.route !== this.props.route) {
			this.init();
		}
	}

	init = async () => {
		const { error, page } = await store.model.Page.findPage({ route: this.props.route, languageId: store.local.languageId, siteId: store.ui.site.id });
		if (!error) {
			this.page = page;
		} else {
			this.error = error;
		}
	};

	onMutation = () => {
		proccessLinks(this.el);
	};

	onMount = (el) => {
		if (el) {
			this.el = el;
			const observer = new MutationObserver(this.onMutation);
			observer.observe(el, {
				childList: true,
				subtree: true,
			});
			proccessLinks(el);
		}
	};

	render() {
		if (!this.page && this.error) return <PageNotFound />;
		if (!this.page) return null;

		return <PageTree tree={this.page} onMount={this.onMount} />;
	}
}
