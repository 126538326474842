import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const PollChart = ({ data }) => (
	<ResponsivePie
		data={data}
		sortByValue
		margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
		radialLabelsLinkDiagonalLength={0}
		colors={{ scheme: 'category10' }}
		fit
	/>
);

export default PollChart;
